<template>
  <v-app>
    <chat-widget
      class="chat-widget"
      v-if="isAppHeader && $route.name != 'create-post'"
    ></chat-widget>
    <app-header
      v-if="
        isAppHeader &&
        $route.name != 'signup' &&
        $route.name != 'login' &&
        $route.name != 'complete-signup' &&
        $route.name != 'home-page' &&
        $route.name != 'login-phone' &&
        $route.name != 'login-email' &&
        $route.name != 'thank-you' &&
        $route.name != 'lite' &&
        $route.name != 'accept-invite' &&
        $route.name != 'store-invitation-accepted'
      "
    ></app-header>
    <v-main>
      <router-view />
    </v-main>
    <Confirm></Confirm>
    <Alert></Alert>
    <SnackBar></SnackBar>
    <Loader></Loader>
    <!-- <install-pwa-dialog @install="installPWA()" v-if="pwaDialog" />
    <pwa-update-dialog /> -->
  </v-app>
</template>

<script>
import { onAuthStateChanged } from "firebase/auth";
import AppHeader from "./components/AppHeader.vue";
import ChatWidget from "./components/ChatWidget.vue";
import Alert from "./components/ui/alert/Alert.vue";
import Confirm from "./components/ui/confirm/Confirm.vue";
import SnackBar from "./components/ui/snackbar/SnackBar.vue";

import { auth, colUsers } from "./utils/firebase.utils";
import { doc, onSnapshot } from "firebase/firestore";
import Loader from "./components/ui/loader/Loader.vue";
import InstallPwaDialog from "./components/InstallPwaDialog.vue";
import { eventBus } from "./utils/common.utils";
import PwaUpdateDialog from "./components/PwaUpdateDialog.vue";
export default {
  name: "App",
  components: {
    Alert,
    AppHeader,
    Confirm,
    SnackBar,
    Loader,
    ChatWidget,
    InstallPwaDialog,
    PwaUpdateDialog,
  },
  computed: {
    isAppHeader() {
      return this.$route.meta.isFly == true;
    },
  },
  data: () => ({
    unsubscribe: null,
    deferredPrompt: null,
    pwaDialog: false,
  }),
  mounted() {
    this.captureEvent();
    onAuthStateChanged(auth, (user) => {
      this.$store.commit("SET_AUTH", user != null);
      if (user) {
        this.$store.dispatch("fetchAuthUser");
        this.unsubscribe = onSnapshot(doc(colUsers, user.uid), (snap) => {
          this.$store.commit("SET_USER", snap.data() || {});
        });
      }
      else{
        
      }
    });
    eventBus.$on("openPwa", () => {
      console.log(this.deferredPrompt);
      if (this.deferredPrompt) {
        this.pwaDialog = true;
      }
    });

    // Add a before guard to the router
  },

  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  },
  beforeMount() {
    this.$store.dispatch("setCurrentLocation");
  },
  methods: {
    captureEvent() {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
      });
    },
    // installPWA() {
    //   this.pwaDialog = false;
    //   this.deferredPrompt.prompt();
    //   this.deferredPrompt.userChoice.then((choiceResult) => {
    //     if (choiceResult.outcome === "accepted") {
    //       // Call another function?
    //     }
    //     this.deferredPrompt = null;
    //   });
    // },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/reset.scss";

.border-bottom {
  border-bottom: 1px solid #e1e3e6 !important;
}

.app-header .v-toolbar__content {
  @extend .border-bottom;
}
</style>
