<template>
  <div>
    <!-- <v-subheader small>Zoho Chat</v-subheader> -->
  </div>
</template>

<script>
export default {
  mounted() {
    var zohoChatScript = document.createElement("script");
    zohoChatScript.type = "text/javascript";
    zohoChatScript.id = "zsiqchat";
    zohoChatScript.innerHTML = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siqdb75ba8b69a8bd26b7c978622a412410c18a41b0727eb7e9829caf93fc97a68c1ee7fb0789fccb1609214c583700732b", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.in/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`;
    document.body.appendChild(zohoChatScript);
    const vm = this;
    $zoho.salesiq.ready = function () {
      console.log(vm.isMobile);
      if (vm.isMobile) {
        $zoho.salesiq.floatbutton.visible("hide");
      } else {
        $zoho.salesiq.floatbutton.visible("hide");
        $zoho.salesiq.floatbutton.position("bottomleft");
      }
    };
  },
};
</script>
