<template>
  <v-container style="max-width: 1000px">
    <div class="flex flex-col justify-center py-3">
      <h2
        class="p-3 pb-2 mb-4 text-5xl font-semibold text-center text-transparent border-b-2 rounded-md border-gradient bg-clip-text bg-gradient-to-r from-pink-500 to-blue-800"
      >
        The people behind Fly <i class="fa fa-paper-plane"></i>
      </h2>
      <div class="flex flex-wrap justify-around mb-4">
        <!-- Team Member 1 -->
        <div class="mb-8 text-center">
          <div class="card">
            <img
              src="@/assets/img/anand-2020_720.png"
              alt="Team Photo"
              class="object-cover mx-auto mb-4 rounded-full h-44 w-44"
            />
            <h2 class="mb-2 text-xl font-semibold">ANAND MAHAJAN</h2>
            <p class="text-gray-600">
              <span class="font-bold text-blue-400">Co-founder & CEO</span>
              <br />
              <a
                href="https://www.linkedin.com/in/anandmahajan84/"
                target="_blank"
              >
                <img
                  src="../../assets/img/home-page/linkedin.svg"
                  alt="LinkedIn"
                  class="inline-block"
                />
              </a>
            </p>
          </div>
        </div>

        <!-- Team Member 2 -->
        <div class="mb-8 text-center">
          <div class="card">
            <img
              src="@/assets/img/dhiraj_480.jpg"
              alt="Team Photo"
              class="object-cover mx-auto mb-4 rounded-full h-44 w-44"
            />
            <h2 class="mb-2 text-xl font-semibold">DHIRAJ NAIR</h2>
            <p class="text-gray-600">
              <span class="font-bold text-blue-400">Co-founder & CTO</span>
              <br />
              <a href="https://www.linkedin.com/in/dhirajnair/" target="_blank">
                <img
                  src="../../assets/img/home-page/linkedin.svg"
                  alt="LinkedIn"
                  class="inline-block"
                />
              </a>
            </p>
          </div>
        </div>

        <!-- Team Member 3 -->
        <div class="mb-8 text-center">
          <div class="card">
            <img
              src="@/assets/img/Manu.png"
              alt="Team Photo"
              class="object-cover mx-auto mb-4 rounded-full h-44 w-44"
            />
            <h2 class="mb-2 text-xl font-semibold">MANU MANI</h2>
            <p class="text-gray-600">
              <span class="font-bold text-blue-400"
                >Co-founder & Head Business Development</span
              ><br />
              <a
                href="https://www.linkedin.com/in/manu-mani-b69b47107/"
                target="_blank"
              >
                <img
                  src="../../assets/img/home-page/linkedin.svg"
                  alt="LinkedIn"
                  class="inline-block"
                />
              </a>
            </p>
          </div>
        </div>

        <!-- Team Member 4 -->
        <div class="mb-8 text-center">
          <div class="card">
            <img
              src="@/assets/img/pareshmadani_720.png"
              alt="Team Photo"
              class="object-cover mx-auto mb-4 rounded-full h-44 w-44"
            />
            <h2 class="mb-2 text-xl font-semibold">PARESH MADANI</h2>
            <p class="text-gray-600">
              <span class="font-bold text-blue-400">Advisor / Mentor</span>
              <br /><a
                href="https://www.linkedin.com/in/pareshpmadani/"
                target="_blank"
              >
                <img
                  src="../../assets/img/home-page/linkedin.svg"
                  alt="LinkedIn"
                  class="inline-block"
                />
              </a>
            </p>
          </div>
        </div>
      </div>

      <h2
        class="p-3 pb-3 mb-4 text-3xl font-semibold text-center text-transparent border-b-2 rounded-md border-gradient bg-clip-text bg-gradient-to-r from-pink-500 to-blue-800"
      >
        Contact Us
      </h2>
      <v-card class="mb-6" max-width="600">
        <v-card-text>
          <div class="mb-6 cursor-pointer" @click="openEmail">
            <v-icon color="primary" class="mr-5">mdi-email</v-icon>
            <strong class="text-lg font-bold">Email Address:</strong>
            <span class="block mt-1 ml-10 font-semibold">
              fly@zoomlocal.in
            </span>
          </div>
          <div
            @click="openWhatsApp"
            class="flex items-center mb-6 cursor-pointer"
          >
            <v-icon color="success" class="mr-4">mdi-whatsapp</v-icon>
            <strong class="text-lg font-bold" style="margin-right: 8px"
              >+91 9881474184</strong
            >
            <span class="block mt-1 font-semibold"> </span>
          </div>

          <div class="mb-6">
            <v-icon color="primary" class="mr-5">mdi-map-marker</v-icon>
            <strong class="text-lg font-bold">Operating Address:</strong>
            <span class="block mt-4 ml-10 font-semibold">
              A-806, Sapphire Park, Park Street,<br />
              Wakad, Pune, State: Maharashtra,<br />
              India 411057
            </span>
          </div>

          <div>
            <v-icon color="primary" class="mr-5">mdi-office-building</v-icon>
            <strong class="text-lg font-bold">Legal Name:</strong>
            <span class="block mt-1 ml-10 font-semibold">
              ZoomLocal Tech Private Limited
            </span>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "AboutUs",
  methods: {
    openWhatsApp() {
      window.location.href = "https://api.whatsapp.com/send?phone=919881474184";
    },
    openEmail() {
      window.location.href = "mailto:fly@zoomlocal.in";
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
/* Add your custom styles here */
.border-gradient {
  border-image: linear-gradient(to right, #ffd700, #ff6347);
  /* Adjust colors as needed */
  border-image-slice: 1;
}

.card {
  background: linear-gradient(to bottom, #f9f9f9 50%, transparent 50%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

.v-card {
  background-color: #ffffff;
  /* Set the background color of the card */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.v-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.v-icon {
  margin-right: 8px;
  font-size: 20px;
}

.text-3xl {
  font-size: 2rem;
  font-weight: bold;
}

/* Increased font size and boldness for contact details */
.text-lg {
  font-size: 1.25rem;
  font-weight: bold;
}
</style>
