<template>
  <input
    v-model="input"
    :dense="dense"
    ref="input"
    v-bind="$attrs"
    :value="input"
    @keyup.enter="$emit('enter')"
   
    outlined
    :class="label ? 'mt-1 ' : ''"
    label=""
    @blur="$emit('blur')"
    @click:clear="$emit('clear')"
    :rules="getRules"
    @input="$emit('input', input)"
    class="w-full relative rounded bg-gray-100 box-border h-[46px] border-[1px] border-solid border-stroke pl-2"
  />
</template>

<script>
import inputMixin from "@/mixins/input.mixin.js";
export default {
  mixins: [inputMixin],
};
</script>

<style scoped>
.no-bottom-border .v-input__slot::after {
  content: ""; /* Create a pseudo-element */
  position: absolute; /* Position it absolutely */
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px; /* Set its height to the same as the bottom border */
  background-color: #fff; /* Set background color to match the background */
  box-shadow: 0px -1px 0px #fff; /* Use box-shadow to hide the bottom border */
}
</style>
