import { onAuthStateChanged, signOut } from "firebase/auth";
import store from "../../store/index.js";
import { auth, colAdmins, colUsers } from "@/utils/firebase.utils.js";
import { doc, getDoc } from "firebase/firestore";
export const isAuth = (_to, _from, next) => {
  let unsubscribe = onAuthStateChanged(auth, (user) => {
    store.commit("SET_AUTH", user != null);
    unsubscribe();
    console.log(user,"this is user")
    if (user) {
      getUser(user)
        .then(() => {
          next();
        })
        .catch((e) => {
          console.log(e,"this is the error")
          signOut(auth);
          next("/login");
        });
    } else {
      sessionStorage.setItem('redirectPath', _to.fullPath);
      
      next("/login");
    }
  });
};

export const checkTeamMember = (_to, _from, next) => {
  if (store.state._teamMemberAdded) {
    next();
  } else {
    next('/');
  }
};
export const invitationAccepted = (_to, _from, next) => {
  if (store.state._acceptInvite) next();
  else next('/');
};

export const checkClientRole = (_to, _from, next) => {
  const currentWorkspace = store.getters.currentWorkspace
  let unsubscribe = onAuthStateChanged(auth, (user) => {
    unsubscribe();
    if (user) {
      if (currentWorkspace && currentWorkspace?.role=="Client") {
        next("/calendar");
      }
      else{
          next();
      }
    } else {
      
      next("/login");
    }
  });
  
}

export const isNotAuth = (_to, _from, next) => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    store.commit("SET_AUTH", user != null);
    if (user) {
      getUser(user)
        .then(() => {
          let redirect = _to.query.redirect;
          if (redirect) {
            next(redirect);
          } else {
            next("/");
          }
        })
        .catch(() => {
          signOut(auth);
          next("/login");
        });
    } else {
      if (_to.name == "complete-signup") {
        next("/login");
      } else {
        next();
      }
    }
    unsubscribe();
  });
};

export const isGuest = (_to, _from, next) => {
  let unsubscribe = onAuthStateChanged(auth, (user) => {
    store.commit("SET_AUTH", user != null);
    unsubscribe()
    if (user) {
      getUser(user)
        .then(() => {
          next();
        })
        .catch(() => {
          next();
        });
    } else {
      next();
    }
  });
};
export const isAdminAuth = (_to, _from, next) => {
  let unsubscribe = onAuthStateChanged(auth, (user) => {
    store.commit("SET_AUTH", user != null);
    unsubscribe()
    if (user) {
      getUser(user)
        .then(() => {
          let isAdmin = store.getters.isAdmin;
          if (isAdmin) {
            next();
          } else {
            next("/");
          }
        })
        .catch(() => {
          signOut(auth);
          next("/login");
        });
    } else {
      next("/login");
    }
  });
};

const getUser = (user) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let storeUser = store.getters.authUser;
    if (storeUser && Object.keys(storeUser).length != 0) {
      resolve(storeUser);
      return;
    }
    try {
      let userDoc = (await getDoc(doc(colUsers, user.uid))).data();
      if (userDoc == null) {
        return reject({ message: "User not found" });
      }
      let adminDoc = (await getDoc(doc(colAdmins, user.uid))).data() || {};
      store.commit("SET_USER", userDoc);
      store.commit("SET_IS_ADMIN", adminDoc.status === true);
      resolve(userDoc);
    } catch (error) {
      reject(error);
    }
  });
};