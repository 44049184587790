<template>
  <div style="width: 50%">
    <button block depressed text color="primary" @touchstart.prevent="dialog = true" @click.prevent="dialog = true" class="-mr-4 pr-2"
      > <img
            src="@/assets/img/pencil.svg"
            alt="translate"
            width="20px"
            style="max-width: 100%; height: auto;"
            class=" mt-2 mr-3"
          /></button
    >
    <v-dialog
      persistent
      v-model="dialog"
      fullscreen
      hide-overlay
      scrollable
      v-if="dialog"
    >
      <dialog-card :isSubmit="false" @close="dialog = false">
        <update-gallery-image :id="id" @onEdit="onEdit" />
      </dialog-card>
    </v-dialog>
  </div>
</template>

<script>
import UpdateGalleryImage from "@/pages/fly/UpdateGalleryImage.vue";
import DialogCard from "../ui/DialogCard.vue";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: { DialogCard, UpdateGalleryImage },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    onEdit(event) {
      this.dialog = false;
      this.$emit("onEdit", event);
    },
  },
};
</script>

<style></style>
