<template>
    <form-wrapper ref="form" @submit="$emit('submit')">
      <v-card class="pa-2">
        <v-card-title class="black--text pb-3 pt-3">
          <slot name="title">
            <div class="w-[18.88px] h-[21px] relative mr-2">
            <img
              src="@/assets/img/webai.svg"
              :width="isSmallAndUp ? '25px' : '16px'"
              :height="isSmallAndUp ? '25px' : '16px'"
              max-height="100%"
            />
          </div>
          <span :class="!isSmallAndUp ? 'text-sm' : ''"> {{ title }}</span>
            <v-spacer />
            
            <v-btn
              
              icon
              color="black"
              @click="$emit('close')"
              :disabled="loading"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </slot>
        </v-card-title>
        <v-card-subtitle class="black--text pt-2">
          {{ message }}
        </v-card-subtitle>
        <v-card-text class="pb-0">
          <slot></slot>
        </v-card-text>
        <v-card-actions class="mr-2" v-if="isSubmit">
          <v-spacer></v-spacer>
          <slot name="footer">
            <!-- <v-btn text :disabled="loading" color="red" @click="$emit('close')"
              >Close</v-btn
            > -->
            <v-btn :loading="loading"
            text
            type="submit"
            class="w-[139.30px] mt-3 py-5 h-12 bg-violet-500 rounded-xl"
            color="white">{{
              submitText
            }}</v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </form-wrapper>
  </template>
  
  <script>
  import FormWrapper from "./form/FormWrapper.vue";
  export default {
    components: { FormWrapper },
    props: {
      isSubmit: {
        type: Boolean,
        default: true,
      },
      submitText: {
        type: String,
        default: "Submit",
      },
      loading: {
        type: Boolean,
        default: false,
      },
      title: {},
      message: {},
      source: String,
    },
    methods: {
      reset() {
        this.$refs.form.reset();
      },
    },
  };
  </script>
  
  <style></style>
  