<template>
  <v-container>
    <!-- Card Container -->
    <v-card class="elevation-4 mx-auto" max-width="650" outlined>
      <!-- Card Title -->
      <v-card-title class="text-center text-primary headline font-weight-bold">
        <v-icon left color="primary">mdi-account-multiple-plus</v-icon>
        {{isEdit?`Edit User Workspaces`:`Invite to Workspace` }}
      </v-card-title>

      <!-- Divider -->
      <v-divider></v-divider>

      <!-- Team Member Details Section -->
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title><strong>Team Member Name:</strong></v-list-item-title>
                <v-list-item-subtitle>{{ addedUser.display_name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title><strong>Team Member Email:</strong></v-list-item-title>
                <v-list-item-subtitle>{{ addedUser.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>

        <!-- Workspaces Section -->
        <v-card class="mt-3 elevation-1 py-3 px-4 bg-lightblue lighten-4">
          <p class="mb-2 text-center">
            Select the workspaces you want to share with 
            <strong>{{ addedUser.display_name }}</strong>
          </p>

          <v-form>
            <!-- Workspace List with Roles -->
            <v-row v-for="workspace in workspaces" :key="workspace.id" class="align-center mb-4">
              <v-col cols="4" class="d-flex align-center">
                <v-checkbox 
                  :label="workspace.name" 
                  v-model="selectedWorkspaces" 
                  :value="workspace.id"
                  color="primary"
                ></v-checkbox>
              </v-col>
              <v-col cols="8">
                <v-radio-group 
                  v-if="selectedWorkspaces.includes(workspace.id)" 
                  v-model="roles[workspace.id]" 
                  row 
                  class="radio-group-spacing">
                  <v-radio label="Creator" :value="'Creator'" color="primary"></v-radio>
                  <v-radio label="Approver" :value="'Approver'" color="primary"></v-radio>
                  <v-radio label="Client" :value="'Client'" color="primary"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-card-text>

      <!-- Actions -->
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn outlined color="grey darken-1" @click="goback">Back</v-btn>
        <v-btn 
          :disabled="loading" 
          color="primary" 
          @click="isEdit ? updateWorkspaces() : shareWorkspaces()">
          {{ isEdit ? 'Update' : 'Share Workspaces' }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Message Dialog -->
    <v-dialog v-model="messageDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline">{{ messageTitle }}</v-card-title>
        <v-card-text><strong>{{ messageContent }}</strong></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeMessageDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { doc, getDoc, collection, getDocs, updateDoc, setDoc, arrayUnion } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import {auth, colUsers, db } from "@/utils/firebase.utils";

export default {
  data() {
    return {
      userEmail: this.$route.params.email || "",
      addedUser: {},
      workspaces: [],
      selectedWorkspaces: [],
      roles: {},
      messageDialog: false,
      messageTitle: "",
      messageContent: "",
      loading: false,
      redirect: false,
    };
  },
  async created() {
    await this.fetchAddedUser();
    await this.fetchCurrentUser();
    await this.fetchWorkspaces();
  },
  mounted(){
    if(!this.$store.state._teamMemberAdded){
      this.$snackbar.show("Direct access is prohibited");
      this.$router.push("/team")
    }
  },
  async beforeMount(){
    if(this.isEdit){
      const workspaceTeamSnap = await getDoc(doc(colUsers, auth.currentUser.uid,'team',this.$route.params.uid));
      if(workspaceTeamSnap.exists()){
        const workspaceTeamData = workspaceTeamSnap.data();
        workspaceTeamData.workSpaceToBeAdded.workspaceData.map((ws)=>{
          this.selectedWorkspaces.push(ws.wid);
          this.roles[ws.wid] = ws.role;
        })
      }
    }
  },
  computed:{
    isEdit(){
      if(this.$route.params.edit) return true;
      return false;
    },
  },
  methods: {
    async goback() {
      this.$router.push({ name: 'team' });
    },
    async fetchAddedUser() {
      this.$loader.show();
      if(this.isValidEmail(this.$route.params.uid)) {
        this.addedUser={
          email : this.$route.params.uid,
          display_name: "Guest"
        }
        return ;
      }
      const addedUserDoc = await getDoc(doc(colUsers, this.$route.params.uid));
      this.addedUser = addedUserDoc.data();
      // const teamUserDoc = await getDoc(doc(db, "users", auth.currentUser.uid, "team", this.$route.params.uid));
      // if (teamUserDoc.exists()) {
      //   const teamData = teamUserDoc.data();
      //   this.selectedWorkspaces = teamData.workSpaceToBeAdded.workspaceData.map(ws => ws.wid);
      //   this.roles = teamData.workSpaceToBeAdded.workspaceData.reduce((acc, ws) => {
      //     acc[ws.wid] = ws.role;
      //     return acc;
      //   }, {});

      // }
    },
    async fetchCurrentUser() {
      const currentUserId = auth.currentUser.uid;
      const currentUserDoc = await getDoc(doc(colUsers, currentUserId));
      if (currentUserDoc.exists()) {
        this.currentUser = currentUserDoc.data();
      }

    },
    async fetchWorkspaces() {
      const currentUserId = auth.currentUser.uid;
      const workspaceRef = collection(db, "users", currentUserId, "fly_workspaces");
      const workspaceSnapshot = await getDocs(workspaceRef);
      this.workspaces = workspaceSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter(doc => doc.role !== 'Approver' && doc.role !== 'Creator' && doc.role!=='Client' && !doc?.storeWorkspace); // Adjusted filter condition
      this.$loader.hide();
    },
    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    async shareWorkspaces() {
      this.$loader.show()
      if (this.selectedWorkspaces.length === 0) {
        this.showMessageDialog("Required", "Please select at least one workspace.");
        this.$loader.hide()
        return;
      }

      for (let workspaceId of this.selectedWorkspaces) {
        if (!this.roles[workspaceId]) {
          const workspaceName = this.workspaces.find(w => w.id === workspaceId).name;
          this.showMessageDialog("Required", `Please select a role for ${workspaceName} workspace.`);
          this.$loader.hide()
          return;
        }
      }

      this.loading = true; // Set loading to true

      const sendTeamInvite = httpsCallable(functions, "sendTeamInviteFunction");
      const payloadWS = Object.keys(this.roles).reduce((acc, key) => {
        const ws = this.workspaces.find(w => w.id === key);
        if (ws) {
          acc[key] = [ws.name, this.roles[key]];
        }
        return acc;
      }, {});

      try {
        const payload = {
          uid: this.$route.params.uid,
          workspaces: payloadWS, // Assuming you want to send invite for the first selected workspace
          email: this.addedUser.email,
          sender_id: auth.currentUser.uid,
        }

        const res = await sendTeamInvite(payload);

        if (res.data.success) {
          this.showMessageDialog("Success", "Invitation sent successfully", true);
        } else {
          this.showMessageDialog("Failed", "Some internal error occurred", true);
        }
      } catch (error) {
        this.showMessageDialog("Failed", "Unable to add a member", true);
      } finally {
        this.loading = false; // Set loading to false
      }
      this.$loader.hide();

    },
    async updateWorkspaces(){
      this.$loader.show()
      this.loading = true; 
      if (this.selectedWorkspaces.length === 0) {
        this.showMessageDialog("Required", "Please select at least one workspace.");
        this.$loader.hide()
        this.loading = false;
        return;
      }

      for (let workspaceId of this.selectedWorkspaces) {
        if (!this.roles[workspaceId]) {
          const workspaceName = this.workspaces.find(w => w.id === workspaceId).name;
          this.showMessageDialog("Required", `Please select a role for ${workspaceName} workspace.`);
          this.loading = false;
          this.$loader.hide()
          return;
        }
      }
      const payloadWS = this.selectedWorkspaces.reduce((acc, workspaceId) => {
        const ws = this.workspaces.find(w => w.id === workspaceId);
        if (ws && this.roles[workspaceId]) {
          acc[workspaceId] = [ws.name, this.roles[workspaceId]];
        }
        return acc;
      }, {});
      const updateTeamWorkspace = httpsCallable(functions, "updateTeamWorkspace");
      try {
        const payload = {
          uid: this.$route.params.uid,
          workspaces: payloadWS, // Assuming you want to send invite for the first selected workspace
          email: this.addedUser.email,
          sender_id: auth.currentUser.uid,
        }
        console.log("worksps",payloadWS)
        console.log("worksps",this.selectedWorkspaces)
        const res = await updateTeamWorkspace(payload);

        if (res.data.success) {
          this.showMessageDialog("Success", "The workspaces were updated successfully.", true);
        } else {
          this.showMessageDialog("Failed", "Some internal error occurred", true);
        }
      } catch (error) {
        this.showMessageDialog("Failed", "Unable to add a member", true);
      } finally {
        this.loading = false; // Set loading to false
      }
      this.$loader.hide();
    },
    showMessageDialog(title, message, redirect = false) {
      this.messageTitle = title;
      this.messageContent = message;
      this.messageDialog = true;
      this.redirect = redirect;
    },
    closeMessageDialog() {
      this.messageDialog = false;
      if (this.redirect) {
        this.$router.push({ name: 'team' });
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-top: 20px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.v-card-title {
  font-size: 22px;
  color: #2c3e50;
}

.v-divider {
  margin: 10px 0;
}

.radio-group-spacing {
  display: flex;
  justify-content: space-between;
}

.text-primary {
  color: #007bff;
}

.v-btn {
  margin: 5px;
}

.bg-lightblue {
  background-color: #e3f2fd !important;
}
</style>