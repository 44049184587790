<template>
    <div>
      <label class="input-label" v-if="label">{{ label }}</label>
      <v-select
        v-model="input"
        label=""
        :items="items"
        :dense="dense"
        item-value="value"
        item-text="name"
        :class="label ? 'mt-1' : ''"
        @blur="$emit('blur')"
        append-icon="mdi-chevron-down"
        outlined
        @input="$emit('input', input)"
        v-bind="$attrs"
        :rules="getRules"
        item-disabled="disable"
        no-filter
        :menu-props="menuProps"
        @keydown="handleKeyDown"
      >
      </v-select>
    </div>
  </template>
  
  <script>
  import inputMixin from "@/mixins/input.mixin.js";
  export default {
    mixins: [inputMixin],
    props: {
      type: String,
      postTypeConfiguration: {
        type: Object,
      },
    },
    data() {
      return {
        search: "",
      };
    },
    computed: {
      menuProps() {
        return {
          minWidth: this.isMobileScreen ? "90%" : "27%",
        };
      },
      isMobileScreen() {
        return window.innerWidth <= 600;
      },
    },
  
    methods: {
      handleKeyDown(event) {
        event.preventDefault();
      },
      isObject(item) {
        return item instanceof Object;
      },
    },
  };
  </script>
  
  <style>
  .autocomplete-selection {
    white-space: nowrap;
    overflow: hidden;
  }
  </style>
  