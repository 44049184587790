<template>
  <div class="flex gap-0 px-5 -mt-3 bg-slate-100 max-md:flex-wrap">
    <div
      class="flex flex-col self-center w-full px-6 pb-12 mt-8 bg-white pt-7 rounded-3xl max-w-auto max-md:px-5 max-md:max-w-full"
    >
      <div class="text-2xl font-medium text-neutral-900">Your Account</div>
      <div
        class="flex flex-col p-6 mt-9 rounded-3xl bg-slate-100 max-md:px-5 max-md:max-w-full"
      >
        <div
          class="flex justify-between gap-5 max-md:flex-wrap max-md:max-w-full"
        >
          <div class="flex flex-col max-md:max-w-full">
            <div class="text-xl font-medium text-neutral-900 max-md:max-w-full">
              Current Subscription
            </div>
            <div
              class="flex gap-5 justify-between items-center px-3.5 py-3 mt-2 bg-white rounded-xl max-md:flex-wrap max-md:max-w-full"
            >
              <div class="flex flex-col font-medium text-neutral-700">
                <div class="text-base capitalize font-bold">
                  {{ planName }} Plan
                  {{
                    authUser.signup_offer_subscribed
                      ? "(Signup Offer)"
                      : authUser.billing_period
                      ? `(${authUser.billing_period})`
                      : ""
                  }}
                  <div class="mt-2.5 text-xs">
                    You can upgrade or modify your account subscription at any
                    time.
                  </div>
                </div>
              </div>
              <div>
                <router-link to="/subscription">
                  <div
                    class="justify-center self-start px-8 py-2.5 text-xs font-medium text-white whitespace-nowrap bg-[#3C50E0] rounded-2xl max-md:px-5"
                  >
                    Change Plan
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col p-6 mt-9 rounded-3xl bg-slate-100 max-md:px-5 max-md:max-w-full"
      >
        <div class="max-w-full w-[657px]">
          <div class="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
            <div class="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div class="flex flex-col grow max-md:mt-6">
                <div class="text-xl font-medium text-neutral-900">
                  Plan Credits
                </div>
                <!-- <div
                class="mt-10 text-xs font-medium whitespace-nowrap text-neutral-700"
              >
                Each Post Consumes 1 Balance From Your Account.
              </div> -->
                <div class="mt-6 text-xs font-medium text-slate-500">
                  <span class="font-bold">Posts</span>:
                  {{ this.fly_bl - this.authUser.fly_credits < 0 ? 0 : this.fly_bl - this.authUser.fly_credits }}
                  used out of
                  {{ this.fly_bl }}
                </div>
                <div class="chip-progress-bar rounded-3xl">
                  <div
                    class="progress"
                    :style="{ width: consumedCreditsProgress }"
                  ></div>
                </div>
                <div class="mt-6 text-xs font-medium text-slate-500">
                  <span class="font-bold">Text AI Writer Actions</span>:
                  {{ this.txt_cr - this.authUser.fly_ai_text_credits <0 ? 0 : this.txt_cr - this.authUser.fly_ai_text_credits }}
                  used out of
                  {{ this.txt_cr }}
                </div>
                <div class="chip-progress-bar rounded-3xl">
                  <div
                    class="progress"
                    :style="{ width: textCreditsProgress }"
                  ></div>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div class="flex flex-col grow mt-13 max-md:mt-10">
                <div class="text-xs font-medium text-slate-500">
                  <span class="font-bold">Storage</span>:
                  {{ this.authUser.fly_storage }}
                  GB out of
                  {{ this.maxStorage }} GB
                </div>
                <div class="chip-progress-bar rounded-3xl">
                  <div
                    class="progress"
                    :style="{ width: storageProgress }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="user_addon_credits && user_addon_credits.length > 0"
        class="flex flex-col p-6 mt-9 rounded-3xl bg-slate-100 max-md:px-5 max-md:max-w-full"
      >
        <div class="max-w-full">
          <div class="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
            <div class="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div class="flex flex-col grow max-md:mt-6">
                <div
                  class="flex justify-between flex-col md:flex-row gap-3 md:gap-0 items-start md:items-center"
                >
                  <div class="text-xl font-medium text-neutral-900">
                    Add-ons Credits
                  </div>
                  <div class="md:-mr-[0px]">
                    <router-link to="/subscription">
                      <div
                        class="justify-center self-start px-8 py-2.5 text-xs font-medium text-white whitespace-nowrap bg-[#3C50E0] rounded-2xl max-md:px-5"
                      >
                        Purchase Addons
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

              <div
                class="flex flex-col md:flex-row flex-wrap gap-5 md:justify-between max-w-full w-[657px]"
              >
                <div
                  v-for="(addon, i) in user_addon_credits"
                  :key="i"
                  class="w-full md:w-[48%]"
                >
                  <div class="mt-6 text-xs font-medium text-slate-500">
                    <span class="font-bold">{{ addon.name }}</span
                    >:
                    {{ addon.used }}
                    used out of
                    {{ addon.total }}
                  </div>
                  <div class="chip-progress-bar rounded-3xl">
                    <div
                      class="progress"
                      :style="{
                        width: consumedAddonCreditsProgress(
                          addon.remaining,
                          addon.total
                        ),
                      }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="subscriptionsHistory?.length > 0"
        class="flex flex-col p-6 mt-9 rounded-3xl bg-slate-100 max-md:px-5 max-md:max-w-full"
      >
        <div class="text-sm font-medium text-neutral-700 max-md:max-w-full">
          Billing history
        </div>
        <div
          v-if="subscriptionsHistory?.length > 0"
          class="flex gap-5 justify-between items-start py-3.5 px-10 mt-2 w-full text-xs font-medium whitespace-nowrap bg-white rounded-xl max-md:flex-wrap max-md:pr-5 max-md:max-w-full"
        >
          <div class="flex flex-col list-view-container p-3 pt-0">
            <!-- Header row for titles -->
            <div class="flex text-left bg-[#F2F6FA] p-3 rounded-[18px]">
              <div class="w-1/5 text-sm font-bold text-center text-[#636B8C]">
                Status
              </div>
              <div class="w-1/5 text-sm font-bold text-center text-[#636B8C]">
                Amount
              </div>
              <div class="w-1/6 text-sm font-bold text-center text-[#636B8C]">
                Billing Period
              </div>
              <div class="w-1/4 text-sm font-bold text-center text-[#636B8C]">
                Purchased on
              </div>
              <div class="w-1/4 text-sm font-bold text-center text-[#636B8C]">
                Cancelled on
              </div>
            </div>
            <div
              v-for="(subscription, index) in subscriptionsHistory"
              :key="index"
              class="flex items-center p-3 border-b"
            >
              <div
                class="w-1/5 pl-4 text-center text-capitalize items-center justify-center"
              >
                <span
                  class="text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold rounded-lg"
                  :class="{
                    'text-blue-500 bg-blue-100':
                      subscription.status === 'active',
                    'text-red-500 bg-red-100':
                      subscription.status === 'cancelled',
                  }"
                >
                  <span
                    v-if="
                      !isSubscriptionActive &&
                      index == 0 &&
                      subscription.status !== 'active'
                    "
                    >In progress</span
                  >
                  <v-progress-circular
                    v-if="
                      !isSubscriptionActive &&
                      index == 0 &&
                      subscription.status !== 'active'
                    "
                    indeterminate
                    :size="20"
                    :width="3"
                    class="pl-8"
                    color="primary"
                  ></v-progress-circular>
                  <span v-else>
                    {{ subscription.status }}
                  </span>
                </span>
              </div>
              <div class="w-1/5 gap-1 text-center">
                <span class="text-neutral-700">
                  {{ isINR ? "INR" : "USD" }}</span
                >
                {{ subscription.amount }}
              </div>
              <div class="w-1/5 text-center">
                {{
                  subscription.billing_period ? subscription.billing_period : ""
                }}
              </div>
              <div class="w-1/5 text-center">
                {{
                  subscription.created_at.seconds
                    ? formatDate(subscription.created_at.seconds * 1000)
                    : formatDate(subscription.created_at)
                }}
              </div>
              <div class="w-1/5 text-center">
                {{
                  subscription.status != "active"
                    ? subscription.cancelled_at?.seconds
                      ? formatDate(subscription.cancelled_at.seconds * 1000)
                      : formatDate(subscription.cancelled_at)
                    : "--"
                }}
              </div>
            </div>
          </div>
          <!-- <div class="flex items-start justify-between gap-5">
            <div class="flex flex-col">
              <div class="text-neutral-700">STATUS</div>
              <div class="mt-6 text-lime-600">
                {{
                  this.authUser.fly_plan === "free"
                    ? "active"
                    : currentSubscription.status === "created"
                    ? "in progress"
                    : currentSubscription.status
                }}
                <v-progress-circular
                  indeterminate
                  :size="20"
                  :width="3"
                  class="pl-8"
                  color="primary"
                  v-if="currentSubscription.status === 'created'"
                ></v-progress-circular>
              </div>
            </div>
            <div class="flex flex-col self-stretch">
              <div class="text-neutral-700">DATE</div>
              <div class="mt-6 text-slate-500">
                {{
                  this.authUser.fly_credits_upd_at === undefined
                    ? new Date(
                        this.authUser.created_time.seconds * 1000
                      ).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                    : new Date(
                        this.authUser.fly_credits_upd_at.seconds * 1000
                      ).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                }}
              </div>
            </div>
            <div class="flex flex-col">
              <div class="text-neutral-700">AMOUNT</div>
              <div class="mt-6 text-slate-500">
                {{
                  isINR ? "INR " + this.plan_price : "USD " + this.plan_price_usd
                }}
              </div>
            </div>
          </div> -->
          <!-- <div class="flex justify-between gap-5">
            <div class="flex flex-col">
              <div class="text-neutral-700">Subscription</div>
              <div class="mt-6 text-slate-500">
                {{ planName }}
              </div>
            </div>
            <div class="flex flex-col">
              <div class="text-neutral-700">BILLING PERIOD</div>
              <div class="mt-6 text-slate-500">Monthly</div>
            </div>
          </div> -->
          <!-- <div class="">
          <div class="text-neutral-700">RECEIPT</div>
          <div
            class="justify-center px-5 py-3 mt-4 bg-green-100 text-lime-700 rounded-2xl max-md:px-5"
          >
            Download
          </div>
        </div> -->
        </div>
        <div class="flex flex-col max-md:max-w-full" v-if="!isINR">
          <div
            class="mt-3 text-sm font-medium text-neutral-700 max-md:max-w-full"
          >
            Payment Information
          </div>
          <div
            class="flex justify-between gap-5 p-5 mt-2 bg-white rounded-xl max-md:flex-wrap max-md:max-w-full"
          >
            <payment-method-details />
          </div>
        </div>
      </div>
      <!-- <div
      class="flex flex-col items-start py-6 pl-6 pr-20 mb-20 text-xs mt-9 rounded-3xl bg-slate-100 max-md:px-5 max-md:mb-10 max-md:max-w-full"
    >
      <div class="flex gap-2 text-sm whitespace-nowrap text-neutral-700">
        <div class="font-medium grow">YouTube Data API Key</div>
        <div class="grow">(optional)</div>
      </div>
      <div class="mt-2.5 font-medium text-slate-500 max-md:max-w-full">
        In order to upload your YouTube videos on your behalf, you will need to
        add your YouTube Data API key
      </div>
       <input
        class="mt-2.5 max-w-full bg-white h-[42px] rounded-[32px] w-[618px] px-8"
        v-model="youtubeapikey"
            id="youtubeApiKey"
           
            :placeholder="this.authUser.yt_data_api_key"
      />
      <div
      @click="updateYoutubeApiKey"
        class="justify-center px-8 py-3 mt-3 font-medium text-white cursor-pointer whitespace-nowrap bg-violet-500 rounded-2xl max-md:px-5"
      >
        Save
      </div>
    </div> -->
      <!-- <div class="p-4 mb-4 bg-white rounded-lg shadow-md">
        <h2 class="mb-2 text-lg font-semibold">Your Current Plan</h2>
        <div class="flex items-center mb-2">
          <p class="mr-2">Plan:</p>
          <p class="font-mono capitalize">
            {{ this.authUser.fly_plan ?? "Free" }}
          </p>
        </div>
        <div class="flex items-center mb-2">
          <p class="mr-2">Subscribed At:</p>
          <p>
            {{
              this.authUser.fly_credits_upd_at === undefined
                ? new Date(
                    this.authUser.created_time.seconds * 1000
                  ).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                : new Date(
                    this.authUser.fly_credits_upd_at.seconds * 1000
                  ).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
            }}
          </p>
        </div>

        <div
          class="flex items-center mb-2"
          :class="this.authUser.fly_plan_valid_until ? 'hidden' : ''"
        >
          <p class="mr-2">Plan status:</p>
          <p class="capitalize">
            {{
              this.authUser.fly_plan === "free"
                ? "active"
                : currentSubscription.status === "created"
                ? "in progress"
                : currentSubscription.status
            }}
          </p>
        </div>
        <div :class="this.authUser.fly_plan_valid_until ? '' : 'hidden'">
          <p>
            Plan valid until:
            {{
              this.authUser.fly_plan_valid_until === undefined ||
              this.authUser.fly_plan_valid_until === null
                ? ""
                : new Date(
                    this.authUser.fly_plan_valid_until.seconds * 1000
                  ).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
            }}
          </p>
        </div>
        <router-link
          to="/subscription"
          class="px-4 py-3 bg-blue-500 rounded-md"
        >
          <span class="text-white"> Change Plan</span>
        </router-link>
        <button
          @click="cancelSubscription"
          class="px-8 py-2.5 ml-2 text-white bg-red-500 rounded-md"
          :class="this.authUser.fly_plan === 'free' ? 'hidden' : ''"
        >
          Cancel
        </button> -->

      <!-- <el-button
          class="ml-2 text-white bg-red-500 rounded-md w-[100px]"
          type="danger"
          @click="open"
        >
          Cancel</el-button
        > -->
      <!-- <button
          @click="regenerateApiKey"
          class="px-4 py-2 ml-2 text-white bg-red-500 rounded-md"
          v-if="this.authUser.fly_api_key"
        >
          Regenerate
        </button> -->
      <!-- </div> -->
      <!-- <div class="p-4 mb-4 bg-white rounded-lg shadow-md">
        <h2 class="mb-2 text-lg font-semibold">API Key</h2>
        <div class="flex items-center mb-2">
          <p class="mr-2">API Key:</p>
          <p class="font-mono">{{ this.authUser.fly_api_key }}</p>
        </div>
        <div class="flex items-center mb-2">
          <p class="mr-2">Generated Time:</p>
          <p>{{ this.authUser.fly_api_key_generated_at }}</p>
        </div>
        <button
          @click="generateApiKey"
          class="px-4 py-2 text-white bg-blue-500 rounded-md"
          v-if="!this.authUser.fly_api_key"
        >
          Generate API Key
        </button>
        <button
          @click="regenerateApiKey"
          class="px-4 py-2 ml-2 text-white bg-red-500 rounded-md"
          v-if="this.authUser.fly_api_key"
        >
          Regenerate
        </button>
      </div> -->
      <!-- <div class="p-4 my-2 mb-4 bg-white rounded-lg shadow-md">
        <h2 class="mb-2 text-lg font-semibold">
          YouTube Data API Key
          <span class="mb-2 text-lg font-normal">(optional)</span>
        </h2>
        <p>
          In order to upload your YouTube videos on your behalf, you will need
          to add your YouTube Data API key
        </p>
        <div class="flex items-center mb-2"> -->
      <!-- <label for="youtubeApiKey" class="mr-2">API Key:</label> -->
      <!-- <input
            v-model="youtubeapikey"
            id="youtubeApiKey"
            class="p-2 border-2 rounded-lg border-rose-600 bg-slate-100 w-80"
            :placeholder="this.authUser.yt_data_api_key"
          />
        </div>
        <button
          @click="updateYoutubeApiKey"
          class="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
        >
          Update
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import "firebase/firestore";
import {
  doc,
  updateDoc,
  collection,
  getDoc,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  auth,
  colGallery,
  colUsers,
  db,
  functions,
  flyplans,
  flyofferplans,
  subscriptions,
} from "@/utils/firebase.utils.js";
import PaymentMethodDetails from "@/components/account/PaymentMethodDetails.vue";
import moment from "moment";

export default {
  components: { PaymentMethodDetails },
  //created(){},
  // data() {
  //   // return {
  //   //   balanceCredits:  this.authUser.fly_credits,
  //   //   apiKey: this.authUser.fly_api_key,
  //   //   generatedTime: this.authUser.fly_api_key_generated_at,
  //      youtubeApiKey: this.authUser.yt_data_user_api,
  //   //   confirmRegenerate: false,
  //   // };
  // },
  data() {
    return {
      fly_bl: 0,
      plan_price_usd: 0,
      img_cr: 0,
      txt_cr: 0,
      maxCredits: 0,
      maxImageCredits: 0,
      maxTextCredits: 0,
      maxStorage: 0,
      youtubeapikey: "",
      showModal: false,
      currentSubscription: {},
      plan_price: 0,
      subscriptionsHistory: [],
      isSubscriptionActive: true,
      pollCount: 0,
      maxPollAttempts: 30,
      creditTypes: [
        {
          key: "ai_writer_actions",
          name: "Text AI Writer Actions",
        },
        {
          key: "ai_image_actions",
          name: "AI Image Actions",
        },
        {
          key: "posts",
          name: "Posts",
        },
        {
          key: "brands",
          name: "Brands",
        },
        {
          key: "storage",
          name: "Storage",
        },
        {
          key: "ai_generated_posts",
          name: "AI Generated Posts",
        },
        {
          key: "ai_image_gen",
          name: "AI Generated Images",
        },
      ],
      user_addon_credits: [],
    };
  },
  computed: {
    // Other computed properties...
    isINR() {
      return (
        this.authUser &&
        this.authUser.country_code &&
        this.authUser.country_code === "IN"
      );
    },
    planName() {
      let plan = this.authUser.fly_plan;
      if (
        plan == "lite" ||
        plan == "lite_3m" ||
        plan == "lite_6m" ||
        plan == "lite_12m"
      ) {
        return "Personal";
      } else if (
        plan == "boost" ||
        plan == "boost_3m" ||
        plan == "boost_6m" ||
        plan == "boost_12m"
      ) {
        return "Enterprise";
      } else if (
        plan == "plus" ||
        plan == "plus_3m" ||
        plan == "plus_6m" ||
        plan == "plus_12m"
      ) {
        return "Professional";
      }
      return "Free";
    },
    consumedCreditsProgress() {
      if((this.maxCredits - this.authUser.fly_credits)<0){
        console.log("reutrnned");
        return "0%"
      }
      const progressPercentage =
        ((this.maxCredits - this.authUser.fly_credits) / this.maxCredits) * 100;
      return progressPercentage + "%";
    },
    imageCreditsProgress() {
      const progressPercentage =
        ((this.maxImageCredits - this.authUser.fly_ai_image_credits) /
          this.maxImageCredits) *
        100;
      return progressPercentage + "%";
    },
    textCreditsProgress() {
      if((this.maxTextCredits - this.authUser.fly_ai_text_credits)<0){
        return "0%"
      }
      const progressPercentage =
        ((this.maxTextCredits - this.authUser.fly_ai_text_credits) /
          this.maxTextCredits) *
        100;
      return progressPercentage + "%";
    },
    storageProgress() {
      const progressPercentage =
        (this.authUser.fly_storage / this.maxStorage) * 100;
      return progressPercentage + "%";
    },
    name() {
      if (this.user?.firstName) {
        return `${this.user.firstName} ${this.user.lastName}`;
      }
      return `${this.authUser.username}`;
    },
  },
  methods: {
    consumedAddonCreditsProgress(rem, total) {
      const progressPercentage = ((total - rem) / total) * 100;
      return progressPercentage + "%";
    },
    async fetchSubscriptionsHistory() {
      try {
        let q = query(
          collection(doc(subscriptions, this.uid), "history"),
          orderBy("created_at", "desc")
        );

        this.subscriptionsHistory = (await getDocs(q)).docs.map((i) => ({
          ...i.data(),
          id: i.id,
        }));
        // console.log("subscriptionsHistory:", this.subscriptionsHistory);
      } catch (e) {
        console.error("Error fetching fly plan history:", e);
      }
    },
    async fetchData() {
      try {
        let flyPlanDoc = await getDoc(doc(flyplans, this.authUser.fly_plan));
        if (this.authUser.offer_availed) {
          flyPlanDoc = await getDoc(doc(flyofferplans, this.authUser.fly_plan));
        }
        if (flyPlanDoc.exists()) {
          this.maxCredits = flyPlanDoc.data().limits.posts;
          this.fly_bl = this.maxCredits;
          this.plan_price = flyPlanDoc.data().monthly_price_inr;
          this.plan_price_usd = flyPlanDoc.data().monthly_price_usd;
          // console.log(this.plan_price);

          this.maxImageCredits = flyPlanDoc.data().limits.ai_image_actions;
          this.img_cr = this.maxImageCredits;

          this.maxTextCredits = flyPlanDoc.data().limits.ai_text_actions;
          this.txt_cr = this.maxTextCredits;

          this.maxStorage = flyPlanDoc.data().limits.storage;
        }
      } catch (error) {
        console.error("Error fetching fly plan data:", error);
      }
    },

    async fetchTotalCredits() {
      // Ensure the function is resilient to the absence of either credit type
      if (
        !this.authUser.addon_credits &&
        !this.authUser.addon_monthly_credits
      ) {
        console.log("No addon credit data available.");
        return; // Early exit if both credit types are absent
      }
      const currentDate = new Date();

      this.creditTypes.forEach((type) => {
        // Initialize variables to store credit data
        let totalOneTimeCredits = 0,
          usedOneTimeCredits = 0,
          remainingOneTimeCredits = 0,
          totalMonthlyCredits = 0,
          usedMonthlyCredits = 0,
          remainingMonthlyCredits = 0;

        // Process one-time credits if available
        if (
          this.authUser.addon_credits &&
          this.authUser.addon_credits.hasOwnProperty(type.key)
        ) {
          const totalKey = `total_${type.key}`;
          totalOneTimeCredits = this.authUser.addon_credits[totalKey] || 0;
          usedOneTimeCredits =
            totalOneTimeCredits - this.authUser.addon_credits[type.key];
          remainingOneTimeCredits = this.authUser.addon_credits[type.key];
        }

        // Process monthly credits if available
        if (
          this.authUser.addon_monthly_credits &&
          this.authUser.addon_monthly_credits.hasOwnProperty(type.key)
        ) {
          const totalKey = `total_${type.key}`;
          const validityKey = `${type.key}_valid_until`;
          let validUntil = this.authUser.addon_monthly_credits[validityKey];
          let isCancelled = this.authUser.addon_monthly_credits[validityKey];
          // console.log("isCancelled:", type.key, isCancelled);
          if (validUntil?.seconds) {
            validUntil = new Date(validUntil.seconds * 1000);
          } else validUntil = new Date(validUntil);

          // addon cancelled but not expired
          if (
            !isCancelled ||
            isCancelled == undefined ||
            (validUntil && validUntil.getTime() > currentDate.getTime())
          ) {
            totalMonthlyCredits =
              this.authUser.addon_monthly_credits[totalKey] || 0;
            usedMonthlyCredits =
              totalMonthlyCredits -
              this.authUser.addon_monthly_credits[type.key];
            remainingMonthlyCredits =
              this.authUser.addon_monthly_credits[type.key];
          }
          // addon expired
          if (validUntil && validUntil.getTime() < currentDate.getTime()) {
            totalMonthlyCredits = 0;
            usedMonthlyCredits = 0;
            remainingMonthlyCredits = 0;
          }
        }

        // Calculate total and used credits across both types
        let total_credits = totalOneTimeCredits + totalMonthlyCredits;
        let used_credits = usedOneTimeCredits + usedMonthlyCredits;
        let remaining_credits =
          remainingOneTimeCredits + remainingMonthlyCredits;

        if (totalOneTimeCredits > 0 || totalMonthlyCredits > 0) {
          this.user_addon_credits.push({
            name: type.name,
            total: total_credits,
            used: used_credits,
            remaining: remaining_credits,
          });
        }
      });
    },

    async getCurrentSubscription() {
      let subscriptionRef = doc(collection(db, "fly_subscriptions"), this.uid);
      this.currentSubscription = (await getDoc(subscriptionRef)).data() || {};
      if (this.currentSubscription.status === "active") {
        if (!this.isSubscriptionActive) {
          this.$router.go();
          // location.reload();  // Reload the page once status is 'active
        }
        // this.isSubscriptionActive = true;
        // this.subscriptionsHistory[0].status = 'active'
      } else {
        this.isSubscriptionActive = false;
        // Increment the poll count
        this.pollCount++;
        // If status is not 'active' and max attempts not reached, keep polling
        setTimeout(this.getCurrentSubscription, 5000); // Poll every 5 seconds
      }
    },
    buyCredits() {
      this.$router.push("/subscription");
    },
    // formatStorage(storageBytes) {
    //   const storageGB = storageBytes / 1073741824; // 1024^3

    //   return `${storageGB.toFixed(2)} GB`;
    // },
    async generateApiKey() {
      // Generate a new API key
      const newApiKey = uuidv4();
      try {
        // Get the current user's ID
        const userId = auth.currentUser.uid;

        // Update the `fly_api_key` field in the user's document
        let data = {
          fly_api_key: newApiKey,
          fly_api_key_generated_at: new Date().toLocaleString(),
        };
        await updateDoc(doc(colUsers, userId), data);
        console.log("API key stored in Firestore.");
      } catch (error) {
        console.error("Error storing API key in Firestore:", error);
      }
    },

    // this.generatedTime = new Date().toLocaleString();

    regenerateApiKey() {
      const confirmRegenerate = window.confirm(
        "Are you sure you want to regenerate the API Key? This will invalidate the current API Key."
      );

      if (!confirmRegenerate) {
        return; // Cancel regeneration
      }

      // Regenerate the API key
      this.generateApiKey();
    },
    async updateYoutubeApiKey() {
      if (this.youtubeapikey) {
        try {
          // Get the current user's ID
          const userId = auth.currentUser.uid;

          // Update the `fly_api_key` field in the user's document
          let data = {
            yt_data_api_key: this.youtubeapikey,
          };
          await updateDoc(doc(colUsers, userId), data);

          console.log("API key stored in Firestore.");
        } catch (error) {
          console.error("Error storing API key in Firestore:", error);
        }
      } else {
        alert("Please enter a valid YouTube Data API Key.");
      }
    },

    closeModal() {
      this.showModal = false;
    },
    async cancelSubscription() {
      let subscriptionRef = doc(collection(db, "fly_subscriptions"), this.uid);
      let currentSubscription = (await getDoc(subscriptionRef)).data() || {};
      console.log("reesult", currentSubscription);
      let data = { subscription: currentSubscription.subscription_id };
      let subscribe = httpsCallable(functions, "razorpaySubscribe");
      let result = (await subscribe(data)).data;
      console.log("res", result);
    },
    copyLinkToClipboard(link) {
      const el = document.createElement("textarea");
      el.value = link;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      alert("Link copied to clipboard!");
    },
  },
  mounted() {
    this.fetchTotalCredits();
    this.fetchData();
    this.fetchSubscriptionsHistory();
    this.getCurrentSubscription();
  },
};
</script>

<style scoped>
.blink {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.chip-progress-bar {
  height: 20px;
  width: 316px;
  max-width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  margin-top: 5px;
}

.progress {
  height: 100%;
  background-color: #3C50E0;
  /* Green color for progress */
  border-radius: 36px;
}
.list-view-container {
  overflow-x: hidden;
  width: 100%;
}

@media (max-width: 768px) {
  .list-view-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .list-view-container > .flex {
    min-width: 700px;
    flex-wrap: nowrap;
  }
}
.list-view-container .flex {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.list-view-container .flex > div {
  flex: 1;
  padding: 8px;
  text-align: left;
}

@media (max-width: 768px) {
  .list-view-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .list-view-container .flex {
    min-width: 800px; /* Increase this if necessary to prevent overlapping */
  }

  .list-view-container .flex > div {
    padding: 4px; /* Adjust padding to manage space */
    font-size: 14px; /* You may adjust font size for better visibility */
  }
}

/* Ensure there's no overlap by adding specific flex-basis values */
.list-view-container .flex > div:nth-child(1) {
  flex-basis: 18%; /* Adjust percentage based on your content needs */
}

.list-view-container .flex > div:nth-child(2),
.list-view-container .flex > div:nth-child(3),
.list-view-container .flex > div:nth-child(4),
.list-view-container .flex > div:nth-child(5) {
  flex-basis: 14%; /* Smaller flex basis for other columns */
}
</style>
