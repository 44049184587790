<template>
  <div class="flex overflow-hidden flex-col w-full bg-white max-md:max-w-full">
    <div
      class="w-full relative [backdrop-filter:blur(32px)] bg-color-white border-neutral-01-5 border-solid box-border flex flex-row items-center justify-between py-5 px-[135px] text-center text-base text-color-black font-text-h6-bold max-md:px-4">

      <!-- Logo section -->
      <div class="flex flex-row items-center justify-start">
        <div class="h-[37px] flex items-center">
          <img class="w-[100px] max-md:w-[80px]" src="@/assets/img/fly-new-logo.png" alt="logo" />
        </div>
      </div>

      <!-- Login Now button section -->
      <div class="flex flex-row items-center justify-end">
        <div v-if="checkAuth"
          class="px-8 py-3 rounded-[90px] border-2 border-[#e6e8ec] inline-flex items-center justify-center gap-3 cursor-pointer max-md:px-4 max-md:py-2"
          @click="handleLogin">
          <div class="text-black text-base font-bold font-['SF Pro Display'] leading-[23px]">
            Login Now
          </div>
        </div>
        <div v-else
          class="px-8 py-3 rounded-[90px] border-2 border-[#e6e8ec] inline-flex items-center justify-center gap-3 cursor-pointer max-md:px-4 max-md:py-2"
          @click="logout">
          <div class="text-black text-base font-bold font-['SF Pro Display'] leading-[23px]">
            Logout
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col items-start self-center py-16 w-full bg-[#f3f7f5] rounded-[28px] max-w-[1340px] max-md:max-w-full pb-10 relative">
      <!-- The top-right image -->
      <img class="origin-top-left absolute top-5 right-4 animate-floating" src="@/assets/img/insta_glow.png"
        alt="Decorative Image" />

      <div
        class="w-[53.68px] h-[53.68px] p-[8.37px] origin-top-left rotate-[18.09deg] opacity-60 bg-white rounded-[6.97px] shadow justify-start items-center gap-[2.79px] inline-flex absolute bottom-28 max-md:bottom-20 left-28">
        <img class="w-[36.95px] h-[36.95px]   " src="@/assets/img/fb_glow.png" />
      </div>
      <img class=" origin-top-left  opacity-50  absolute top-6 left-14" src="@/assets/img/yt_glow.png"
        alt="Top-left Image" />
      <img class=" opacity-50   absolute left-40 top-1/2 transform -translate-y-1/2  max-md:left-1 max-md:top-1/3"
        src="@/assets/img/thread_glow.png" alt="Middle-left Image" />
      <img class=" opacity-50   absolute right-40 top-1/2 transform -translate-y-1/2 max-md:right-1 max-md:top-1/3"
        src="@/assets/img/Gmb_glow.png" alt="Middle-right Image" />
      <img class=" origin-top-left  opacity-70 absolute bottom-10 right-10" src="@/assets/img/pinterest_glow.png"
        alt="Bottom-right Image" />

      <img loading="lazy"
        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d5692bf7c827fe270cf05d953f8cf4fa06b1e863696da336fc2a1f74364e49c0?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d5692bf7c827fe270cf05d953f8cf4fa06b1e863696da336fc2a1f74364e49c0?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d5692bf7c827fe270cf05d953f8cf4fa06b1e863696da336fc2a1f74364e49c0?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d5692bf7c827fe270cf05d953f8cf4fa06b1e863696da336fc2a1f74364e49c0?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d5692bf7c827fe270cf05d953f8cf4fa06b1e863696da336fc2a1f74364e49c0?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d5692bf7c827fe270cf05d953f8cf4fa06b1e863696da336fc2a1f74364e49c0?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d5692bf7c827fe270cf05d953f8cf4fa06b1e863696da336fc2a1f74364e49c0?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d5692bf7c827fe270cf05d953f8cf4fa06b1e863696da336fc2a1f74364e49c0?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea"
        class="object-contain ml-96 shadow-2xl aspect-square rounded-[116px] w-[29px] max-md:ml-2.5" />

      <div
        class="flex z-10 flex-col self-stretch px-16 pb-28 mt-4 font-bold max-md:px-5 max-md:pb-24 max-md:max-w-full">
        <div class="flex flex-col justify-center max-md:max-w-full">
          <div class="flex flex-col w-full max-md:max-w-full">
            <div
              class="flex gap-2 justify-center items-center self-center px-3 py-1.5 text-lg leading-none whitespace-nowrap bg-white border-2 border-rose-400 border-solid rounded-[144px]">
              <div class="self-stretch my-auto bg-clip-text text-[#fa8c8c] linear-gradient ">
                Fly-Lite
              </div>
            </div>

            <!-- The text section with Share Once, Post Everywhere -->
            <div class="text-center">
              <div class="text-black font-bold leading-none lg:text-[80px] lg:leading-[80px] text-[2.7rem] max-md:mt-4">
                Share Once,
                <span class="font-extrabold text-[#fa8c8c] lg:font-[800] linear-gradient">
                  Post
                </span>
              </div>
              <div class="text-black font-bold leading-none lg:text-[80px] lg:leading-[80px] text-[2.7rem]">
                Everywhere
              </div>
            </div>


            <div
              class="text-center text-[#667790] text-[1rem] lg:text-2xl font-medium font-['Poppins'] leading-loose[1] mt-5">
              Amplify your social media presence effortlessly. Let your content shine <br />across all
              platforms!
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col justify-center items-center px-28 pb-36 mt-36 w-full max-md:px-5 max-md:pb-24 max-md:mt-10 max-md:max-w-full">
      <div class="flex flex-col items-center w-full text-center max-md:max-w-full">
        <div class="text-5xl font-bold leading-none text-zinc-800 max-md:max-w-full max-md:text-2xl">
          Why Choose Fly-Lite?
        </div>

        <!-- <div class="mt-4 text-2xl leading-none text-slate-500 max-md:max-w-full max-md:text-xl">
      We are the best in the global world.
    </div> -->
      </div>

      <div
        class="flex gap-10 justify-center items-center self-center mt-10 w-full max-w-[1200px] max-md:mt-10 max-md:max-w-full max-md:flex-col">

        <!-- Time-Saver Section -->
        <div class="flex flex-col flex-1 min-w-[240px]">
          <div
            class="gap-2.5 self-start px-3 py-0.5 text-lg font-medium leading-none text-gray-50 whitespace-nowrap bg-gradient-to-br from-[#8cb0fb] to-[#236ceb] rounded-[100px]">
            01
          </div>
          <div class="flex flex-col mt-4 w-full">
            <div class="text-2xl font-bold leading-none text-zinc-800">
              🚀 Expand Your Reach
            </div>
            <div class="mt-4 text-lg leading-6 text-slate-500">
              Automatically share your posts and stories on Facebook, Twitter, YouTube,Instagram, Pinterest and more.
              Watch your follower count soar across multiple networks!
            </div>
          </div>
        </div>

        <!-- Smart Adaptation Section -->
        <div class="flex flex-col flex-1 min-w-[240px] ">
          <div
            class="gap-2.5 self-start px-3 py-0.5 text-lg font-medium leading-none text-gray-50 whitespace-nowrap bg-lime-300 rounded-[100px]">
            02
          </div>
          <div class="flex flex-col mt-4 w-full">
            <div class="text-2xl font-bold leading-none text-zinc-800">
              ⏱️ Save Time, Create <br />More
            </div>
            <div class="mt-4 text-lg leading-6 text-slate-500">
              Share your posts across platforms in seconds. Spend less time managing accounts and more time
              creating amazing content.
            </div>
          </div>
        </div>

        <!-- Wider Reach Section -->
        <div class="flex flex-col flex-1 min-w-[240px]">
          <div
            class="gap-2.5 self-start px-3 py-0.5 text-lg font-medium leading-none text-gray-50 whitespace-nowrap bg-red-400 rounded-[100px]">
            03
          </div>
          <div class="flex flex-col mt-4 w-full">
            <div class="text-2xl font-bold leading-none text-zinc-800">
              💰 Boost Your Earning Potential
            </div>
            <div class="mt-4 text-lg leading-6 text-slate-500">
              Increase your visibility to attract diverse sponsorship deals, collaborations, and monetization options.
              Turn your passion into profit effortlessly!
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="text-center text-[#22262e] text-5xl font-bold font-['SF Pro Display'] leading-[56px] max-md:text-3xl"> How it works</div>

    <div class="text-center text-[#667790] text-2xl font-normal font-['SF Pro Display'] leading-loose max-md:text-sm max-md:mb-2 mb-7">You post on your primary channel as before and Fly takes it everywhere! 🚀</div>
    <div
    class="flex flex-col justify-center items-end self-stretch py-10 pr-10 pl-10 my-auto bg-gray-50 rounded-3xl border border-gray-200 border-solid shadow-2xl min-w-[240px] max-md:px-5 max-md:max-w-full ml-auto mr-auto mb-16"
  >
    <img
      loading="lazy"
      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/92d235ec304a988fd4e93c9fa494ff99c78fe845d4c0b328648bce46f5e740b9?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/92d235ec304a988fd4e93c9fa494ff99c78fe845d4c0b328648bce46f5e740b9?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/92d235ec304a988fd4e93c9fa494ff99c78fe845d4c0b328648bce46f5e740b9?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/92d235ec304a988fd4e93c9fa494ff99c78fe845d4c0b328648bce46f5e740b9?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/92d235ec304a988fd4e93c9fa494ff99c78fe845d4c0b328648bce46f5e740b9?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/92d235ec304a988fd4e93c9fa494ff99c78fe845d4c0b328648bce46f5e740b9?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/92d235ec304a988fd4e93c9fa494ff99c78fe845d4c0b328648bce46f5e740b9?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/92d235ec304a988fd4e93c9fa494ff99c78fe845d4c0b328648bce46f5e740b9?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea"
      class="object-contain max-w-full aspect-[0.71] w-[440px]"
    />
  </div>
    <lite-page-1 id="lite-page" :checkAuth="isAuthenticated" :handleLogin="handleLogin" @checkInsta="handleChildData" @primary_platform="currentPrimary($event)" />


    <div v-if="isInstaConnected && isAuthenticated && hasActiveSubscription"
      class="flex flex-col md:flex-row gap-20 justify-center items-center self-center mt-10 max-md:mt-10 max-md:max-w-full">
      <img loading="lazy"
        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/17de16e04852e7b7516e7fe1183c4f90e7d5145a088ebeb4b0c0bbd9c571b2ae?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/17de16e04852e7b7516e7fe1183c4f90e7d5145a088ebeb4b0c0bbd9c571b2ae?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/17de16e04852e7b7516e7fe1183c4f90e7d5145a088ebeb4b0c0bbd9c571b2ae?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/17de16e04852e7b7516e7fe1183c4f90e7d5145a088ebeb4b0c0bbd9c571b2ae?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea&width=800 800w"
        class="object-contain rounded-[10px] aspect-[0.97] min-w-[240px] w-[496px] max-md:max-w-full bg-transparent" />
      <div
        class="flex flex-col justify-start self-stretch my-auto min-w-[240px] w-[520px] text-left max-md:max-w-full max-md:p-4 ">
        <div class="flex flex-col justify-start w-full font-bold text-left max-md:max-w-full">
          <div
            class="flex gap-2 justify-start items-center px-3 py-1.5 text-lg leading-none bg-white border-2 text-[#fa8c8c] border-rose-400 border-solid rounded-[144px] max-w-max">
            <div class="bg-clip-text  text-[#fa8c8c]">You are done</div>
          </div>

          <div class="mt-3 text-5xl leading-none text-black max-md:max-w-full max-md:text-2xl">
            You're All Set!
          </div>
          <div class="mt-3 text-2xl leading-8 text-slate-500 max-md:max-w-full max-md:text-sm">
            Congratulations! Your social media game just leveled up.
          </div>
        </div>
        <div class="flex flex-col items-start mt-8 w-full text-lg leading-6 text-slate-500 max-md:max-w-full">
          <div class="flex flex-wrap gap-3.5 items-start w-full">
            <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d0198af57e5a4945951945ede1847388a72414bdc8faf0f22a474b6d808aeda?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea"
              class="object-contain shrink-0 w-6 aspect-square" />
            <div class="flex-1 max-md:text-sm">
              Every {{primary_platform=='youtube'?'YouTube':'Instagram'}} post you publish will now automatically go live on all your
              connected platforms.
            </div>
          </div>
          <div class="flex flex-wrap gap-3.5 items-start mt-8 w-full text-lg leading-6 text-slate-500 max-md:max-w-full">
            <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b2ff71852d887b308bfc0dad0e0cdfea32e61f4a262dcb89bfbc790f61c5bbfb?placeholderIfAbsent=true&apiKey=239101f26c4943daa470daa2ac55baea"
              class="object-contain shrink-0 w-6 aspect-square" />
            <div class="flex-1 max-md:text-sm">
              We'll keep you in the loop with notifications at
              <span class="text-base font-bold leading-6 text-black">{{ email }} </span>or on WhatsApp at <div
                v-if="phone_number && !showEditPhone">
                <!-- Display the phone number and pencil icon for editing -->
                <div class="flex items-center gap-2">
                  <span class="font-bold text-black">{{ phone_number }}</span>
                  <!-- Vuetify v-icon for pencil icon -->
                  <v-icon class="cursor-pointer text-gray-600" @click="showEditPhone = true">
                    mdi-pencil
                  </v-icon>
                </div>
              </div>

              <div v-else>
                <!-- Display input field and button to add or update phone number -->
                <div class="flex items-center gap-4 max-md:flex-col">
                  <vue-tel-input-vuetify
                    class="bg-[#f2f6fa] flex flex-row gap-3 outline-[#8056DE] md:w-[309px] h-14 rounded-[26px] mt-2 pb-3 mb-3"
                    mode="international" v-model="newPhoneNumber">
                    <input type="text"
                      class="bg-[#f2f6fa] flex flex-row gap-3 w-full outline-[#8056DE] md:w-[309px] h-12 items-start rounded-[26px]"
                      placeholder="Phone Number" />
                  </vue-tel-input-vuetify>

                  <button @click="savePhoneNumber"
                    class="px-8 py-4 bg-[#8056DE] text-white rounded-full text-sm max-md:text-xs max-md:w-full font-semibold">
                    {{ phone_number ? 'Update' : 'Add' }}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div  v-if="isInstaConnected && isAuthenticated && hasActiveSubscription" class="published-cross-post">
      <lite-page-2/>
    </div>


    <div class="h-[510px] py-20 bg-[#f7f6ff] flex-col justify-start items-center gap-14 flex max-w-full mt-32">
  <div class="self-stretch h-[156px] flex-col justify-start items-center gap-4 flex">
    <div class="px-3 py-1.5 bg-white rounded-[144px] border-2 border-[#fa8c8c] justify-center items-center gap-2 inline-flex">
      <div class="text-[#fa8c8c] text-lg font-bold font-['SF Pro Display'] leading-normal">Have any questions?</div>
    </div>
    <div class="self-stretch text-center text-[#22262e] text-5xl font-bold font-['SF Pro Display'] leading-[56px] max-md:text-2xl">
      Do you need any help?
    </div>
    <div class="self-stretch text-center text-[#667790] text-2xl font-normal font-['SF Pro Display'] leading-loose max-md:text-sm">
      Our team is ready to give a prompt response and assist you right away.
    </div>

    <!-- This is the section placed directly below the above text -->
    <div class="w-full flex justify-center items-start mt-10">
  <div class="w-[540px] flex-col justify-start items-start gap-14 inline-flex ml-5 max-md:-mr-8">
    <div class="self-stretch justify-between items-center flex gap-12"> <!-- Added gap-12 for spacing -->
      <div class="w-[159px] flex-col justify-start items-start gap-4 inline-flex cursor-pointer" @click="openEmail">
        <div class="w-6 h-6 relative"><img src="@/assets/img/email.svg" class="cursor-pointer" /></div>
        <div class="h-[60px] flex-col justify-start items-start gap-3 flex">
          <div class="text-black text-lg font-bold font-['SF Pro Display'] leading-normal max-md:text-sm">Email</div>
          <div class="w-[357px] text-[#667790] text-lg font-medium font-['SF Pro Display'] leading-normal max-md:text-sm">
            fly@zoomlocal.in
          </div>
        </div>
      </div>
      <div class="w-[159px] flex-col justify-start items-start gap-4 inline-flex cursor-pointer" @click="openWhatsApp">
        <div class="w-6 h-6 relative"><img src="@/assets/img/phone.svg" class="cursor-pointer" /></div>
        <div class="h-[60px] flex-col justify-start items-start gap-3 flex">
          <div class="text-black text-lg font-bold font-['SF Pro Display'] leading-normal max-md:text-sm">WhatsApp</div>
          <div class="w-[357px] text-[#667790] text-lg font-medium font-['SF Pro Display'] leading-normal max-md:text-sm">
            +917756947197
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    <!-- Section for Chat with Us and Book Demo -->
    <div class="w-full flex justify-center">
      <div class="w-full flex justify-center mt-8 gap-[8rem]">
        <a @click="openChat" class="text-[#8056DE] text-lg font-semibold cursor-pointer underline max-md:text-sm max-md:-ml-8">
          Chat with Us
        </a>
        <!-- Book Demo Link -->
        <a href="https://calendly.com/fly-social-zoomlocal/30min" target="_blank" class="text-[#8056DE] text-lg font-semibold cursor-pointer underline max-md:text-sm max-md:-mr-3 ">
          Book Demo
        </a>
      </div>
    </div>
  </div>
</div>


    <div class="flex flex-col items-center justify-center w-full bg-white py-2 ">
      <!-- Gray border, not full width -->
      <div class="w-3/4 border-t border-gray-300 mb-4"></div>
      <!-- Centered Copyright Text -->
      <div class="text-center text-gray-600 text-base font-medium">
        © 2024 Fly Social. All rights reserved.
      </div>
    </div>
  </div>
</template>
<script>
import {
  doc,
  getDocs,
  collection,
  updateDoc,
  setDoc,
  getDoc,
  query,
  addDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  auth,
  colUsers,
  db,
  functions,
  colScheduledPostsDelete,
  colSocialPostsDelete,
} from "@/utils/firebase.utils.js";
import { onAuthStateChanged, signOut } from "firebase/auth";
import LitePage1 from "./LitePage1.vue";
import LitePage2 from "./LitePage2.vue";
export default {
  data() {
    return {
      primary_platform:false,
      isAuthenticated: false,
      isInstaConnected: false,
      showEditPhone: false,
      phone_number: null,
      email: null, // Store email
      paymentStatus: "", // Add this to store the payment status
      newPhoneNumber: "",
      loadingAuth: true,
    };
  },
  components: {
    LitePage1,
    LitePage2,
  },
  watch: {
    isAuthenticated() {
      this.fetchUserPhoneNumber()
    }
  },
  computed: {
    hasActiveSubscription() {
      return this.paymentStatus === "paid";
    },
    checkAuth() {
      return !this.isAuthenticated && !this.loadingAuth;
    },

  },
  methods: {
    async initPlan() {
      const vm = this;

      // Ensure vm.uid is defined
      if (!vm.uid) {
        console.error("User ID (uid) is undefined.");
        return;
      }

      try {
        vm.isLoading = true;

        // Debugging: log the UID
        // console.log("Fetching subscription for UID:",  auth.currentUser.uid);

        // Fetch the subscription document for the user
        let subscriptionRef = doc(db, "fly_cross_posting_subscriptions", auth.currentUser.uid);
        let subscriptionDoc = await getDoc(subscriptionRef);

        // Check if the document exists
        if (!subscriptionDoc.exists()) {
          console.error("Subscription document does not exist for UID:", auth.currentUser.uid);
          vm.isLoading = false;
          return;
        }

        // Debugging: log the fetched document data
        // console.log("Fetched subscription data:", subscriptionDoc.data());

        // Fetch only the `payment_status` field
        vm.paymentStatus = subscriptionDoc.data().payment_status || "";

        vm.isLoading = false;
      } catch (error) {
        console.error("Error fetching subscription data:", error);
        vm.handleError(error);
      }
    },

    openChat() {
      if (window.$zoho) {
        $zoho.salesiq.floatwindow.visible("show");
      }
    },
    openEmail() {
      window.location.href = "mailto:fly@zoomlocal.in";
    },
    openWhatsApp() {
      window.open("https://wa.me/7756947197", "_blank");
    },
    async fetchUserPhoneNumber() {
      try {

        const userRef = doc(db, "users", auth.currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          this.email = userData.email || "";
          this.phone_number = userData.phone_number || null;
        }
      } catch (error) {
        console.error("Error fetching phone number:", error);
      }
    },
    async savePhoneNumber() {
      if (!this.newPhoneNumber) {
        alert("Please enter a valid phone number");
        return;
      }

      try {
        const userRef = doc(db, "users", auth.currentUser.uid);

        // Update the phone number in Firestore
        await updateDoc(userRef, {
          phone_number: this.newPhoneNumber,
        });

        this.phone_number = this.newPhoneNumber;
        this.newPhoneNumber = ""; // Clear input field
        this.showEditPhone = false; // Hide input field and show the updated phone number
        alert(`${this.phone_number ? 'Phone number updated' : 'Phone number added'} successfully!`);
      } catch (error) {
        console.error("Error saving phone number:", error);
      }
    },
    async addPhoneNumber() {
      if (!this.newPhoneNumber) {
        alert("Please enter a phone number");
        return;
      }
      try {
        const userRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userRef, {
          phone_number: this.newPhoneNumber,
        });
        this.phone_number = this.newPhoneNumber;
        this.newPhoneNumber = ""; // Clear the input field
        alert("Phone number added successfully!");
      } catch (error) {
        console.error("Error adding phone number:", error);
      }
    },
    handleChildData(data) {
      this.isInstaConnected = data;
    },
    currentPrimary(data){
      this.primary_platform = data;
    },
    handleLogin() {
      // Store the redirect path in sessionStorage
      sessionStorage.setItem('redirectPath', 'lite');

      // Redirect to /login page
      this.$router.push('/login');
    },
    async logout() {
      try {
        // Store the uid before logging out
        let uid = auth.currentUser ? auth.currentUser.uid : null;
        if (uid) {
          // Proceed to log the logout event
          const loginSessionRef = collection(db, "fly_login_sessions");
          const loginSessionData = {
            uid: uid,
            created_at: new Date(),
            origin_url: window.location.hostname,
            action: "logout",
          };
          await addDoc(loginSessionRef, loginSessionData);
        }
        // Proceed with logout
        this.$store.commit("SET_AUTH", false);
        this.users = [];
        this.$mixpanel.track("User Logged Out");
        this.$mixpanel.reset();
        // Sign out the user
        await signOut(auth);
        // Redirect to another route after sign-out
        this.$router.replace("/lite");
        localStorage.clear();
      } catch (error) {
        console.error("Logout error:", error);
      }
    }
  },
  async mounted() {

    // Immediate auth check on mount
    if (auth.currentUser) {
      this.isAuthenticated = true;
      // Scroll to <lite-page-1> component if user is authenticated
      const litePageElement = document.getElementById('lite-page');
      if (litePageElement) {
        litePageElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (this.$route.query) {
      sessionStorage.setItem("ref_code", JSON.stringify(this.$route.query));
    }
    // Listen to auth state changes for future updates
    onAuthStateChanged(auth, async (user) => {
      if (user) {


        this.isAuthenticated = true;
        // Scroll to <lite-page-1> component when user logs in
        const litePageElement = document.getElementById('lite-page');
        if (litePageElement) {
          litePageElement.scrollIntoView({ behavior: 'smooth' });
        }
        await this.initPlan();
      } else {
        this.isAuthenticated = false;
      }
      this.loadingAuth = false; // Mark loading as false once auth state is determined
    });
  }


  //     mounted() {
  //     // Listen to auth state changes
  //     console.log("Mounting",auth)
  //     onAuthStateChanged(auth, (user) => {
  //       if (user) {
  //         this.isAuthenticated = true;
  //       } else {
  //         this.isAuthenticated = false;
  //       }
  //     });
  //   }
};
</script>
<style>
@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.animate-floating {
  animation: float 3s ease-in-out infinite;
}

.linear-gradient {


  background: linear-gradient(145deg, #FB8C8C 13.58%, #FF1D1D 86.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-input {
  border: 1px solid #d1d5db;
  /* Tailwind's gray-300 */
  border-radius: 0.375rem;
  /* Tailwind's default rounded */
}


</style>