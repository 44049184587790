<template>
  <v-form ref="form" v-model="isValid" @submit.prevent="submit">
    <slot></slot>
    <div class="d-flex">
      <v-spacer></v-spacer>
      <slot name="footer"></slot>
    </div>
  </v-form>
</template>
<script>
export default {
  data() {
    return {
      isValid: false,
    };
  },
  methods: {
    reset() {
      const vm = this;
      vm.$refs.form.reset();
    },
    validate() {
      const vm = this;
      vm.$refs.form.validate();
      return vm.isValid;
    },
    submit() {
      const vm = this;
      vm.$refs.form.validate();
      if (vm.isValid) {
        vm.$emit("submit");
        vm.$emit("onSubmit");
      } else {
        vm.$emit("notValid");
      }
    },
  },
};
</script>

<style></style>
