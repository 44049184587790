export default {
  fly: "Your Posts",
  "connected-apps": "Connected Apps",
  gallery: "Gallery",
  "create-post": "Create Post",
  "update-post": "Update Post",
  "update-gallery-image": "Update Gallery Image",
  "update-gallery-video": "Update Gallery Video",
  error: "Error",
};
