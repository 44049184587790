<template>
  <v-card outlined class="mb-3">
    <v-card-text>
      <div class="subtitle-1 black--text font-weight-medium d-flex align-center">
        <v-img :src="platform.logo_url" max-width="25px" class="mr-2"></v-img>
        Mastodon Settings
      </div>
      <div class="px-3 py-2">
        <v-row>
          <v-col cols="12" md="6" class="pb-2">
            <radio-group @input="$emit('input', form)" label="Visibility Type" v-model="form.ms_visibility" row
              :items="visibilityTypes" />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import FileInputField from "@/components/ui/form/FileInputField.vue";
import InputField from "../../ui/form/InputField.vue";
import RadioGroup from "../../ui/form/RadioGroup.vue";
import SelectField from "../../ui/form/SelectField.vue";
import ComboBox from "@/components/ui/form/ComboBox.vue";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    vidDuration: Number,
    configuration: {
      type: Object,
    },
    isEditing: Boolean,
    isRepost: Boolean,
    isDraftPost: Boolean,
    validVideoTypes: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { RadioGroup, InputField, SelectField, FileInputField, ComboBox },
  data() {
    return {
      form: {
        ms_visibility: "public",
        ms_sensitive: false,
      },
      visibilityTypes: [
        {
          name: "Public",
          value: "public",
        },
        {
          name: "Private",
          value: "private",
        },
        {
          name: "Unlisted",
          value: "unlisted",
        },
        {
          name: "Direct",
          value: "direct",
        },
      ],

      sensitive: [
        { name: "Yes", value: true },
        { name: "No", value: false },
      ],
    };
  },
  watch: {
    isDraftPost: {
      handler(newVal) {
        if (newVal) {
          this.updateConfiguration();
          this.form.yt_title = this.yt_title;
        }
      },
      immediate: true,
    },
    isEditing: {
      handler(newVal) {
        if (newVal) {
          // If isEditing is true, update the form with configuration values
          if (this.isEditing) {
            this.updateConfiguration();
            this.form.yt_title = this.yt_title;
          }
        }
      },
      immediate: true,
    },
    isRepost: {
      handler(newVal) {
        if (newVal) {
          // If isEditing is true, update the form with configuration values
          if (this.isRepost) {
            this.updateConfiguration();
            this.form.yt_title = this.yt_title;
            // console.log("thumbbb", this.thumbnail)
            // this.form.yt_thumb = this.thumbnail;
          }
        }
      },
      immediate: true,
    },
    value: {
      handler(val) {
        // console.log(val);
        Object.keys(this.form).forEach((key) => {
          if (val[key] != undefined) {
            this.form[key] = val[key];
          }
        });
      },
      deep: true,
    },
    "form.yt_title": {
      handler(newValue) {
        const characterCount = newValue.length;
        this.$emit("characterCount", characterCount);
        this.$emit("titleChanged", this.form.yt_title);
      },
      deep: true,
    },
  },
  computed: {
    platform() {
      let platforms = this.$store.getters.flyPlatforms;
      let platform = platforms.find((i) => i.alias == "mastodon");
      return platform || {};
    },
    tagsCharacterCount() {
      return this.tagsArray.join("").length;
    },
    ytTags: {
      get() {
        if (this.form.yt_tags.length) {
          const tagsArray = this.form.yt_tags
            .split(",")
            .map((tag) => tag.trim());
          return tagsArray;
        }
        return null;
      },
      set(newValue) {
        const flattenedTags = newValue
          .flatMap((tag) => tag.split(","))
          .map((tag) => tag.trim())
          .filter((tag) => tag.length > 0);

        this.tagsArray = flattenedTags;

        this.form.yt_tags = this.tagsArray.join(",");
        this.$emit("tagsAdded", this.form.yt_tags);
        this.$emit("input", this.form);
        this.tagCount = this.tagsCharacterCount;
        this.$emit("tagCharacterCount", this.tagCount);
        this.tagChanged = true;
      },
    },
  },
  methods: {
    initForm() {
      Object.keys(this.form).forEach((key) => {
        if (this.value[key] != undefined) {
          this.form[key] = this.value[key];
        }
      });
    },
    async onImageChange(e) {
      const vm = this;
      let image = e.target.files[0];
      vm.$refs.image.value = null;
      if (image) {
        this.thumbRemoved = false;
        this.$emit("thumbremoved", this.thumbRemoved);
        // console.log("img", image);
        this.thumbChanged = true;
        this.form.yt_thumb = image;
        // console.log("ty im", typeof(image), this.form.yt_thumb)
        if (image instanceof File) {
          var reader = new FileReader();
          reader.onload = function (e) {
            vm.thumbnail = e.target.result;
          };
          if (this.isRepost || this.isEditing || vm.isDraftPost) {
            this.$emit("thumbchanged", vm.thumbnail);
          }
          reader.readAsDataURL(image);
        }
        // this.$emit("input", this.form);
      }
    },
    removeThumb() {
      this.form.yt_thumb = null;
      this.thumbnail = "";
      this.thumbRemoved = true;
      this.$emit("thumbremoved", this.thumbRemoved);
    },
    handlePresetTags() {
      let tags = this.form.yt_tags;
      if (tags?.length) {
        this.tagsArray = tags.split(",");
      }
    },
    updateConfiguration() {
      this.form.ms_visibility = this.configuration.ms_visibility
        ? this.configuration.ms_visibility
        : "public";
    },
  },
  beforeMount() {
    this.initForm();
    // to render tags after selecting preset
    this.handlePresetTags();
  },
};
</script>

<style></style>