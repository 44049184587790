<template>
  <v-card>
    <v-card-title class="text-h5">Login to OLX</v-card-title>
    <v-card-text>
      <form @submit.prevent="isValid">
        <v-text-field
          label="Registered Phone Number"
          prepend-icon="mdi-cellphone"
          type="number"
          v-model="phone_number"
          :rules="[rules.required, rules.phoneLength]"
          required
        ></v-text-field>
        <v-text-field
          label="OLX Password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          v-model="password"
          @click:append="togglePasswordVisibility"
          :rules="[rules.required]"
          required
        ></v-text-field>
        <v-btn
          :color="buttonColor"
          :disabled="loading || success"
          @click="isValid"
          block
        >
          <v-progress-circular
            indeterminate
            color="black"
            v-if="loading"
          ></v-progress-circular>
          <v-icon left v-if="success" color="black">mdi-check</v-icon>
          <span v-if="loading" class="text-black">Verifying...</span>
          <span v-if="success" class="text-black">Connected</span>
          <span v-if="!loading && !success" class="text-white">Login</span>
        </v-btn>
      </form>
    </v-card-text>
    <v-card-actions class="d-flex justify-space-between align-center">
      <span class="password-setup-note">
        <span v-if="loading"
          >This could take up to a minute.. Please stand by.</span
        >
        <span v-else
          >You need to have a password set up on your OLX account. Please follow
          the steps given here to set it up.
          <a
            href="https://help.olx.in/hc/en-us/articles/10910827388445-How-do-I-change-password-for-My-Account"
            target="_blank"
            class="link"
            >Click here</a
          >
        </span>
      </span>
      <v-btn text @click="close">Close</v-btn>
    </v-card-actions>

    <!-- Dialog for Login Fail -->
    <v-dialog v-model="showDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6">Login Failed</v-card-title>
        <v-card-text>{{ errorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";

export default {
  data() {
    return {
      wid: this.$store.getters.WID,
      phone_number: '',
      password: this.password,
      showPassword: false,
      loading: false,
      success: false,
      loginStatus: false,
      showDialog: false,
      errorMessage: "",
      ignoreResult: false,
      rules: {
        required: (value) => !!value || "Required.",
        phoneLength: (value) =>
          (value && value.length === 10) ||
          "Phone number must be exactly 10 digits",
      },
    };
  },
  computed: {
    buttonColor() {
      if (this.loading) return "#a0aec0"; // Grey color while loading
      else if (this.success) return "#8056DE"; // Green color on success
      return "#8056DE"; // Default purple color
    },
  },
  methods: {
    isValid() {
      // Check that the phone number is exactly 10 digits and the password is more than 2 characters
      if (this.phone_number.length === 10 && this.password.length > 2) {
        this.submitLogin(); // Only call submitLogin if both conditions are true
      } else {
        // Optionally set an error message or handle the error state here
        this.errorMessage = "Please enter a valid phone number and password.";
        this.$emit("input-validation-failed", this.errorMessage);
        this.$emit("login-failed", this.errorMessage);
        this.showDialog = true; // Stop execution if conditions are not met
      }
    },
    closeDialog() {
      // Check if this gets logged
      this.showDialog = false;
      //   console.log("Terms Dialog Open:", this.showTermsDialog);
      //   console.log("Error Dialog Open:", this.showDialog);
      // },
      // agreeTerms() {
      //   this.termsAgreed = true;
      //   this.showTermsDialog = false;
      // },
      // disagreeTerms() {

      //   this.showTermsDialog = false;
      //   this.termsAgreed=false;
      //   this.close();

    },
    async submitLogin() {

      this.loading = true;
      this.errorMessage = "";

      // Define the payload for the callable function
      const payload = {
        uid: this.currentUID,
        wid: this.WID,
        phoneNumber: this.phone_number,
        password: this.password,
        action: "login",
      };

      // Use Firebase callable function with v9 syntax

      try {
        const url = `${process.env.VUE_APP_CLOUDRUN_KEY}/validateOlxLogin`;

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const data = await response.json(); // Parsing JSON from the response
        // console.log("data:: ", data.message);

        if (this.ignoreResult) {
          return;
        }

        if (data.message == "Everthing fine") {

          const handleOLXLogin = httpsCallable(functions, "olxRoutes");
          const loginResponse = await handleOLXLogin(payload);

          // Check the response from the Firebase function
          if (loginResponse.data.success) {
            this.success = true; // Indicate success
            this.loading = false;
            // Emit success event
            this.$emit("login-success", "Connected");

            // Simulate a delay then close dialog
          } else {
            this.errorMessage = "Extended validation failed.";
            this.$emit("login-failed", this.errorMessage);
            this.showDialog = true;
            this.loading = false;
          }
        } else {
          this.errorMessage =
            " Please check your credentials and retry after some time.";
          this.$emit("login-failed", this.errorMessage);
          this.showDialog = true; // Ensure the dialog box is shown on error
          this.success = false; // Reset success flag
          this.loading = false;
        }

        // Make a POST request using Fetch API
        // fetch(url, {
        //   method: "POST", // Set the method to POST
        //   headers: {
        //     "Content-Type": "application/json", // Set the headers content type to application/json
        //   },
        //   body: JSON.stringify(payload), // Convert the postData object to JSON string
        // })
        //   .then(async(data) => {
        //     console.log("Your result: ", data); // Process your data here
        //     if (data.status == 200) {
        //       this.success = true;
        //       this.buttonColor();
        //       const handleOLXLogin = httpsCallable(functions, 'olxRoutes');
        //       const loginResponse = await handleOLXLogin(payload);
        //       console.log("user",loginResponse);

        //       this.$emit("login-success", data.body.message); // Optionally emit an event
        //       this.loading = false;
        //     } else {
        //       this.errorMessage = data.body;
        //       this.showDialog = true; // Show dialog on failure
        //       this.loading = false;
        //       this.$emit("login-failed", data.body.message); // Optionally emit an event
        //     }
        //   });

        // const handleOLXLogin = httpsCallable(functions, 'olxRoutes');
        // const loginResponse = await handleOLXLogin(payload);

        // //To handle cancel button
        // if (this.ignoreResult) return;
      } catch (error) {
        if (!this.ignoreResult) {
          this.loading = false;
          this.showDialog = true;
          this.errorMessage = "An unexpected error occurred.";
          this.$emit("login-failed", this.errorMessage);
          
        }
      } finally {
        if (!this.ignoreResult) {
          this.loading = false;
        }
      }
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    resetForm() {
      this.phone_number = "";
      this.password = "";
    },
    close() {
      //   this.$emit('close-dialog');
      this.ignoreResult = true; // Set the flag to ignore the result of the ongoing operation
      this.resetForm();
      if (this.loading) {
        this.loading = false; // Optionally reset loading state
        this.showDialog = false; // Close any open dialogs
      }
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped>
.password-setup-note {
  padding: 4px; /* 4 unit grid */
  background-color: #ffffff; /* Light grey background */
  border-radius: 4px; /* Rounded corners */
  margin-top: 1px; /* Space from the main form */
  font-size: 0.825rem; /* Smaller font size for note text */
  color: #666; /* Dark gray text color for less emphasis */
}

.note-content {
  display: block; /* Ensures proper line spacing */
}

.link {
  color: #2329cd; /* Blue color for the link */
  text-decoration: none; /* No underline */
}

.link:hover {
  text-decoration: underline; /* Underline on hover for better accessibility */
}
</style>
