<template>
    <div data-v-000dea5e="" class=" flex-d1 bg-[#F7F6FF] gap-11 font-poppins py-11 rounded-3xl w-full md:w-auto">
        <div data-v-000dea5e="">
            <p data-v-000dea5e="" class="text-3xl font-semibold text-center mb-4">
                Subscription
            </p>
            <div class="flex gap-12 flex-wrap max-md:justify-center max-md:gap-3">
                <div data-v-c5917f36="" data-v-000dea5e="" style="font-family: Inter, sans-serif;">
                    <section data-v-c5917f36="" class="flex flex-wrap justify-center gap-6 pb-12">
                        <article data-v-c5917f36=""
                            class="w-[280px] max-md:w-[220px] border text-center rounded-2xl bg-white hover:bg-gray-50 border-gray-300 transition-transform transform">

                            <header data-v-c5917f36="" class="pt-6">
                                <h1 data-v-c5917f36="" class="text-3xl max-md:text-2xl font-bold text-gray-800">
                                    Free Trial
                                </h1>
                            </header>

                            <p data-v-c5917f36="" class="mt-4 text-lg max-md:text-[0.9rem] text-gray-600 px-4">
                                Experience Insta-Everywhere free for 14 days
                            </p>

                            <ul data-v-c5917f36=""
                                class="max-md:text-[0.9rem] mt-6 text-left text-gray-700 space-y-2 px-8">
                                <li data-v-c5917f36="" class="flex items-center">
                                    <i data-v-c5917f36="" aria-hidden="true"
                                        class="v-icon notranslate mr-2 mdi mdi-check-circle theme--light"
                                        style="color: rgb(128, 86, 222); caret-color: rgb(128, 86, 222);"></i>
                                    Unlimited Access to All Features
                                </li>
                                <li data-v-c5917f36="" class="flex items-center">
                                    <i data-v-c5917f36="" aria-hidden="true"
                                        class="v-icon notranslate mr-2 mdi mdi-check-circle theme--light"
                                        style="color: rgb(128, 86, 222); caret-color: rgb(128, 86, 222);"></i>
                                    Free Cross Posting on all platforms
                                </li>
                            </ul>

                            <div data-v-c5917f36="" class="flex flex-col gap-4">
                                <div data-v-c5917f36="" class="px-9">
                                    <button @click="loginHandle" data-v-c5917f36=""
                                        class="rounded-md py-2 px-3 w-full text-white text-sm font-bold mt-6 bg-[#8056DE]">
                                        Sign up Now
                                    </button>
                                </div>
                                <p data-v-c5917f36="" class="mb-4"></p>
                            </div>

                        </article>
                    </section>
                </div>

                <div data-v-000dea5e="" style="font-family: Inter, sans-serif;">
                    <div>
                        <!-- Subscription Section -->
                        <section class="flex flex-wrap justify-center gap-4 pb-10 xl:gap-1">
                            <div
                                class="px-10 p-4 md:p-2 border rounded-2xl md:w-[280px] bg-white hover:bg-gray-100 border-gray-300">
                                <h1 class="text-3xl font-bold flex justify-center pt-3 text-gray-800">
                                    Annual
                                </h1>
                                <h1 class="mt-4 text-sm flex justify-center text-gray-600"></h1>
                                <h4
                                    class="mt-2 text-3xl font-semibold flex flex-col justify-center items-center text-gray-800">
                                    {{newPlan}}
                                </h4>
                                <div class="flex flex-col gap-2 justify-center items-center">
                                    <div>
                                        <span class="text-gray-400 text-xl line-through pt-1 font-medium">
                                            {{oldPlan}}
                                        </span>
                                    </div>
                                    <div>
                                        <span class="text-gray-400 text-xl">Per Year</span>
                                    </div>
                                    <div class="mt-5">
                                        <button type="button"
                                            class="v-btn v-btn--has-bg theme--light v-size--default black"
                                            style="height: 50px;">
                                            <span class="v-btn__content">
                                                <span class="text-white text-xl">50% OFF</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <!-- Subscribe Button -->
                                <div>
                                    <button
                                        class="bg-[#8056DE] mb-6 rounded-md py-2 px-3 w-full text-white text-sm font-bold mt-6"
                                        @click="loginHandle">
                                        Sign Up Now
                                    </button>
                                </div>
                            </div>
                            <div class="mb-14"></div>
                        </section>
                        <div class="sm:flex sm:items-center sm:justify-between">
                            <div class="w-full">
                                <!-- Additional Content (if any) -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LiteTrial from "./LiteTrial.vue"
import axios from "axios";
import LiteSubscription from '@/pages/fly-lite/LiteSubcription.vue';
import { auth, colUsers, db, platforms } from '@/utils/firebase.utils';
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, updateDoc } from 'firebase/firestore';
import connectedAppsMixins from "@/mixins/connected.apps.mixins";
import { onAuthStateChanged } from 'firebase/auth';
import TestingPost from '@/components/post/TestingPost.vue';
import { functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import PostCard from "@/components/post/Pc.vue";
import InfiniteLoading from "vue-infinite-loading";
export default {
    props: {
        checkAuth: {
            type: Boolean,
            default: false,
        },
        handleLogin: {
            type: Function,
            required: true
        }
    },
    components: {
        LiteSubscription,
        LiteTrial,
        TestingPost
    },
    data() {
        return {
            plans:null,
            countryCode:'IN',
            
        };
    },
    computed: {
        newPlan(){
            if(this.plans){
                if(this.countryCode=='IN'){
                    return `₹ ${this.plans.monthly_price_inr}`
                }
                return `$ ${this.plans.monthly_price_usd}`
            }
        },
        oldPlan(){
            if(this.plans){
                if(this.countryCode=='IN'){
                    return `₹ ${this.plans.previous_monthly_price_inr}`
                }
                return `$ ${this.plans.previous_monthly_price_usd}`
            }
        }
    },
    mixins: [connectedAppsMixins],
    methods: {
        loginHandle() {
            this.handleLogin();
        },
        async fetchPlans() {
            const vm = this;
            try {
                let col = collection(db, "fly_cross_posting_plans");
                // 49.99 - 29.99
                // 3999 - 2499
                let pricingField = "monthly_price_inr";
                let queries = [];
                queries.push(orderBy(pricingField, "asc"));

                let q = query(col, ...queries);
                let docs = (await getDocs(q)).docs;
                let allPlans = docs.map((i) => {
                    return {
                        ...i.data(),
                        id: i.id,
                    };
                });
                vm.plans = allPlans[0];
                console.log("all Plans",vm.plans);
            } catch (error) {
                vm.handleError(error);
            }
        },
      async fetchCountry() {
    let ipinfo = null;
    const maxRetries = 5;
    const baseDelay = 1000; // base delay of 1 second

    for (let i = 0; i < maxRetries; i++) {
        try {
            const ipinfo_url = "https://ipinfo.io/?token=56f9ff0203be46";
            ipinfo = (await axios.get(ipinfo_url)).data;
            break; // If the request is successful, break the loop
        } catch (error) {
            console.error(`Attempt ${i + 1} failed: ${error.message}`);
            // Implement exponential backoff for retries
            const delay = baseDelay * Math.pow(2, i); 
            await new Promise(resolve => setTimeout(resolve, delay));
        }
    }

    if (ipinfo) {
        this.countryCode = ipinfo.country;
    } else {
        console.error('Failed to fetch country info after multiple retries.');
    }
}

    },
    mounted(){
        this.fetchCountry();
    },
    created(){
        this.fetchPlans();
    }
};
</script>


<style scoped>
</style>