<template>
    <div style="font-family: 'Inter', sans-serif">
        <please-wait v-if="isLoading" class="mt-5" />
        <div v-else>

            <please-wait v-if="loading" class="mt-5" />
            <div class="text-center" v-if="!loading && plans.length == 0">
                <h4 class="text-xl font-bold">No plans to display</h4>
            </div>
            <section v-if="!loading && plans.length > 0" class=" flex flex-wrap justify-center gap-4 pb-10 xl:gap-1">
                <div v-for="(plan, index) in plans" :key="index" class=" px-10 p-4 md:p-2 border border-gray-200 rounded-2xl md:w-[280px]"
                    :class="isActivePlan(plan, userData) ? 'bg-gray-800 border-gray-700' : 'bg-white hover:bg-gray-100 border-gray-300'">
                    
                    <h1 class="text-3xl font-bold flex justify-center pt-3" :class="isActivePlan(plan, userData) ? 'text-gray-100' : 'text-gray-800'">
                        {{ plan.name }}
                    </h1>

                    <h1 class="mt-4 text-sm flex justify-center" :class="isActivePlan(plan, userData) ? 'text-gray-300' : 'text-gray-600'">
                        {{ plan.short_desc }}
                    </h1>

                    <h4 class="mt-2 text-3xl font-semibold flex flex-col justify-center items-center"
                        :class="isActivePlan(plan, userData) ? 'text-gray-100' : 'text-gray-800'">
                        <template v-if="isINR && userCountry === 'IN'">
                            ₹ {{ plan.monthly_price_inr }}
                        </template>
                        <template v-else> $ {{ plan.monthly_price_usd }} </template>
                    </h4>

                    <div class="flex flex-col gap-2 justify-center items-center">
                        <div>
                            <span class="text-gray-400 text-xl line-through pt-1 font-medium">
                                <template v-if="isINR && userCountry === 'IN'">
                                    ₹ {{ plan.previous_monthly_price_inr }}
                                </template>
                                <template v-else> $ {{ plan.previous_monthly_price_usd }} </template>
                            </span>
                        </div>
                        <div>
                            <span class="text-gray-400 text-xl">Per Year</span>
                        </div>
                        <div class="mt-5">
                            <v-btn depressed color="black" height="50px"> 
                                <span class="text-white text-xl">50% OFF</span>
                            </v-btn>
                        </div>
                    </div>

                    <div v-if="isActivePlan(plan, userData)" :class="plan.name === 'Free' ? '' : 'hidden'">
                        <button class="w-full px-3 py-2 mt-6 mb-8 text-sm font-bold text-black bg-white rounded-md">Free</button>
                    </div>

                    <div v-if="isActivePlan(plan, userData)" :class="plan.name === 'Free' ? 'hidden' : ''">
                        <span v-if="isSubscriptionCancelled">
                            <div class="text-red-500 mt-2 font-semibold text-center">Cancelled</div>
                        </span>
                        <button class="w-full px-3 py-2 mt-4 mb-2 text-sm font-bold text-white bg-green-500 rounded-md">
                            <template v-if="userData.fly_lite_plan_valid_until">
                                Plan valid until -
                                {{ new Date(userData.fly_lite_plan_valid_until.seconds * 1000).toLocaleDateString("en-US", {day: "numeric", month: "short", year: "numeric"}) }}
                            </template>
                            <span v-else> Subscribed </span>
                        </button>
                        <div class="text-gray-100 text-sm flex justify-center" v-if="isActivePlan(plan, userData) && !isSubscriptionCancelled && userData.fly_lite_subscribed_at">
                            <span>Subscribed on {{ formatDateDDMMYYYY(userData.fly_lite_subscribed_at) }}</span>
                        </div>
                        <div class="text-gray-100 text-sm flex justify-center" v-if="isActivePlan(plan, userData) && !isSubscriptionCancelled && userData.fly_lite_subscription_renew_at">
                            <span>Renewal on {{ formatDateDDMMYYYY(userData.fly_lite_subscription_renew_at) }}</span>
                        </div>
                    </div>

                    <div v-else>
                        <button @click="plan.name === 'Free' ? null : subscribePlan(plan)"
                            class="bg-[#8056DE] mb-6 rounded-md py-2 px-3 w-full text-white text-sm font-bold mt-6"
                            :class="plan.name === 'Free' ? 'hidden' : ''">
                            Subscribe
                        </button>
                    </div>
                    <div class="test-div"></div>
                    <div v-if="isActivePlan(plan, userData)" class="flex items-center justify-center"
                        :class="plan.name === 'Free' ? 'hidden' : ''" @click="cancelSubscription">
                        <button v-if="!cancelLoading && (authUser.fly_subscription_cancelled == undefined || !isSubscriptionCancelled)"
                            :class="userData.fly_lite_plan_valid_until == null ? '' : 'hidden'"
                            class="w-full px-3 py-2 mb-8 text-sm font-medium text-white transition-colors duration-300 transform rounded-md">
                            Cancel
                        </button>
                        <img v-if="cancelLoading" src="../../assets/img/cancel-loading.gif" class="w-8" alt="" />
                    </div>

                    <div v-for="features in plan.features" :key="features" class="flex items-center mb-3" :class="index === 0 ? 'mt-5' : ''">
                        <v-icon color="#8056DE">mdi-check</v-icon>
                        <span class="mx-4" :class="isActivePlan(plan, userData) ? 'text-gray-300' : 'text-gray-700'">
                            {{ features }}
                        </span>
                    </div>
                </div>
                <div class="test-div"></div>
            </section>
            <div class="sm:flex sm:items-center sm:justify-between">
                <div class="w-full">
                    <p v-if="currentSubscription.status === 'created'" class="text-center text-black my-2">
                        Subscription is in progress...
                        <v-progress-circular indeterminate :size="20" :width="3" class="pl-8" color="primary"></v-progress-circular>
                    </p>
                </div>
            </div>
        </div>
        <v-dialog v-model="dialog" max-width="700px" persistent>
            <v-card class="pa-2">
                <v-card-title class="pt-3 pb-3 primary--text">
                Update Payment Details</v-card-title
                >
                <v-card-text>
                <stripe-payment-method
                    @update="subscribeStripe()"
                    :isDialog="true"
                    @close="dialog = false"
                />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    collection,
    doc,
    getDoc,
    getDocs,
    orderBy,
    query,
    where,
    updateDoc,
    onSnapshot
} from "firebase/firestore";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import { colUsers, db, functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import _ from "lodash";
import DialogCard from "@/components/ui/DialogCard.vue";
import InputField from "@/components/ui/form/InputField.vue";
import StripePaymentMethod from "@/components/account/StripePaymentMethod.vue";
import moment from "moment";
export default {
    components: { PleaseWait, DialogCard, InputField, StripePaymentMethod },
    data() {
        return {
            userData: {},
            cancelLoading: false,
            dialog: false,
            plans: [],
            currentSubscription: {},
            isLoading: true,
            selectedPlan: null,
            isSubscriptionCancelled: false,
            plan: null,
            subscription_id: "",
            addonId: "",
            tab: 0,
            loading: false,
            dialog: false,
            selectedPlan: null,
        };
    },
    computed: {
        planName() {
            let plan = this.authUser.fly_lite_plan;
            if (plan == "lite") {
                return "Personal";
            } else if (plan == "boost") {
                return "Enterprise";
            } else if (plan == "plus") {
                return "Professional";
            }
            return "Free";
        },
        renewDate() {
            let date = null;
            date = this.authUser.plan_renew_date.seconds
                ? this.authUser.plan_renew_date.seconds
                : this.authUser.plan_renew_date;
            date = new Date(
                this.authUser.plan_renew_date.seconds * 1000
            ).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
            return date;
        },
    },
    methods: {
        isActivePlan(plan, userData) {
            // let { status, plan_id } = this.currentSubscription;
            return plan.id == userData.fly_lite_plan;
        },
        async fetchUserCountry() {
            try {
                const response = await fetch(
                    "https://ipinfo.io/json?token=f8e9b91843be5c"
                );
                const data = await response.json();
                this.userCountry = data.country; // Corrected from country_code to country
                console.log(this.userCountry); // Ensure we're using this.userCountry for consistency
            } catch (error) {
                console.error("Error fetching user country:", error);
                this.userCountry = "US"; // Default to US if there's an error
            }
        },
        async initPlan() {
            const vm = this;
            try {
                vm.isLoading = true;
                await this.fetchUserCountry();
                let userRef = doc(db, "users", vm.uid);
                onSnapshot(userRef, (userDocSnapshot) => {
                    if (userDocSnapshot.exists()) {
                        const user = userDocSnapshot.data();
                        vm.userData = userDocSnapshot.data();
                        this.isSubscriptionCancelled = user.fly_lite_cancelled || false;
                    } else {
                        console.log("No subscription document found");
                        vm.currentSubscription = {};
                    }
                });
                let subscriptionRef = doc(
                    collection(db, "fly_cross_posting_subscriptions"),
                    vm.uid
                );
                onSnapshot(subscriptionRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        vm.currentSubscription = docSnapshot.data();
                    } else {
                        console.log("No subscription document found");
                        vm.currentSubscription = {};
                    }
                });
                vm.isLoading = false;
            } catch (error) {
                console.log(error);
                vm.handleError(error);
            }
        },
        async fetchPlans() {
            const vm = this;
            let currentUID = vm.currentUID || vm.authUser.uid || vm.uid;
            if (!currentUID && !vm.WID) return;
            try {
                vm.loading = true;
                await this.fetchUserCountry();
                let userRef = doc(db, "users", vm.uid);
                let user = (await getDoc(userRef)).data();
                let col = collection(db, "fly_cross_posting_plans");
                // 49.99 - 29.99
                // 3999 - 2499
                this.isSubscriptionCancelled = user.fly_lite_cancelled || false
                vm.userData = user;
                // let pricingField = "monthly_price_usd"; // Default to USD pricing
                // if (vm.userCountry === "IN") {
                    let pricingField = "monthly_price_inr"; // Use INR pricing for users in India
                // }
                let queries = [];
                queries.push(orderBy(pricingField, "asc"));

                let q = query(col, ...queries);
                let docs = (await getDocs(q)).docs;
                let allPlans = docs.map((i) => {
                    return {
                        ...i.data(),
                        id: i.id,
                    };
                });
                vm.plans = allPlans;
                console.log("all Plans",vm.plans);
                vm.loading = false;
            } catch (error) {
                vm.handleError(error);
            }
        },
        async subscribePlan(plan) {
            const vm = this;
            try {
                if (!vm.isINR) {
                    vm.openStripe(plan);
                    return;
                }
                vm.$confirm.show({
                    message: `Are you sure you wish to subscribe to ${plan.name} package?`,
                    onConfirm: async () => {
                        vm.$loader.show();
                        let data = { plan: plan.id };
                        data.type = "cross-posting";
                        data.uid = vm.uid;
                        let subscribe = httpsCallable(functions, "razorpaySubscribe");
                        let result = (await subscribe(data)).data;
                        if (result.status == "authenticate_payment") {
                            vm.openRazorPay(result.subscription_id, "", "cross-posting-subscription");
                        }
                        vm.$loader.hide();
                    },
                });
            } catch (error) {
                vm.$loader.hide();
                vm.handleError(error);
            }
        },
        openStripe(plan) {
            this.selectedPlan = { ...plan, type: "cross-posting" };
            if (!this.authUser.card_details) {
                this.dialog = true;
                return;
            }
            this.subscribeStripe();
        },
        async subscribeStripe() {
            this.dialog = false;
            const vm = this;
            try {
                vm.$loader.show();
                let subscribe = httpsCallable(functions, "stripeSubscribe");
                let request = {
                id: this.selectedPlan.id,
                type: this.selectedPlan.type,
                isINR: vm.isINR,
                };
                let result = (await subscribe(request)).data;
                if (result.type == "confirm_payment") {
                    const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
                    let { error } = await stripe.confirmCardPayment(result.client_secret);
                    if (error) {
                        vm.$alert.show("Error in payment, please try again later");
                    }
                }
                vm.$loader.hide();
            } catch (error) {
                vm.handleError(error);
            }
        },
        isIndianPhoneNumber() {
            return this.userData.country_code && this.userData.country_code === "IN";
        },
        sleep(s) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, s || 1000);
            });
        },
        openRazorPay(id, recurring, type) {
            const vm = this;
            var options = {
                key: process.env.VUE_APP_RP_KEY,
                subscription_id: id,
                name: "ZoomLocal",
                description: recurring ? recurring : "Monthly Plan",
                handler: function (response) {
                    vm.handlePayment(response, type);
                    // console.log('response', JSON.stringify(response));
                    // Redirect to /account page after successful payment
                    // vm.$router.push("/account"); // If using Vue Router
                    // Or use window.location for a hard redirect:
                    // window.location.href = '/account';
                },
                prefill: {
                    name: this.authUser.display_name,
                    email: this.authUser.email,
                    //contact: this.authUser.phone_number,
                },
                theme: {
                    color: "#1773EA",
                },
            };

            // Code to open Razorpay payment modal
            var rzp = new Razorpay(options);
            rzp.open();
        },
        async handlePayment(json, type) {
            const vm = this;
            try {
                vm.$loader.show();
                let userRef = doc(db, "users", vm.uid);
                let addonId = this.addonId;
                let subscribe = httpsCallable(functions, "validateRazorpayPayment");
                (await subscribe({ json, type, addonId })).data;
                await updateDoc(userRef, {
                    fly_subscription_cancelled: false,
                });
                this.initPlan();
                vm.$loader.hide();
            } catch (error) {
                vm.handleError(error);
            }
        },
        async cancelSubscription() {
            this.$confirm.show({
                message: "Are you sure you want to cancel?",
                onConfirm: async () => {
                    try {
                        let userRef = doc(db, "users", this.uid);
                        this.cancelLoading = true;
                        await updateDoc(userRef, {
                            fly_subscription_cancelled: true,
                        });
                        let subscriptionRef = doc(
                            collection(db, "fly_cross_posting_subscriptions"),
                            this.uid
                        );
                        let currentSubscription =
                            (await getDoc(subscriptionRef)).data() || {};
                        console.log(currentSubscription);
                        let functionName = "razorpaySubscribe";
                        if (currentSubscription.payment_gateway == "stripe") {
                            functionName = "stripeSubscribe";
                        }
                        let data = { subscription: currentSubscription.subscription_id, type: "cross-posting", action: 'cancel' };
                        let subscribe = httpsCallable(functions, functionName);
                        (await subscribe(data)).data;

                        this.isSubscriptionCancelled = true;
                        this.cancelLoading = false;
                    } catch (error) {
                        this.cancelLoading = false;
                        this.$alert.show(error.message);
                    }
                },
            });
        },
    },
    mounted() {
        this.initPlan();
        this.fetchPlans();
    },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.test-div{
    margin-bottom: 1.1rem;
}
.addon-input {
    .v-input__slot {
        background: #f2f6fa !important;
        border-radius: 50px !important;
    }
}

.list-view-container {
    overflow-x: hidden;
    width: 100%;
}

@media (max-width: 768px) {
    .list-view-container {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .list-view-container>.flex {
        min-width: 700px;
        flex-wrap: nowrap;
    }
}

.list-view-container .flex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.list-view-container .flex>div {
    flex: 1;
    padding: 8px;
    text-align: left;
}

@media (max-width: 768px) {
    .list-view-container {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .list-view-container .flex {
        min-width: 800px;
        /* Increase this if necessary to prevent overlapping */
    }

    .list-view-container .flex>div {
        padding: 4px;
        /* Adjust padding to manage space */
        font-size: 14px;
        /* You may adjust font size for better visibility */
    }
}

/* Ensure there's no overlap by adding specific flex-basis values */
.list-view-container .flex>div:nth-child(1) {
    flex-basis: 18%;
    /* Adjust percentage based on your content needs */
}

.list-view-container .flex>div:nth-child(2),
.list-view-container .flex>div:nth-child(3),
.list-view-container .flex>div:nth-child(4),
.list-view-container .flex>div:nth-child(5) {
    flex-basis: 14%;
    /* Smaller flex basis for other columns */
}
</style>