<template>
  <v-menu
    v-if="isAuth"
    offset-y
    left
    v-model="menu"
   
    class="relative z-[9999999] bg-[#3C50E0]"
    max-width="300px"
  >
    <template v-slot:activator="{ on }">
      <div
        v-on="on"
        flat
        :width="isMobile ? '40px' : '300px'"
        :disabled="isLoading"
      >
        <v-skeleton-loader
          v-if="isLoading"
          type="list-item-avatar"
        ></v-skeleton-loader>
        <user-logo
          :size="40"
          v-else-if="isMobile"
          :user="selectedUser"
        ></user-logo>
        <v-list-item v-else>
          <!-- Use v-if with a computed property to conditionally show user logo and name -->
          <div v-if="showUserLogoAndName" class="flex jus">
            <v-list-item-avatar class="cursor-pointer">
              <user-logo :size="50" :user="selectedUser"></user-logo>
            </v-list-item-avatar>
            <!-- <v-list-item-content>
              <div>
                <v-list-item-title>{{
                  selectedUser.display_name
                }}</v-list-item-title>
              </div>
              <v-list-item-subtitle class="caption">{{
                selectedUser.email
              }}</v-list-item-subtitle>
            </v-list-item-content> -->
          </div>

          <!-- <v-list-item-avatar>
            <v-icon class="drop">mdi-chevron-down</v-icon>
          </v-list-item-avatar> -->
        </v-list-item>
      </div>
    </template>
    <v-list class="pt-0">
      <v-divider class="mb-2"></v-divider>
      <div class="flex p-6">
        <v-list-item-avatar @click="$router.replace('/edit-profile')" class="cursor-none">
          <user-logo :size="50" :user="selectedUser"></user-logo>
        </v-list-item-avatar>
        <v-list-item-content @click="$router.replace('/edit-profile')" class="cursor-pointer">
  <div class="text-neutral-900 text-lg font-medium font-['Poppins'] flex items-center">
    <span>{{ selectedUser.display_name }}</span>
    <v-icon class="ml-2" small>mdi-pencil</v-icon>
  </div>
  <div class="text-slate-500 text-xs font-normal font-['Poppins']">
    {{ selectedUser.email }}
  </div>
</v-list-item-content>

      </div>

      <div class="w-[347px] h-[0px] border-2 border-slate-100"></div>
      <!-- <v-list-item dense @click="$router.replace('/edit-profile')" class="my-2">
        <div class="w-[37px] h-[37px] bg-slate-100 rounded-full">
          <div class="relative top-2 left-3">
            <img loading="lazy" src="@/assets/img/mgr.svg" />
          </div>
        </div>
        <v-list-item-content>
          <v-list-item-title class="ml-4">Manage Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-list-item dense @click="$router.replace('/account')" class="my-2">
        <div class="w-[37px] h-[37px] bg-slate-100 rounded-full">
          <div class="relative top-2 left-3">
            <img loading="lazy" src="@/assets/img/mgacc.svg" />
          </div>
        </div>
        <v-list-item-content>
          <v-list-item-title class="ml-4">Manage Account</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense @click="$router.replace('/team')" class="my-2">
        <div class="w-[37px] h-[37px] bg-slate-100 rounded-full">
          <div class="relative top-2 left-3">
            <img class="w-[22px] h-[22px] -ml-1" loading="lazy" src="@/assets/img/team.svg" />
          </div>
        </div>
        <v-list-item-content>
          <v-list-item-title class="ml-4">Manage Team</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense @click="$router.replace('/subscription')">
        <div class="w-[37px] h-[37px] bg-slate-100 rounded-full">
          <div class="relative top-2 left-2">
            <img loading="lazy" src="@/assets/img/subs.svg" />
          </div>
        </div>
        <v-list-item-content>
          <v-list-item-title class="ml-4">Subscription</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item dense @click="$router.replace('/terms-of-use')" class="my-2">
        <div class="w-[37px] h-[37px] bg-slate-100 rounded-full">
          <div class="relative top-2 left-2">
            <img loading="lazy" src="@/assets/img/terms.svg" />
          </div>
        </div>
        <v-list-item-content>
          <v-list-item-title class="ml-4">Terms of use</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- <v-list-item
        dense
        @click="$router.replace('/privacy-policy')"
        class="my-2"
      >
        <div class="w-[37px] h-[37px] bg-slate-100 rounded-full">
          <div class="relative top-2 left-2">
            <img loading="lazy" src="@/assets/img/privacy.svg" />
          </div>
        </div>
        <v-list-item-content>
          <v-list-item-title class="ml-4">Privacy Policy</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item dense @click="open()" class="my-2">
        <div class="w-[37px] h-[37px] bg-slate-100 rounded-full">
          <div class="relative top-2 left-2">
            <img loading="lazy" src="@/assets/img/sup.svg" />
          </div>
        </div>
        <v-list-item-content>
          <v-list-item-title class="ml-4">Support</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item dense @click="navigateToFeedback" class="my-2">
  <div class="w-[37px] h-[37px] bg-slate-100 rounded-full">
    <div class="relative top-2 left-2">
      <img loading="lazy" src="@/assets/img/bulb.svg" class="w-6  h-6" />
    </div>
  </div>
  <v-list-item-content>
    <v-list-item-title class="ml-4">Feedback</v-list-item-title>
  </v-list-item-content>
</v-list-item>

      <!-- <v-list-item dense @click="logout()">
        <v-list-item-avatar size="20">
          <v-icon>mdi-location-exit</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <div class="flex justify-end mr-2">
        <v-btn
          :loading="loading"
          text
          class="w-[114px] h-[39px] bg-[#3C50E0] rounded-[32px]"
          color="white"
          @click="logout()"
          ><span class="text-xs font-medium text-white">Logout</span></v-btn
        >
      </div>
      <!-- Display the app version -->
      <div class="ml-4 app-version">[ {{ getVersion() }} ]</div>
    </v-list>
  </v-menu>
</template>

<script>
import { onAuthStateChanged, signOut } from "firebase/auth";
import UserLogo from "./UserLogo.vue";
import { auth, colUsers, db } from "@/utils/firebase.utils";
import {
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore";

export default {
  components: { UserLogo },
  data() {
    return {
      users: [],
      menu: false,
      subscribe: null,
      isLoading: false,
      loading: false,
    };
  },
  computed: {
    showUserLogoAndName() {
      return window.innerWidth > 480;
    },
    selectedUser() {
      let user = this.users.find((i) => i.id == this.currentOriginalUID);
      return user || this.authUser;
    },
  },
  methods: {
    navigateToFeedback() {
    window.open("https://fly-social.canny.io/feature-requests", "_blank");
  },
    getVersion() {
      if (process.env.VUE_APP_ENV === "production") {
        return process.env.VUE_APP_VERSION;
      } else {
        return process.env.VUE_APP_ENV + "-" + process.env.VUE_APP_VERSION;
      }
    },
    open() {
      if ($zoho) {
        $zoho.salesiq.floatwindow.visible("show");
      }
    },
    
    async logout() {
      // console.log("entered in logout");
      let uid = auth.currentUser.uid;
      const loginSessionRef = collection(db, "fly_login_sessions");
      const loginSessionData = {
        uid: uid,
        created_at: new Date(),
        origin_url: window.location.hostname,
        action: "logout",
      };
      addDoc(loginSessionRef, loginSessionData);
      this.$store.commit("SET_AUTH", false);
      this.users = [];
      this.$mixpanel.track("User Logged Out");
      this.$mixpanel.reset();
      await signOut(auth);
      this.$router.replace("/login")
      localStorage.clear();
    },
    changeUser(user) {
      this.$store.commit("SET_CURRENT_UID", user.id);
      this.$store.commit("SET_CURRENT__ORIGINAL_UID",user.id);
      this.$store.commit("SET_CURRENT_USER", user);
      updateDoc(doc(colUsers, this.uid), {
        default_uid: user.id,
      });
    },
    async listenUsers() {
      const vm = this;
      vm.isLoading = true;
      let currentUID = vm.authUser.default_uid || vm.uid;
      if (!currentUID) return;
      try {
        const userDocRef = doc(colUsers, auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          let user = { ...userDocSnap.data(), id: userDocSnap.id };
          // console.log("user.id:", user.id);
          vm.users = [user];
          // console.log("vm.users[0].id:", vm.users[0].id);
          vm.$store.commit("SET_CURRENT_UID", user.id);
          vm.$store.commit("SET_CURRENT__ORIGINAL_UID",user.id);
          vm.$store.commit("SET_CURRENT_USER", user || vm.authUser);
          vm.isLoading = false;
        } else {
          console.log("User document does not exist for uid:", vm.uid);
        }
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if (this.subscribe) this.subscribe();
      console.log(user);
      if (user) {
        setTimeout(() => {
          this.listenUsers();
        }, 2000);
      }
    });
  },
};
</script>

<style scoped></style>
