import mixpanel from 'mixpanel-browser';

export default {
  install(Vue, options) {
    mixpanel.init("0c0b9b9dd8bb2dfab0fdb32f2bc1a53b", {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });

    Vue.prototype.$mixpanel = mixpanel;
  }
};
