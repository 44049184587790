<template>
    <v-card flat tile max-width="500px" class="mx-auto mb-2">
      <v-list-item class="px-0">
        <v-list-item-avatar class="mr-2" size="40" @click="openProfile()">
          <v-img v-if="userData.picture" :src="userData.picture" width="30px"></v-img>
          <user-logo v-else :size="40" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-if="userData.name" class="text-sm md:text-lg d-flex align-center">
            {{ userData.name }}
          </v-list-item-title>
          <v-list-item-title v-else>
            username
          </v-list-item-title>
          <v-list-item-subtitle class="text-xs md:text-sm">
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-img
            src="https://firebasestorage.googleapis.com/v0/b/around-dev-1.appspot.com/o/fly_platforms%2Ficons%2Fbluesky.svg?alt=media&token=74d7a221-51fa-46d2-84cf-daa58e731ff5"
            width="30px"
          ></v-img>
        </v-list-item-action>
      </v-list-item>
  
      <v-card-text class="pa-0" v-if="hasPreview">
        <div v-html="bluesky_text ? bluesky_text : content" class="mb-3" />
      </v-card-text>
  
      <v-card :href="url" target="_blank" v-if="hasPreview">
        <v-img :src="detail?.image" />
        <v-card-title>{{ detail?.title }}</v-card-title>
        <v-card-text>{{ detail?.description }}</v-card-text>
      </v-card>
  
      <v-card-text v-else class="pa-0">
        <div v-html="bluesky_text ? bluesky_text : content" class="mb-3" />
        <div v-html="hashtags" />
  
        <!-- Image Grid -->
        <v-row v-if="imageList.length" dense>
          <v-col v-for="(item, i) in imageList" :key="i" :cols="imageList.length === 1 ? 12 : 6">
            <v-img :src="item.src" />
          </v-col>
        </v-row>
  
        <div class="d-flex my-3 align-center">
          <div class="flex-1">
            <v-icon>mdi-message-outline</v-icon>
          </div>
          <div class="flex-1">
            <v-icon>mdi-repeat-variant</v-icon>
          </div>
          <div class="flex-1">
            <v-icon>mdi-heart-outline</v-icon>
          </div>
          <div class="flex-1">
            <v-icon>mdi-dots-horizontal</v-icon>
          </div>
        </div>
      </v-card-text>
  
      <v-divider></v-divider>
    </v-card>
  </template>
  
<script>
import UserLogo from "@/components/UserLogo.vue";

export default {
  components: { UserLogo },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    userData: {
      type: Array,
      default: () => ([]),
    },
    detail: Object,
    hasPreview: Boolean,
    url: String,
    bluesky_text: String,
  },
  computed: {
    hashtags() {
      let hashtags = this.post.hashtags || [];
      return hashtags
        .map((i) => {
          if (!i.startsWith("#")) return `#${i}`;
          return i;
        })
        .toString()
        .split(",")
        .join(" ");
    },
    content() {
      let content = this.post.text || "";
      content = content.toString().replace(/(?:\r\n|\r|\n)+/g, "<br>");
      content = content.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" class="primary--text text-decoration-underline">$1</a>'
      );
      return content;
    },
    imageList() {
      let list = [];
      if (this.post.images) {
        list = this.post.images.slice(0, 4).map((item) => ({
          src: item.thumbnail || item.url || item,
        }));
      }
      return list;
    },
  },
};
</script>


  
  <style></style>
  