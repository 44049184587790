import { render, staticRenderFns } from "./UploadProgressDialog.vue?vue&type=template&id=389e99d8&scoped=true"
import script from "./UploadProgressDialog.vue?vue&type=script&lang=js"
export * from "./UploadProgressDialog.vue?vue&type=script&lang=js"
import style0 from "./UploadProgressDialog.vue?vue&type=style&index=0&id=389e99d8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "389e99d8",
  null
  
)

export default component.exports