<template>
  <v-container style="max-width: 1000px">
    <div class="d-flex mb-3 mt-3">
      <div class="title">Add members to your team</div>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="dialog = true">
        <v-icon left>mdi-plus</v-icon>
        Add Member
      </v-btn>
    </div>
    <div class="d-flex align-center mb-3 note">
      <v-icon color="info" class="mr-2">mdi-information-outline</v-icon>
      <span>Note: You can only add team members who have updated their emails in their respective profiles.</span>
    </div>
    <v-card outlined class="overflow-hidden">
      <v-data-table :loading="isUsersLoading" :items="usersList" :headers="headers">
        <template v-slot:item.status="{ item }">
          <span :class="{ 'text-success': item.status === 'accepted', 'text-warning': item.status === 'pending' }">
            {{ item.status }}
          </span>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon text color="green" @click="editUser(item)">
            mdi-pencil
          </v-icon>
          <v-icon text color="red" @click="removeUser(item)">
            mdi-delete-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="400px">
      <dialog-card :title="dialogTitle" @close="dialog = false" :loading="loading" @submit="saveUser()">
        <v-text-field label="Email" v-model="email" outlined dense></v-text-field>
      </dialog-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DialogCard from "@/components/ui/DialogCard.vue";
import { auth, colUsers, db } from "@/utils/firebase.utils"; // Ensure `db` is imported here
import _ from "lodash";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import {
  arrayRemove,
  arrayUnion,
  doc,
  onSnapshot,
  documentId,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
  deleteDoc,
  collection,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

export default {
  components: { DialogCard },
  data() {
    return {
      dialog: false,
      workspaceDialog: false,
      loading: false,
      email: "",
      userId: null,
      isEditMode: false,
      usersList: [],
      isUsersLoading: false,
      workspaces: [],
      headers: [
        {
          text: "Display Name",
          class: "text-uppercase primary white--text",
          value: "display_name",
        },
        {
          text: "Email",
          value: "email",
          class: "text-uppercase primary white--text",
        },
        {
          text: "Request Status",
          value: "status",
          class: "text-uppercase primary white--text",
        },
        {
          text: "Actions",
          value: "action",
          class: "text-uppercase primary white--text",
        },
      ],
    };
  },
  mounted() {
    this.fetchUsers();
  },
  computed: {
    dialogTitle() {
      return this.isEditMode ? "Edit User" : "Add User";
    },
  },
  methods: {
    async removeUser({ id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to remove this member?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            const removeUserFunction = httpsCallable(functions, 'removeUserFunction');
            const response = await removeUserFunction({ sender_id: vm.uid, uid: id });
            if (response.data.success) {
              vm.usersList = vm.usersList.filter(user => user.id !== id);
              vm.fetchUsers();
              vm.$alert.show("User removed successfully");
            } else {
              vm.$alert.show("Failed to remove user. Please try again.");
            }
            vm.$loader.hide();
          } catch (error) {
            vm.handleError(error);
          }
        },
      });
    },
    async editUser(user) {
      this.$store.dispatch('addTeamMember', true);
      this.$router.push({ name: 'Assign-workspace', params: { email: user.email, uid: user.id , edit: true} });
    },
    async addingNewUser(){
      const vm = this;
      try {
        const mail = vm.email
        vm.email = "";
        vm.userId = null;
        vm.isEditMode = false;
        vm.dialog = false;
        vm.loading = false;
        vm.fetchUsers();
        vm.$store.dispatch('addTeamMember', true);
        this.$router.push({ name: 'Assign-workspace', params: { email: mail, uid: mail } });
      } catch (error) {
        vm.handleError(error);
      }
    },
    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    async saveUser() {
      const vm = this;
      try {
        if (!this.isValidEmail(vm.email)) {
          vm.$alert.show("Please enter a valid email address");
          return;
        }
        vm.loading = true;
        let userQuery = query(
          colUsers,
          where("email", "==", vm.email),
          limit(1)
        );
        let userDocs = (await getDocs(userQuery)).docs;
        let user = (await getDoc(doc(colUsers, vm.uid))).data();
        let users = user.users || [];
        if (userDocs[0] == null) {
          if (users.indexOf(vm.email) != -1) {
            vm.loading = false;
            vm.$alert.show("User already exists.");
            return;
          }
          this.addingNewUser();
          return ;
        }
        let uid = userDocs[0].id;
        if(this.email==user.email){
          vm.loading = false;
          vm.$alert.show("Cannot add your own account");
          return ;
        }
        if (vm.isEditMode) {
          // For edit mode
          // Update logic here if needed
        } else {
          // For add mode
          if (users.indexOf(uid) != -1) {
            vm.loading = false;
            vm.$alert.show("User already exists.");
            return;
          }

        }
        vm.email = "";
        vm.userId = null;
        vm.isEditMode = false;
        vm.dialog = false;
        vm.loading = false;
        vm.fetchUsers();
        // Set the Vuex state to indicate a team member has been added
        this.$store.dispatch('addTeamMember', true);
        // Navigate to the new page
        this.$router.push({ name: 'Assign-workspace', params: { email: vm.email, uid: uid } });
      } catch (error) {
        vm.handleError(error);
      }
    },
    async fetchUsers() {
      const vm = this;
      try {
        vm.isUsersLoading = true;

        // Listen to changes in the current user document in real-time
        const currentUserRef = doc(colUsers, vm.uid);

        onSnapshot(currentUserRef, async (currentUserDoc) => {
          let userIds = currentUserDoc.data().users || [];

          if (userIds.length === 0) {
            vm.usersList = [];
            vm.isUsersLoading = false;
            return;
          }

          let users = [];

          // Separate valid document IDs from emails
          const docIds = [];
          const emailIds = [];

          for (let id of userIds) {
            // If the ID contains "@" assume it's an email, else it's a valid Firebase document ID
            if (id.includes("@")) {
              emailIds.push(id);
            } else {
              docIds.push(id);
            }
          }

          // Directly add email users to the users list with default data

          // Now handle Firebase document IDs in chunks
          for (let ids of _.chunk(docIds, 10)) {
            let userQuery = query(colUsers, where(documentId(), "in", ids));

            onSnapshot(userQuery, async (snapshot) => {
              for (const userDoc of snapshot.docs) {
                const userData = userDoc.data();
                const userId = userDoc.id;

                // Listen to the associated team document for this user
                const teamDocRef = doc(db, "users", vm.uid, "team", userId);

                onSnapshot(teamDocRef, (teamDoc) => {
                  let status = 'pending';
                  let workspaceData ;
                  if (teamDoc.exists()) {
                    const teamData = teamDoc.data();
                    status = teamData.workSpaceToBeAdded?.status ? "accepted" : "pending";
                    workspaceData = teamData.workSpaceToBeAdded.workspaceData;
                  }

                  // Update or add the user in the users list
                  const userIndex = users.findIndex((u) => u.id === userId);
                  if (userIndex > -1) {
                    users[userIndex] = { ...userData, id: userId, status ,workspaceData };
                  } else {
                    users.push({ ...userData, id: userId, status,workspaceData });
                  }

                  // Update the usersList with real-time data
                  vm.usersList = [...users];
                  emailIds.forEach(email => {
                    vm.usersList.push({
                      id: email,
                      display_name: "Guest",
                      email: email,
                      status: "pending"
                    });
                  });
                });
              }
            });
          }
        });

      } catch (error) {
        vm.handleError(error);
      } finally {
        vm.isUsersLoading = false;
      }
    }


  },
};
</script>

<style scoped>
.note {
  background-color: #e0f7fa;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #00796b;
}
</style>
