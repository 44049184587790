@ -0,0 +1,256 @@
<template>
  <form-wrapper @submit="submit()">
    <v-row class="mb-2">
      <v-col cols="12">
        <div id="stripe-card" :class="isFocused ? 'stripe-focus' : ''"></div>
        <div class="pb-2 mt-1 v-messages theme--light error--text" role="alert">
          <div class="v-messages__wrapper">
            <div class="v-messages__message">
              {{ message }}
              <span style="visibility: hidden">-</span>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <input-field
          v-model="form.name"
          label="Name"
          rules="required"
        ></input-field>
      </v-col>
      <v-col cols="6">
        <input-field
          v-model="form.address.line1"
          label="Address Line 1"
          rules="required"
        ></input-field>
      </v-col>
      <v-col cols="6">
        <input-field
          v-model="form.address.line2"
          label="Address Line 2"
        ></input-field>
      </v-col>
      <v-col cols="6">
        <input-field
          v-model="form.address.city"
          label="City"
          rules="required"
        ></input-field>
      </v-col>
      <v-col cols="6">
        <input-field
          v-model="form.address.state"
          label="State"
          rules="required"
        ></input-field>
      </v-col>
      <v-col cols="6">
        <label class="input-label">Country</label>

        <v-autocomplete
          v-model="form.address.country"
          :items="countriesList"
          item-text="name"
          dense
          class="mt-1"
          :rules="[(v) => !!v || 'Country is required']"
          item-value="code"
          autocomplete="off"
          single-line
          clearable
          outlined
        ></v-autocomplete>
      </v-col>
    </v-row>
    <div class="d-flex">
      <img
        width="200px"
        height="44px"
        src="https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg"
        alt=""
      />

      <v-spacer></v-spacer>
      <v-btn
        large
        outlined
        color="red"
        @click="$emit('close')"
        :disabled="loading"
        >Cancel</v-btn
      >
      <v-btn
        large
        depressed
        class="ml-4 primary"
        type="submit"
        :loading="loading"
      >
        {{ isDialog ? "Submit" : "UPDATE PAYMENT METHOD" }}
      </v-btn>
    </div>
  </form-wrapper>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import InputField from "../ui/form/InputField.vue";
import FormWrapper from "../ui/form/FormWrapper.vue";
import Autocomplete from "../ui/form/Autocomplete.vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils.js";
export default {
  components: {
    InputField,
    FormWrapper,
    Autocomplete,
  },
  props: {
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      message: "",
      isFocused: false,
      isCompleted: false,
      countries: [],
      card: null,
      stripe: null,
      form: {
        name: "",
        address: {
          state: "",
          line1: "",
          city: "",
          country: null,
        },
      },
      elements: null,
    };
  },
  computed: {
    countriesList() {
      const countriesObject =
        require("../../assets/constants/country.constants.js").default;
      return Object.entries(countriesObject).map(([code, name]) => ({
        code,
        name,
      }));
    },
    rules() {
      return [() => this.message == "" || this.message];
    },
  },
  methods: {
    async submit() {
      const vm = this;
      if (!vm.isCompleted) {
        vm.message = vm.message || "Your card number is incomplete.";
        return;
      }
      try {
        vm.loading = true;
        let response = await vm.stripe.createToken(vm.card);
        if (response.error) {
          console.error(response.error.message);
          vm.message = response.error.message;
          vm.loading = false;
          return;
        } else {
          let { token } = response;
          console.log(token);

          let doc = {
            token: token.id,
            billingDetails: vm.form,
          };
          await httpsCallable(functions, "updateStripePayment")(doc);
          if (this.isDialog) {
            vm.$emit("update");
          } else {
            vm.$emit("close");
          }
          vm.loading = false;
        }
      } catch (error) {
        vm.handleError(error);
      }
    },
    async fetchCountry() {
      let countries =
        require("../../assets/constants/country.constants.js").default;
      let list = [];
      Object.keys(countries).forEach((key) => {
        if (key != "IN") {
          list.push({
            value: key,
            name: countries[key],
          });
        }
      });
      this.countries = list;
    },
    async initStripe() {
      const vm = this;
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
      vm.stripe = stripe;
      var elements = stripe.elements({
        fonts: [
          {
            cssSrc:
              "https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap",
          },
        ],
      });
      vm.elements = elements;
      var style = {
        base: {
          color: "rgba(0, 0, 0, 0.6)",
          fontFamily: ' "DM Sans", sans-serif',
          fontSize: "18px",
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.6)",
          },
        },
        invalid: {
          color: "red",
          iconColor: "red",
        },
      };
      var card = elements.create("card", {
        style: style,
      });
      vm.card = card;
      card.on("focus", function () {
        vm.isFocused = true;
      });
      card.on("blur", function () {
        vm.isFocused = false;
      });

      card.on("change", function (event) {
        vm.isCompleted = event.complete;
        if (event.error) {
          console.log(event.error);
          vm.message = event.error.message;
        } else {
          vm.message = "";
        }
      });
      card.mount("#stripe-card");
    },
    init() {
      const vm = this;
      if (vm.authUser.billing_details) {
        vm.form = vm.authUser.billing_details;
      }
    },
  },
  mounted() {
    this.fetchCountry();
    this.init();
    this.initStripe();
  },
};
</script>

<style lang="scss">
#stripe-card {
  padding: 12px;
  background: white;
  border-radius: 10px !important;
  border: 1px solid #e1e3e6;
}

.stripe-focus {
  // border: 2px solid  !important;
}
// .v-input__control > .v-input__slot {
//   min-height: 50px !important;
// }
</style>
