<template>
  <div></div>
</template>

<script>
import { auth } from "@/utils/firebase.utils";
import { signInWithCustomToken } from "firebase/auth";
export default {
  async mounted() {
    try {
      this.$loader.show();
      signInWithCustomToken(auth, this.$route.query.token);
      this.$loader.hide();
      this.$router.replace("/");
    } catch (error) {
      console.log(error);
      this.$router.push("/login");
      this.handleError(error);
    }
  },
};
</script>

<style></style>
