<template>
  <div>
    <div class="flex gap-0 bg-slate-100 max-md:flex-wrap">
      <div
        class="flex flex-col grow shrink-0 font-medium basis-0 w-fit max-md:max-w-full"
      >
        <div
          class="flex flex-col items-start self-center pt-7 pr-20 pb-20 pl-6 mt-8 w-full bg-white rounded-3xl max-w-[1285px] max-md:px-5 max-md:max-w-full mb-5"
        >
          <div
            class="flex flex-wrap gap-3 text-2xl whitespace-nowrap text-neutral-800"
          >
          <img
            loading="lazy"
            src="@/assets/img/robot.svg"
            class="shrink-0 w-7 aspect-square"
          />
            <div class="grow">
              Suggest With AI<sup class="beta-indicator">Beta</sup>
            </div>
          </div>
        
          <!-- <div
            class="flex flex-col px-5 py-5 mt-5 max-w-full text-sm rounded-xl bg-slate-100 text-slate-500 w-[624px] max-md:px-5"
          >
            <div class="flex gap-5 justify-between max-md:flex-wrap">
              <div
                class="flex flex-auto gap-5 justify-between px-8 py-4 whitespace-nowrap bg-white rounded-[32px] max-md:px-5"
              >
                <div>Theme</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/eb5e190aef6b67c20c919abdc7eb53b927938803762d29c639ab97a0d53d847c?"
                  class="shrink-0 self-start aspect-[1.61] fill-slate-500 w-[13px]"
                />
              </div>
              <div
                class="flex flex-auto gap-5 justify-between px-8 py-3.5 bg-white rounded-[32px] max-md:px-5"
              >
                <div>Tone of voice</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5f9fe9e984407233c9f55cf0d3b33d5b0ba7247da74856e2d21518d5301a197?"
                  class="shrink-0 self-start w-3 aspect-[1.49] fill-slate-500"
                />
              </div>
            </div>
            <div
              class="flex gap-5 justify-between mt-5 whitespace-nowrap max-md:flex-wrap"
            >
              <div
                class="flex flex-auto gap-5 justify-between px-8 py-3.5 bg-white rounded-[32px] max-md:px-5"
              >
                <div>Audience</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/eb5e190aef6b67c20c919abdc7eb53b927938803762d29c639ab97a0d53d847c?"
                  class="shrink-0 self-start aspect-[1.61] fill-slate-500 w-[13px]"
                />
              </div>
              <div
                class="flex flex-auto gap-5 justify-between px-8 py-3.5 bg-white rounded-[32px] max-md:px-5"
              >
                <div>Platforms</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5f9fe9e984407233c9f55cf0d3b33d5b0ba7247da74856e2d21518d5301a197?"
                  class="shrink-0 self-start w-3 aspect-[1.49] fill-slate-500"
                />
              </div>
            </div>
            <div
            @click="postSuggestPosts(businessDetails.profile)"
              class=" cursor-pointer justify-center items-center self-end px-16 py-3.5 mt-8 max-w-full text-base font-semibold text-white whitespace-nowrap bg-violet-500 rounded-3xl w-[216px] max-md:px-5"
            >
              Generate
            </div>
          </div> -->
          <div
  @click="brandCollectionExists ? openGenerateDialog() : null"
  class="flex cursor-pointer justify-center items-center self-end px-10 py-2 md:-mt-10 max-w-full text-base font-semibold text-white whitespace-nowrap bg-violet-500 rounded-3xl w-[160px] max-md:px-2"
  :class="{ 'bg-gray-400 cursor-not-allowed opacity-50': !brandCollectionExists }"
>
  <img src="@/assets/img/sidebar/create-white.svg" alt="translate" class="m-[5px]" width="22px" />
  Generate
</div>


          <div class="flex gap-2 text-xs whitespace-nowrap">
            <img
            loading="lazy"
            src="@/assets/img/flash.svg"
            class="shrink-0 w-5 aspect-square"
          />
            <div class="flex-auto md:text-2xl text-xs"
>
              If you haven’t done so, please <br class="d-sm-none" />
              <div :class="{ shake: shakeElement }" style="display: inline-block;">
              <router-link
                to="/brand-info"
                class="text-blue-500 hover:text-blue-700"
                >add your brand info</router-link
              >
              </div>
              first.
            </div>
          </div>
          <div
            v-for="(response, index) in responseData"
            :key="index"
            class="flex flex-col px-5 mt-5 rounded-xl bg-slate-100 max-md:max-w-full"
            @click="selectResponse(response)"
          >
            <div class="mt-5">
              <!-- <div class=" flex justify-end text-sm text-neutral-500">
        <div class="">
        Suggested At : {{ formatDate(response.created_at) }}
    </div>
      </div> -->
              <div
                class="text-base font-bold text-neutral-900 max-md:max-w-full"
              >
                {{ response.suggestion.posts[0].title }}
              </div>
              <div class="text-justify text-black max-md:max-w-full">
                {{ response.suggestion.posts[0].example }}
              </div>
              <div class="flex justify-end text-xs font-semibold text-gray-500">
                {{ response.suggestion.posts[0].example.length }} chars
              </div>
              <!-- Other content for each response... -->
              <div
                    class="flex cursor-pointer text-sm justify-center px-9 py-2.5 bg-violet-500 rounded-3xl max-md:px-5 w-28 text-white"
                    @click="
                      emitTitleToBus(
                        response.suggestion.posts[0].title,
                        response.suggestion.posts[0].example,
                        response.suggestion.posts[0].content
                      )
                    "
                  >
                  <v-icon color="white" size="20px" class="mr-1  rotate-icon text-white">mdi-send</v-icon>

                    Post
                  </div>
              <div
                class="shrink-0 mt-6 h-0.5 bg-white border-2 border-white border-solid max-md:max-w-full"
              ></div>
              <div>About this suggestion</div>
              <div
                class="flex relative gap-3 px-px mt-2 font-medium text-slate-500 max-md:flex-wrap"
              >
                <div class="flex gap-5 px-3 py-3.5 bg-white rounded-2xl">
                  <div
                    class="absolute -top-1 left-10 text-orange-500 px-2 py-1 text-xs rounded-md"
                  >
                    Theme
                  </div>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8676f4dd4589e84fc63987f3a84a9e903b3427278ca5d7d6e0fc8d5c374fb5cc?"
                    class="shrink-0 w-4 aspect-[1.45]"
                  />

                  <div class="flex-auto">
                    {{ response.suggestion.posts[0].theme }}
                  </div>
                </div>
                <div
                  class="relative flex gap-5 px-3 py-3  bg-white rounded-2xl"
                >
                  <div
                    class="absolute -top-1 left-10 text-orange-500 px-2 py-1 text-xs rounded-md"
                  >
                    Tone of Voice
                  </div>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc3fee2525d3801750c358db1c494456100ae02dad1ea9e56fbd9d2d80910076?"
                    class="shrink-0 mt-1 self-start aspect-[0.93] w-[15px]"
                  />
                  <div class="flex-auto">
                    <div class="flex-auto ">
  {{ response.suggestion.posts[0].tone_of_voice.map(tone => tone.charAt(0).toUpperCase() + tone.slice(1)).join(", ") }}
</div>
                  </div>
                </div>
                <div
                  class="flex cursor-pointer gap-3.5 px-3 py-3 bg-white rounded-2xl"
                  @click="openDialog(response.suggestion.posts[0].reasoning)"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e1404ddd66507f31b7a9056d439eb84dfe70ef530040c2833dd11a0f61a718dd?"
                    class="shrink-0 w-4 aspect-square"
                  />
                  <div class="flex-auto">Why this suggestion?</div>
                </div>
              </div>
              <div
                class="flex gap-3 px-px mt-3 font-medium  text-slate-500 max-md:flex-wrap"
              >
                <div
                  class="flex cursor-pointer gap-3 px-3 py-3 bg-white rounded-2xl"
                  @click="openSugDialog(response.suggestion.posts[0].media)"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6f62b04f4e9f7f52bdfa7502ee5f97df25802edfe711534f712c1d2c91a18cc?"
                    class="shrink-0 self-start w-4 aspect-square"
                  />
                  <div class="grow">Suggest Media</div>
                </div>
                <div
                  class="flex cursor-pointer gap-5 justify-between px-3 py-3 bg-white rounded-2xl"
                  @click="openExDialog(response.suggestion.posts[0].content)"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/278c70c6ba7e144ebc528d00ec94ba17eacb6efd89524aec1189d989506938bc?"
                    class="shrink-0 self-start aspect-[0.93] w-[15px]"
                  />
                  <div class="flex-auto">Long Post Example</div>
                </div>
                <div
                  class="relative flex gap-3.5 px-3 py-3 bg-white rounded-2xl"
                >
                  <div
                    class="absolute -top-1 left-9 text-orange-500 px-2 py-1 text-xs rounded-md"
                  >
                    Platforms
                  </div>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/13a56c16e4b64afcd4860cc2683647410fce5b27a6ccbeb1f7546a55c67a767e?"
                    class="shrink-0 self-start w-4 aspect-square mt-1"
                  />
                  <div class="flex-auto">
                    {{ response.suggestion.posts[0].platforms.join(", ") }}
                  </div>
                </div>
              </div>
              <div
                class="flex justify-between items-center mt-7 max-w-full text-base font-semibold text-white whitespace-nowrap w-[459px] max-md:flex-wrap my-3"
              >
                <div class="flex flex-col sm:flex-row gap-5">
                    <div
  class="cursor-pointer justify-center items-center text-center px-16 py-3.5 bg-slate-200 rounded-3xl text-sm w-fit max-md:px-5 text-black"
  @click="postMoreLikeThis(businessDetails.profile, response.suggestion)"
>
<v-icon color="#8056DE" size="20px" class="mr-1  text-white">mdi-cards-outline</v-icon>  More like this
</div>
<div
                    class="cursor-pointer text-sm justify-center px-9 py-3.5 bg-slate-200 rounded-3xl max-md:px-5 text-black"
                    @click="openRefineDialog"
                  >
                  <v-icon color="#8056DE" size="20px" class="mr-1  text-white">mdi-reload</v-icon>
                  Refine this suggestion
                  </div>
     
                </div>
                
                <div class="text-sm text-neutral-500 md:ml-[410px]">
                  Suggested At: {{ formatDate(response.created_at) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Reasoning</v-card-title>
        <v-card-text>{{ dialogContent }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="exdilaog" persitant max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Example</v-card-title>
        <v-card-text>{{ exampledilaog }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="exdilaog = false">Close</v-btn>
          <div
            class="cursor-pointer justify-center px-9 py-2 bg-violet-500 rounded-3xl max-md:px-5"
            @click="copytoclipboard"
          >
          <v-icon color="white" size="20px" class="mr-1 mb-1 rotate-icon text-white">mdi-send</v-icon>
            <span class="text-white">Post</span>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sugdilaog" persitant max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Suggest Media</v-card-title>
        <v-card-text>{{ suggestdilalog }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="sugdilaog = false">Close</v-btn>
          <v-btn color="secondary" text @click="copysuggest">Copy</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="generateDialog" persistent max-width="800px">
      <v-card>
        <v-card-title 
          ><img
            src="@/assets/img/postidea.svg"
            :width="isSmallAndUp ? '24px' : '10px'"
            :height="isSmallAndUp ? '12px' : '10px'"
            max-height="50%"
            class="mr-2"
          />
          <span :class="!isSmallAndUp ? 'text-sm' : ''">    Generate post suggestions</span> <v-spacer /> <v-btn
            icon
            color="black"
            @click="generateDialog = false"            
          class="w-[15%]"
          > 
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title
        >
        <!-- <div  class="ml-7 text-base my-2 font-semibold" >
            Generate with defaults
        </div> -->
        <div
            @click="postSuggestPosts(businessDetails.profile)"
            class="flex cursor-pointer justify-center items-center self-end px-10 py-2 ml-2 md:ml-7 mb-6 max-w-full text-base font-semibold text-white whitespace-nowrap bg-violet-500 rounded-3xl w-[300px] max-md:px-2"
          >
          <img src="@/assets/img/sidebar/create-white.svg" alt="translate" class="m-[5px]" width="22px" />

          Generate with defaults
          </div>
          <div class="text-center">OR</div>
          <v-divider></v-divider>
          <div class="ml-7 text-base my-2 font-semibold mt-6">
            Generate with following input 
          </div>
        <v-card-text>
          
          <!-- <v-select
            v-model="postTheme"
            :items="themes"
            color="#8056DE"
            label="Post Theme"
            class="w-[293px] h-12 bg-slate-100 rounded-3xl px-3 pt-2 mb-2"
          ></v-select> -->
          <!-- <label class="input-label -mb-1">For selected product/service</label> -->
  <v-select
              color="#8056DE"
              v-model="selectedProductService"
              :items="
                businessDetails.productsServicesCatalogue.map(
                  (item) => item.name
                )
              "
              label="For selected product/service"
              class="w-[503px] h-12 bg-slate-100 rounded-3xl px-3 pt-2  mb-2 mt-0 "
            ></v-select>
            <!-- <label class="input-label -mb-1">For selected highlight</label> -->
            <v-select
  v-model="selectedHighlight"
  :items="businessDetails.highlights"
  label="For selected highlight"
  class="w-[503px] h-12 bg-slate-100 rounded-3xl px-3 pt-2 mb-2 mt-0 "
></v-select>


            
          <label class="input-label">With these instructions to AI </label>
          <v-textarea
            v-model="customInstruction"
            color="#8056DE"
            rows="2"
            :class="[
        
              'pt-3',
              ,
              'pr-5',
              'pl-5',
              'mt-2.5',
              'whitespace-nowrap',
              'bg-slate-100',
              'rounded-[32px]',
              'text-slate-500',
              'max-md:pr-5',
              'mb-2',
            ]"
          ></v-textarea>
          <v-slider
            v-model="postLength"
            :max="500"
            :min="0"
            color="#8056DE"
            step="10"
            thumb-label
            :label="`${postLength} words long`"
          ></v-slider>
          <!-- <label class="input-label">Tone for your generation</label> -->
          <v-select
  label="With the selected tone"
  :items="tones"
  class="my-2 w-[503px] bg-slate-100 rounded-3xl px-3 pt-3"
  color="#8056DE"
  dense
  chips
  deletable-chips
  small-chips
  multiple
  item-text="name"
  item-value="name"
  v-model="selectedTone"
>
  <template v-slot:item="{ item, on, attrs }">
    <v-list-item v-on="on" v-bind="attrs">
      <v-list-item-action>
        <v-checkbox v-model="selectedTone" :value="item.name"></v-checkbox>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </template>
</v-select>

          <!-- <label class="input-label">For this audience</label> -->
          <v-select
            color="#8056DE"
            v-model="audience"
            :items="businessDetails.targetAudience"

            label="For this audience"
            class="h-12 bg-slate-100 rounded-3xl px-3 pt-2 mb-2 w-[503px]"
          ></v-select>
          <!-- <label class="input-label">For the selected business stage</label> -->
            <v-select
              color="#8056DE"
              v-model="stageOfBusiness"
              :items="businessStages"
              label="For the selected business stage"
              class="w-[503px] h-12 bg-slate-100 rounded-3xl px-3 pt-2 mb-2"
            ></v-select>
            <!-- <label class="input-label">For selected business visibility</label> -->
         
          <v-select
            v-model="selectedVisibility"
            :items="visibilityOptions"
            label="For selected business visibility"
            class="w-[503px] h-12 bg-slate-100 rounded-3xl px-3 pt-2"
          ></v-select>
        
        </v-card-text>
        <v-card-actions>
         
          <!-- <v-btn color="primary" text @click="generateDialog = false"
            >Close</v-btn
          > -->
          <div
            @click="postCustomInstructions(businessDetails.profile)"
            class="flex cursor-pointer justify-center items-center self-end px-10 py-2 -mt-4 max-w-full text-base font-semibold text-white whitespace-nowrap bg-violet-500 rounded-3xl w-[160px] max-md:px-2"
          >
          <img src="@/assets/img/sidebar/create-white.svg" alt="translate" class="m-[5px]" width="22px" />

            Generate
          </div>
          <v-spacer></v-spacer>
         <v-spacer></v-spacer>
         <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="refineDialog" persistent max-width="600px">
  <v-card>
    <v-card-title class="text-h5">Refine this suggestion</v-card-title>
    <v-card-text>
      <v-textarea
        v-model="newInstruction"
        placeholder="Type in your refinement instruction here..."
        color="#8056DE"
        rows="2"
        class="pt-3 pr-5 pl-5 mt-2.5 whitespace-nowrap bg-slate-100 rounded-[32px] text-slate-500 mb-2"
      ></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="refineDialog = false">Close</v-btn>
      <div
      @click="postRefine(businessDetails.profile, getFirstSuggestion())"
        class="cursor-pointer justify-center items-center px-10 py-2 text-base font-semibold text-white bg-violet-500 rounded-3xl"
      >
        Generate
      </div>
    </v-card-actions>
  </v-card>
</v-dialog>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import "firebase/firestore";
import { storage, flyplans } from "@/utils/firebase.utils";
import {
  getDownloadURL,
  ref,
  uploadString,
  uploadBytes,
} from "firebase/storage";
import {
  doc,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { auth, colUsers, db, functions } from "@/utils/firebase.utils.js";
import openaiMixin from "@/mixins/openai.mixin";
export default {
  components: {},
  data() {
    return {
        refineDialog: false,
        selectedResponse: null,
    newInstruction: '',
      selectedVisibility: "",
      selectedHighlight: '',
      visibilityOptions: ["Well known", "Little known"],
      selectedProductService: null,
      selectedTone: "",
      generateDialog: false,
      postTheme: "",
      customInstruction: "",
      postLength: 50,
      audience: "",
      stageOfBusiness: "",
      themes: ["Theme 1", "Theme 2", "Theme 3"], // Add your themes here
      businessStages: [
        "Just started",
        "Validation phase",
        "Revenue generating",
        "Growing",
        "Large enterprise",
      ],
      dialog: false,
      dialogContent: "",
      exdilaog: false,
      sugdilaog: false,
      suggestdilalog: "",
      exampledilaog: "",
      responseData: [],
      search: "",
      imageModal: false,
      step: 1,
      brandCollectionExists: false,
      imageModal: false,
      selectedImage: "",
      showColorPicker: false,
      showSecondaryColorPicker: false,
      catalogueDialog: false,

      selectedCountry: "",

      catalogue: {
        name: "",
        description: "",
        images: [],
        video: [],
        price: 0,
        postFrequency: "",
        // Add other fields as needed
      },
      brandImages: {
        logoOriginal: null,
        logoDark: null,
        logoTransparent: null,
      },
      businessDetails: {
        businessName: "",
        Tagline: "",
        website: "",
        pictures_sample_flyers: [],
        placeOfBusiness: "",
        businessDescription: "",
        highlights: [""],
        brandvalues: [""],
        tone_of_voice: [""],
        contenttheme: [""],
        unique_selling_proposition: "",
        purpose: "",
        logoOriginal: null,
        logoDark: null,
        logoTransparent: null,
        primaryColor: "",
        secondaryColor: "",
        primaryFont: "",
        secondaryFont: "",
        productsServicesCatalogue: [],
        targetAudience: [""],
        profile: "",
      },
      fontOptions: [
        { text: "Font 1", value: "font1" },
        { text: "Font 2", value: "font2" },
      ],
    };
  },
  async created() {
    // try {
    //   const userId = auth.currentUser.uid;
    //   const userDocRef = doc(colUsers, userId,"fly_workspaces", this.WID);
    //   const brandCollectionRef = collection(userDocRef, 'brand');
    //   const brandDocSnapshot = await getDocs(brandCollectionRef);
    //   if (brandDocSnapshot.empty) {
    //     console.error("Brand document not found");
    //     return;
    //   }
    //   const brandDoc = brandDocSnapshot.docs[0].data();
    //   console.log("brnad",brandDoc)
    //   this.businessDetails.productsServicesCatalogue = brandDoc.products_services_catalogue || [];
    // } catch (error) {
    //   console.error("Error fetching business details from Firestore:", error);
    // }
  },
  mixins: [openaiMixin],
  watch: {
    WID() {
      this.getbrand();
    },
  },
  methods: {
    getFirstSuggestion() {
    if (this.responseData.length > 0) {
      return this.responseData[0].suggestion;
    }
    return null; // or an appropriate default value
  },
    openGenerateDialog() {
      this.generateDialog = true;
    },
    formatDate(isoDateString) {
      const date = new Date(isoDateString);
      const day = date.getDate().toString().padStart(2, "0");
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = monthNames[date.getMonth()];
      const year = date.getFullYear();
      return `${day}-${monthName}-${year}`;
    },

    // Other methods...
    async fetchSuggestions() {
      const userId = auth.currentUser.uid;
      const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
      const brandCollectionRef = collection(userDocRef, "brand");
      const brandDocSnapshot = await getDocs(brandCollectionRef);

      if (!brandDocSnapshot.empty) {
        const brandDocRef = brandDocSnapshot.docs[0].ref;
        const suggestionSubcollectionRef = collection(
          brandDocRef,
          "suggestion"
        );
        const suggestionSnapshot = await getDocs(suggestionSubcollectionRef);

        this.responseData = suggestionSnapshot.docs
          .map((doc) => doc.data())
          .sort((a, b) => b.created_at.localeCompare(a.created_at));
      }
    },

    emitTitleToBus(title, content, example) {
      let type = ['social'];
    this.$store.commit("SET_FLY_PLATFORMS_TYPE", type);
      console.log("emitted", title);
      console.log("content", content);
      this.$router.push({
        name: "create-post",
        params: {
          title: title,
          content: content,
          example: example,
        },
      });
    },
    openRefineDialog() {
    this.refineDialog = true;
  },
    openDialog(content) {
      this.dialogContent = content;
      this.dialog = true;
    },
    openExDialog(content) {
      this.exampledilaog = content;
      this.exdilaog = true;
    },
    openSugDialog(content) {
      this.suggestdilalog = content;
      this.sugdilaog = true;
    },
    copytoclipboard() {
      let type = ['social'];
    this.$store.commit("SET_FLY_PLATFORMS_TYPE", type);
  if (this.selectedResponse) {
    navigator.clipboard.writeText(this.selectedResponse.suggestion.posts[0].content);
    this.emitTitleToBus(
      this.selectedResponse.suggestion.posts[0].title,
      this.selectedResponse.suggestion.posts[0].content
    );
  }
},
selectResponse(response) {
    this.selectedResponse = response;
  },
    copysuggest() {
      navigator.clipboard.writeText(this.suggestdilalog);
    },
    openImageModal(imageUrl) {
      this.selectedImage = imageUrl;
      this.imageModal = true;
    },
    goToProductServicePage() {
      this.$router.push({ name: "productservice" });
    },
    addHighlight() {
      this.businessDetails.highlights.push("");
    },
    addbrandvalues() {
      this.businessDetails.brandvalues.push("");
    },
    addcontent() {
      this.businessDetails.contenttheme.push("");
    },
    addtonevoice() {
      this.businessDetails.tone_of_voice.push("");
    },
    // Y
    addTargetAudience() {
      this.businessDetails.targetAudience.push("");
    },
    openFileInput() {
      this.$refs.picturesSampleFlyersInput.click();
    },
    async postRefine(profileName, suggestionValue) {
  const apiEndpoint = "https://sap-post-suggester-nfp3rxpvjq-el.a.run.app/suggest/posts";
  const payload = {
    system_preset: {
      id: 2,
    },
    user_preset: {
      id: 2,
      placeholders: {
        profile_name: profileName,
      },
    },
    profile: profileName,
    num_posts: 1,
    suggestion: {
      reasoning: suggestionValue.posts[0].reasoning,
      platforms: suggestionValue.posts[0].platforms, 
      media: suggestionValue.posts[0].media,
      title: suggestionValue.posts[0].title,
      content: suggestionValue.posts[0].content,
      example: suggestionValue.posts[0].example,
      hashtags: suggestionValue.posts[0].hashtags, 
      theme: suggestionValue.posts[0].theme,
      tone_of_voice: suggestionValue.posts[0].tone_of_voice 
    },
    history_k: 3,
    custom_instructions: this.newInstruction
  };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  try {
        this.$loader.show({
          message:
            "FlyAI’s cooking up the best social media posts for your brand – just hang tight!",
          type: "ai",
        });

        const response = await fetch(apiEndpoint, requestOptions);

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error("API Response Error:", errorDetails);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        this.responseData.unshift(responseData);
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (!brandDocSnapshot.empty) {
          const brandDocRef = brandDocSnapshot.docs[0].ref;

          // Create a subcollection named 'suggestion' and add the response data
          const suggestionSubcollectionRef = collection(
            brandDocRef,
            "suggestion"
          );
          await addDoc(suggestionSubcollectionRef, {
            created_at: responseData.created_at,
            suggestion: responseData.suggestion,
          });
        }
        this.$loader.hide();
        this.newInstruction = "";
        this.refineDialog = false;
        this.generateDialog = false;
        // console.log("Successfully posted suggest more like this posts:", responseData);
        return responseData;
      }  catch (error) {
    console.error("Error posting more like this to endpoint:", error);
    return null;
  }
},

    async postCustomInstructions(profileName) {
  const apiEndpoint = "https://sap-post-suggester-nfp3rxpvjq-el.a.run.app/suggest/posts";
  const selectedProduct = this.businessDetails.productsServicesCatalogue.find(
    (product) => product.name === this.selectedProductService
  );
  let customInstructions = `${this.customInstruction}.`;
  if (this.postLength) {
    customInstructions += `Keep this post ${this.postLength} words long.`;
  }
  const stageOfBusinessMap = {
    'Just started': 'Generate this post for a business that has just started',
    'Validation phase': 'Generate this post for a business that is in the validation phase',
    'Revenue generating': 'Generate this post for a business that is revenue generating',
    'Growing': 'Generate this post for a business that is growing',
    'Large enterprise': 'Generate this post for a business that is a well established large business'
  };

 
  const visibilityMap = {
    'Well known': 'Assume the business is a well known brand',
    'Little known': 'Assume the business is a little known brand'
  };


 
  if (this.stageOfBusiness && stageOfBusinessMap[this.stageOfBusiness]) {
    customInstructions += ` ${stageOfBusinessMap[this.stageOfBusiness]}.`;
  }

 
  if (this.selectedVisibility && visibilityMap[this.selectedVisibility]) {
    customInstructions += ` ${visibilityMap[this.selectedVisibility]}.`;
  }


  if(this.audience){
  customInstructions += ` Target this post to the following audience: ${this.audience || "your target audience"}.`;

  }
  const payload = {
    system_preset: {
      id: 2,
    },
    user_preset: {
      id: 2,
      placeholders: {
        profile_name: profileName,
      },
    },
    profile: profileName,
    num_posts: 1,
    history_k: 3,
    priority_products: selectedProduct ? [{
      name: selectedProduct.name,
      description: selectedProduct.description,
      images: selectedProduct.images.length > 0 ? selectedProduct.images : ["https://example.com/placeholder.jpg"],
      price_fee: "Custom quote based on requirements",
      offers_discounts: null,
      key_features_highlights: selectedProduct.key_features_highlights || [],
      product_service_description_web_page: selectedProduct.product_service_description_web_page || ""
    }] : [],
    // tone_of_voice: this.selectedTone,
    highlights: this.selectedHighlight ? [this.selectedHighlight] : ["Multi Platform Posting"],
    custom_instructions:  customInstructions 
  };
  console.log("cust",payload)
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };

  try {
        this.$loader.show({
          message:
            "FlyAI’s cooking up the best social media posts for your brand – just hang tight!",
          type: "ai",
        });

        const response = await fetch(apiEndpoint, requestOptions);

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error("API Response Error:", errorDetails);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        this.responseData.unshift(responseData);
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (!brandDocSnapshot.empty) {
          const brandDocRef = brandDocSnapshot.docs[0].ref;

        
          const suggestionSubcollectionRef = collection(
            brandDocRef,
            "suggestion"
          );
          await addDoc(suggestionSubcollectionRef, {
            created_at: responseData.created_at,
            suggestion: responseData.suggestion,
          });
        }
        this.selectedVisibility =""
        this.customInstruction = ""
        this.postLength = 50
        this.audience = ""
        this.stageOfBusiness = ""
    this.selectedProductService = null
        this.$loader.hide();
        this.generateDialog = false;
        // console.log("Successfully posted suggest custoum:", responseData);
        return responseData;
      } catch (error) {
    console.error("Error posting custom instructions to endpoint:", error);
    return null;
  }
},

    async postMoreLikeThis(profileName, suggestionValue) {
  const apiEndpoint = "https://sap-post-suggester-nfp3rxpvjq-el.a.run.app/suggest/posts";
  const payload = {
    system_preset: {
      id: 2,
    },
    user_preset: {
      id: 2,
      placeholders: {
        profile_name: profileName,
      },
    },
    profile: profileName,
    num_posts: 1,
    suggestion: {
      reasoning: suggestionValue.posts[0].reasoning,
      platforms: suggestionValue.posts[0].platforms, 
      media: suggestionValue.posts[0].media,
      title: suggestionValue.posts[0].title,
      content: suggestionValue.posts[0].content,
      example: suggestionValue.posts[0].example,
      hashtags: suggestionValue.posts[0].hashtags, 
      theme: suggestionValue.posts[0].theme,
      tone_of_voice: suggestionValue.posts[0].tone_of_voice 
    },
    history_k: 3,
    custom_instructions: "More like this: different suggestion"
  };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  };
  try {
        this.$loader.show({
          message:
            "FlyAI’s cooking up the best social media posts for your brand – just hang tight!",
          type: "ai",
        });

        const response = await fetch(apiEndpoint, requestOptions);

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error("API Response Error:", errorDetails);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        this.responseData.unshift(responseData);
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (!brandDocSnapshot.empty) {
          const brandDocRef = brandDocSnapshot.docs[0].ref;

          // Create a subcollection named 'suggestion' and add the response data
          const suggestionSubcollectionRef = collection(
            brandDocRef,
            "suggestion"
          );
          await addDoc(suggestionSubcollectionRef, {
            created_at: responseData.created_at,
            suggestion: responseData.suggestion,
          });
        }
        this.$loader.hide();
        this.generateDialog = false;
        console.log("Successfully posted suggest more like this posts:", responseData);
        return responseData;
      }  catch (error) {
    console.error("Error posting more like this to endpoint:", error);
    return null;
  }
}
,
    async postSuggestPosts(profileName) {
      const apiEndpoint =
        "https://sap-post-suggester-nfp3rxpvjq-el.a.run.app/suggest/posts";
      const payload = {
        system_preset: {
          id: 2,
        },
        user_preset: {
          id: 2,
          placeholders: {
            profile_name: profileName,
          },
        },
        profile: profileName,
        num_posts: 1,
        history_k: 3,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };

      try {
        this.$loader.show({
          message:
            "FlyAI’s cooking up the best social media posts for your brand – just hang tight!",
          type: "ai",
        });

        const response = await fetch(apiEndpoint, requestOptions);

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error("API Response Error:", errorDetails);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        this.responseData.unshift(responseData);
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (!brandDocSnapshot.empty) {
          const brandDocRef = brandDocSnapshot.docs[0].ref;

          
          const suggestionSubcollectionRef = collection(
            brandDocRef,
            "suggestion"
          );
          await addDoc(suggestionSubcollectionRef, {
            created_at: responseData.created_at,
            suggestion: responseData.suggestion,
          });
        }
        this.$loader.hide();
        this.generateDialog = false;
        console.log("Successfully posted suggest posts:", responseData);
        return responseData;
      } catch (error) {
        console.error("Error posting suggest posts to endpoint:", error);
        return null;
      }
    },

    async handleLogoTransparentChange(event) {
      const file = event.target.files[0];
      if (file) {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/logoTransparent/${file.name}`
        );

        try {
          const uploadTaskSnapshot = await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
          this.businessDetails.logoTransparent = imageUrl;

          // Update Firestore with the new logoTransparent URL
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              logo_transparent: this.businessDetails.logoTransparent,
            });
          }
        } catch (error) {
          console.error("Error uploading logoTransparent: ", error);
        }
      }
    },
    async handleLogoOriginalChange(event) {
      const file = event.target.files[0];
      if (file) {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/logooriginal/${file.name}`
        );

        try {
          const uploadTaskSnapshot = await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
          this.businessDetails.logoOriginal = imageUrl;

          // Update Firestore with the new logooriginal URL
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              logo_original: this.businessDetails.logoOriginal,
            });
          }
        } catch (error) {
          console.error("Error uploading logooriginal: ", error);
        }
      }
    },
    async handleLogoDarkChange(event) {
      const file = event.target.files[0];
      if (file) {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/logoDark/${file.name}`
        );

        try {
          const uploadTaskSnapshot = await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
          this.businessDetails.logoDark = imageUrl;

          // Update Firestore with the new logoDark URL
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              logo_dark: this.businessDetails.logoDark,
            });
          }
        } catch (error) {
          console.error("Error uploading logoDark: ", error);
        }
      }
    },
    async handlePicturesSampleFlyersChange(event) {
      const files = event.target.files;
      if (files) {
        const userId = auth.currentUser.uid;
        const uploadPromises = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const storageRef = ref(
            storage,
            `users/${userId}/pictures_sample_flyers/${file.name}`
          );
          const uploadTask = uploadBytes(storageRef, file).then(() =>
            getDownloadURL(storageRef)
          );
          uploadPromises.push(uploadTask);
        }

        try {
          const imageUrls = await Promise.all(uploadPromises);
          this.businessDetails.pictures_sample_flyers = [
            ...this.businessDetails.pictures_sample_flyers,
            ...imageUrls,
          ];

          // Update Firestore with the new pictures_sample_flyers array
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              pictures_sample_flyers:
                this.businessDetails.pictures_sample_flyers,
            });
          }
        } catch (error) {
          console.error("Error uploading images: ", error);
        }
      }
    },
    previewImages(event) {
      const input = event.target;
      const preview = document.getElementById("sample_flyers_preview");
      const files = input.files;

      // Clear the preview container before adding new images
      preview.innerHTML = "";

      // Iterate through the selected files and create image previews
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = function (e) {
          const img = document.createElement("img");
          img.src = e.target.result;
          img.classList.add("preview-image");
          preview.appendChild(img);
        };

        reader.readAsDataURL(file);
      }
    },

    openFileDialog(refName) {
      // Trigger click event on the file input to open the file dialog
      this.$refs[refName].click();
    },
    handleImageChange(imageKey, event) {
      const file = event.target.files[0];
      if (file instanceof File) {
        // Read the selected file as a data URL
        const reader = new FileReader();
        reader.onload = () => {
          // Update the corresponding image source with the selected file
          // this.brandImages[imageKey] = reader.result;
          this.businessDetails[imageKey] = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },

    async nextStep() {
      if (this.step < 5) {
        try {
          const userId = auth.currentUser.uid;
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);

          let brandDocRef;
          if (brandDocSnapshot.empty) {
            // If the brand collection is empty, create a new document
            brandDocRef = await addDoc(brandCollectionRef, {});
          } else {
            // If the brand collection exists, use the existing document
            brandDocRef = brandDocSnapshot.docs[0].ref;
          }

          // Update the brand document with the current businessDetails
          await updateDoc(brandDocRef, {
            // Add or update fields as needed

            business_name: this.businessDetails.businessName,
            website: this.businessDetails.website,
            tagline: this.businessDetails.Tagline,
            purpose: this.businessDetails.purpose,
            highlights: this.businessDetails.highlights,
            brand_values: this.businessDetails.brandvalues,
            content_themes: this.businessDetails.contenttheme,
            tone_of_voice: this.businessDetails.tone_of_voice,
            unique_selling_proposition:
              this.businessDetails.unique_selling_proposition,
            pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
            place_business: this.businessDetails.placeOfBusiness,
            business_description: this.businessDetails.businessDescription,
            logo_original: this.businessDetails.logoOriginal,
            logo_dark: this.businessDetails.logoDark,
            logo_transparent: this.businessDetails.logoTransparent,
            primary_color: this.businessDetails.primaryColor,
            secondary_color: this.businessDetails.secondaryColor,
            primary_font: this.businessDetails.primaryFont,
            secondary_font: this.businessDetails.secondaryFont,
            products_services_catalogue:
              this.businessDetails.productsServicesCatalogue,
            age_groups: this.selectedAgeGroups,
            genders: this.selectedGenders,
            income_levels: this.selectedIncomeLevels,
            education_levels: this.selectedEducationLevels,
            employment_statuses: this.selectedEmploymentStatuses,
            marital_statuses: this.selectedMaritalStatuses,
            ethnicities_races: this.selectedEthnicitiesRaces,
            interests_hobbies: this.selectedInterestsHobbies,
            household_sizes: this.selectedHouseholdSizes,
            number_of_children: this.selectedNumberOfChildren,
            home_ownership: this.selectedHomeOwnership,
            disability_status: this.selectedDisabilityStatus,
            industry_category: this.selectedIndustryCategory,
            target_audience: this.businessDetails.targetAudience,
            country: this.selectedCountry,
          });
          this.brandCollectionExists = true;
          // Proceed to the next step
          this.step++;
        } catch (error) {
          console.error("Error updating brand collection:", error);
          alert("An error occurred while updating the brand information.");
        }
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    editCatalogueItem(item) {
      // Populate the catalogue object with the data of the selected item
      this.catalogue.name = item.name;
      this.catalogue.description = item.description;
      this.catalogue.price = item.price;
      this.catalogue.postFrequency = item.post_frequency;
      // Set the images and video to empty arrays to clear previous values
      this.catalogue.images = [];
      this.catalogue.video = [];
      // Populate the images and video arrays with the URLs from the selected item
      this.catalogue.images.push(...item.images);
      this.catalogue.video.push(...item.video);
      // Open the dialog
      this.$nextTick(() => {
        this.$refs.catalogueNameInput.readonly = true;
      });
      this.catalogueDialog = true;
    },
    async deleteImageFromCatalogue(itemIndex, imageIndex) {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (brandDocSnapshot.empty) {
          console.error("Brand document not found");
          return;
        }

        const brandDoc = brandDocSnapshot.docs[0];
        const updatedCatalogue = [
          ...brandDoc.data().products_services_catalogue,
        ];

        // Remove the image from the images array of the corresponding item
        updatedCatalogue[itemIndex].images.splice(imageIndex, 1);

        // Update Firestore document with the modified catalogue
        await updateDoc(brandDoc.ref, {
          products_services_catalogue: updatedCatalogue,
        });

        // Update local data to reflect changes in UI
        this.$set(
          this.businessDetails,
          "productsServicesCatalogue",
          updatedCatalogue
        );

        alert("Image deleted successfully!");
      } catch (error) {
        console.error("Error deleting image:", error);
        alert("An error occurred while deleting the image.");
      }
    },
    async fetchCountries() {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const countriesData = await response.json();
        this.countries = countriesData.map((country) => country.name.common);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    },
    async deleteCatalogueItem(index) {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (brandDocSnapshot.empty) {
          console.error("Brand document not found");
          return;
        }

        const brandDoc = brandDocSnapshot.docs[0];
        const updatedCatalogue = [
          ...brandDoc.data().products_services_catalogue,
        ];
        updatedCatalogue.splice(index, 1); // Remove item at the specified index

        // Update Firestore document with the modified catalogue
        await updateDoc(brandDoc.ref, {
          products_services_catalogue: updatedCatalogue,
        });

        // Update local data to reflect changes in UI
        this.$set(
          this.businessDetails,
          "productsServicesCatalogue",
          updatedCatalogue
        );

        alert("Catalogue item deleted successfully!");
      } catch (error) {
        console.error("Error deleting catalogue item:", error);
        alert("An error occurred while deleting the catalogue item.");
      }
    },
    openImageModal(image) {
      this.selectedImage = image;
      this.imageModal = true;
    },

    async uploadImageToStorage(file, itemIndex) {
      try {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/catalogue/${new Date().getTime()}_${Math.random()}.png`
        );

        // Upload the image to storage
        await uploadBytes(storageRef, file);

        // Get the download URL
        const imageUrl = await getDownloadURL(storageRef);

        // Update the item.images array with the new image URL
        this.businessDetails.productsServicesCatalogue[itemIndex].images.push(
          imageUrl
        );

        // Update the Firestore document with the modified array
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (!brandDocSnapshot.empty) {
          const brandDoc = brandDocSnapshot.docs[0];
          const updatedCatalogue = [
            ...brandDoc.data().products_services_catalogue,
          ];
          updatedCatalogue[itemIndex].images.push(imageUrl);

          await updateDoc(brandDoc.ref, {
            products_services_catalogue: updatedCatalogue,
          });

          alert("Image uploaded successfully!");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("An error occurred while uploading the image.");
      }
    },
    toggleSecondaryColorPicker() {
      this.showSecondaryColorPicker = !this.showSecondaryColorPicker;
    },
    toggleColorPicker() {
      this.showColorPicker = !this.showColorPicker;
    },

    openCataloguePopup() {
      this.catalogueDialog = true;
    },
    closeCataloguePopup() {
      this.catalogueDialog = false;
    },
    async saveCatalogueItem() {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);

        // Fetch the current brand document
        const brandCollectionRef = collection(userDocRef, "brand");
        const querySnapshot = await getDocs(brandCollectionRef);
        const brandDoc = querySnapshot.docs[0]; // Assuming there's only one brand document per user

        if (!brandDoc) {
          // Handle the case where the brand document doesn't exist
          alert("Brand document not found");
          return;
        }

        // Process and upload each image
        const uploadedImages = await Promise.all(
          this.catalogue.images.map(async (imageFile) => {
            const storageRef = ref(
              storage,
              `users/${
                auth.currentUser.uid
              }/catalogue/${new Date().getTime()}_${Math.random()}.png`
            );
            await uploadBytes(storageRef, imageFile);
            return getDownloadURL(storageRef);
          })
        );

        const uploadVideo = await Promise.all(
          this.catalogue.video.map(async (videoFile) => {
            const storageRef = ref(
              storage,
              `users/${
                auth.currentUser.uid
              }/catalogue/${new Date().getTime()}_${Math.random()}.mp4`
            );
            await uploadBytes(storageRef, videoFile);
            return getDownloadURL(storageRef);
          })
        );

        // Continue with the rest of your code
        const catalogueItem = {
          name: this.catalogue.name || "",
          description: this.catalogue.description || "",
          images: uploadedImages || [],
          video: uploadVideo || [],
          price: this.catalogue.price || 0,
          post_frequency: this.catalogue.postFrequency || "",
          // Add other fields as needed
        };

        // Concatenate the existing productsServicesCatalogue array with the new catalogueItem
        const updatedCatalogue = [
          ...brandDoc.data().products_services_catalogue,
          catalogueItem,
        ];

        // Update the existing brand document to include the updated productsServicesCatalogue array
        await updateDoc(brandDoc.ref, {
          products_services_catalogue: updatedCatalogue,
        });

        alert("Catalogue item added successfully!");
        const itemIndex =
          this.businessDetails.productsServicesCatalogue.findIndex(
            (item) => item.name === this.catalogue.name
          );
        if (itemIndex !== -1) {
          // Update the existing item in the array
          this.businessDetails.productsServicesCatalogue[itemIndex] = {
            name: this.catalogue.name,
            description: this.catalogue.description,
            images: this.catalogue.images,
            video: this.catalogue.video,
            price: this.catalogue.price,
            post_frequency: this.catalogue.postFrequency,
            // Add other fields as needed
          };

          // Update the Firestore document with the modified array
          const userId = auth.currentUser.uid;
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);

          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              products_services_catalogue:
                this.businessDetails.productsServicesCatalogue,
            });

            alert("Catalogue item updated successfully!");
            // Refresh the data after updating
          }
        }
        this.getbrand();
      } catch (error) {
        console.error("Error saving catalogue item in Firestore:", error);
        alert("An error occurred while saving the catalogue item.");
      }

      // Reset the catalogue object after saving
      this.catalogue = {
        name: "",
        description: "",
        images: [],
        video: [],
        // Add other fields as needed
      };

      this.catalogueDialog = false;
    },
    addRandomColor() {
      // Generate a random color (you can modify this logic as needed)
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;

      // Assign the random color to the primaryColor property
      this.businessDetails.primaryColor = randomColor;
    },
    addRandomColor2() {
      // Generate a random color (you can modify this logic as needed)
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;

      // Assign the random color to the primaryColor property
      this.businessDetails.secondaryColor = randomColor;
    },
    async fetchBrandDetailsAndConvertToJson() {
      const userId = auth.currentUser.uid;
      const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
      const brandCollectionRef = collection(userDocRef, "brand");
      const brandDocSnapshot = await getDocs(brandCollectionRef);

      if (brandDocSnapshot.empty) {
        console.error("Brand document not found");
        return;
      }

      const brandDoc = brandDocSnapshot.docs[0].data();

      const brandDetails = {
        brand_details: {
          name: brandDoc.business_name || "",
          description: brandDoc.business_description || "",
          purpose: brandDoc.purpose || "",
          tagline: brandDoc.tagline || "",
          highlights: brandDoc.highlights || [],
          website: brandDoc.website || "",
          logo: brandDoc.logo_original || "",
          social_handles: {
            facebook: "https://www.facebook.com/example",
            twitter: "https://www.twitter.com/example",
            instagram: "https://www.instagram.com/example",
          },
          primary_colours: [brandDoc.primary_color || ""],
          secondary_colours: [brandDoc.secondary_color || ""],
          pictures_sample_flyers: brandDoc.pictures_sample_flyers || [],
          industry_category: brandDoc.industry_category, // This field is not present in the provided data
          // This field is not present in the provided data
          target_audience: {
            primary: [
              "Automotive solutions businesses seeking reliable data feeds",
            ],
            secondary: [
              "Top car manufacturers, large retailers, auction houses, lenders, and insurers in the automotive industry",
            ],
          },
          brand_values: brandDoc.brand_values || [],
          unique_selling_proposition: brandDoc.unique_selling_proposition || "",
          content_themes: brandDoc.content_themes || [],
          tone_of_voice: brandDoc.tone_of_voice || [],
        },
        product_service_catalogue: brandDoc.products_services_catalogue.map(
          (item) => ({
            name: item.name || "",
            description: item.description || "",
            offers_discounts: ["10% off for first-time customers"],

            images: item.images || [],
            offers_discounts: null,
            price_fee: item.price
              ? `Custom quote based on requirements: ${item.price}`
              : "Custom quote based on requirements",
            key_features_highlights: item.key_features_highlights || [],
            product_service_description_web_page:
              item.product_service_description_web_page || "",
          })
        ),
      };
      console.log("brandDetails", brandDetails);
      return brandDetails;
    },
    async postBrandInfoToEndpoint(operationType) {
      try {
        const brandDetails = await this.fetchBrandDetailsAndConvertToJson();
        let apiEndpoint;

        if (operationType === "create") {
          apiEndpoint =
            "https://sap-post-suggester-nfp3rxpvjq-el.a.run.app/profiles/create";
        } else if (operationType === "update") {
          apiEndpoint =
            "https://sap-post-suggester-nfp3rxpvjq-el.a.run.app/profiles/update";
        } else {
          throw new Error(
            'Invalid operation type. Must be "create" or "update".'
          );
        }

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(brandDetails),
        };

        console.log("Sending payload to API:", brandDetails); // Log the payload for debugging

        const response = await fetch(apiEndpoint, requestOptions);

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error("API Response Error:", errorDetails);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log("Successfully posted brand info:", responseData);
        return responseData;
      } catch (error) {
        console.error("Error posting brand info to endpoint:", error);
        return null;
      }
    },

    async submitBusinessInfo() {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");

        // Check if the user already has a brand collection
        const brandDocsSnapshot = await getDocs(brandCollectionRef);
        let brandDocRef;
        let operationType;
        if (!brandDocsSnapshot.empty) {
          // If a brand collection exists, get its reference
          brandDocRef = brandDocsSnapshot.docs[0].ref;
          this.brandCollectionExists = true;
          operationType = "update";
        } else {
          // If no brand collection exists, create a new document
          brandDocRef = await addDoc(brandCollectionRef, {
            business_name: this.businessDetails.businessName,
            website: this.businessDetails.website,
            tagline: this.businessDetails.Tagline,
            purpose: this.businessDetails.purpose,
            highlights: this.businessDetails.highlights,
            brand_values: this.businessDetails.brandvalues,
            content_themes: this.businessDetails.contenttheme,
            tone_of_voice: this.businessDetails.tone_of_voice,
            unique_selling_proposition:
              this.businessDetails.unique_selling_proposition,
            pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
            place_business: this.businessDetails.placeOfBusiness,
            business_description: this.businessDetails.businessDescription,
            logo_original: this.businessDetails.logoOriginal,
            logo_dark: this.businessDetails.logoDark,
            logo_transparent: this.businessDetails.logoTransparent,
            primary_color: this.businessDetails.primaryColor,
            secondary_color: this.businessDetails.secondaryColor,
            primary_font: this.businessDetails.primaryFont,
            secondary_font: this.businessDetails.secondaryFont,
            products_services_catalogue:
              this.businessDetails.productsServicesCatalogue,
            age_groups: this.selectedAgeGroups,
            genders: this.selectedGenders,
            income_levels: this.selectedIncomeLevels,
            education_levels: this.selectedEducationLevels,
            employment_statuses: this.selectedEmploymentStatuses,
            marital_statuses: this.selectedMaritalStatuses,
            ethnicities_races: this.selectedEthnicitiesRaces,
            interests_hobbies: this.selectedInterestsHobbies,
            household_sizes: this.selectedHouseholdSizes,
            number_of_children: this.selectedNumberOfChildren,
            home_ownership: this.selectedHomeOwnership,
            disability_status: this.selectedDisabilityStatus,
            industry_category: this.selectedIndustryCategory,
            target_audience: this.businessDetails.targetAudience,
          });
          this.brandCollectionExists = false;
          operationType = "create";
        }
        await this.postBrandInfoToEndpoint(operationType);
        const responseData = await this.postBrandInfoToEndpoint(operationType);
        await updateDoc(brandDocRef, {
          business_name: this.businessDetails.businessName,
          website: this.businessDetails.website,
          tagline: this.businessDetails.Tagline,
          purpose: this.businessDetails.purpose,
          highlights: this.businessDetails.highlights,
          brand_values: this.businessDetails.brandvalues,
          content_themes: this.businessDetails.contenttheme,
          tone_of_voice: this.businessDetails.tone_of_voice,
          unique_selling_proposition:
            this.businessDetails.unique_selling_proposition,
          pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
          place_business: this.businessDetails.placeOfBusiness,
          business_description: this.businessDetails.businessDescription,
          logo_original: this.businessDetails.logoOriginal,
          logo_dark: this.businessDetails.logoDark,
          logo_transparent: this.businessDetails.logoTransparent,
          primary_color: this.businessDetails.primaryColor,
          secondary_color: this.businessDetails.secondaryColor,
          primary_font: this.businessDetails.primaryFont,
          secondary_font: this.businessDetails.secondaryFont,
          products_services_catalogue:
            this.businessDetails.productsServicesCatalogue,
          age_groups: this.selectedAgeGroups,
          genders: this.selectedGenders,
          income_levels: this.selectedIncomeLevels,
          education_levels: this.selectedEducationLevels,
          employment_statuses: this.selectedEmploymentStatuses,
          marital_statuses: this.selectedMaritalStatuses,
          ethnicities_races: this.selectedEthnicitiesRaces,
          interests_hobbies: this.selectedInterestsHobbies,
          household_sizes: this.selectedHouseholdSizes,
          number_of_children: this.selectedNumberOfChildren,
          home_ownership: this.selectedHomeOwnership,
          disability_status: this.selectedDisabilityStatus,
          industry_category: this.selectedIndustryCategory,
          target_audience: this.businessDetails.targetAudience,
          country: this.selectedCountry,
          profile: responseData.profile,
        });

        alert(
          `Brand information updated successfully with profile: ${responseData.profile}`
        );

        // alert(`Brand information ${operationType}d successfully and posted to the endpoint!`);
        if (!this.brandCollectionExists) {
          alert("Brand collection created successfully!");
        } else {
          alert("Brand collection updated successfully!");
        }
      } catch (error) {
        console.error("Error saving brand collection in Firestore:", error);
        alert("An error occurred while saving the brand collection.");
      }
    },

    //     async submitBusinessInfo() {
    //       try {
    //         const userId = auth.currentUser.uid;
    //         console.log("WID:", this.WID);
    //         const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
    //         const brandCollectionRef = collection(userDocRef, "brand");

    //         // Check if the user already has a businessDetails document
    //         const existingBrandDocs = await getDocs(brandCollectionRef);

    //         if (!existingBrandDocs.empty) {
    //           // If documents already exist, inform the user or handle it as needed
    //           alert(
    //             "You have already added business details.you can add multiple Products/Services Catalogue."
    //           );
    //           return;
    //         }

    //         // Continue with adding business details if no existing documents are found

    //         const logoOriginalFile = this.businessDetails.logoOriginal;
    //         // console.log("logoOriginalFile: ", logoOriginalFile);
    //         if (logoOriginalFile) {
    //           const storageRef = ref(
    //             storage,
    //             `users/${userId}/logo_original/${new Date().getTime()}.png`
    //           );
    //           if (logoOriginalFile instanceof File) {
    //             await uploadBytes(storageRef, logoOriginalFile);
    //           } else {
    //             await uploadString(storageRef, logoOriginalFile, "data_url");
    //           }
    //           this.businessDetails.logoOriginal = await getDownloadURL(storageRef);
    //           // console.log('getDownloadURL:', this.businessDetails.logoOriginal)
    //         }

    //         if (this.businessDetails.logoDark) {
    //           const storageRef = ref(
    //             storage,
    //             `users/${userId}/logo_dark/${new Date().getTime()}.png`
    //           );
    //           if (this.businessDetails.logoDark instanceof File) {
    //             await uploadBytes(storageRef, this.businessDetails.logoDark);
    //           } else {
    //             await uploadString(storageRef, this.businessDetails.logoDark, "data_url");
    //           }
    //           this.businessDetails.logoDark = await getDownloadURL(storageRef);
    //         }
    //         if (this.businessDetails.logoTransparent) {
    //           const storageRef = ref(
    //             storage,
    //             `users/${userId}/logo_transparent/${new Date().getTime()}.png`
    //           );
    //           if (this.businessDetails.logoTransparent instanceof File) {
    //             await uploadBytes(storageRef, this.businessDetails.logoTransparent);
    //           } else {
    //             await uploadString(storageRef, this.businessDetails.logoTransparent, "data_url");
    //           }

    //           this.businessDetails.logoTransparent = await getDownloadURL(
    //             storageRef
    //           );
    //         }
    //         const uploadedImages = await Promise.all(
    //   this.businessDetails.pictures_sample_flyers.map(async (imageFile) => {
    //     const storageRef = ref(
    //       storage,
    //       `users/${auth.currentUser.uid}/catalogue/${new Date().getTime()}_${Math.random()}.png`
    //     );
    //     if (imageFile instanceof File) {
    //       await uploadBytes(storageRef, imageFile);
    //     } else {
    //       await uploadString(storageRef, imageFile, "data_url");
    //     }
    //     return await getDownloadURL(storageRef);
    //   })
    // );
    // this.businessDetails.pictures_sample_flyers = uploadedImages;
    //         await addDoc(brandCollectionRef, {
    //           business_name: this.businessDetails.businessName,
    //           website: this.businessDetails.website,
    //           tagline: this.businessDetails.Tagline,
    //           purpose: this.businessDetails.purpose,
    //           highlights: this.businessDetails.highlights,
    //           brand_values: this.businessDetails.brandvalues,
    //           content_themes: this.businessDetails.contenttheme,
    //           tone_of_voice: this.businessDetails.tone_of_voice,
    //           unique_selling_proposition:
    //             this.businessDetails.unique_selling_proposition,
    //             pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
    //           place_business: this.businessDetails.placeOfBusiness,
    //           business_description: this.businessDetails.businessDescription,
    //           logo_original: this.businessDetails.logoOriginal,
    //           logo_dark: this.businessDetails.logoDark,
    //           logo_transparent: this.businessDetails.logoTransparent,
    //           primary_color: this.businessDetails.primaryColor,
    //           secondary_color: this.businessDetails.secondaryColor,
    //           primary_font: this.businessDetails.primaryFont,
    //           secondary_font: this.businessDetails.secondaryFont,
    //           products_services_catalogue:
    //             this.businessDetails.productsServicesCatalogue,
    //             age_groups: this.selectedAgeGroups,
    //             genders: this.selectedGenders,
    //             income_levels: this.selectedIncomeLevels,
    //             education_levels: this.selectedEducationLevels,
    //             employment_statuses: this.selectedEmploymentStatuses,
    //             marital_statuses: this.selectedMaritalStatuses,
    //             ethnicities_races: this.selectedEthnicitiesRaces,
    //             interests_hobbies: this.selectedInterestsHobbies,
    //             household_sizes: this.selectedHouseholdSizes,
    //             number_of_children: this.selectedNumberOfChildren,
    //             home_ownership: this.selectedHomeOwnership,
    //             disability_status: this.selectedDisabilityStatus,
    //             industry_category: this.selectedIndustryCategory,
    //         });
    //         this.brandCollectionExists = true;

    //         alert("Business information saved successfully!");
    //       } catch (error) {
    //         console.error("Error saving business information in Firestore:", error);
    //         alert("An error occurred while saving the business information.");
    //       }
    //     },
    async getbrand() {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (brandDocSnapshot.empty) {
          this.brandCollectionExists = false;
          console.error("Brand document not found");
          // Clear businessDetails object properties
          this.businessDetails.businessName = "";
          this.businessDetails.Tagline = "";
          this.businessDetails.website = "";
          this.bussinessDetails.highlights = [];
          this.businessDetails.brandvalues = [];
          this.businessDetails.contenttheme = [];
          this.businessDetails.tone_of_voice = [];
          this.businessDetails.unique_selling_proposition = "";
          this.businessDetails.purpose = "";
          this.businessDetails.placeOfBusiness = "";
          this.businessDetails.businessDescription = "";
          this.businessDetails.logoOriginal = null;
          this.businessDetails.logoDark = null;
          this.businessDetails.logoTransparent = null;
          this.businessDetails.primaryColor = "";
          this.businessDetails.secondaryColor = "";
          this.businessDetails.primaryFont = "";
          this.businessDetails.secondaryFont = "";
          this.businessDetails.productsServicesCatalogue = [];
          this.businessDetails.targetAudience = "";
          return;
        }
        this.brandCollectionExists = true;
        const brandDoc = brandDocSnapshot.docs[0].data();
        // console.log("brnad", brandDoc);
        this.businessDetails.businessName = brandDoc.business_name || "";
        this.businessDetails.Tagline = brandDoc.tagline || "";
        this.businessDetails.purpose = brandDoc.purpose || "";
        this.businessDetails.highlights = brandDoc.highlights || [];
        this.businessDetails.brandvalues = brandDoc.brand_values || [];
        this.businessDetails.contenttheme = brandDoc.content_themes || [];
        this.businessDetails.tone_of_voice = brandDoc.tone_of_voice || [];
        this.businessDetails.pictures_sample_flyers =
          brandDoc.pictures_sample_flyers || [];
        this.businessDetails.unique_selling_proposition =
          brandDoc.unique_selling_proposition || "";
        this.businessDetails.website = brandDoc.website || "";
        this.businessDetails.placeOfBusiness = brandDoc.place_business || "";
        this.businessDetails.businessDescription =
          brandDoc.business_description || "";
        this.businessDetails.logoOriginal = brandDoc.logo_original || null;
        this.businessDetails.logoDark = brandDoc.logo_dark || null;
        this.businessDetails.logoTransparent =
          brandDoc.logo_transparent || null;
        this.businessDetails.primaryColor = brandDoc.primary_color || "";
        this.businessDetails.secondaryColor = brandDoc.secondary_color || "";
        this.businessDetails.primaryFont = brandDoc.primary_font || "";
        this.businessDetails.secondaryFont = brandDoc.secondary_font || "";
        this.businessDetails.productsServicesCatalogue =
          brandDoc.products_services_catalogue || [];
        this.businessDetails.productsServicesCatalogue =
          brandDoc.products_services_catalogue || [];
        this.selectedAgeGroups = brandDoc.age_groups || [];
        this.selectedGenders = brandDoc.genders || [];
        this.selectedIncomeLevels = brandDoc.income_levels || [];
        this.selectedEducationLevels = brandDoc.education_levels || [];
        this.selectedEmploymentStatuses = brandDoc.employment_statuses || [];
        this.selectedMaritalStatuses = brandDoc.marital_statuses || [];
        this.selectedEthnicitiesRaces = brandDoc.ethnicities_races || [];
        this.selectedInterestsHobbies = brandDoc.interests_hobbies || [];
        this.selectedHouseholdSizes = brandDoc.household_sizes || [];
        this.selectedNumberOfChildren = brandDoc.number_of_children || [];
        this.selectedHomeOwnership = brandDoc.home_ownership || [];
        this.selectedDisabilityStatus = brandDoc.disability_status || [];
        this.selectedIndustryCategory = brandDoc.industry_category || [];
        this.businessDetails.targetAudience = brandDoc.target_audience || "";
        this.selectedCountry = brandDoc.country || "";
        this.businessDetails.profile = brandDoc.profile || "";
        console.log("jsjsj", this.businessDetails.profile);
      } catch (error) {
        console.error("Error fetching business details from Firestore:", error);
      }
      console.log(
        "businessDetails.logoOriginal: " + this.businessDetails.logoOriginal
      );
    },
  },
  mounted() {
    this.getbrand();
    // this.fetchBrandDetailsAndConvertToJson();

    this.fetchSuggestions();
  },
};
</script>
<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
}

.close-button {
  float: right;
  cursor: pointer;
}
@media (max-width: 768px) {
  .text-base {
    font-size: 14px;
  }
  .text-2xl {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .px-5 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mt-8 {
    margin-top: 2rem;
  }
}
@media (max-width: 768px) {
  .px-5 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mt-8 {
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .px-10 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 768px) {
  .hidden-sm {
    display: none;
  }
}
.beta-indicator {
  margin-left: 3px;
}
.rotate-icon {
  transform: rotate(-45deg);
}

</style>
