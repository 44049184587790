/* eslint-disable no-useless-escape */
const emailRe = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
const workspaceNameRe = /^[a-zA-Z0-9_]+$/; // Regular expression for workspace names
const urlRe = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/; // Regular expression for URLs

export default {
  required: (v) => !!v || "Field is required",
  max200k: (v) => {
    if (!v) return "Field is required";
    if (v >= 200000) return 'Kms driven must be less than 200,000';
    return true;
  },
  maxprice: (v) => {
    if (!v) return "Field is required";
    if (v >= 3000000) return 'Price must be less than 30,00,000';
    if(v<15000) return 'Price has minimum value of 15000';
    return true;
  },
  email: (v) => {
    if (!v) return "Field is required";
    if (!emailRe.test(v)) return "E-mail must be valid";
    return true;
  },
  phone: (v) => {
    return !v || v.length == 10 || "Please enter a valid phone number";
  },
  maxsuggestions: (v) => {
    if (!v) return "Field is required";
    if (v>3) return "Maximum suggestions: 3";
    return true;
  },
  maxchars: (v) => {
    if (!v) return "Field is required";
    if (v < 32 || v > 100) return "Must be between 32 - 100 chars";
    return true;
  },
  maxcharspostideaoutput: (v) => {
    if (!v) return "Field is required";
    if (v < 140 || v > 2000) return "Must be between 140 - 2000 chars";
    return true;
  },
  maxcharspost: (v) => {
    if (!v) return "Field is required";
    if (v < 32 || v > 1024) return "Must be between 32 - 1024 chars";
    return true;
  },
  maxcharsposttext: (v) => {
    if (!v) return "Field is required";
    if (v.length < 32 || v.length > 10000) return "Must be between 32 - 10000 chars";
    return true;
  },
  maxworkspacename: (v) => {
    if (!v) return "Field is required";
    if (!(v.length <= 32)) return "Max 32 chars";
    return true;
  },
  maxcharspostideainput: (v) => {
    if (!v) return "Field is required";
    if (v.length < 60 || v.length > 4000) return "Must be between 60 - 4000 chars";
    return true;
  },
  ssn: (v) => {
    return !v || v.length == 11 || "Please enter a valid SSN number";
  },
  zip: (v) => !v || v.length == 5 || "Please enter a valid zip code",
  password: (v) =>
    (v &&
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        v
      )) ||
    "At least 8 characters, should contain Letters, Numbers and Special Characters",
  multiselect: (v) => v.length > 0 || "Please select atleast one item",
  percentage: (v) =>
    (v <= 99 && v >= 1) || "Percentage should be between 1 to 99",
  commission_rate: (v) =>
    (v <= 100 && v >= 0) || "Percentage should be between 0 to 100",
  commission: (v) => v < 1 || "Commission rate must be less then 100%",
  workspaceName: (v) => {
    if (!v) return "Field is required";
    return workspaceNameRe.test(v) || "Can only contain letters, numbers, and underscores";
  },
  validUrl: (v) => {
    if (!v) return "Field is required";
    return urlRe.test(v) || "Must be a valid URL";
  }
};
