<template>
  <div>
    <div v-if="disabled && !isDocumentUpload" class="mb-2">
      <label> {{ label }}</label>
      <br />
      <div class="mt-2"></div>
      <v-btn v-if="value" @click="viewFile()" small class="secondary"
        >View File</v-btn
      >
    </div>

    <div v-else-if="isFileExits" class="mb-4">
      <label> {{ label }}</label>
      <br />
      <div class="mt-2"></div>
      <v-chip small color="primary" label>File Uploaded </v-chip>
    </div>
    <template v-else>
      <label class="input-label" v-if="label">{{ label }}</label>
      <v-file-input
        v-model="input"
        :class="label ? 'mt-1' : ''"
        :dense="dense"
        prepend-inner-icon="mdi-paperclip"
        v-bind="$attrs"
        prepend-icon=""
        outlined
        :rules="getRules"
        @change="$emit('input', input)"
      >
        <template v-slot:append>
          <v-tooltip bottom v-if="help">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="" x-small>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ help }}</span>
          </v-tooltip>
        </template>
      </v-file-input>
    </template>
  </div>
</template>

<script>
import { getRules } from "@/utils/common.utils";
export default {
  inheritAttrs: false,
  components: {},
  props: {
    value: {},
    label: {},
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: "",
    },
    help: String,
    customRules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: null,
    };
  },
  computed: {
    isFileExits() {
      let isUpload = this.$route.name == "application-document-upload";
      return (
        isUpload && this.value && this.value.toString().startsWith("https")
      );
    },
    isDocumentUpload() {
      return this.$route.name == "application-document-upload";
    },
    getRules() {
      let { customRules, rules } = this;
      if (customRules.length > 0) return customRules;
      return getRules(rules);
    },
  },
  watch: {
    value(val) {
      this.input = val;
    },
  },
  methods: {
    viewFile() {
      window.open(this.value, "_blank");
    },
  },
  mounted() {
    if (this.value instanceof File) {
      this.input = this.value;
    }
  },
};
</script>
