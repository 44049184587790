<template>
  <div class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]">
    <section class="flex flex-col items-center w-full justify-center xl:w-[1285px]">
      <div class="flex items-center justify-between md:mt-5 px-4 md:px-0 w-full xl:w-[1285px]">
        <div class="flex items-center gap-2">
          <v-icon :style="{
            color: theme !== '' ? theme : '#8056dd',
            fontSize: '35px',
          }">mdi-chart-box-outline</v-icon>
          <p class="text-[22px] font-bold dashboard-title">Performance</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon class="py-4 px-14">
                <span>Channels</span>

                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="always-show-scrollbar">
              <v-list-item v-for="(item, index) in processedData" :key="index">
                <v-btn icon @click.stop="
                  moveUp(index);
                $event.stopPropagation();
                " :disabled="index === 0">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn icon @click.stop="
                  moveDown(index);
                $event.stopPropagation();
                " :disabled="index === processedData.length - 1">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
                <v-list-item-avatar class="mr-2">
                  <img :src="item.logo_url || 'https://i.stack.imgur.com/ATB3o.gif'
                    " alt="icon" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click.stop="toggleVisibility(item)">
                    <v-icon>{{
                      item.isVisible ? "mdi-eye" : "mdi-eye-off"
                    }}</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="flex justify-end w-full">
          <!-- Conditionally show based on the number of connected apps -->
          <!-- Show the 'Connect' button when no apps are connected -->
          <router-link to="/connected-apps" v-if="
            !isFetchingConnectedApps && connectedAppsCount === 0 && !isWidget
          ">
            <div class="bg-blue-500 text-white px-4 py-2 rounded-full font-semibold">
              Connect
            </div>
          </router-link>
          <div v-else class="flex justify-end">
            <div class="flex items-center justify-end mr-3">
              <v-menu v-model="isMenuOpen" offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :style="{
                      color: theme !== '' ? theme : '#8056dd',
                      fontSize: '30px',
                    }">mdi-file-download-outline</v-icon>
                </template>

                <v-list v-if="!isReportPrepared">
                  <!-- Option to select Excel -->
                  <v-list-item @click="generateReport('excel')">
                    <v-list-item-icon><v-icon>mdi-file-excel</v-icon></v-list-item-icon>
                    <v-list-item-title>Excel Report</v-list-item-title>
                  </v-list-item>
                  <!-- Option to select PDF -->
                  <v-list-item @click="generateReport('pdf')">
                    <v-list-item-icon><v-icon>mdi-file-pdf-box</v-icon></v-list-item-icon>
                    <v-list-item-title>PDF Report</v-list-item-title>
                  </v-list-item>
                </v-list>

                <!-- Dropdown menu options for download and share -->
                <v-list v-if="isReportPreparedByUser">
                  <!-- Download Excel -->
                  <v-list-item v-if="selectedReportType === 'excel'" @click="downloadFile('excel')">
                    <v-list-item-icon><v-icon>mdi-download</v-icon></v-list-item-icon>
                    <v-list-item-title>Download Excel</v-list-item-title>
                  </v-list-item>

                  <!-- Download PDF -->
                  <v-list-item v-if="selectedReportType === 'pdf'" @click="downloadFile('pdf')">
                    <v-list-item-icon><v-icon>mdi-download</v-icon></v-list-item-icon>
                    <v-list-item-title>Download PDF</v-list-item-title>
                  </v-list-item>

                  <!-- Share Excel -->
                  <v-list-item v-if="selectedReportType === 'excel'" @click="shareReport('excel')">
                    <v-list-item-icon><v-icon>mdi-share-variant</v-icon></v-list-item-icon>
                    <v-list-item-title>Share Excel</v-list-item-title>
                  </v-list-item>

                  <!-- Share PDF -->
                  <v-list-item v-if="selectedReportType === 'pdf'" @click="shareReport('pdf')">
                    <v-list-item-icon><v-icon>mdi-share-variant</v-icon></v-list-item-icon>
                    <v-list-item-title>Share PDF</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div
              class="bg-gray-200 text-sm text-gray-500 leading-none border-2 border-gray-200 rounded-full inline-flex">
              <button @click="setView('grid')" :class="{ active: selectedView === 'grid' }"
                class="inline-flex items-center transition-colors duration-300 ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-l-full px-4 py-2"
                id="grid">
                <!-- Grid Icon -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="fill-current w-4 h-4 mr-2">
                  <rect x="3" y="3" width="7" height="7"></rect>
                  <rect x="14" y="3" width="7" height="7"></rect>
                  <rect x="14" y="14" width="7" height="7"></rect>
                  <rect x="3" y="14" width="7" height="7"></rect>
                </svg>

                <span class="toggle-text">Grid</span>
              </button>
              <button @click="setView('list')" :class="{ active: selectedView === 'list' }"
                class="inline-flex items-center transition-colors duration-300 ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-r-full px-4 py-2"
                id="list">
                <!-- List Icon -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="fill-current w-4 h-4 mr-2">
                  <line x1="8" y1="6" x2="21" y2="6"></line>
                  <line x1="8" y1="12" x2="21" y2="12"></line>
                  <line x1="8" y1="18" x2="21" y2="18"></line>
                  <line x1="3" y1="6" x2="3.01" y2="6"></line>
                  <line x1="3" y1="12" x2="3.01" y2="12"></line>
                  <line x1="3" y1="18" x2="3.01" y2="18"></line>
                </svg>

                <span class="toggle-text">List</span>
              </button>
            </div>
            <div class="ml-3">
              <embed-widget v-if="!isWidget" />
            </div>
          </div>

          <!-- Show the view selection buttons when apps are connected -->
        </div>
      </div>
      <div class="flex items-center justify-start mb-5 ml-[66px] md:mb-0 px-2 md:px-0 w-full xl:w-[1285px]">
        <div class="items-center gap-2 px-4 -ml-2 flex justify-between">
          <div class="flex items-center gap-1">
            <p class="text-xs md:text-[18px] text-gray-600">
              In-depth analytics of your connected channels
            </p>
          </div>
        </div>
      </div>
      <div class="mt-[18px] w-full xl:w-[1285px] flex justify-center items-center gap-4">
        <div class="w-full h-screen bg-white border md:h-auto md:rounded-3xl">
          <div class="flex items-center justify-between border-[#F2F6FA] w-full px-5 py-2 border">
            <p class="mt-2 text-lg font-semibold text-[#121212]">
              Channel Performance
            </p>
            <div
              class="border text-xs rounded-full cursor-pointer w-[136px] bg-white py-2 text-center text-[#636B8C] font-bold shadow-md">
              Last 30 days
            </div>
          </div>

          <div v-if="processedData.length === 0 && !isMetricsLoading"
            class="flex flex-col items-center mt-[65px] justify-center">
            <h1 class="text-base font-semibold text-neutral-900">
              Nothing to report yet!
            </h1>
            <template v-if="!isWidget">
              <p class="text-sm font-medium text-[#636B8C] mt-[7px]">
                Start by connecting your social handles
              </p>
              <router-link to="/connected-apps">
                <div class="py-[8px] px-[25px] text-white font-semibold bg-[#8056DE] rounded-full mt-[14px]">
                  Connect
                </div>
              </router-link>
            </template>
          </div>

          <div class="w-full px-4 pb-6 mt-4 overflow-hidden">
            <div class="overflow-hidden">
              <template v-if="isMetricsLoading">
                <div v-if="selectedView === 'grid'"
                  class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-[10px]">
                  <div v-for="i in 15" :key="i">
                    <v-skeleton-loader class="mx-auto" type="image,article"></v-skeleton-loader>
                  </div>
                </div>
                <v-skeleton-loader v-else>
                  <v-skeleton-loader class="mx-auto" type="table-row-divider@20"></v-skeleton-loader>
                </v-skeleton-loader>
              </template>
              <template v-else>
                <div v-if="selectedView === 'grid'"
                  class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-[10px]">
                  <div v-for="(item, index) in processedData" :key="index" v-show="item.isVisible"
                    class="px-[25px] py-5 swiper-slide bg-[#F2F6FA] rounded-xl">
                    <div v-if="item.loading">
                      <v-skeleton-loader class="mx-auto" type="image,article"></v-skeleton-loader>
                    </div>
                    <div v-else class="">
                      <div class="flex -ml-1.5">
                        <div class="flex items-center justify-between mb-1">
                          <div class="flex items-center gap-1">
                            <img :src="item.logo_url" alt="icon" class="w-[30px]" />
                            <p class="text-[#636B8C] text-xs font-bold">
                              @{{ item.name }}
                            </p>
                          </div>
                          <div v-if="item.platform_name == 'X / Twitter'" class="text-xs text-gray-500">
                            Last 7 days
                          </div>
                        </div>
                        <!-- <div
                        class="flex flex-col items-center justify-center mt-4"
                      >
                        <h1 class="text-[#121212] text-sm font-bold">
                          {{ item.platform_name }}
                        </h1>
                      </div> -->
                      </div>
                      <div class="flex w-full gap-">
                        <div class="flex w-full">
                          <div class="flex flex-col">
                            <p class="text-[#636B8C] text-sm">
                              {{
                                ["Instagram", "Mastodon", "Bluesky"].includes(
                                  item.platform_name
                                )
                                  ? "Total"
                                  : "Last 30d"
                              }}
                            </p>
                            <p class="text-[#636B8C] text-sm">Posts</p>

                            <h1 class="text-[#121212] flex text-xl md:text-2xl font-extrabold">
                              {{
                                item.total_posts !== undefined
                                  ? item.total_posts >= 1000
                                    ? `${(item.total_posts / 1000).toFixed(0)}k`
                                    : item.total_posts
                                  : 0
                              }}
                            </h1>
                          </div>
                        </div>
                        <div class="flex w-full">
                          <div class="flex flex-col -ml-2" :class="item.engagement?.last_30d > 0 ||
                              item.engagement?.last_30d === 'NA'
                              ? '-ml-1'
                              : '-ml-3'
                            ">
                            <p class="text-[#636B8C] flex text-sm">
                              {{
                                [
                                  "X / Twitter",
                                  "Pinterest",
                                  "Instagram",
                                  "Bluesky",
                                  "Mastodon",
                                  "Threads",
                                ].includes(item.platform_name)
                                  ? "Total"
                                  : "New"
                              }}
                            </p>
                            <p class="text-[#636B8C] flex text-sm">
                              Followers
                              <tool-tip class="absolute z-[9999999999]"
                                :message="getTooltipMessage(item.platform_name)">
                                <div class="border rounded-full ml-1 flex align-center justify-center w-5 h-5 bg-white">
                                  <v-icon size="10">mdi-help</v-icon>
                                </div>
                              </tool-tip>
                            </p>
                            <h1 class="text-[#121212] text-xl md:text-2xl font-extrabold">
                              {{
                                item.followers?.last_30d === "NA"
                                  ? "NA"
                                  : item.followers?.last_30d !== undefined
                                    ? item.followers.last_30d >= 1000000
                                      ? `${(
                                        item.followers.last_30d / 1000000
                                      ).toFixed(1)}M`
                                      : item.followers.last_30d >= 1000
                                        ? `${(
                                          item.followers.last_30d / 1000
                                        ).toFixed(1)}K`
                                        : item.followers.last_30d > 0
                                          ? item.followers.last_30d
                                          : 0
                                    : 0
                              }}
                            </h1>
                            <div v-if="
                              item.followers?.change &&
                              item.followers?.change !== 'NA' &&
                              !isNaN(item.followers?.change)
                            "
                              class="bg-green-100 mb-5 rounded-full flex items-center -ml-2 gap-1 font-semibold w-20 text-sm text-center p-1 text-[#6BB251]"
                              :class="{
                                'bg-red-100 text-[#FF6F66] font-semibold w-[80px]': 
                                  item.followers?.change < 0
                              }">
                              <v-icon v-if="item.followers?.change > 0" size="18" color="green">mdi-arrow-up</v-icon>
                              <v-icon v-if="item.followers?.change < 0" size="18" color="red">mdi-arrow-down</v-icon>
                              {{ Math.floor(item.followers.change) }}%
                            </div>
                            <div v-else
                              class="bg-green-100 mb-5 rounded-full flex items-center -ml-2 gap-1 font-semibold w-10 text-sm text-center p-1 text-[#6BB251]"
                              style="visibility: hidden">
                              Placeholder
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="xl:w-[183px] w-full h-[0px] border border-slate-200"></div>
                      <div class="flex items-center gap-5">
                        <div class="flex gap-[11px] mt-[15px] justify-start">
                          <div class="flex flex-col">
                            <h1 class="text-[#636B8C] flex text-sm">
                              <span v-if="
                                item.platform_name ==
                                'Google Business Profile' ||
                                item.platform_name ==
                                'google_business_profile'
                              ">
                                Impressions
                              </span>
                              <span v-else> Reach </span>
                              <tool-tip class="absolute z-[9999999999]" :message="getTooltipGmbMessage(
                                item.platform_name,
                                'impressions'
                              )
                                ">
                                <div class="border rounded-full ml-1 flex align-center justify-center w-5 h-5 bg-white">
                                  <v-icon size="10">mdi-help</v-icon>
                                </div>
                              </tool-tip>
                            </h1>
                            <div class="flex flex-col mt-2 mb-2">
                              <h1 class="text-[#121212] text-xl md:text-2xl font-extrabold">
                                {{
                                  item.reach?.last_30d !== undefined
                                    ? item.reach.last_30d >= 1000000
                                      ? `${(
                                        item.reach.last_30d / 1000000
                                      ).toFixed(0)}M`
                                      : item.reach.last_30d >= 1000
                                        ? `${(item.reach.last_30d / 1000).toFixed(
                                          0
                                        )}K`
                                        : item.reach.last_30d
                                    : 0
                                }}
                              </h1>

                              <div v-if="item.reach?.change && !isNaN(item.reach?.change)" 
                                :class="item.reach?.change < 0
                                  ? 'bg-[#FFE0DE] text-[#FF6F66] -ml-2 font-semibold mt-1 flex items-center gap-1 rounded-full text-sm w-[80px] justify-center py-1.5 text-center'
                                  : 'bg-[#DEFFE3] text-[#6BB251] -ml-2 font-semibold mt-1 flex items-center gap-1 rounded-full text-sm w-[80px] justify-center py-1.5 text-center'
                                ">
                                <v-icon v-if="item.reach?.change > 0" size="18" color="green">mdi-arrow-up</v-icon>
                                <v-icon v-if="item.reach?.change < 0" size="18" color="red">mdi-arrow-down</v-icon>
                                {{ Math.floor(item.reach.change) }}%
                              </div>
                              <div v-else
                                class="bg-green-100 mb-5 rounded-full flex items-center -ml-2 gap-1 font-semibold w-10 text-sm text-center p-1 text-[#6BB251] -mt-3"
                                style="visibility: hidden">
                                Placeholder
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="flex gap-[11px] justify-start" :class="item.engagement?.last_30d > 0 ||
                            item.engagement?.last_30d === 'NA'
                            ? 'mt-[15px]'
                            : ' mt-[15px]'
                          ">
                          <div class="flex flex-col mb-2">
                            <h1 class="text-[#636B8C] flex text-sm">
                              <span v-if="
                                item.platform_name ==
                                'Google Business Profile' ||
                                item.platform_name ==
                                'google_business_profile'
                              ">
                                Clicks
                              </span>
                              <span v-else> Engagement </span>
                              <tool-tip class="absolute z-[9999999999]" :message="getTooltipGmbMessage(
                                item.platform_name,
                                'clicks'
                              )
                                ">
                                <div class="border rounded-full ml-1 flex align-center justify-center w-5 h-5 bg-white">
                                  <v-icon size="10">mdi-help</v-icon>
                                </div>
                              </tool-tip>
                            </h1>
                            <div class="flex flex-col mt-2">
                              <h1 
                                class="text-[#121212] flex gap-[18px] text-xl md:text-2xl text-center font-extrabold">
                                {{
                                  item.engagement?.last_30d == "NA"
                                    ? item.engagement?.last_30d
                                    : item.engagement?.last_30d !== undefined
                                      ? item.engagement.last_30d >= 1000000
                                        ? `${(
                                          item.engagement.last_30d / 1000000
                                        ).toFixed(1)}M`
                                        : item.engagement.last_30d >= 1000
                                          ? `${(
                                            item.engagement.last_30d / 1000
                                          ).toFixed(1)}K`
                                          : item.engagement.last_30d
                                      : 0
                                }}
                              </h1>
                              <div v-if="item.engagement?.change && !isNaN(item.engagement?.change)" 
                                :class="item.engagement?.change < 0
                                  ? 'bg-[#FFE0DE] text-[#FF6F66] font-semibold mt-1 flex items-center justify-center gap-1 rounded-full text-sm w-[80px] py-1.5 text-center'
                                  : 'bg-[#DEFFE3] text-[#6BB251] font-semibold mt-1 flex items-center justify-center gap-1 rounded-full text-sm w-[80px] py-1.5 text-center'
                                ">
                                <v-icon v-if="item.engagement?.change > 0" size="18" color="green">mdi-arrow-up</v-icon>
                                <v-icon v-if="item.engagement?.change < 0" size="18" color="red">mdi-arrow-down</v-icon>
                                {{ Math.floor(item.engagement.change) }}%
                              </div>
                              <div v-else
                                class="bg-green-100 mb-5 rounded-full flex items-center -ml-2 gap-1 font-semibold w-10 text-sm text-center p-1 text-[#6BB251] -mt-3"
                                style="visibility: hidden">
                                Placeholder
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="selectedView === 'list'" class="flex flex-col mt-4 list-view-container">
                  <!-- Header row for titles -->
                  <div class="flex text-left bg-[#F2F6FA] p-3 rounded-t-md">
                    <div class="w-1/5 pl-10 font-bold text-[#636B8C]">
                      Platform
                    </div>
                    <div class="w-1/5 font-bold text-[#636B8C]">
                      Total Posts
                    </div>
                    <div class="w-1/5 font-bold text-[#636B8C]">
                      New Followers
                    </div>
                    <div class="w-1/5 font-bold text-[#636B8C]">Reach</div>
                    <div class="w-1/5 font-bold text-[#636B8C]">Engagement</div>
                  </div>
                  <!-- Data rows -->
                  <div v-for="(item, index) in processedData" :key="index" v-show="item.isVisible"
                    class="flex items-center p-3 border-b">
                    <img v-if="item.logo_url" :src="item?.logo_url" alt="icon" class="w-[30px]" />

                    <div class="w-1/5 pl-4">@{{ item.name }}</div>
                    <div class="w-1/5">
                      {{
                        item.total_posts !== undefined
                          ? item.total_posts >= 1000
                            ? `${(item.total_posts / 1000).toFixed(0)}k`
                            : item.total_posts
                          : 0
                      }}
                      <!-- {{ formatNumber(item.total_posts) }} -->
                    </div>
                    <div class="w-1/5">
                      {{
                        item.followers?.last_30d === "NA"
                          ? "NA"
                          : item.followers?.last_30d !== undefined
                            ? item.followers.last_30d >= 1000000
                              ? `${(item.followers.last_30d / 1000000).toFixed(
                                1
                              )}M`
                              : item.followers.last_30d >= 1000
                                ? `${(item.followers.last_30d / 1000).toFixed(1)}K`
                                : item.followers.last_30d > 0
                                  ? item.followers.last_30d
                                  : 0
                            : 0
                      }}
                      <!-- {{ formatNumber(item.followers?.last_30d) }} -->
                    </div>
                    <div class="w-1/5">
                      {{
                        item.reach?.last_30d !== undefined
                          ? item.reach.last_30d >= 1000000
                            ? `${(item.reach.last_30d / 1000000).toFixed(1)}M`
                            : item.reach.last_30d >= 1000
                              ? `${(item.reach.last_30d / 1000).toFixed(1)}K`
                              : item.reach.last_30d
                          : 0
                      }}
                      <!-- {{ formatNumber(item.reach?.last_30d) }} -->
                    </div>
                    <div class="w-1/5">
                      {{
                        item.engagement?.last_30d !== undefined
                          ? item.engagement.last_30d >= 1000000
                            ? `${(item.engagement.last_30d / 1000000).toFixed(
                              1
                            )}M`
                            : item.engagement.last_30d >= 1000
                              ? `${(item.engagement.last_30d / 1000).toFixed(1)}K`
                              : item.engagement.last_30d
                          : 0
                      }}
                      <!-- {{ formatNumber(item.engagement?.last_30d) }} -->
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section
      class="flex flex-col items-center w-full justify-center xl:w-[1285px]"
    >
      <div class="mt-[18px] flex gap-4">
        <div
          class="xl:w-[904px] w-full pb-5 bg-white border rounded-3xl px-2 md:px-5"
        >
          <div class="flex items-center justify-start w-full p-5">
            <p class="mt-1 text-base font-semibold text-[#121212]">
              Recent Posts
            </p>
          </div>

          <div
            v-if="recentPostsNodata"
            class="flex flex-col items-center mt-[151px] justify-center"
          >
            <h1 class="text-base font-bold text-neutral-900">NO DATA</h1>
          </div>
          <section class="flex w-full xl:w-[904px] gap-4">
            <div class="w-full swiper mySwiper2">
              <div class="flex w-full gap-2 md:gap-5 swiper-wrapper">
                <div
                  class="w-[274px] h-[336px] flex flex-col swiper-slide bg-[#F2F6FA] rounded-3xl p-6"
                  v-for="(post, index) in recentPosts"
                  :key="index"
                >
                  <div class="flex gap-[11px] -ml-1.5 justify-start">
                    <div>
                      <img :src="post.logo_url" alt="icon" class="w-[44px]" />
                    </div>
                    <div class="flex flex-col gap-[2px]">
                      <h1 class="text-[#121212] text-sm font-bold">
                        {{ post.social_media[0] }}
                      </h1>
                      <p class="text-[#636B8C] text-xs">
                        {{
                          post.created_at === undefined
                            ? null
                            : new Date(
                                (post.created_at.seconds ?? 0) * 1000
                              ).toLocaleDateString("en-US", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                                minute: "numeric",
                                hour: "numeric",
                              })
                        }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="w-[250px] text-neutral-900 text-[10px] mb-1 font-normal"
                  >
                    {{ post.name }}
                  </div>
                  <img
                    class="w-[250px] h-[200px] rounded-3xl object-cover"
                    v-if="post.images.length"
                    :src="post.images[0]"
                    alt="post image"
                  />
                  <div class="mt-1 overflow-hidden h-[200px] rounded-3xl">
                    <video-auto-play
                      :autoplay="false"
                      class="w-[250px] h-[200px] rounded-3xl object-cover"
                      v-if="post.videos.length > 0"
                      :videoSrc="post.videos[0]"
                    ></video-auto-play>
                  </div>
                  <div
                    class="flex items-center justify-center w-full pl-6 mt-2"
                  >
                    <div class="flex gap-1">
                      <img
                        src="..//..//assets/img/gallery-icons/like.svg"
                        alt="like"
                      />
                      <span class="text-[#636B8C] font-semibold text-[8px]">
                        {{ getCount(post, post.statistics, "likes") }}
                    
                      </span>
                    </div>
                    <div class="flex gap-1">
                      <img
                        src="..//..//assets/img/gallery-icons/comments.svg"
                        alt="comments"
                      />
                      <span class="text-[#636B8C] font-semibold text-[8px]">
                        {{ getCount(post, post.statistics, "comments") }}
                      </span>
                    </div>
                    <div class="flex gap-1">
                      <img
                        src="..//..//assets/img/gallery-icons/view.svg"
                        alt="views"
                      />
                      <span class="text-[#636B8C] font-semibold text-[8px]">
                        {{ getCount(post, post.statistics, "views") }}
                      </span>
                    </div>
                    <div class="flex gap-1">
                      <img
                        src="..//..//assets/img/gallery-icons/share.svg"
                        alt="share"
                      />
                      <span class="text-[#636B8C] font-semibold text-[8px]"
                        >22</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
       
      </div>
    </section> -->
    <!-- <div v-if="!dismissed" class="onboarding-popup">
      <div
        @click="toggleOnboardingVisibility"
        class="flex items-center justify-between cursor-pointer"
      >
        <h2 class="font-bold">Onboarding Steps</h2>
        <v-icon
          :name="isOnboardingVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          color="white"
        ></v-icon>
        <v-icon v-if="isOnboardingVisible" color="white"
          >mdi-chevron-down</v-icon
        >
        <v-icon color="white" v-if="!isOnboardingVisible"
          >mdi-chevron-up</v-icon
        >
      </div>
      <div v-if="isOnboardingVisible">
        <div class="progress-text">
          {{ completedSteps }} of {{ totalSteps }} steps completed
        </div>
        <div class="progress-bar">
          <div
            class="progress-bar-fill"
            :style="{ width: progressPercentage + '%' }"
          ></div>
        </div>
        <div
          v-for="(item, index) in onboardingSteps"
          :key="index"
          class="checkbox-item flex justify-between"
        >
          <input
            type="checkbox"
            :id="`step-${index}`"
            class="custom-checkbox"
            v-model="item.completed"
          />
          <label :for="`step-${index}`">
            <router-link
              :to="item.route"
              :style="{ color: 'white' }"
              :class="{ strikethrough: item.completed }"
              >{{ item.name }}</router-link
            >
          </label>
          <v-icon color="white">mdi-chevron-right</v-icon>
        </div>

        <div class="text-center bg-slate-800 mt-2 p-2 rounded-sm text-xs">
          <a
            href="https://calendly.com/fly-social/30min"
            target="_blank"
            class="need-help-link"
            >Need help? Connect with a member of our team</a
          >
        </div>
        <button @click="dismissOnboarding" class="dismiss-button text-sm">
          Dismiss
        </button>
      </div>
    </div> -->
    <div v-if="!dismissed" class="onboarding-popup">
      <div @click="toggleOnboardingVisibility" class="flex items-center justify-between cursor-pointer">
        <h2 class="font-bold">Onboarding Steps</h2>
        <v-icon :name="isOnboardingVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'" color="white"></v-icon>
        <v-icon v-if="isOnboardingVisible" color="white">mdi-chevron-down</v-icon>
        <v-icon color="white" v-if="!isOnboardingVisible">mdi-chevron-up</v-icon>
      </div>
      <div v-if="isOnboardingVisible">
        <div class="progress-text">
          {{ completedSteps }} of {{ totalSteps }} steps completed
        </div>
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="{ width: progressPercentage + '%' }"></div>
        </div>
        <div v-for="(item, index) in onboardingSteps" :key="index" class="checkbox-item flex justify-between">
          <input type="checkbox" :id="`step-${index}`" class="custom-checkbox" v-model="item.completed" />
          <label :for="`step-${index}`">
            <router-link :to="item.route" :style="{ color: 'white' }" :class="{ strikethrough: item.completed }">{{
              item.name }}</router-link>
          </label>
          <router-link :to="item.route">
            <v-icon color="white cursor-pointer">mdi-chevron-right</v-icon>
          </router-link>
        </div>
        <div class="text-center bg-slate-800 mt-2 p-2 rounded-sm text-xs">
          <a href="https://calendly.com/fly-social/30min" target="_blank" class="need-help-link">Need help? Connect with
            a
            member of our team</a>
        </div>
        <button @click="dismissOnboarding" class="dismiss-button text-sm">
          Dismiss
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ToolTip from "../../components/ui/ToolTip.vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import { auth, db } from "@/utils/firebase.utils";
import { onSnapshot, doc, updateDoc, setDoc } from "firebase/firestore";
import { trackGTM } from "../../utils/common.utils";

import { collection, getDocs, getDoc } from "firebase/firestore";
import EmbedWidget from "@/components/EmbedWidget.vue";
export default {
  components: {
    ToolTip,
    EmbedWidget,
  },
  data() {
    return {
      connectedAppsCount: 0,
      isFetchingConnectedApps: true,
      selectedView: localStorage.getItem("selectedView") || "grid",
      viewOptions: [
        { text: "Grid View", value: "grid" },
        { text: "List View", value: "list" },
      ],
      isMetricsLoading: true,
      metrics: [],
      processedData: [],
      recentPosts: [],
      isLoading: false,
      isMenuOpen: false,
      reportTimeoutId: null,
      isReportPrepared: false,
      isReportPreparedByUser: false,
      selectedReportType: "",
      reportUrl: "",
      reportUrlExcel: "",
      openReportUrl: false,
      openReportUrlExcel: false,
      recentPostsNodata: false,
      connecTedApps: false,
      isOnboardingVisible: true,
      theme:"",
      dismissed: true,
      onboardingSteps: [
        // {
        //   name: "Complete  profile details",
        //   completed: false,
        //   route: "/edit-profile",
        //   key: "is_profile_completed",
        // },
        {
          name: "Connect your social handles",
          completed: false,
          route: "/connected-apps",
          key: "is_connected",
        },
        {
          name: "Publish your first post",
          completed: false,
          route: "/create",
          key: "is_posted",
        },
        {
          name: "Generate content ideas",
          completed: false,
          route: "/content-ai",
          key: "is_content_ai_generated",
        },
        // {
        //   name: "Generate content ideas using Fly",
        //   completed: false,
        //   route: "/create",
        //   key: "is_idea_generated",
        // },
      ],
    };
  },
  watch: {
    selectedView(newValue) {
      localStorage.setItem("selectedView", newValue);
    },
    onboardingSteps: {
      handler(newSteps) {
        this.updateOnboardingStepsInFirestore(newSteps);
      },
      deep: true,
    },
  },
  computed: {
    completedSteps() {
      return this.onboardingSteps.filter((step) => step.completed).length;
    },
    totalSteps() {
      return this.onboardingSteps.length;
    },
    progressPercentage() {
      return (this.completedSteps / this.totalSteps) * 100;
    },
    isWidget() {
      return this.$route.name == "dashboard-widget";
    },
  },

  methods: {
    resetReportState() {
      this.isReportPrepared = false;
      this.isReportPreparedByUser = false;
      this.selectedReportType = "";
      this.reportUrl = "";
      this.reportUrlExcel = "";
      this.openReportUrl = false;
      this.openReportUrlExcel = false;
      this.reportTimeoutId = null;
    },
    async generateReport(type) {
      this.selectedReportType = type;
      // Show the loader while preparing the report
      this.$loader.show(`Preparing your ${type.toUpperCase()} report...`);

      let urlKey = type === "pdf" ? "reportUrl" : "reportUrlExcel";
      let openUrlKey = type === "pdf" ? "openReportUrl" : "openReportUrlExcel";

      try {
        let payload = {
          uid: this.currentUID,
          wid: this.WID,
        };

        // Call the report generation function
        const reportURl = httpsCallable(
          functions,
          `generateStorePerformanceReport${type === "pdf" ? "" : "Excel"}`
        );
        const url = await reportURl(payload);

        // Store the generated URL
        this[urlKey] = url.data;
        this.$loader.hide();

        // Mark the report as prepared and open the menu
        this.isReportPrepared = true;
        this.isReportPreparedByUser = true;
        this[openUrlKey] = true;
        this.isMenuOpen = true;
        if (this.reportTimeoutId) {
          clearTimeout(this.reportTimeoutId); // Clear any existing timeout
        }
        this.reportTimeoutId = setTimeout(() => {
          this.resetReportState();
        }, 60000); // 60000 milliseconds = 1 minute
      } catch (error) {
        this.$loader.hide();
        console.error(`Error generating ${type} report:`, error);
      }
    },
    async downloadFile(type) {
      const urlKey = type === "pdf" ? "reportUrl" : "reportUrlExcel";
      let fileName = `Report.${type}`;
      if (type == "excel") {
        fileName = "Report.xlsx";
      }

      if (!this[urlKey]) {
        console.error(`No report URL found for ${type}.`);
        return;
      }

      const response = await fetch(this[urlKey]);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);

      // Reset report state
      this.isReportPrepared = false;
      this.isReportPreparedByUser = false;
      this.resetReportState();
      this.isMenuOpen = false;

      // Clear the timeout
      if (this.reportTimeoutId) {
        clearTimeout(this.reportTimeoutId);
        this.reportTimeoutId = null;
      }
    },
    async shareReport(type) {
      const urlKey = type === "pdf" ? "reportUrl" : "reportUrlExcel";

      if (!this[urlKey]) {
        console.error(`No report URL found for ${type}.`);
        return;
      }

      const report_url = this[urlKey];

      try {
        if (type === "pdf") {
          // For PDF: Fetch the file and share it directly
          const response = await fetch(report_url);
          const blob = await response.blob();

          const file = new File([blob], "Report.pdf", {
            type: "application/pdf",
          });

          if (navigator.canShare && navigator.canShare({ files: [file] })) {
            await navigator.share({
              title: "PDF report",
              text: "Check out this PDF report!",
              files: [file], // Share the PDF file
            });
            // console.log("PDF report shared successfully.");
          } else {
            alert("Web Share API is not supported for file sharing.");
          }
        } else if (type === "excel") {
          // For Excel: Share the URL
          if (navigator.canShare && navigator.canShare({ url: report_url })) {
            await navigator.share({
              title: "Excel Report",
              text: "Check out this Excel report!",
              url: report_url, // Share the Excel URL
            });
            // console.log("Excel report URL shared successfully.");
          } else {
            alert("Web Share API is not supported or cannot share URLs.");
          }
        }
      } catch (error) {
        console.error(`Error sharing ${type} report:`, error);
      }

      // Reset report state
      this.isReportPrepared = false;
      this.isReportPreparedByUser = false;
      this.resetReportState();
      this.isMenuOpen = false;

      // Clear the timeout
      if (this.reportTimeoutId) {
        clearTimeout(this.reportTimeoutId);
        this.reportTimeoutId = null;
      }
    },
    getTooltipMessage(platformName) {
      const totalPlatforms = [
        "X / Twitter",
        "Pinterest",
        "Instagram",
        "Bluesky",
        "Mastodon",
        "Threads",
      ];
      if (totalPlatforms.includes(platformName)) {
        return "Total count of audience for your channel";
      } else {
        return "Total count of audience for your channel in the past 30 days";
      }
    },
    getTooltipGmbMessage(platformName, type) {
      if (type === "impressions") {
        if (
          platformName === "Google Business Profile" ||
          platformName === "google_business_profile"
        ) {
          return "Total number of impressions on your channel for the past 30 days";
        } else {
          return "Total number of unique view counts on your channel for the past 30 days";
        }
      } else if (type === "clicks") {
        if (
          platformName === "Google Business Profile" ||
          platformName === "google_business_profile"
        ) {
          return "Total number of clicks on your channel for the past 30 days";
        } else {
          return "Total number of engagement on your content on your channel for the past 30 days";
        }
      }
    },
    async fetchConnectedApps() {
      this.isFetchingConnectedApps = true; // Start fetching state
      try {
        const channelCollection = collection(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          this.WID,
          "connected-apps"
        );
        const channelSnapshot = await getDocs(channelCollection);
        this.connectedAppsCount = channelSnapshot.docs.length;
      } catch (error) {
        console.error("Error fetching connected apps:", error);
      } finally {
        this.isFetchingConnectedApps = false; // Stop fetching state
      }
    },
    setView(view) {
      this.selectedView = view;
      localStorage.setItem("selectedView", view);
    },
    toggleOnboardingVisibility() {
      this.isOnboardingVisible = !this.isOnboardingVisible;
    },
    moveUp(index) {
      if (index > 0) {
        const item = this.processedData[index];
        this.processedData.splice(index, 1);
        this.processedData.splice(index - 1, 0, item);
        this.saveOrder();
      }
    },

    moveDown(index) {
      if (index < this.processedData.length - 1) {
        const item = this.processedData[index];
        this.processedData.splice(index, 1);
        this.processedData.splice(index + 1, 0, item);
        this.saveOrder();
      }
    },

    saveOrder() {
      // Save the current order based on unique identifiers such as IDs
      const order = this.processedData.map((item) => item.id || item.name);
      localStorage.setItem("channelOrder", JSON.stringify(order));
    },

    loadOrder() {
      const order = JSON.parse(localStorage.getItem("channelOrder"));
      if (order) {
        // Reorder processedData based on the stored order
        const orderedData = [];
        order.forEach((id) => {
          const item = this.processedData.find(
            (item) => item.id === id || item.name === id
          );
          if (item) {
            orderedData.push(item);
          }
        });
        // Include items that might not be in the saved order (new items)
        this.processedData.forEach((item) => {
          if (!orderedData.includes(item)) {
            orderedData.push(item);
          }
        });
        this.processedData = orderedData;
      }
    },
    async toggleVisibility(item) {
      // Toggle visibility
      item.isVisible = !item.isVisible;

      // Construct the document reference
      // console.log('item.name:', item)
      const itemId = item.id || item.name;
      const docRef = doc(
        db,
        "users",
        this.currentUID,
        "fly_workspaces",
        this.WID,
        "channels",
        itemId
      );

      try {
        await setDoc(
          docRef,
          {
            isVisible: item.isVisible,
            platform: item.platform,
            name: item.name,
          },
          { merge: true }
        );

        console.log("Item visibility updated or created successfully");
      } catch (error) {
        console.error("Error updating or creating item visibility: ", error);
      }
    },
    async fetchChannels() {
      const userId = this.currentUID; // Make sure the user is authenticated
      const channelsRef = collection(
        db,
        "users",
        userId,
        "fly_workspaces",
        this.WID,
        "channels"
      );
      console.log(
        "Item visibility updated or created successfully",
        channelsRef
      );

      onSnapshot(
        channelsRef,
        (querySnapshot) => {
          let channels = [];
          querySnapshot.forEach((doc) => {
            let channelData = doc.data();
            channelData.id = doc.id; // Store document ID (if needed)
            channels.push(channelData);
            this.processedData.forEach((data) => {
              if (data.name === channelData.name) {
                data.isVisible = channelData.isVisible;
              }
            });
          });
          // console.log('channels:', channels);
        },
        (error) => {
          console.error("Failed to fetch channels: ", error);
        }
      );
    },
    formatNumber(value) {
      return value >= 1000 ? `${(value / 1000).toFixed(1)}K` : value;
    },
    async fetchOnboardingSteps() {
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.onboarding_steps) {
            const stepsData = data.onboarding_steps;
            this.onboardingSteps = this.onboardingSteps.map((step) => ({
              ...step,
              completed: stepsData[step.key] || false,
            }));
          }
          // Retrieve and update the dismissed property
          this.dismissed =
            data.dismissed !== undefined ? data.dismissed : false;
        } else {
          console.log("No user data found in Firestore, using defaults.");
        }
      } catch (error) {
        console.error("Error fetching onboarding data from Firestore: ", error);
      }
    },

    async dismissOnboarding() {
      this.dismissed = true; // Update local state
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, {
          dismissed: this.dismissed, // Update Firestore
        });
        console.log("Dismiss state updated in Firestore!");
      } catch (error) {
        console.error("Error updating dismiss state in Firestore: ", error);
      }
    },
    async initializeOnboardingSteps() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists() || !docSnap.data().onboarding_steps) {
        await setDoc(
          docRef,
          {
            onboarding_steps: stepsData,
          },
          { merge: true }
        );
        console.log("Onboarding steps initialized in Firestore.");
      } else {
        console.log("Firestore already has onboarding steps.");
      }
    },

    async updateOnboardingStepsInFirestore() {
      const stepsData = {};
      this.onboardingSteps.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, {
          onboarding_steps: stepsData,
        });
        console.log("Onboarding steps updated in Firestore successfully!");
      } catch (error) {
        console.error("Error updating onboarding steps in Firestore: ", error);
      }
    },

    async userCollections() {
      this.isLoading = true;
      const channelCollection = collection(
        db,
        "users",
        this.currentUID,
        "fly_workspaces",
        this.WID,
        "connected-apps"
      );
      const channelSnapshot = await getDocs(channelCollection);
      const channelData = [];
      channelSnapshot.docs.forEach((doc) => {
        channelData.push({ id: doc.id, ...doc.data() });
      });

      // Initialize processedData array
      this.processedData = [];
      channelData.forEach(async (channel) => {
        switch (channel.id) {
          case "facebook":
            channel.pages.map((page) => {
              this.processedData.push({
                id: page.id,
                name: page.name,
                platform: channel.id,
                loading: true,
                metrics: [],
                isVisible: true,
              });
            });
            break;
          case "instagram":
            channel.users.map((user) => {
              this.processedData.push({
                id: user.user_id,
                name: user.user_name,
                platform: channel.id,
                loading: true,
                metrics: [],
                isVisible: true,
              });
            });
            break;

          case "pinterest":
            this.processedData.push({
              id: channel.user_id,
              name: channel.name,
              platform: channel.id,
              loading: true,
              metrics: [],
              isVisible: true,
            });
            break;

          // case "reddit":
          //   this.processedData.push({
          //     id: channel.user_id,
          //     name: channel.name,
          //     platform: channel.id,
          //     loading: true,
          //     metrics: [],
          //     isVisible: true,
          //   });
          //   break;

          case "tumblr":
            channel.blogs?.map((blog) => {
              this.processedData.push({
                name: blog.name,
                platform: channel.id,
                loading: true,
                metrics: [],
                isVisible: true,
              });
            });
            break;

          case "twitter":
            this.processedData.push({
              id: channel.user_id,
              name: channel.name,
              platform: channel.id,
              loading: true,
              metrics: [],
              isVisible: true,
            });
            break;
          case "mastodon":
            this.processedData.push({
              id: channel.user_id,
              name: channel.name,
              platform: channel.id,
              loading: true,
              metrics: [],
              isVisible: true,
            });
            break;
          case "bluesky":
            this.processedData.push({
              id: channel.user_id,
              name: channel.name,
              platform: channel.id,
              loading: true,
              metrics: [],
              isVisible: true,
            });
            break;
          case "threads":
            this.processedData.push({
              id: channel.user_id,
              name: channel.name,
              platform: channel.id,
              loading: true,
              metrics: [],
              isVisible: true,
            });
            break;
          case "youtube":
            this.processedData.push({
              id: channel.user_id,
              name: channel.name,
              platform: channel.id,
              loading: true,
              metrics: [],
              isVisible: true,
            });
            break;
          case "linkedin":
            channel.pages?.map((page) => {
              this.processedData.push({
                id: page.id,
                name: page.name,
                platform: channel.id,
                loading: true,
                metrics: [],
                isVisible: true,
              });
            });
            // this.processedData.push({
            //     id: channel.user_id,
            //     name: channel.name,
            //     platform: channel.id,
            //     loading: true,
            //     metrics: [],
            //   });
            break;
          case "google_business_profile":
            channel.accounts.map((account) => {
              account.locations.map((location) => {
                this.processedData.push({
                  id: location.name,
                  account: account.id,
                  name: location.title,
                  platform: channel.id,
                  loading: true,
                  metrics: [],
                  isVisible: true,
                });
              });
            });
            break;
          default:
        }
      });
      // Fetch metrics in parallel
      const metricPromises = this.processedData.map(async (data) => {
        const result = await this.getMetrics(data);
        if (result) {
          data.metrics.push({ name: data.name, ...result.data });
          data.loading = false;
        }
      });

      await Promise.all(metricPromises);
      this.isLoading = false;
      // for (let data of this.processedData) {
      //   // console.log("data", data.platform);
      //   let result = await this.getMetrics(data);
      //   if (result) {
      //     // console.log("result", result.data);
      //     data.metrics.push({ name: data.name, ...result.data });
      //     data.loading = false;
      //   }
      //   // this.initializeSwiper();
      //   this.isLoading = false;
      // }
    },
    async getMetrics(data) {
      try {
        let payload = {
          uid: this.currentUID,
          platform: data.platform,
          wid: this.WID,
          name: data.name,
        };
        if (data.platform === "tumblr") {
          payload.blog = data.name;
        }
        if (data.platform === "facebook") {
          payload.page = data.id;
        }
        if (data.platform === "linkedin") {
          payload.page = data.id;
        }
        if (data.platform === "instagram") {
          payload.user = data.id;
        }
        if (data.platform === "google_business_profile") {
          payload.location = data.id;
          payload.account = data.account;
        }

        let subscribe = httpsCallable(functions, "fetchPlatformMetrics");
        let result = await subscribe(payload);

        return result;
      } catch (error) {
        console.error("Error fetching platform metrics:", error);
      }
    },

    // async fetchRecentPosts() {
    //   const vm = this;
    //   try {
    //     vm.isLoading = true;
    //     let currentUID = this.currentUID || vm.uid;
    //     if (!currentUID && !vm.WID) return;
    //     let col = collection(
    //       colUsers,
    //       currentUID,
    //       "fly_workspaces",
    //       vm.WID,
    //       "social-posts"
    //     );
    //     let q = query(col, orderBy("created_at", "desc"), limit(3));
    //     let querySnapshot = await getDocs(q);
    //     let recentPosts = querySnapshot.docs.map((doc) => ({
    //       ...doc.data(),
    //       id: doc.id,
    //     }));

    //     for (let post of recentPosts) {
    //       console.log("post", post);
    //       {
    //         const channelCollection = collection(db, "fly_platforms");
    //         const channelSnapshot = await getDocs(channelCollection);
    //         channelSnapshot.forEach((doc) => {
    //           if (doc.id === post.social_media[0]) {
    //             post.logo_url = doc.data().logo_url;
    //           }
    //         });
    //         post.statistics = await vm.fetchPostStatistics(post.id);
    //       }
    //     }
    //     console.log("Fetching recent posts...", recentPosts);
    //     vm.recentPosts = recentPosts;
    //     vm.isLoading = false;

    //     // this.initializeSwiperTwo();
    //   } catch (error) {
    //     console.error("Error fetching recent posts:", error);
    //     vm.isLoading = false;
    //   }
    // },
    // initializeSwiper() {
    //   this.$nextTick(() => {
    //     new window.Swiper(".mySwiper", {
    //       loop: true,
    //       freemode: true,
    //       breakpoints: {
    //         320: {
    //           slidesPerView: "1.1",
    //         },
    //         768: {
    //           slidesPerView: "2.5",
    //         },
    //         1024: {
    //           slidesPerView: "3",
    //         },
    //         1440: {
    //           slidesPerView: "3",
    //         },
    //       },
    //     });
    //   });
    // },
    // initializeSwiperTwo() {
    //   this.$nextTick(() => {
    //     new window.Swiper(".mySwiper2", {
    //       loop: true,
    //       freemode: true,
    //       breakpoints: {
    //         320: {
    //           slidesPerView: "1.1",
    //         },
    //         768: {
    //           slidesPerView: "2.5",
    //         },
    //         1024: {
    //           slidesPerView: "3",
    //         },
    //         1440: {
    //           slidesPerView: "3.2",
    //         },
    //       },
    //     });
    //   });
    // },
    // getCount({ id, social_media, output }, statistics, type) {
    //   if (!id) return 0;
    //   let platform = social_media[0];
    //   let postId =
    //     output[`${social_media[0]}`].data !== undefined
    //       ? output[`${social_media[0]}`].data[0].post_id
    //       : 0;
    //   return _.get(statistics, `${platform}.${postId}.${type}`, 0);
    // },
    // async fetchPostStatistics(postId) {
    //   const vm = this;
    //   try {
    //     let fetchPostStatistics = httpsCallable(
    //       functions,
    //       "fetchPostStatistics"
    //     );

    //     let result = (
    //       await fetchPostStatistics({
    //         uid: this.uid,
    //         post_id: postId,
    //         wid: this.WID,
    //       })
    //     ).data;
    //     if (!result.status) {
    //       vm.$alert.show(result.message);
    //     } else {
    //       return result.result;
    //     }
    //   } catch (error) {
    //     vm.handleError(error);
    //   }
    // },
    async fetchMetrics() {
      try {
        this.isMetricsLoading = true;
        let subscribe = httpsCallable(functions, "fetchPlatformMetrics");
        let payload = {
          widget_key: this.isWidget ? this.$route.params.id : undefined,
          uid: this.currentUID,
          response_type: "all",
          wid: this.WID,
        };
        let result = (await subscribe(payload)).data;
        this.processedData = result.result;
        console.log(result);
        this.isMetricsLoading = false;
      } catch (error) {
        this.isMetricsLoading = false;
        this.handleError(error);
      }
    },
  },
  async mounted() {
    if (!this.isWidget) {
      this.initializeOnboardingSteps();

      this.fetchOnboardingSteps();
      // await this.fetchConnectedApps();
    }
    trackGTM(
      "Dashboard Loaded",
      "app",
      "dashboard-loaded",
      "Dashboard Loaded",
      0
    );
    // await this.userCollections();
    // await this.fetchChannels();
    this.fetchMetrics();
    this.loadOrder();
    //this.fetchRecentPosts();
  },
  beforeMount(){
    if(this.$route?.query?.ch){
      this.theme = `#${this.$route?.query?.ch}`
    }
  },
};
</script>
<style>
.v-application p {
  margin-bottom: 0 !important;
}

.onboarding-popup {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 300px;
  background-color: black;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: white;
}

.onboarding-popup h2 {
  margin-top: 0;
  color: white;
}

.checkbox-item {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #565454;
  /* Gray border, can adjust to darker if needed */
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #333;
  /* Dark background for each checkbox item */
  color: white;
  /* Text color for items */
}

.checkbox-item input[type="checkbox"] {
  margin-right: 10px;
  /* Space between checkbox and label */
}

.progress-text {
  margin-top: 10px;
  color: white;
  font-size: 14px;
}

.progress-bar {
  width: 100%;
  background-color: #555;
  border-radius: 5px;
  margin-top: 5px;
  height: 10px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #8056de;
  width: 0%;
  border-radius: 5px;
}

.custom-checkbox {
  display: none;
}

.custom-checkbox+label {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
}

.custom-checkbox+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: white;
}

.custom-checkbox+label:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4caf50;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}

.custom-checkbox:checked+label:after {
  transform: scale(1);
}

.dismiss-button {
  display: block;
  width: 100%;
  /* Full width of the container */
  padding: 5px 20px;
  margin-top: 1px;
  /* Space above the button */
  color: white;
  /* Text color */
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.dismiss-button:hover {
  background-color: #8056de;
  /* Darker green on hover */
}

.need-help-link {
  color: white;
  /* Ensures the link is white */
  text-decoration: none;
  /* Removes underline from the link */
}

.need-help-link:hover {
  text-decoration: underline;
  /* Adds underline on hover for better visual feedback */
}

.onboarding-link {
  color: white !important;
  /* Forces text color to white */
  text-decoration: none !important;
  /* Forces removal of underline */
}

.onboarding-link:hover {
  text-decoration: underline !important;
  /* Forces underline on hover */
}

.flex-table-header,
.flex-table-row {
  display: flex;
  justify-content: space-between;
}

.flex-table-header>div,
.flex-table-row>div {
  flex: 1;
  /* Adjust this value as needed to control column width */
  padding: 8px;
  text-align: center;
}

.flex-table-header {
  background-color: #eee;
  /* Light gray background for header */
  font-weight: bold;
}

.flex-table-row:nth-child(odd) {
  background-color: #f9f9f9;
  /* Slightly different background for odd rows */
}

@media (max-width: 768px) {

  .flex-table-header>div,
  .flex-table-row>div {
    padding: 4px;
    /* Smaller padding on smaller screens */
    font-size: 14px;
    /* Smaller font size on smaller screens */
  }
}

.strikethrough {
  text-decoration: line-through;
  color: #ccc;
  /* Optional: change the color to gray to indicate completion */
}

.icon-color {
  color: white;
  /* Adjust the color as needed */
}

.toggle-text {
  display: none;
  /* Hide text by default */
}

@media (min-width: 768px) {

  /* This breakpoint is typically for tablets and above, adjust as needed */
  .toggle-text {
    display: inline;
    /* Show text on larger screens */
  }
}

.active {
  background: white;
  color: #63b3ed;
  border-radius: 9999px;
}

@media (max-width: 768px) {
  .dashboard-title {
    display: none;
  }
}

.list-view-container {
  overflow-x: hidden;
  width: 100%;
}

@media (max-width: 768px) {
  .list-view-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .list-view-container>.flex {
    min-width: 700px;
    flex-wrap: nowrap;
  }
}

.list-view-container .flex {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.list-view-container .flex>div {
  flex: 1;
  padding: 8px;
  text-align: left;
}

@media (max-width: 768px) {
  .list-view-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .list-view-container .flex {
    min-width: 800px;
    /* Increase this if necessary to prevent overlapping */
  }

  .list-view-container .flex>div {
    padding: 4px;
    /* Adjust padding to manage space */
    font-size: 14px;
    /* You may adjust font size for better visibility */
  }
}

/* Ensure there's no overlap by adding specific flex-basis values */
.list-view-container .flex>div:nth-child(1) {
  flex-basis: 18%;
  /* Adjust percentage based on your content needs */
}

.list-view-container .flex>div:nth-child(2),
.list-view-container .flex>div:nth-child(3),
.list-view-container .flex>div:nth-child(4),
.list-view-container .flex>div:nth-child(5) {
  flex-basis: 14%;
  /* Smaller flex basis for other columns */
}

.always-show-scrollbar {
  max-height: 500px;
  overflow-y: scroll;
}

/* Webkit scrollbar modifications */
.always-show-scrollbar::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}

.always-show-scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  /* color of the scroll thumb */
  border-radius: 10px;
  /* roundness of the scroll thumb */
}

.always-show-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* color of the track (part that the thumb slides within) */
}

.export-icon {
  font-size: 1.5rem !important;
}
</style>
