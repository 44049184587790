var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.label)?_c('label',{staticClass:"input-label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('v-textarea',_vm._b({class:[
    _vm.label ? 'mt-1' : '',
    'pt-3',
    ,
    'pr-5',
    'pl-5',
    'mt-2.5',
    'whitespace-nowrap',
    'bg-slate-100',
    'rounded-[32px]',
    'text-slate-500',
    'max-md:pr-5',
  ],attrs:{"dense":_vm.dense,"rows":"3","rules":_vm.getRules},on:{"input":function($event){return _vm.$emit('input', _vm.input)}},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}},'v-textarea',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }