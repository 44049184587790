<template>
    <v-card>
      <v-card-title class="text-h5">Login to BlueSky</v-card-title>
      <v-card-text>
        <form-wrapper @submit="submitLogin()">
          <radio-group
            label="Hosting provider"
            v-model="form.service"
            :row="true"
            :items="services"
          />
          <v-text-field
            v-if="form.service == 'custom'"
            label="Server address"
            prepend-icon="mdi-web"
            v-model="form.server"
            :rules="[rules.required, rules.validUrl]"
            required
          ></v-text-field>
          <v-text-field
            label="Email"
            prepend-icon="mdi-mail"
            v-model="form.email"
            :rules="[rules.required, rules.email]"
            required
          ></v-text-field>
          <span>Please create an app-specific password so we don’t have full access to your account. Generate it here: </span>
          <a href="https://bsky.app/settings/app-passwords" target="_blank">Bluesky App Password</a>
          <v-text-field
            label="Password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            v-model="form.password"
            @click:append="togglePasswordVisibility"
            :rules="[rules.validBskyPassword]"
            required
          ></v-text-field>
          <v-text-field
            v-if="show2FA"
            label="2FA Confirmation"
            prepend-icon="mdi-two-factor-authentication"
            v-model="form.authFactorToken"
            :rules="[rules.required]"
            required
          ></v-text-field>
          <div class="text-caption mb-4" v-if="show2FA">
            Check your email for a login code and enter it here.
          </div>
          <v-btn
            :color="buttonColor"
            type="submit"
            :disabled="loading || success"
            block
          >
            <v-progress-circular
              indeterminate
              color="black"
              v-if="loading"
            ></v-progress-circular>
            <v-icon left v-if="success" color="black">mdi-check</v-icon>
            <span v-if="loading" class="text-black">Verifying...</span>
            <span v-if="success" class="text-black">Connected</span>
            <span v-if="!loading && !success" class="text-white">Login</span>
          </v-btn>
        </form-wrapper>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between align-center">
        <v-spacer></v-spacer>
        <v-btn text @click="close">Close</v-btn>
      </v-card-actions>
  
      <!-- Dialog for Login Fail -->
      <v-dialog v-model="showDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h6">Login Failed</v-card-title>
          <v-card-text>{{ errorMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </template>
  
  <script>
  import {
    functions,
    googleProjectId,
    googleRegion,
    isLocalHost,
  } from "@/utils/firebase.utils";
  import axios from "axios";
  import FormWrapper from "@/components/ui/form/FormWrapper.vue";
  import rulesConstants from "@/assets/constants/rules.constants";
  import RadioGroup from "@/components/ui/form/RadioGroup.vue";
  
  export default {
    components: {
      FormWrapper,
      RadioGroup,
    },
    data() {
      return {
        services: [
          {
            name: "Bluesky",
            value: "bluesky",
          },
          {
            name: "Custom",
            value: "custom",
          },
        ],
        wid: this.$store.getters.WID,
        show2FA: false,
        form: {
          service: "bluesky",
          server: "https://bsky.social",
          email: "",
          password: "",
          authFactorToken: "",
        },
        showPassword: false,
        loading: false,
        success: false,
        loginStatus: false,
        showDialog: false,
        errorMessage: "",
        ignoreResult: false,
        rules: rulesConstants,
      };
    },
    computed: {
      buttonColor() {
        if (this.loading) return "#a0aec0"; // Grey color while loading
        else if (this.success) return "#8056DE"; // Green color on success
        return "#8056DE"; // Default purple color
      },
    },
    methods: {
      closeDialog() {
        this.showDialog = false;
      },
      async submitLogin() {
        this.errorMessage = "";
  
        // Define the payload for the callable function
        const data = {
          ...this.form,
          uid: this.currentUID,
          wid: this.WID,
        };
        console.log(JSON.stringify(data));
        try {
          this.loading = true;
          let url = `https://${googleRegion}-${googleProjectId}.cloudfunctions.net/oauth/bluesky/login`;
          if (isLocalHost) {
            url = `http://localhost:5001/${googleProjectId}/${googleRegion}/oauth/bluesky/login`;
          }
          let response = (await axios({ url, data, method: "post" })).data;
          console.log(response);
          this.loading = false;
          this.success = true; // Indicate success
          // Emit success event
          this.$emit("login-success", "Connected");
        } catch (error) {
          this.loading = false;
          let message = error?.response?.data || error.message;
          console.log(message);
          this.errorMessage = message;
          if (message == "AuthFactorTokenRequired") {
            this.show2FA = true;
          } else if (message == "AuthenticationRequired") {
            this.errorMessage = "Invalid username or password.";
            this.showDialog = true;
          } else if (message == "InvalidToken") {
            this.errorMessage = "Invalid 2FA confirmation code.";
            this.showDialog = true;
          } else {
            this.showDialog = true;
          }
          console.log(error);
        }
      },
  
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
      },
      resetForm() {
        this.form = {
          service: "bluesky",
          email: "",
          password: "",
          server: "",
          authFactorToken: "",
        };
      },
      close() {
        this.show2FA = false;
        this.ignoreResult = true; // Set the flag to ignore the result of the ongoing operation
        this.resetForm();
        if (this.loading) {
          this.loading = false; // Optionally reset loading state
          this.showDialog = false; // Close any open dialogs
        }
        this.$emit("close-dialog");
      },
    },
  };
  </script>