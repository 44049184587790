<template>
  <v-card flat tile max-width="500px" class="mx-auto mb-2">
    <v-list-item class="px-0">
      <v-list-item-avatar class="mr-2" size="40" @click="openProfile()">
        <v-img
        v-if="userData.picture"
        :src="userData.picture"
        width="30px"
        ></v-img>
        <user-logo v-else :size="40" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-if="userData.name" class="text-sm md:text-lg d-flex align-center">
          {{ userData.name }}
        </v-list-item-title>
        <v-list-item-title v-else>
          username
        </v-list-item-title>
        <v-list-item-subtitle class="text-xs md:text-sm">
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-img
          src="https://img.icons8.com/fluency/48/facebook-new.png"
          width="30px"
        ></v-img>
      </v-list-item-action>
    </v-list-item>
    <v-card-text class="pa-0 " v-if="hasPreview">
      <div v-html="facebook_text ? facebook_text : content" class="mb-3" />
    </v-card-text>
    <v-card :href="url" target="_blank" v-if="hasPreview">
      <v-img :src="detail?.image" />
      <v-card-title>{{ detail?.title }}</v-card-title>
      <v-card-text>{{ detail?.description }}</v-card-text>
    </v-card>
    <v-card-text v-else class="pa-0">
        <div v-html="facebook_text ? facebook_text : content" class="mb-3" />
        <div v-html="hashtags" />
      <v-carousel
        hide-delimiters
        v-model="carousel"
        v-if="carousels.length"
        :show-arrows="carousels.length > 1"
        :height="isMobile ? '400px' : '500px'"
      >
        <v-carousel-item v-for="(item, i) in carousels" :key="i">
          <div class="d-flex align-center h-full black">
            <v-img :src="item.src || item" v-if="item.type == 'image'" />
            <video-auto-play
              v-if="item.type == 'video'"
              :autoplay="false"
              :videoSrc="item.src"
            />
          </div>
        </v-carousel-item>
      </v-carousel>
      <div class="d-flex my-3 align-center">
        <div class="d-flex flex-1 align-center justify-center">
          <v-img
            max-width="20px"
            class="mr-2"
            src="https://img.icons8.com/external-sbts2018-outline-sbts2018/58/external-like-social-media-basic-1-sbts2018-outline-sbts2018.png"
          ></v-img>
          <b> Like </b>
        </div>
        <div class="d-flex flex-1 align-center justify-center">
          <v-img
            max-width="20px"
            style="transform: rotate(270deg)"
            class="mr-2"
            src="https://img.icons8.com/fluency-systems-regular/48/000000/speech-bubble--v1.png"
          ></v-img>
          <b> Comment </b>
        </div>
        <div class="d-flex flex-1 align-center justify-center">
          <v-img
            max-width="20px"
            class="mr-2"
            src="https://img.icons8.com/sf-regular/48/forward-arrow.png"
          ></v-img>
          <b> Share </b>
        </div>
      </div>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import UserLogo from "@/components/UserLogo.vue";
import VideoAutoPlay from "@/components/public/VideoAutoPlay.vue";

export default {
  components: { UserLogo, VideoAutoPlay },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    userData: {
      type: Array,
      default: () => ([]),
    },
    detail: Object,
    hasPreview: Boolean,
    url: String,
    facebook_text: String,
    thumbRemoved: Boolean,
  },
  data() {
    return {
      carousel: 0,
      thumbnail: "",
    };
  },
  watch: {
    "configuration.fb_thumb": {
      handler(val) {
        const vm = this;
        // console.log(val);
        if (val) {
          if (typeof(val) === 'object') {
            if (val instanceof File) {
              var reader = new FileReader();
              reader.onload = function (e) {
                vm.thumbnail = e.target.result;
              };
              reader.readAsDataURL(val);
            }
          }
          else {
            vm.thumbnail = val;
          }
        } else { vm.thumbnail = null; }
      },
      deep: true,
    },
  },
  computed: {
    logoName() {
      let name = this.currentUser.display_name || "";
      return name.charAt(0).toUpperCase();
    },
    hashtags() {
      let hashtags = this.post.hashtags || [];
      return hashtags
        .map((i) => {
          if (!i.startsWith("#")) return `#${i}`;
          return i;
        })
        .toString()
        .split(",")
        .join(" ");
    },
    content() {
      let content = this.post.text || "";
      // content = content.toString().replace(/(?:\r\n|\r|\n)/g, " <br>");
      content = content.toString().replace(/(?:\r\n|\r|\n)+/g, "<br>");
      content = content.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" class="primary--text text-decoration-underline">$1</a>'
      );
      return content;
    },
    carousels() {
      let list = [];
      if (this.post.videos) {
        // console.log("thumb rem", this.thumbRemoved)
        if ((this.thumbnail || this.thumb) && !this.thumbRemoved) {
          list.push({
            src: this.thumbnail ? this.thumbnail : this.thumb,
            type: "image",
          });
        } else {
          this.post.videos.forEach((item) => {
            list.push({
              src: item.url || item,
              type: "video",
            });
          });
        }
      }
      if (this.post.images) {
        if (this.post.images.length > 10) {
          let images = this.post.images.slice(0, 10);
          images.forEach((item) => {
            list.push({
              src: item.thumbnail || item.url || item,
              type: "image",
            });
          });
        }
        else {
          this.post.images.forEach((item) => {
            list.push({
              src: item.thumbnail || item.url || item,
              type: "image",
            });
          });
        }
      }
      return list;
    },
  },
};
</script>

<style></style>
