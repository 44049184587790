<template>
  <div>
    <!-- <label class="input-label" v-if="label">{{ label }}</label> -->
    <div class="relative">
      <div
        class="bg-[#f2f6fa] w-full outline-[#8056DE] md:w-[309px] h-12 pl-4 rounded-[26px] flex items-center justify-between cursor-pointer mt-4"
        @click="toggleDropdown"
      >
        <div class="flex items-center gap-2">
          <img
            src="../../../assets/img/complete-signup/user-type.svg"
            alt="Group"
            class="w-4"
          />
          <span>{{ selectedName || "Select your category" }}</span>
        </div>
        <button v-if="help" @click.stop="showHelp">Help</button>
      </div>
      <div
        v-if="dropdownVisible"
        class="absolute w-full bg-white mt-1 pl-4 rounded-[26px] shadow-lg z-10  max-w-[400px]"
        style="max-height: 200px; overflow-y: auto"
      >
        <div
          class="text-sm bg-[#F2F6FA] py-[6px] md:w-[213px] mb-[7px] px-[20px] rounded-full font-medium cursor-pointer text-slate-500 hover:text-slate-900"
          v-for="item in items"
          :key="item.value"
          @click="selectItem(item)"
        >
          <span class="">
            {{ item.name || item.user_name || item.user_id || item }}</span
          >
        </div>
      </div>

      <span v-if="showHelpText">{{ help }}</span>
    </div>
  </div>
</template>

<script>
import inputMixin from "@/mixins/input.mixin.js";

export default {
  props: {
    value: {
      type: [String, Array, Number],
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    help: String,
  },
  data() {
    return {
      dropdownVisible: false,
      selectedName: "",
      selectedValue: "",
      showHelpText: false,
    };
  },
  mixins: [inputMixin],
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    selectItem(item) {
      this.selectedName = item.name || item.user_name || item.user_id || item;
      this.selectedValue = item.value;
      this.dropdownVisible = false;
      this.$emit("input", this.selectedName);
    },
    showHelp() {
      this.showHelpText = !this.showHelpText;
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
