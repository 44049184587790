<template>
  <div class="bg-slate-100 pa-6 h-full">
    <div class="max-w-[1276px] max-md:max-w-full mx-auto">
      <p class="text-base md:text-[22px] font-bold">
        <v-breadcrumbs :items="breadcrumbs" v-if="!loading">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              @click="openLink(item)"
              class="text-capitalize"
              :class="{ 'text-purple cursor-pointer': item.href }"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </p>
      <div class="bg-white mt-3 rounded-xl pa-6">
        <div class="bg-slate-100 rounded-xl pb-3 pr-3">
          <v-row class="ma-0">
            <template v-if="loading">
              <v-col
                cols="12"
                md="3"
                class="pb-0 pr-0"
                v-for="i in 24"
                :key="i"
              >
                <v-skeleton-loader
                  type="image"
                  class="rounded-xl"
                ></v-skeleton-loader>
              </v-col>
            </template>
            <v-col
              v-for="item in templates"
              :key="item.id"
              cols="12"
              md="3"
              class="pb-0 pr-0"
            >
              <div
                class="rounded-xl bg-white h-[300px] overflow-hidden pa-3 !cursor-pointer"
                @click="$router.push(`/generate-image/${item.id}`)"
              >
                <v-img
                  :src="item.preview_url"
                  contain
                  class="w-[100%] h-[100%] rounded-[12px] !cursor-pointer"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapDocs } from "@/utils/common.utils";
import { bb_categories, bb_templates } from "@/utils/firebase.utils";
import { doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { orderBy } from "lodash";

export default {
  data() {
    return {
      loading: true,
      templates: [],
      category: {},
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Create",
          href: "/create",
        },
        {
          text: this.category.name,
        },
      ];
    },
  },
  methods: {
    openLink({ href }) {
      if (href) {
        this.$router.replace(href);
      }
    },
    async fetchTemplates() {
      try {
        this.loading = true;
        let id = this.$route.params.id;
        this.category = (await getDoc(doc(bb_categories, id))).data();
        if (this.category == null) {
          this.$router.replace("/create");
          this.$alert.show("Category not found");
          return;
        }
        let listQuery = query(
          bb_templates,
          orderBy("created_at", "desc"),
          where("tags", "array-contains", this.category.name)
        );
        this.templates = mapDocs((await getDocs(listQuery)).docs);
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchTemplates();
  },
};
</script>

<style></style>
