<template>
  <div
    class="py-1 pb-md-5 mx-auto"
    :class="fluid ? 'md:max-w-7xl mx-auto' : 'app-layout'"
  >
    <v-app-bar
      flat
      height="65px"
      color="white"
      app
      class="app-layout-header p-4 mb-14"
    >
      <div class="d-flex align-center px-4 px-md-0" style="width: 100%">
        <div>
          <div class="d-flex align-center">
            <a @click="$router.push('/')">
              <img src="@/assets/img/fly-new-logo.png" style="width: 60px" />
            </a>
          </div>
        </div>
        <v-spacer></v-spacer>
      </div>
    </v-app-bar>

    <div class="px-3 px-md-0">
      <slot></slot>
    </div>
    <!-- <v-bottom-navigation app active-class="primary--text " class="d-md-none">
      <v-btn to="/">
        <span>Discover</span>
        <v-icon>mdi-compass-outline</v-icon>
      </v-btn>
      <v-btn to="/people">
        <span>People</span>
        <v-icon>mdi-account-group</v-icon>
      </v-btn>
      <v-btn to="/feed">
        <span>Feed</span>
        <v-icon>mdi-lightning-bolt</v-icon>
      </v-btn>
      <v-btn to="/post">
        <span>Post</span>
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </v-bottom-navigation> -->
  </div>
</template>

<script>
import { signOut } from "firebase/auth";
import { auth } from "@/utils/firebase.utils";
export default {
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    showLocation() {
      let skipRoutes = ["post", "signup", "login", "profile"];
      return skipRoutes.indexOf(this.$route.name) == -1;
    },
    isLoginSignup() {
      return ["signup", "login", "profile"].indexOf(this.$route.name) != -1;
    },
  },
  methods: {
    openLocation() {
      if (this.$refs.location) {
        this.$refs.location.dialog = true;
      }
    },
    logout() {
      this.$store.commit("SET_AUTH", false);
      signOut(auth);
    },
  },
};
</script>

<style lang="scss">
.app-layout-header {
  .v-toolbar__content {
    padding: 0px !important;
    max-width: 70rem;
    margin: 0 auto;
  }
}
.app-layout {
  max-width: 500px;
  .v-toolbar__content {
    padding: 0px !important;
    max-width: 500px;
    padding-bottom: 10px !important;
  }
}
</style>
