<template>
  <v-container style="max-width: 1000px">
    <please-wait v-if="isLoading"></please-wait>
    <form-wrapper @submit="updatePlatform()">
      <vue-editor
        class="mt-1 release-note-editor"
        v-model="form.intro_content"
      ></vue-editor>
      <v-row class="mt-3">
        <v-col cols="12" class="py-0">
          <input-field
            v-model="form.intro_video"
            label="Video Instructions:"
          ></input-field>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <input-field
            rules="required"
            v-model="form.name"
            label="Platform Name"
          />
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <input-field rules="required" v-model="form.alias" label="Alias" />
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <input-field
            rules="required"
            v-model="form.logo_url"
            label="Logo URL"
          />
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <input-field
            rules="required"
            type="number"
            v-model="form.display_priority"
            label="Display Priority"
          />
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <v-checkbox v-model="form.is_active" label="Is Active?"></v-checkbox>
        </v-col>
      </v-row>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          class="mr-3"
          color="red"
          large
          depressed
          @click="$router.replace('/help-cms')"
          :disabled="loading"
          >Close</v-btn
        >
        <v-btn class="primary" :loading="loading" type="submit" large depressed
          >Submit</v-btn
        >
      </div>
    </form-wrapper>
  </v-container>
</template>

<script>
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { VueEditor } from "vue2-editor";
import { platforms } from "@/utils/firebase.utils";
import InputField from "@/components/ui/form/InputField.vue";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      form: {
        intro_content: "",
        intro_video: "",
        name: "",
        is_active: false,
        display_priority: "",
        alias: "",
        logo_url: "",
      },
    };
  },
  components: {
    VueEditor,
    InputField,
    PleaseWait,
    FormWrapper,
  },
  methods: {
    updatePlatform() {
      const vm = this;
      try {
        vm.loading = true;
        let id = vm.$route.params.id;
        updateDoc(doc(platforms, id), vm.form);
        vm.$router.replace("/help-cms");
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    async fetchHandler() {
      const vm = this;
      try {
        vm.isLoading = true;
        let id = vm.$route.params.id;
        let handler = (await getDoc(doc(platforms, id))).data();
        if (handler == null) {
          vm.$alert.show("Social handler not found.");
          vm.$router.replace("/help-cms");
          return;
        }
        Object.keys(vm.form).forEach((key) => {
          if (handler[key] != undefined) {
            vm.form[key] = handler[key];
          }
        });
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchHandler();
  },
};
</script>

<style></style>
