<template>
  <div
    class="md:px-10 bg-[#F2F6FA] flex flex-col justify-center items-center"
    style="font-family: Poppins, sans-serif"
  >
    <section
      class="flex flex-col items-center w-full justify-center xl:w-[1285px]"
    >
      <div
        class="flex items-center justify-between md:mt-5 px-4 md:px-0 w-full xl:w-[1285px]"
      >
        <div>
          <div class="flex items-center gap-2">
            <img
              src="../../assets/img/gallery-icons/gallery.svg"
              class="cursor-pointer w-[28px]"
              alt="gallery"
            />
            <p class="text-[22px] font-bold">Gallery</p>
          </div>
        </div>
        <!-- <div class="">
          <div
            class="border rounded-full cursor-pointer w-[136px] h-[41px] text-center text-[#636B8C] font-bold shadow-md"
            @click="selectAssets"
            :class="tempSelected.length > 0 ? 'bg-[#8056DE] text-white' : ''"
          >
            <p class="mt-2">Create Post</p>
          </div>
        </div> -->
      </div>
      <!-- <div class="flex w-full h-full max-h-[600px]" id="cloudinaryWidget"></div> -->
      <div class="w-full pb-[33px] mt-4 bg-white md:px-8 md:rounded-t-3xl">
        <div class="py-[28px] px-2 flex gap-4 font-semibold w-full">
          <div class="bg-[#F2F6FA] flex gap-4 p-2 rounded-full w-full">
            <button
              class="rounded-full py-[9px] w-full"
              :class="
                tab == 0 ? 'bg-[#8056DE] text-white' : 'bg-white text-[#636B8C]'
              "
              @click="
                () => {
                  tab = 0;
                  closeSearch(), resetFilter();
                }
              "
            >
              Image
            </button>
            <button
              @click="
                () => {
                  tab = 1;
                  closeSearch(), resetFilter();
                }
              "
              class="rounded-full py-[9px] w-full"
              :class="
                tab == 1 ? 'bg-[#8056DE] text-white' : 'bg-white text-[#636B8C]'
              "
            >
              Video
            </button>
          </div>
        </div>
        <section
          class="md:px-5 px-2 pt-5 pb-5 md:pt-[33px] bg-[#F2F6FA] md:rounded-3xl"
        >
          <div
            class="relative flex flex-col items-center w-full md:flex-row md:justify-between"
          >
            <div class="w-full md:w-auto">
              <input
                type="text"
                v-model="searchInput"
                class="xl:w-[332px] w-full md:w-auto h-10 pl-[60px] bg-white rounded-3xl outline-[#8056DE]"
                placeholder="Search"
              />

              <img
                src="../../assets/img/gallery-icons/search.svg"
                class="cursor-pointer -mt-8 ml-6 w-[20px]"
                alt="search"
              />
            </div>
            <div
              class="flex items-center w-full gap-2 mt-6 md:absolute md:w-auto md:right-0"
            >
              <!-- <div class="w-12">
                <div
                  class="h-12 flex justify-center items-center rounded-[7px] mb-2"
                  :class="{
                    'bg-[#F22613]': isDeleting,
                    'bg-white': !isDeleting,
                  }"
                >
                  <img
                    v-if="!isDeleting"
                    src="../../assets/img/gallery-icons/delete.svg"
                    class="cursor-pointer w-[21px]"
                    alt="delete"
                    @click="
                      isDeleting = !isDeleting;
                      deleteImage();
                    "
                  />
                  <img
                    v-else
                    src="../../assets/img/gallery-icons/delete2.svg"
                    class="cursor-pointer w-[15px]"
                    alt="delete"
                    @click="
                      isDeleting = !isDeleting;
                      deleteImage();
                    "
                  />
                </div>
              </div> -->

              <div class="md:w-[198px] w-full">
                <div
                  v-if="tab == 0"
                  class="flex upload-image items-center justify-center h-9 gap-1 text-white cursor-pointer bg-violet-500 rounded-3xl mb-2"
                  @click="$refs.image.click()"
                >
                  <!-- Show upload icon only when not loading -->
                  <img
                    v-if="!isImageLoading"
                    src="../../assets/img/gallery-icons/upload.svg"
                    class="cursor-pointer w-[16px]"
                    alt="upload"
                  />
                  Upload Image
                  <!-- Show loading gif only when isImageLoading is true -->
                  <img
                    v-if="isImageLoading"
                    src="../../assets/img/gallery-icons/loading.gif"
                    class="cursor-pointer w-[26px]"
                    alt="loading"
                  />
                </div>

                <div
                  v-if="tab == 1"
                  class="flex items-center justify-center h-9 gap-1 text-white cursor-pointer bg-violet-500 rounded-3xl mb-2"
                  @click="$refs.video.click()"
                >
                  <img
                    v-if="!isVideoLoading"
                    src="../../assets/img/gallery-icons/upload.svg"
                    class="cursor-pointer w-[16px]"
                    alt="upload"
                  />
                  Upload Video
                  <img
                    v-if="isVideoLoading"
                    src="../../assets/img/gallery-icons/loading.gif"
                    class="cursor-pointer w-[26px]"
                    alt="loading"
                  />
                </div>

                <input
                  accept="image/*,.heic,.heif"
                  type="file"
                  class="d-none"
                  ref="image"
                  @change="onImageChange"
                />
                <input
                  accept="video/*"
                  type="file"
                  class="d-none"
                  ref="video"
                  @change="onVideoChange"
                />
              </div>
            </div>
          </div>

          <div
            class="grid grid-cols-2 gap-4 mt-8 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5"
          >
            <div class="relative" v-for="n in filteredList" :key="n.id">
              <div class="w-full">
                <div
                  class="rounded-t-[14px] overflow-hidden object-contain"
                  v-if="n.type == 'video'"
                >
                  <div
                    class="w-full h-[200px] overflow-hidden object-contain bg-white rounded-t-[14px] shadow"
                  >
                    <video-auto-play
                      :autoplay="false"
                      :playOnHover="true"
                      class="z-10 h-[200px] overflow-hidden object-contain"
                      :videoSrc="n.export || n.url"
                      :videoId="n.id"
                      @toggle-selection="toggleSelection(n)"
                      @fetchedDuration="handleFetchedDuration"
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="w-full relative bg-white rounded-t-[14px] shadow"
                >
                  <div class="">
                    <img
                      :src="n.thumbnail"
                      alt=""
                      class="w-full h-[200px] rounded-t-[14px] object-cover"
                      @click="toggleSelection(n)"
                    />
                  </div>
                </div>
                <div class="w-full bg-white rounded-b-[14px] shadow">
                  <!-- <input
                    type="checkbox"
                    v-model="tempSelected"
                    @change="$emit('input', tempSelected)"
                    :value="n"
                    class="absolute z-10 mt-0 cursor-pointer rounded w-[28px] right-2 top-3"
                  /> -->
                  <!-- <div
                    class="text-slate-500 text-xs font-semibold pl-[11px] pt-[7px] leading-[18px]"
                    v-if="fileNameEditMode != n.asset_id"
                  >
                    {{
                      fileNameEditMode == n.asset_id
                        ? ""
                        : isFileName(n)
                        ? `${n.type} name`
                        : n.name
                    }}
                  </div> -->
                  <div class="flex items-center justify-around py-[12px]">
                    <post-image-dialog v-if="n.type == 'image'" :url="n.url">
                      <img
                        src="../../assets/img/gallery-icons/eye.svg"
                        class="cursor-pointer w-[18px]"
                        alt="eye"
                      />
                    </post-image-dialog>

                    <!-- <img
                      src="../../assets/img/gallery-icons/copy.svg"
                      class="cursor-pointer w-[18px]"
                      alt="copy"
                      @click="duplicateImage(n.asset_id)"
                    /> -->
                    <!-- <img
                      src="../../assets/img/gallery-icons/update.svg"
                      class="cursor-pointer w-[18px]"
                      alt="update"
                      @click="handleFileNameMode(n)"
                    /> -->
                    <!-- <a :href="getEditorHref(n)" v-if="n.type == 'image'">
                      <img
                        src="../../assets/img/gallery-icons/edit.svg"
                        class="cursor-pointer w-[18px]"
                        alt="edit"
                      />
                    </a> -->
                  </div>

                  <template v-if="fileNameEditMode == n.asset_id">
                    <div class="flex flex-col mt-3">
                      <input
                        type="text"
                        v-model="fileName"
                        label="File Name"
                        class="px-2 py-1 text-sm border border-gray-300 rounded-lg outline-1 focus:outline-none"
                        style="
                          display: block;
                          border: 1px solid #ddd !important;
                        "
                        placeholder="Enter file name..."
                      />
                      <div class="flex items-center justify-between gap-3 mb-2">
                        <button
                          type="button"
                          class="w-6/12 px-4 py-1 mt-2 text-white bg-red-500 rounded-lg"
                          @click="handleFileNameMode(n)"
                        >
                          <span class="">Cancel</span>
                        </button>
                        <button
                          type="button"
                          class="w-6/12 px-4 py-1 mt-2 text-white rounded-lg bg-lime-500"
                          @click="updateName(n.asset_id)"
                        >
                          <span class="">Save</span>
                        </button>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <infinite-loading ref="loader" @infinite="fetchGallery">
            <div slot="spinner">
              <v-row>
                <v-col cols="2" v-for="(item, i) in 12" :key="i">
                  <v-skeleton-loader
                    class="mx-auto"
                    type="image,article"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
          </infinite-loading>
        </section>
      </div>
    </section>
    <video
      id="video"
      type="video/mp4"
      style="visibility: hidden"
      controls
    ></video>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { colGallery, storage, db, functions } from "@/utils/firebase.utils";
import { getDate } from "@/utils/common.utils";
import heic2any from "heic2any";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import {
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
  deleteDoc,
  getDoc,
  addDoc,
  limit,
  startAfter,
} from "firebase/firestore";
import moment from "moment";
import PostImageDialog from "../../components/ui/PostImageDialog";
import VideoAutoPlay from "@/components/public/VideoAutoPlay.vue";

import { compressImage, MAX_IMAGE_SIZE } from "@/utils/image.utils";
import { httpsCallable } from "firebase/functions";


export default {
  components: {
    PostImageDialog,
    VideoAutoPlay,
    InfiniteLoading,
  },
  data() {
    return {
      tab: 0,
      cloudinaryWidget: null,
      list: [],
      tempSelected: [],
      durations: {},
      startAfter: null,
      next_cursor: null,
      hoveredItem: null,
      fileName: "",
      searchInput: "",
      isLoading: false,
      isDeleting: false,
      searchEnable: false,
      isVideoLoading: false,
      isImageLoading: false,
      fileNameEditMode: false,
      load: true,
    };
  },
  watch: {
    currentUID() {
      this.resetFilter();
    },
    WID() {
      this.resetFilter();
    },
  },
  computed: {
    // Keep your existing computed properties as they are
    items() {
      let type = this.tab === 0 ? "image" : "video";
      return this.list.filter((i) => i.type === type);
    },
    filteredList() {
      let list = this.items;
      let searchText = this.searchInput.trim().toLowerCase();
      return list.filter((item) => {
        let name = item.name?.trim().toLowerCase() || "";
        return name.includes(searchText);
      });
    },
  },

  methods: {
    openCloudinaryWidget() {
      console.log("env:", process.env.VUE_APP_CLOUDINARY_API_KEY);
      const options = {
        cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
        api_key: process.env.VUE_APP_CLOUDINARY_API_KEY,
        username: "fly@zoomlocal.in",
        insert_caption: "Insert Asset",
        folder: { path: `${this.uid}/${this.WID}` },
        thumbnails: true,
        multiple: true,
        inline_container: "#cloudinaryWidget",
      };
      let list = [];
      this.cloudinaryWidget = cloudinary.createMediaLibrary(options, {
        insertHandler: (data) => {
          data.assets.forEach((asset) => {
            list.push({
              url: asset.url,
              thumbnail: asset.thumbnail_url || asset.url,
              id: asset.public_id,
              type: asset.resource_type.includes("image") ? "image" : "video",
            });
          });
        },
      });
      console.log("list:", list);
      this.cloudinaryWidget.show();
    },
    formattedDate(dateToFormat) {
      return moment(dateToFormat).format("YYYY-MM-DD HH:mm:ss");
    },
    resetFilter() {
      this.list = [];
      this.startAfter = null;
      this.$refs.loader.stateChanger.reset();
    },
    getDay(dateStr) {
      return moment(dateStr).format("DD");
    },
    getMonth(dateStr) {
      return moment(dateStr).format("MMM"); // Outputs 'Jan', 'Feb', etc.
    },
    getTime(dateStr) {
      return moment(dateStr).format("hh:mm A"); // Outputs time in 12-hour format with AM/PM
    },
    isHovered(item) {
      // console.log("item", item);
      return this.hoveredItem === item.id;
    },
    getEditorHref({ id, type }) {
      if (type == "image") return `/update-gallery-image/${id}`;
      return `/update-gallery-video/${id}`;
    },
    handleFetchedDuration(data) {
      const { videoId, duration } = data;
      this.$set(this.durations, videoId, duration);
    },

    selectAssets() {
      let type = ["social"];
      this.$store.commit("SET_FLY_PLATFORMS_TYPE", type);
      let vidCount = 0;
      let imgCount = 0;
      let assetId = null;
      this.tempSelected.map((asset) => {
        this.$mixpanel.track("Create Post - Gallery");
        if (asset.type === "video") {
          vidCount = vidCount + 1;
          if (vidCount > 1) {
            this.$alert.show("Please select only 1 video!");
            return;
          } else {
            assetId = asset.id;
          }
        } else if (asset.type !== "video") {
          imgCount = imgCount + 1;
        }
      });
      if(vidCount > 0 && imgCount > 0) {
        this.$alert.show('Please select either a video or an image.');
        return;
      }
      if (vidCount <= 1) {
        this.$router.push({
          name: "create-post",
          params: {
            assets: this.tempSelected,
            duration: this.durations[assetId],
          },
        });
      } else if (vidCount === 0 && imgCount >= 1) {
        this.$router.push({
          name: "create-post",
          params: {
            assets: this.tempSelected,
          },
        });
      }
    },
    toggleSelection(item) {
      if (this.tempSelected.includes(item)) {
        // If the item is already in the temporary selection, remove it
        this.tempSelected = this.tempSelected.filter(
          (selectedItem) => selectedItem !== item
        );
      } else {
        // If the item is not in the temporary selection, add it
        this.tempSelected.push(item);
      }
    },
    async onVideoChange(e) {
      const vm = this;
      let video = e.target.files[0];
      vm.$refs.video.value = null;

      if (video) {
        try {
          const MAX_SIZE = 1024 * 1024 * 1024;
          if (video.size > MAX_SIZE) {
            vm.$alert.show("Video size cannot exceed 1 GB.");
            return;
          }
          vm.isVideoLoading = true;
          let thumbnail = await vm.createVideoThumbnail(video);
          let id = new Date().getTime().toString();
          let data = {
            created_at: getDate(),
            updated_at: getDate(),
            type: "video",
            uid: vm.currentUID,
            wid: vm.WID,
            json: {},
            size: video.size,
          };
          // let videoRef = ref(storage, `gallery/${vm.currentUID}/${id}.mp4`);
          // let thumbnailRef = ref(
          //   storage,
          //   `gallery/${vm.currentUID}/${id}_thumb.jpeg`
          // );
          let videoRef;
          let thumbnailRef;
          if (this.WID === "default") {
            videoRef = ref(storage, `gallery/${vm.currentUID}/${id}.mp4`);
            thumbnailRef = ref(
              storage,
              `gallery/${vm.currentUID}/${id}_thumb.jpeg`
            );
          } else {
            videoRef = ref(
              storage,
              `gallery/${vm.currentUID}/${vm.WID}/${id}.mp4`
            );
            thumbnailRef = ref(
              storage,
              `gallery/${vm.currentUID}/${vm.WID}/${id}_thumb.jpeg`
            );
          }
          await uploadBytes(videoRef, video);
          await uploadString(thumbnailRef, thumbnail, "data_url");
          data.url = await getDownloadURL(videoRef);
          data.thumbnail = await getDownloadURL(thumbnailRef);
          await setDoc(doc(colGallery, id), data);
          vm.tab = 1;
          vm.resetFilter();
          vm.isVideoLoading = false;
        } catch (error) {
          vm.isVideoLoading = false;
          vm.handleError(error);
        }
      }
    },
    async createVideoThumbnail(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const videoUrl = e.target.result;
          const video = document.getElementById("video");
          video.src = videoUrl;
          video.crossOrigin = "anonymous";
          video.addEventListener("loadedmetadata", function () {
            setTimeout(() => {
              const canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              const context = canvas.getContext("2d");
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              const thumbnailUrl = canvas.toDataURL("image/jpeg");
              resolve(thumbnailUrl);
            }, 1000);
          });
        };
        // Read the selected video file as data URL
        reader.readAsDataURL(file);
      });
    },
    async onImageChange(e) {
      const vm = this;
      let image = e.target.files[0];
      vm.$refs.image.value = null;
      if (image) {
        try {
          if (image.type == "image/heic" || image.type === "image/heif") {
            let blob = await heic2any({
              blob: image,
              toType: "image/jpeg",
            });
            image = new File([blob], "converted.jpg", {
              type: "image/jpeg",
            });
          }
          let imageSize = image.size;
          vm.isImageLoading = true;
          if (image.size > MAX_IMAGE_SIZE) {
            let { base64, size } = await compressImage(image);
            image = base64;
            imageSize = size;
            console.log("File Compressed....");
          }
          let id = new Date().getTime().toString();
          let data = {
            created_at: getDate(),
            updated_at: getDate(),
            type: "image",
            size: imageSize,
            uid: vm.currentUID,
            wid: vm.WID,
            name: vm.currentUID + "/" + id,
            json: {},
          };
          // let imageRef = ref(storage, `gallery/${vm.currentUID}/${id}.png`);
          let imageRef;
          if (this.WID === "default") {
            imageRef = ref(storage, `gallery/${vm.currentUID}/${id}.png`);
          } else {
            imageRef = ref(
              storage,
              `gallery/${vm.currentUID}/${vm.WID}/${id}.png`
            );
          }
          if (image instanceof File) {
            await uploadBytes(imageRef, image);
          } else {
            console.log("upload base64");
            await uploadString(imageRef, image, "data_url");
          }
          data.url = await getDownloadURL(imageRef);
          data.thumbnail = data.url;
          await setDoc(doc(colGallery, id), data);
          vm.tab = 0;
          vm.resetFilter();
          vm.isImageLoading = false;
        } catch (error) {
          vm.isImageLoading = false;
          console.log(error);
          vm.handleError(error);
        }
      }
    },
    async fetchGallery($state) {
      const vm = this;
      if(vm.tab == 0) {
        this.fetchAssets($state)
        // return;
      } else {
        if (!vm.currentUID) return;
        try {
          vm.isLoading = true;
          let queries = [
            orderBy("updated_at", "desc"),
            where("uid", "==", vm.currentUID),
            where("wid", "==", vm.WID),
            where("type", "==", vm.tab == 0 ? "image" : "video"),
          ];
          // let queries = [
          //   orderBy("updated_at", "desc"),
          //   where("uid", "==", vm.currentUID),
          //   where("type", "==", vm.tab == 0 ? "image" : "video"),
          // ];
          if (vm.startAfter) {
            queries.push(startAfter(vm.startAfter));
          }
          let q = query(colGallery, ...queries, limit(10));
          let docs = (await getDocs(q)).docs;
          let items = docs.map((i) => ({
            ...i.data(),
            id: i.id,
          }));
          vm.startAfter = docs[docs.length - 1];
          vm.list = [...vm.list, ...items];
  
          if (items.length == 10) {
            $state.loaded();
          } else {
            $state.complete();
          }
  
          vm.isLoading = false;
        } catch (error) {
          $state.complete();
          vm.handleError(error);
        }
      }
    },

    closeSearch() {
      this.searchEnable = false;
      this.searchInput = "";
    },
    async deleteImage() {
      const vm = this;
      let assetType = this.tab == 0 ? "image" : "video";
      this.$mixpanel.track(`Delete ${assetType} initiated - Gallery`);

      // Confirmation before proceeding with deletion of all selected assets
      vm.$confirm.show({
        message: `Are you sure you want to delete the selected ${assetType}(s)?`,
        onConfirm: async () => {
          if (vm.tempSelected.length === 0) {
            vm.$snackbar.show("Please select an item to delete");
            vm.isDeleting = false; // Reset deleting state
            return;
          }
          for (let asset of vm.tempSelected) {
            try {
              this.$mixpanel.track(`Deleted ${assetType} - Gallery`);
              console.log("Deleting asset:", asset);
              const docRef = doc(db, "fly_gallery", asset.id);
              await deleteDoc(docRef); // Delete the document from Firestore

              let index = vm.list.findIndex((i) => i.id === asset.id);
              if (index !== -1) {
                vm.list.splice(index, 1); // Remove the item from the local list
              }

              // Delete storage objects based on asset type
              const { type, uid } = (await getDoc(docRef)).data() || {};
              if (type === "image") {
                const imageRef = ref(storage, `gallery/${uid}/${asset.id}.png`);
                await deleteObject(imageRef);
              } else if (type === "video") {
                const videoRef = ref(storage, `gallery/${uid}/${asset.id}.mp4`);
                const thumbnailRef = ref(
                  storage,
                  `gallery/${uid}/${asset.id}_thumb.jpeg`
                );
                await deleteObject(videoRef);
                await deleteObject(thumbnailRef);
              }
            } catch (error) {
              console.error("Error removing item:", error);
            }
          }

          vm.$snackbar.show(`${assetType}(s) deleted successfully`);
          vm.tempSelected = []; // Clear the selection after deletion
          vm.isDeleting = false; // Reset deleting state
        },
        onCancel: () => {
          // This is where you handle the dialog cancellation
          vm.isDeleting = false; // Reset deleting state if the dialog supports onCancel or similar callback
        },
      });
    },
    async duplicateImage(documentId) {
      try {
        const docRef = doc(db, "fly_gallery", documentId);

        // Get the data from the source document
        const sourceDocSnapshot = await getDoc(docRef);
        const sourceDocData = sourceDocSnapshot.data();

        // Modify the created_at time to the current time
        const currentTime = new Date();
        sourceDocData.created_at = currentTime.toISOString();

        // Add a new document with the same data
        await addDoc(colGallery, sourceDocData);
        this.resetFilter();
      } catch (error) {
        console.error("Error on duplicating item: ", error);
      }
    },
    handleFileNameMode(obj) {
      if (this.fileNameEditMode == obj.id) {
        this.fileNameEditMode = false;
        this.fileName = "";
      } else {
        const file_name = obj.name;
        this.fileNameEditMode = obj.id;
        this.fileName = !file_name
          ? ""
          : file_name.startsWith(obj.uid)
          ? ""
          : obj.name;
      }
    },
    isFileName(n) {
      const file_name = n.name;
      if (file_name) {
        return file_name.startsWith(n.uid);
      }
      if (!file_name) {
        return true;
      }
    },
    async updateName(documentId) {
      try {
        const docRef = doc(db, "fly_gallery", documentId);
        const docSnapshot = await getDoc(docRef);
        const docData = docSnapshot.data();

        // Update the 'name' field in the document data
        docData.name = this.fileName;

        // Update the document with the modified data
        await setDoc(docRef, docData);

        // Update the local data with the new name
        const updatedItem = this.list.find((item) => item.id === documentId);
        if (updatedItem) {
          updatedItem.name = this.fileName;
        }

        // Toggle off edit mode after updating
        this.fileNameEditMode = false;
      } catch (error) {
        console.error("Error updating name: ", error);
      }
    },
    formatDate(date) {
      // Use Moment.js to format the date
      return moment(date).format("MM/DD/YYYY h:mm A");
    },
    openMediaWIdget() {
      console.log("inside");
      const mloptions = {
        cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
        api_key: process.env.CLOUDINARY_API_KEY,
        username: this.authUser.display_name || "",
        button_class: "myBtn",
        button_caption: "Insert Images",
        // default_transformations: [
        //   [{ quality: "auto" }, { fetch_format: "auto" }],
        //   [
        //     {
        //       width: 80,
        //       height: 80,
        //       crop: "fill",
        //       gravity: "auto",
        //       radius: "max",
        //     },
        //     { fetch_format: "auto", quality: "auto" },
        //   ],
        // ],
      };

      // window.ml = cloudinary.createMediaLibrary(mloptions)
      window.ml = cloudinary.createMediaLibrary(
        {
          cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
          api_key: process.env.CLOUDINARY_API_KEY,
          remove_header: false,
          max_files: "1",
          insert_caption: "Insert",
          inline_container: "#widget_container",
          default_transformations: [[]],
          button_class: "myBtn",
          button_caption: "Select Image or Video",
        },
        {
          insertHandler: function (data) {
            data.assets.forEach((asset) => {
              console.log("Inserted asset:", JSON.stringify(asset, null, 2));
            });
          },
        },
        document.getElementById("open-btn")
      );
    },
    async fetchAssets($state) {
      const fetchCloudinaryAssets = httpsCallable(functions,
        "fetchCloudinaryAssets"
      );

      try {
        let response = await fetchCloudinaryAssets({
          uid: this.currentUID,
          wid: this.WID,
          next_cursor: this.next_cursor,
        });
        console.log('res:', response.data)
        if (response.data) {
          const { resources, next_cursor } = response.data;
          console.log('next_cursor:', next_cursor)
          this.list = [...this.list, ...resources];
          // this.list.push(resources);
          this.next_cursor = next_cursor;
          console.log('this.list:', this.list) 
          if (next_cursor) {
            $state.loaded();
          } else {
            $state.complete();
          }
        } else {
          console.error("Failed to fetch assets:", response.status);
          $state.complete();
        }
      } catch (error) {
        console.error("Error calling fetchCloudinaryAssets function:", error);
        $state.complete();
      }
    },
  },
  mounted() {
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap");
.v-btn .v-btn--text .theme--light {
  color: #8056de !important;
}
</style>
