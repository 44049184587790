<template>
  <v-card outlined class="mb-3">
    <v-card-text>
      <div
        class="subtitle-1 black--text font-weight-medium d-flex align-center"
      >
        <v-img :src="platform.logo_url" max-width="25px" class="mr-2"></v-img>
        Linkedin Settings
      </div>
      <div class="px-7 py-3">
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <AutocompleteFacebook
              label="Profile"
              chips
              deletable-chips
              small-chips
              @input="$emit('input', form)"
              v-model="form.profile"
              :items="profile"
              multiple
              :menu-props="menuProps"
            ></AutocompleteFacebook>
          </v-col>

          <v-col cols="12" md="6" class="pb-0">
            <AutocompleteFacebook
              label="Pages"
              v-model="form.linkedin_pages"
              :items="pages"
              @input="$emit('input', form)"
              multiple
              chips
              deletable-chips
              small-chips
            />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AutocompleteFacebook from "@/components/ui/form/AutocompleteFacebook.vue";
export default {
  components: { AutocompleteFacebook },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    configuration: {
      type: Object,
    },
    isEditing: Boolean,
    isRepost: Boolean,
    isDraftPost: Boolean,
    pages: Array,
    profile: Array,
    connectedApps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        linkedin_pages: [],
        profile: [],
      },
    };
  },
  async created() {
    if (this.isEditing || this.isRepost || this.isDraftPost) {
      this.$watch(
        () => this.connectedApps,
        (newVal) => {
          if (newVal && newVal.length > 0) {
            this.updateConfiguration();
          }
        }
      );
    }
  },
  computed: {
    platform() {
      let platforms = this.$store.getters.flyPlatforms;
      let platform = platforms.find((i) => i.alias == "linkedin");
      return platform || {};
    },
    menuProps() {
      return {
        minWidth: this.isMobileScreen ? "90%" : "27%",
      };
    },
    isMobileScreen() {
      return window.innerWidth <= 600;
    },
  },
  methods: {
    initForm() {
      Object.keys(this.form).forEach((key) => {
        if (this.value[key] != undefined) {
          this.form[key] = this.value[key];
        }
      });
      //   if (this.pages && this.pages.length === 1) {
      //   this.form.linkedin_pages = [this.pages[0].value];
      //   this.$emit("input", this.form);
      // }
    },
    updateConfiguration() {
      // console.log('config:', this.configuration);
      if (this.configuration.linkedin_pages?.length) {
        this.form.linkedin_pages = this.configuration.linkedin_pages;
      }
      if (this.configuration.profile?.length) {
        this.form.profile = this.configuration.profile;
      }
    },
  },
  beforeMount() {
    this.initForm();
  },
};
</script>

<style></style>
