<template>
  <div>
    <div class="flex h-screen overflow-y-hidden bg-white">
      <div class="flex flex-col flex-1 h-full overflow-hidden">
        <main class="flex-1 h-screen p-5 overflow-hidden overflow-y-scroll">
          <!-- Main content header -->

          <!-- Start Content -->
          <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            <template>
              <div
                class="p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg"
              >
                <div class="flex items-start justify-between">
                  <div class="flex flex-col space-y-2">
                    <span class="text-gray-400">Total Users</span>
                    <span class="text-lg font-semibold">{{
                      userDetails.length
                    }}</span>
                  </div>
                  <div class="w-20 rounded-md">
                    <img src="../../assets/img/fly-new-logo.png" alt="" />
                  </div>
                </div>
                <div>
                  <span
                    class="inline-block px-2 text-sm text-white bg-green-300 rounded"
                    >{{
                      calculatePercentage(userDetails.length, totalCampaigns)
                    }}%</span
                  >
                  <span class="ml-2">From 2024</span>
                </div>
              </div>
            </template>
          </div>
          <div class="flex items-center justify-between">
            <h3 class="mt-6 text-xl">Campaign Users</h3>
            <select
              v-model="multipleSelectedPlan"
              class="px-2 py-2 font-semibold text-white bg-blue-600 rounded-md outline-none cursor-pointer"
              @change="
                addMultipleUsers(multipleSelectedPlan);
                multipleSelectedPlan = 0;
              "
            >
              <option
                disabled
                value="0"
                class="text-black bg-gray-100 outline-none"
              >
                Add Multiple Users
              </option>
              <option
                v-for="(campaign, index) in campaignDetails"
                :key="index"
                :value="campaign"
                class="text-black bg-gray-100 outline-none"
              >
                {{ campaign.id }}
              </option>
            </select>
          </div>
          <div class="flex flex-col h-screen pb-[250px] mt-2 overflow-y-auto">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div
                class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
              >
                <div
                  class="overflow-hidden border-b border-gray-200 rounded-md shadow-md"
                >
                  <table
                    class="min-w-full overflow-x-scroll divide-y divide-gray-200"
                  >
                    <div
                      class="absolute flex justify-center items-center w-full z-[99]"
                    >
                      <img
                        v-if="isLoading"
                        src="../../assets/img/cancel-loading.gif"
                        class="w-[100px]"
                        alt=""
                      />
                    </div>
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Created At
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Valid Until
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Plan
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Campaign
                        </th>

                        <th
                          scope="col"
                          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Multiple Campaign
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(user, index) in userDetails"
                      :key="index"
                      class="bg-white divide-y divide-gray-200"
                    >
                      <template>
                        <tr
                          class="transition-all hover:bg-gray-100 hover:shadow-lg"
                        >
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="flex items-center">
                              <div class="flex-shrink-0 w-10 h-10">
                                <img
                                  class="w-10 h-10 rounded-full"
                                  :src="user.photo_url"
                                  alt=""
                                />
                              </div>
                              <div class="ml-4">
                                <div class="text-sm font-medium text-gray-900">
                                  {{ user.display_name }}
                                </div>
                                <div class="text-sm text-gray-500">
                                  {{ user.email }}
                                </div>
                                <div class="text-sm text-gray-500">
                                  {{ user.phone_number }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-900">
                              {{
                                user.created_time === undefined ||
                                user.created_time === null
                                  ? ""
                                  : new Date(
                                      user.created_time.seconds * 1000
                                    ).toLocaleDateString("en-US", {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })
                              }}
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <span
                              class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                            >
                              {{
                                user.fly_plan_valid_until === undefined ||
                                user.fly_plan_valid_until === null
                                  ? ""
                                  : new Date(
                                      user.fly_plan_valid_until?.seconds * 1000
                                    ).toLocaleDateString("en-US", {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })
                              }}
                            </span>
                          </td>
                          <td
                            class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap"
                          >
                            {{ user.fly_plan }}
                          </td>
                          <td
                            class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap"
                          >
                            <select
                              v-model="selectedPlan"
                              class="px-2 py-2 font-semibold text-center text-white bg-blue-600 rounded-md outline-none cursor-pointer"
                              @change="
                                changePlan(selectedPlan, user.id),
                                  (selectedPlan = 0)
                              "
                            >
                              <option
                                disabled
                                value="0"
                                class="text-black bg-gray-100 outline-none"
                              >
                                Add
                              </option>
                              <option
                                v-for="(campaign, index) in campaignDetails"
                                :key="index"
                                :value="campaign"
                                class="text-black bg-gray-100 outline-none"
                              >
                                {{ campaign.id }}
                              </option>
                            </select>
                          </td>
                          <td
                            class="flex justify-center py-4 text-sm text-gray-500 md:mt-6 md:mr-8"
                          >
                            <input
                              type="checkbox"
                              v-model="user.selected"
                              @change="addUsers($event.target.checked, user.id)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <div class="flex items-center justify-center w-full">
                    <div class="">
                      <div class="flex w-[300px]">
                        <a
                          href="#"
                          @click="getUsers()"
                          class="flex items-center justify-center h-8 px-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          Previous
                        </a>

                        <a
                          href="#"
                          @click="getUsers()"
                          class="flex items-center justify-center h-8 px-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg ms-3 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          Next
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import {
  collection,
  doc,
  updateDoc,
  getDocs,
  startAfter,
  query,
  getDoc,
  limit,
  where,
} from "firebase/firestore";
import { db } from "@/utils/firebase.utils";
import { f } from "vuetify-google-autocomplete";

export default {
  data() {
    return {
      userDetails: [],
      multiUser: [],
      campaignDetails: [],
      selectedPlan: 0,
      multipleSelectedPlan: 0,
      totalCampaigns: 0,
      campaignPercentage: 0,
      page: 1,
      isLoading: false,
      showOptions: false,
      startAfter: null,
    };
  },

  methods: {
    async getUsers() {
      const vm = this;
      try {
        const pageSize = 1000;
        let usersQuery = query(
          collection(db, "users"),
          where("fly_credits", "!=", null),
          limit(pageSize)
        );

        if (vm.startAfter) {
          usersQuery = query(usersQuery, startAfter(vm.startAfter));
        }

        let usersSnapshot = await getDocs(usersQuery);
        let users = usersSnapshot.docs
          .map((doc) => {
            return { ...doc.data(), id: doc.id, selected: false };
          })
          .sort(
            (a, b) => b.created_time.toMillis() - a.created_time.toMillis()
          );

        vm.userDetails = users;
        vm.totalCampaigns = users.length;
        vm.isLoading = false;

        if (usersSnapshot.docs.length > 0) {
          vm.startAfter = usersSnapshot.docs[usersSnapshot.docs.length - 1];
        } else {
          vm.startAfter = null;
        }

        console.log(vm.userDetails);
      } catch (error) {
        vm.handleError(error);
      }
    },
    async getCampaignDetails() {
      const vm = this;
      try {
        let campaignQuery = query(collection(db, "fly_campaign"));
        let campaignSnapshot = await getDocs(campaignQuery);
        vm.campaignDetails = campaignSnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        console.log(vm.campaignDetails);
      } catch (error) {
        vm.handleError(error);
      }
    },
    async getPlan(id) {
      const vm = this;
      try {
        let planRef = doc(collection(db, "fly_plans"), id);
        let plan = (await getDoc(planRef)).data() || {};
        console.log("plans", plan);
        return plan;
      } catch (error) {
        vm.handleError(error);
      }
    },
    async changePlan(campaign, uid) {
      console.log(uid);
      const vm = this;
      try {
        vm.isLoading = true; // Set loading state
        let planDetails = await vm.getPlan(campaign.plan);
        let userRef = doc(collection(db, "users"), uid);
        await updateDoc(userRef, {
          fly_plan: campaign.plan,
          fly_ai_image_credits: planDetails.limits.ai_image_actions,
          fly_ai_text_credits: planDetails.limits.ai_text_actions,
          fly_credits: planDetails.limits.posts,
          fly_campaign: campaign.id,
          fly_plan_valid_until: new Date(
            new Date().getTime() +
              campaign.valid_for_months * 30 * 24 * 60 * 60 * 1000
          ),
        });

        vm.startAfter = null;
        await vm.getUsers();
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
        vm.isLoading = false;
      }
    },

    async addMultipleUsers(campaign) {
      const vm = this;
      try {
        vm.isLoading = true; // Set loading state
        for (let user of vm.multiUser) {
          let planDetails = await vm.getPlan(campaign.plan);
          let userRef = doc(collection(db, "users"), user);
          await updateDoc(userRef, {
            fly_plan: campaign.plan,
            fly_ai_image_credits: planDetails.limits.ai_image_actions,
            fly_ai_text_credits: planDetails.limits.ai_text_actions,
            fly_credits: planDetails.limits.posts,
            fly_campaign: campaign.id,
            fly_plan_valid_until: new Date(
              new Date().getTime() +
                campaign.valid_for_months * 30 * 24 * 60 * 60 * 1000
            ),
          });
        }

        // Reset the pagination by setting startAfter to null
        vm.startAfter = null;

        // Fetch users after adding multiple users
        vm.getUsers();

        vm.isLoading = false; // Reset loading state
      } catch (error) {
        vm.handleError(error);
      }
    },

    async addUsers(checked, uid) {
      const vm = this;
      try {
        if (checked) {
          vm.multiUser.push(uid);
        } else {
          vm.multiUser = vm.multiUser.reduce((acc, item) => {
            if (item !== uid) {
              acc.push(item);
            }
            return acc;
          }, []);
        }
        console.log(vm.multiUser);
      } catch (error) {
        vm.handleError(error);
      }
    },
    calculatePercentage(totalUsers, totalCampaigns) {
      if (totalCampaigns === 0) return 0;
      return ((totalUsers / totalCampaigns) * 100).toFixed(2);
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    handleError() {
      // Handle error here
    },
  },
  mounted() {
    this.getCampaignDetails();
  },
  created() {
    this.getUsers();
  },
};
</script>
<style>
#custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  display: none;
}

#custom-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  display: none;
}

#custom-scroll::-webkit-scrollbar-thumb {
  background-color: #8056d6;
}
</style>
