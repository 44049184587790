<template>
  <div class="bg-slate-100 pa-6 pa-md-12 h-full">
    <div
      class="bg-white rounded-xl pa-6 pa-md-8 max-w-[1276px] max-md:max-w-full mx-auto"
    >
      <div class="pa-6 bg-slate-100 rounded-xl">
        <v-row v-if="isBBCategoryLoading">
          <v-col cols="12" md="3" v-for="i in 12" :key="i">
            <v-skeleton-loader
              class="rounded-xl"
              type="card"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-if="createOptions1 && createOptions1.length">
          <v-col v-for="(item, i) in createOptions1" :key="i" cols="12" md="3">
            <div
              class="bg-white pa-6 rounded-xl d-flex flex-column align-center cursor-pointer h-[200px]"
              @click="item.click()"
            >
              <v-avatar :color="item.color" size="60">
                <img
                  :src="item.image"
                  style="width: 30px; height: 30px; object-fit: contain"
                />
              </v-avatar>
              <div class="text-xl mt-6 text-center" v-html="item.title" />
            </div>
          </v-col>
        </v-row>
     
        <v-row v-if="createOptions && createOptions.length">
          <v-col v-for="(item, i) in createOptions" :key="i" cols="12" md="3">
            <div
              class="bg-white pa-6 rounded-xl d-flex flex-column align-center cursor-pointer h-[200px]"
              @click="item.click()"
            >
              <v-avatar :color="item.color" size="60">
                <img
                  :src="item.image"
                  style="width: 30px; height: 30px; object-fit: contain"
                />
              </v-avatar>
              <div class="text-xl mt-6 text-center" v-html="item.title" />
            </div>
          </v-col>
          <v-col
            v-for="(item, i) in bbCategories"
            :key="`category-${i}`"
            cols="12"
            md="3"
          >
            <div
              class="bg-white pa-6 rounded-xl d-flex flex-column align-center cursor-pointer h-[200px]"
              @click="$router.push(`/choose-template/${item.id}`)"
            >
              <v-avatar color="#fae8ff" size="60">
                <img
                  :src="item.icon_url"
                  style="width: 30px; height: 30px; object-fit: contain"
                />
              </v-avatar>
              <div
                class="text-xl mt-6 text-center text-capitalize"
                v-html="item.name"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="dialogg" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >No Social Handles Connected</v-card-title
        >
        <v-card-text>
          Please connect your social handles before posting!
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="navigateToConnectedApps"
            >Connect Social Handles</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import "firebase/firestore";
import { bb_categories, storage } from "@/utils/firebase.utils";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  doc,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  query,
} from "firebase/firestore";
import { db, auth, colUsers } from "@/utils/firebase.utils.js";
import openaiMixin from "@/mixins/openai.mixin";
import { mapDocs } from "@/utils/common.utils";
import { orderBy } from "lodash";
export default {
  components: {},
  data() {
    return {
      dialogg: false,
      currentItem: 0,
      totalItems: 3,
      isDesktop: window.innerWidth >= 768,

      shakeElement: false,
      shakecar: false,
      flashMessage: "",
      selectedVisibility: "",
      visibilityOptions: ["Well known", "Little Known"],
      selectedProductService: null,
      selectedTone: "",
      generateDialog: false,
      postTheme: "",
      customInstruction: "",
      postLength: 50,
      occasion: "",
      stageOfBusiness: "",
      themes: ["Theme 1", "Theme 2", "Theme 3"], // Add your themes here
      businessStages: [
        "Just started",
        "Validation Phase",
        "Revenue generating",
        "Growing",
        "Large Enterprise",
      ],
      dialog: false,
      dialogContent: "",
      exdilaog: false,
      sugdilaog: false,
      suggestdilalog: "",
      exampledilaog: "",
      responseData: [],
      search: "",
      imageModal: false,
      step: 1,
      brandCollectionExists: false,
      selectedImage: "",
      showColorPicker: false,
      showSecondaryColorPicker: false,
      catalogueDialog: false,

      selectedCountry: "",

      catalogue: {
        name: "",
        description: "",
        images: [],
        video: [],
        price: 0,
        postFrequency: "",
        // Add other fields as needed
      },
      brandImages: {
        logoOriginal: null,
        logoDark: null,
        logoTransparent: null,
      },
      businessDetails: {
        businessName: "",
        Tagline: "",
        website: "",
        pictures_sample_flyers: [],
        placeOfBusiness: "",
        businessDescription: "",
        highlights: [""],
        brandvalues: [""],
        tone_of_voice: [""],
        contenttheme: [""],
        unique_selling_proposition: "",
        purpose: "",
        logoOriginal: null,
        logoDark: null,
        logoTransparent: null,
        primaryColor: "",
        secondaryColor: "",
        primaryFont: "",
        secondaryFont: "",
        productsServicesCatalogue: [],
        targetAudience: [""],
        profile: "",
      },
      fontOptions: [
        { text: "Font 1", value: "font1" },
        { text: "Font 2", value: "font2" },
      ],
      isBBCategoryLoading: true,
      bbCategories: [],
    };
  },
  computed: {
    createOptions1(){
      let items1 =[
      {
          image: require("@/assets/img/crpost.svg"),
          title: "Create Social<br/>Media Post",
          click: () => {
            this.checkAndProceed(this.createPage);
          },
          isActive: true,
          color: "#E8DFFF",
        },
      ]
      return items1.filter((i) => i.isActive);
    },
    createOptions() {
      let items = [
     
        // {
        //   image: require("@/assets/img/Robotai.svg"),
        //   title: "Suggest<br/> With AI",
        //   click: () => {
        //     this.goToSuggestAiPage();
        //   },
        //   isActive: true,
        //   color: "#fae8ff",
        // },
        {
          image: require("@/assets/img/car-list.svg"),
          title: "Car Listing",
          click: () => {
            this.checkAndProceed(this.goToCreatecar);
          },
          isActive: true,
          color: "#e8dfff",
        },
        {
          image: "https://img.icons8.com/ios-filled/50/7950F2/car-sale.png",
          title: "Car Sell Listing",
          click: () => {
            this.goToCreatecarsell();
          },
          isActive: true,
          color: "#e8dfff",
        },
        // {
        //   image: require("@/assets/img/image-template.png"),
        //   title: "Generate Image",
        //   click: () => this.$router.push("/choose-template"),
        //   isActive: true,
        //   color: "#fae8ff",
        // },
      ];
      return items.filter((i) => i.isActive);
    },
    // Checks if the user's phone number starts with '+91'
    showCarListingOption() {
      return this.authUser && this.authUser.country_code == "IN";
    },
  },
  async created() {
    // try {
    //   const userId = auth.currentUser.uid;
    //   const userDocRef = doc(colUsers, userId,"fly_workspaces", this.WID);
    //   const brandCollectionRef = collection(userDocRef, 'brand');
    //   const brandDocSnapshot = await getDocs(brandCollectionRef);
    //   if (brandDocSnapshot.empty) {
    //     console.error("Brand document not found");
    //     return;
    //   }
    //   const brandDoc = brandDocSnapshot.docs[0].data();
    //   console.log("brnad",brandDoc)
    //   this.businessDetails.productsServicesCatalogue = brandDoc.products_services_catalogue || [];
    // } catch (error) {
    //   console.error("Error fetching business details from Firestore:", error);
    // }
  },
  mixins: [openaiMixin],
  watch: {
    WID() {
      this.getbrand();
    },
  },
  methods: {
    async fetchLocationsConnectionStatus() {
      const workspaceRef = collection(db, "users", this.currentUID, "fly_workspaces")
      try {
        const querySnapshot = await getDocs(workspaceRef);
        for (let doc of querySnapshot.docs) {
          const data = doc.data();
          if (
            doc.id !== "default" &&
            data.storeWorkspace &&
            data.status === "connected" &&
            data.connected_apps && 
            data.connected_apps.length > 0
          ) {
            return true; 
          }
        }
        return false;
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    },
    async checkUserPlan(){
      const requiredPlan = process.env.VUE_APP_BROADCAST_PLAN || 'boost' ;
      return this.authUser.fly_plan === requiredPlan;
    },
    async getConnected() {
      let vm = this;
      let q = query(
        collection(
          doc(colUsers, this.currentUID, "fly_workspaces", vm.WID),
          "connected-apps"
        )
      );
      let connectedApps = (await getDocs(q)).docs.map((i) => i.id);
      return connectedApps;
    },
    navigateToConnectedApps() {
      this.dialogg = false;
      this.$router.push({ name: "connected-apps" });
    },
    async checkAndProceed(action) {
      try {
        const connectedApps = await this.getConnected();
        if (connectedApps.length === 0) {
          // Show the pop-up if no social handles are connected
          const [isLocationConnected, isUserPlanValid] = await Promise.all([
            this.fetchLocationsConnectionStatus(),
            this.checkUserPlan()
          ]);
          if (isLocationConnected && isUserPlanValid) {
            action(); 
          } else {
            this.dialogg = true; 
          }
        } else if(connectedApps.length === 1 && connectedApps[0]==='google_business_profile_bulk'){
            this.dialogg = true; 
        }
        else {
          // Proceed with the desired action if social handles are connected
          action(); 
        }
      } catch (error) {
        console.error("Error in checkAndProceed:", error);
        this.dialogg = true;
      }
    },
    promptToConnectSocialHandles() {
      this.flashMessage = "Please connect your social handles first!";
      this.shakeElement = true;
      setTimeout(() => {
        this.shakeElement = false;
      }, 1000); // Shake duration
    },
    handleResize() {
      this.isDesktop = window.innerWidth >= 768;
    },
    nextItem() {
      if (this.currentItem < this.totalItems - 1) {
        this.currentItem++;
      }
    },
    prevItem() {
      if (this.currentItem > 0) {
        this.currentItem--;
      }
    },
    createPage() {
      let type = ["social"];
      this.$store.commit("SET_FLY_PLATFORMS_TYPE", type);
      this.$router.push({ name: "create-post" });
    },
    promptToAddBrandInfo() {
      this.flashMessage = "Please add your brand info!";
      this.shakeElement = true;
      setTimeout(() => {
        this.shakeElement = false;
      }, 1000); // Shake duration
    },
    promptToAddCarList() {
      this.flashMessage = "Please add your brand info!";
      this.shakecar = true;
      setTimeout(() => {
        this.shakecar = false;
      }, 1000); // Shake duration
    },
    goToSuggestAiPage() {
      this.$router.push({ name: "suggestai" });
    },
    goToCreatecar() {
      this.$router.push({ name: "create-car-listings" });
    },
    goToCreatecarsell() {
      this.$router.push({ name: "create-car-sell" });
    },
    openGenerateDialog() {
      this.generateDialog = true;
    },
    formatDate(isoDateString) {
      const date = new Date(isoDateString);
      const day = date.getDate().toString().padStart(2, "0");
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = monthNames[date.getMonth()];
      const year = date.getFullYear();
      return `${day}-${monthName}-${year}`;
    },

    // Other methods...
    async fetchSuggestions() {
      const userId = auth.currentUser.uid;
      const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
      const brandCollectionRef = collection(userDocRef, "brand");
      const brandDocSnapshot = await getDocs(brandCollectionRef);

      if (!brandDocSnapshot.empty) {
        const brandDocRef = brandDocSnapshot.docs[0].ref;
        const suggestionSubcollectionRef = collection(
          brandDocRef,
          "suggestion"
        );
        const suggestionSnapshot = await getDocs(suggestionSubcollectionRef);

        this.responseData = suggestionSnapshot.docs
          .map((doc) => doc.data())
          .sort((a, b) => b.created_at.localeCompare(a.created_at));
      }
    },

    emitTitleToBus(title, content, example) {
      console.log("emitted", title);
      console.log("content", content);
      this.$router.push({
        name: "create-post",
        params: {
          title: title,
          content: content,
          example: example,
        },
      });
    },

    openDialog(content) {
      this.dialogContent = content;
      this.dialog = true;
    },
    openExDialog(content) {
      this.exampledilaog = content;
      this.exdilaog = true;
    },
    openSugDialog(content) {
      this.suggestdilalog = content;
      this.sugdilaog = true;
    },
    copytoclipboard() {
      navigator.clipboard.writeText(this.exampledilaog);
      this.emitTitleToBus(
        this.responseData[0].suggestion.posts[0].title,
        this.responseData[0].suggestion.posts[0].content
      );
    },
    copysuggest() {
      navigator.clipboard.writeText(this.suggestdilalog);
    },
    openImageModal(imageUrl) {
      this.selectedImage = imageUrl;
      this.imageModal = true;
    },
    goToProductServicePage() {
      this.$router.push({ name: "productservice" });
    },
    addHighlight() {
      this.businessDetails.highlights.push("");
    },
    addbrandvalues() {
      this.businessDetails.brandvalues.push("");
    },
    addcontent() {
      this.businessDetails.contenttheme.push("");
    },
    addtonevoice() {
      this.businessDetails.tone_of_voice.push("");
    },
    // Y
    addTargetAudience() {
      this.businessDetails.targetAudience.push("");
    },
    openFileInput() {
      this.$refs.picturesSampleFlyersInput.click();
    },
    async postSuggestPosts(profileName) {
      const apiEndpoint =
        "https://sap-post-suggester-nfp3rxpvjq-el.a.run.app/suggest/posts";
      const payload = {
        system_preset: {
          id: 1,
        },
        user_preset: {
          id: 1,
          placeholders: {
            profile_name: profileName,
          },
        },
        profile: profileName,
        num_posts: 1,
        history_k: 3,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };

      try {
        this.$loader.show({
          message:
            "FlyAI’s cooking up the best social media posts for your brand – just hang tight!",
          type: "ai",
        });

        const response = await fetch(apiEndpoint, requestOptions);

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error("API Response Error:", errorDetails);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        this.responseData.unshift(responseData);
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (!brandDocSnapshot.empty) {
          const brandDocRef = brandDocSnapshot.docs[0].ref;

          // Create a subcollection named 'suggestion' and add the response data
          const suggestionSubcollectionRef = collection(
            brandDocRef,
            "suggestion"
          );
          await addDoc(suggestionSubcollectionRef, {
            created_at: responseData.created_at,
            suggestion: responseData.suggestion,
          });
        }
        this.$loader.hide();
        console.log("Successfully posted suggest posts:", responseData);
        return responseData;
      } catch (error) {
        console.error("Error posting suggest posts to endpoint:", error);
        return null;
      }
    },

    async handleLogoTransparentChange(event) {
      const file = event.target.files[0];
      if (file) {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/logoTransparent/${file.name}`
        );

        try {
          const uploadTaskSnapshot = await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
          this.businessDetails.logoTransparent = imageUrl;

          // Update Firestore with the new logoTransparent URL
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              logo_transparent: this.businessDetails.logoTransparent,
            });
          }
        } catch (error) {
          console.error("Error uploading logoTransparent: ", error);
        }
      }
    },
    async handleLogoOriginalChange(event) {
      const file = event.target.files[0];
      if (file) {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/logooriginal/${file.name}`
        );

        try {
          const uploadTaskSnapshot = await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
          this.businessDetails.logoOriginal = imageUrl;

          // Update Firestore with the new logooriginal URL
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              logo_original: this.businessDetails.logoOriginal,
            });
          }
        } catch (error) {
          console.error("Error uploading logooriginal: ", error);
        }
      }
    },
    async handleLogoDarkChange(event) {
      const file = event.target.files[0];
      if (file) {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/logoDark/${file.name}`
        );

        try {
          const uploadTaskSnapshot = await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
          this.businessDetails.logoDark = imageUrl;

          // Update Firestore with the new logoDark URL
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              logo_dark: this.businessDetails.logoDark,
            });
          }
        } catch (error) {
          console.error("Error uploading logoDark: ", error);
        }
      }
    },
    async handlePicturesSampleFlyersChange(event) {
      const files = event.target.files;
      if (files) {
        const userId = auth.currentUser.uid;
        const uploadPromises = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const storageRef = ref(
            storage,
            `users/${userId}/pictures_sample_flyers/${file.name}`
          );
          const uploadTask = uploadBytes(storageRef, file).then(() =>
            getDownloadURL(storageRef)
          );
          uploadPromises.push(uploadTask);
        }

        try {
          const imageUrls = await Promise.all(uploadPromises);
          this.businessDetails.pictures_sample_flyers = [
            ...this.businessDetails.pictures_sample_flyers,
            ...imageUrls,
          ];

          // Update Firestore with the new pictures_sample_flyers array
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);
          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              pictures_sample_flyers:
                this.businessDetails.pictures_sample_flyers,
            });
          }
        } catch (error) {
          console.error("Error uploading images: ", error);
        }
      }
    },
    previewImages(event) {
      const input = event.target;
      const preview = document.getElementById("sample_flyers_preview");
      const files = input.files;

      // Clear the preview container before adding new images
      preview.innerHTML = "";

      // Iterate through the selected files and create image previews
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = function (e) {
          const img = document.createElement("img");
          img.src = e.target.result;
          img.classList.add("preview-image");
          preview.appendChild(img);
        };

        reader.readAsDataURL(file);
      }
    },

    openFileDialog(refName) {
      // Trigger click event on the file input to open the file dialog
      this.$refs[refName].click();
    },
    handleImageChange(imageKey, event) {
      const file = event.target.files[0];
      if (file instanceof File) {
        // Read the selected file as a data URL
        const reader = new FileReader();
        reader.onload = () => {
          // Update the corresponding image source with the selected file
          // this.brandImages[imageKey] = reader.result;
          this.businessDetails[imageKey] = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },

    async nextStep() {
      if (this.step < 5) {
        try {
          const userId = auth.currentUser.uid;
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);

          let brandDocRef;
          if (brandDocSnapshot.empty) {
            // If the brand collection is empty, create a new document
            brandDocRef = await addDoc(brandCollectionRef, {});
          } else {
            // If the brand collection exists, use the existing document
            brandDocRef = brandDocSnapshot.docs[0].ref;
          }

          // Update the brand document with the current businessDetails
          await updateDoc(brandDocRef, {
            // Add or update fields as needed

            business_name: this.businessDetails.businessName,
            website: this.businessDetails.website,
            tagline: this.businessDetails.Tagline,
            purpose: this.businessDetails.purpose,
            highlights: this.businessDetails.highlights,
            brand_values: this.businessDetails.brandvalues,
            content_themes: this.businessDetails.contenttheme,
            tone_of_voice: this.businessDetails.tone_of_voice,
            unique_selling_proposition:
              this.businessDetails.unique_selling_proposition,
            pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
            place_business: this.businessDetails.placeOfBusiness,
            business_description: this.businessDetails.businessDescription,
            logo_original: this.businessDetails.logoOriginal,
            logo_dark: this.businessDetails.logoDark,
            logo_transparent: this.businessDetails.logoTransparent,
            primary_color: this.businessDetails.primaryColor,
            secondary_color: this.businessDetails.secondaryColor,
            primary_font: this.businessDetails.primaryFont,
            secondary_font: this.businessDetails.secondaryFont,
            products_services_catalogue:
              this.businessDetails.productsServicesCatalogue,
            age_groups: this.selectedAgeGroups,
            genders: this.selectedGenders,
            income_levels: this.selectedIncomeLevels,
            education_levels: this.selectedEducationLevels,
            employment_statuses: this.selectedEmploymentStatuses,
            marital_statuses: this.selectedMaritalStatuses,
            ethnicities_races: this.selectedEthnicitiesRaces,
            interests_hobbies: this.selectedInterestsHobbies,
            household_sizes: this.selectedHouseholdSizes,
            number_of_children: this.selectedNumberOfChildren,
            home_ownership: this.selectedHomeOwnership,
            disability_status: this.selectedDisabilityStatus,
            industry_category: this.selectedIndustryCategory,
            target_audience: this.businessDetails.targetAudience,
            country: this.selectedCountry,
          });
          this.brandCollectionExists = true;
          // Proceed to the next step
          this.step++;
        } catch (error) {
          console.error("Error updating brand collection:", error);
          alert("An error occurred while updating the brand information.");
        }
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    editCatalogueItem(item) {
      // Populate the catalogue object with the data of the selected item
      this.catalogue.name = item.name;
      this.catalogue.description = item.description;
      this.catalogue.price = item.price;
      this.catalogue.postFrequency = item.post_frequency;
      // Set the images and video to empty arrays to clear previous values
      this.catalogue.images = [];
      this.catalogue.video = [];
      // Populate the images and video arrays with the URLs from the selected item
      this.catalogue.images.push(...item.images);
      this.catalogue.video.push(...item.video);
      // Open the dialog
      this.$nextTick(() => {
        this.$refs.catalogueNameInput.readonly = true;
      });
      this.catalogueDialog = true;
    },
    async deleteImageFromCatalogue(itemIndex, imageIndex) {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (brandDocSnapshot.empty) {
          console.error("Brand document not found");
          return;
        }

        const brandDoc = brandDocSnapshot.docs[0];
        const updatedCatalogue = [
          ...brandDoc.data().products_services_catalogue,
        ];

        // Remove the image from the images array of the corresponding item
        updatedCatalogue[itemIndex].images.splice(imageIndex, 1);

        // Update Firestore document with the modified catalogue
        await updateDoc(brandDoc.ref, {
          products_services_catalogue: updatedCatalogue,
        });

        // Update local data to reflect changes in UI
        this.$set(
          this.businessDetails,
          "productsServicesCatalogue",
          updatedCatalogue
        );

        alert("Image deleted successfully!");
      } catch (error) {
        console.error("Error deleting image:", error);
        alert("An error occurred while deleting the image.");
      }
    },
    async fetchCountries() {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const countriesData = await response.json();
        this.countries = countriesData.map((country) => country.name.common);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    },
    async deleteCatalogueItem(index) {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (brandDocSnapshot.empty) {
          console.error("Brand document not found");
          return;
        }

        const brandDoc = brandDocSnapshot.docs[0];
        const updatedCatalogue = [
          ...brandDoc.data().products_services_catalogue,
        ];
        updatedCatalogue.splice(index, 1); // Remove item at the specified index

        // Update Firestore document with the modified catalogue
        await updateDoc(brandDoc.ref, {
          products_services_catalogue: updatedCatalogue,
        });

        // Update local data to reflect changes in UI
        this.$set(
          this.businessDetails,
          "productsServicesCatalogue",
          updatedCatalogue
        );

        alert("Catalogue item deleted successfully!");
      } catch (error) {
        console.error("Error deleting catalogue item:", error);
        alert("An error occurred while deleting the catalogue item.");
      }
    },
    openImageModal(image) {
      this.selectedImage = image;
      this.imageModal = true;
    },

    async uploadImageToStorage(file, itemIndex) {
      try {
        const userId = auth.currentUser.uid;
        const storageRef = ref(
          storage,
          `users/${userId}/catalogue/${new Date().getTime()}_${Math.random()}.png`
        );

        // Upload the image to storage
        await uploadBytes(storageRef, file);

        // Get the download URL
        const imageUrl = await getDownloadURL(storageRef);

        // Update the item.images array with the new image URL
        this.businessDetails.productsServicesCatalogue[itemIndex].images.push(
          imageUrl
        );

        // Update the Firestore document with the modified array
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (!brandDocSnapshot.empty) {
          const brandDoc = brandDocSnapshot.docs[0];
          const updatedCatalogue = [
            ...brandDoc.data().products_services_catalogue,
          ];
          updatedCatalogue[itemIndex].images.push(imageUrl);

          await updateDoc(brandDoc.ref, {
            products_services_catalogue: updatedCatalogue,
          });

          alert("Image uploaded successfully!");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("An error occurred while uploading the image.");
      }
    },
    toggleSecondaryColorPicker() {
      this.showSecondaryColorPicker = !this.showSecondaryColorPicker;
    },
    toggleColorPicker() {
      this.showColorPicker = !this.showColorPicker;
    },

    openCataloguePopup() {
      this.catalogueDialog = true;
    },
    closeCataloguePopup() {
      this.catalogueDialog = false;
    },
    async saveCatalogueItem() {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);

        // Fetch the current brand document
        const brandCollectionRef = collection(userDocRef, "brand");
        const querySnapshot = await getDocs(brandCollectionRef);
        const brandDoc = querySnapshot.docs[0]; // Assuming there's only one brand document per user

        if (!brandDoc) {
          // Handle the case where the brand document doesn't exist
          alert("Brand document not found");
          return;
        }

        // Process and upload each image
        const uploadedImages = await Promise.all(
          this.catalogue.images.map(async (imageFile) => {
            const storageRef = ref(
              storage,
              `users/${
                auth.currentUser.uid
              }/catalogue/${new Date().getTime()}_${Math.random()}.png`
            );
            await uploadBytes(storageRef, imageFile);
            return getDownloadURL(storageRef);
          })
        );

        const uploadVideo = await Promise.all(
          this.catalogue.video.map(async (videoFile) => {
            const storageRef = ref(
              storage,
              `users/${
                auth.currentUser.uid
              }/catalogue/${new Date().getTime()}_${Math.random()}.mp4`
            );
            await uploadBytes(storageRef, videoFile);
            return getDownloadURL(storageRef);
          })
        );

        // Continue with the rest of your code
        const catalogueItem = {
          name: this.catalogue.name || "",
          description: this.catalogue.description || "",
          images: uploadedImages || [],
          video: uploadVideo || [],
          price: this.catalogue.price || 0,
          post_frequency: this.catalogue.postFrequency || "",
          // Add other fields as needed
        };

        // Concatenate the existing productsServicesCatalogue array with the new catalogueItem
        const updatedCatalogue = [
          ...brandDoc.data().products_services_catalogue,
          catalogueItem,
        ];

        // Update the existing brand document to include the updated productsServicesCatalogue array
        await updateDoc(brandDoc.ref, {
          products_services_catalogue: updatedCatalogue,
        });

        alert("Catalogue item added successfully!");
        const itemIndex =
          this.businessDetails.productsServicesCatalogue.findIndex(
            (item) => item.name === this.catalogue.name
          );
        if (itemIndex !== -1) {
          // Update the existing item in the array
          this.businessDetails.productsServicesCatalogue[itemIndex] = {
            name: this.catalogue.name,
            description: this.catalogue.description,
            images: this.catalogue.images,
            video: this.catalogue.video,
            price: this.catalogue.price,
            post_frequency: this.catalogue.postFrequency,
            // Add other fields as needed
          };

          // Update the Firestore document with the modified array
          const userId = auth.currentUser.uid;
          const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
          const brandCollectionRef = collection(userDocRef, "brand");
          const brandDocSnapshot = await getDocs(brandCollectionRef);

          if (!brandDocSnapshot.empty) {
            const brandDoc = brandDocSnapshot.docs[0];
            await updateDoc(brandDoc.ref, {
              products_services_catalogue:
                this.businessDetails.productsServicesCatalogue,
            });

            alert("Catalogue item updated successfully!");
            // Refresh the data after updating
          }
        }
        this.getbrand();
      } catch (error) {
        console.error("Error saving catalogue item in Firestore:", error);
        alert("An error occurred while saving the catalogue item.");
      }

      // Reset the catalogue object after saving
      this.catalogue = {
        name: "",
        description: "",
        images: [],
        video: [],
        // Add other fields as needed
      };

      this.catalogueDialog = false;
    },
    addRandomColor() {
      // Generate a random color (you can modify this logic as needed)
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;

      // Assign the random color to the primaryColor property
      this.businessDetails.primaryColor = randomColor;
    },
    addRandomColor2() {
      // Generate a random color (you can modify this logic as needed)
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;

      // Assign the random color to the primaryColor property
      this.businessDetails.secondaryColor = randomColor;
    },
    // async fetchBrandDetailsAndConvertToJson() {
    //   const userId = auth.currentUser.uid;
    //   const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
    //   const brandCollectionRef = collection(userDocRef, "brand");
    //   const brandDocSnapshot = await getDocs(brandCollectionRef);

    //   if (brandDocSnapshot.empty) {
    //     console.error("Brand document not found");
    //     return;
    //   }

    //   const brandDoc = brandDocSnapshot.docs[0].data();

    //   const brandDetails = {
    //     brand_details: {
    //       name: brandDoc.business_name || "",
    //       description: brandDoc.business_description || "",
    //       purpose: brandDoc.purpose || "",
    //       tagline: brandDoc.tagline || "",
    //       highlights: brandDoc.highlights || [],
    //       website: brandDoc.website || "",
    //       logo: brandDoc.logo_original || "",
    //       social_handles: {
    //         facebook: "https://www.facebook.com/example",
    //         twitter: "https://www.twitter.com/example",
    //         instagram: "https://www.instagram.com/example",
    //       },
    //       primary_colours: [brandDoc.primary_color || ""],
    //       secondary_colours: [brandDoc.secondary_color || ""],
    //       pictures_sample_flyers: brandDoc.pictures_sample_flyers || [],
    //       industry_category: brandDoc.industry_category, // This field is not present in the provided data
    //       // This field is not present in the provided data
    //       target_audience: {
    //         primary: [
    //           "Automotive solutions businesses seeking reliable data feeds",
    //         ],
    //         secondary: [
    //           "Top car manufacturers, large retailers, auction houses, lenders, and insurers in the automotive industry",
    //         ],
    //       },
    //       brand_values: brandDoc.brand_values || [],
    //       unique_selling_proposition: brandDoc.unique_selling_proposition || "",
    //       content_themes: brandDoc.content_themes || [],
    //       tone_of_voice: brandDoc.tone_of_voice || [],
    //     },
    //     product_service_catalogue: brandDoc.products_services_catalogue.map(
    //       (item) => ({
    //         name: item.name || "",
    //         description: item.description || "",
    //         offers_discounts: ["10% off for first-time customers"],

    //         images: item.images || [],
    //         offers_discounts: null,
    //         price_fee: item.price
    //           ? `Custom quote based on requirements: ${item.price}`
    //           : "Custom quote based on requirements",
    //         key_features_highlights: item.key_features_highlights || [],
    //         product_service_description_web_page:
    //           item.product_service_description_web_page || "",
    //       })
    //     ),
    //   };
    //   console.log("brandDetails", brandDetails);
    //   return brandDetails;
    // },
    // async postBrandInfoToEndpoint(operationType) {
    //   try {
    //     const brandDetails = await this.fetchBrandDetailsAndConvertToJson();
    //     let apiEndpoint;

    //     if (operationType === "create") {
    //       apiEndpoint =
    //         "https://sap-post-suggester-nfp3rxpvjq-el.a.run.app/profiles/create";
    //     } else if (operationType === "update") {
    //       apiEndpoint =
    //         "https://sap-post-suggester-nfp3rxpvjq-el.a.run.app/profiles/update";
    //     } else {
    //       throw new Error(
    //         'Invalid operation type. Must be "create" or "update".'
    //       );
    //     }

    //     const requestOptions = {
    //       method: "POST",
    //       headers: { "Content-Type": "application/json" },
    //       body: JSON.stringify(brandDetails),
    //     };

    //     console.log("Sending payload to API:", brandDetails); // Log the payload for debugging

    //     const response = await fetch(apiEndpoint, requestOptions);

    //     if (!response.ok) {
    //       const errorDetails = await response.json();
    //       console.error("API Response Error:", errorDetails);
    //       throw new Error(`HTTP error! Status: ${response.status}`);
    //     }

    //     const responseData = await response.json();
    //     console.log("Successfully posted brand info:", responseData);
    //     return responseData;
    //   } catch (error) {
    //     console.error("Error posting brand info to endpoint:", error);
    //     return null;
    //   }
    // },

    async submitBusinessInfo() {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");

        // Check if the user already has a brand collection
        const brandDocsSnapshot = await getDocs(brandCollectionRef);
        let brandDocRef;
        let operationType;
        if (!brandDocsSnapshot.empty) {
          // If a brand collection exists, get its reference
          brandDocRef = brandDocsSnapshot.docs[0].ref;
          this.brandCollectionExists = true;
          operationType = "update";
        } else {
          // If no brand collection exists, create a new document
          brandDocRef = await addDoc(brandCollectionRef, {
            business_name: this.businessDetails.businessName,
            website: this.businessDetails.website,
            tagline: this.businessDetails.Tagline,
            purpose: this.businessDetails.purpose,
            highlights: this.businessDetails.highlights,
            brand_values: this.businessDetails.brandvalues,
            content_themes: this.businessDetails.contenttheme,
            tone_of_voice: this.businessDetails.tone_of_voice,
            unique_selling_proposition:
              this.businessDetails.unique_selling_proposition,
            pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
            place_business: this.businessDetails.placeOfBusiness,
            business_description: this.businessDetails.businessDescription,
            logo_original: this.businessDetails.logoOriginal,
            logo_dark: this.businessDetails.logoDark,
            logo_transparent: this.businessDetails.logoTransparent,
            primary_color: this.businessDetails.primaryColor,
            secondary_color: this.businessDetails.secondaryColor,
            primary_font: this.businessDetails.primaryFont,
            secondary_font: this.businessDetails.secondaryFont,
            products_services_catalogue:
              this.businessDetails.productsServicesCatalogue,
            age_groups: this.selectedAgeGroups,
            genders: this.selectedGenders,
            income_levels: this.selectedIncomeLevels,
            education_levels: this.selectedEducationLevels,
            employment_statuses: this.selectedEmploymentStatuses,
            marital_statuses: this.selectedMaritalStatuses,
            ethnicities_races: this.selectedEthnicitiesRaces,
            interests_hobbies: this.selectedInterestsHobbies,
            household_sizes: this.selectedHouseholdSizes,
            number_of_children: this.selectedNumberOfChildren,
            home_ownership: this.selectedHomeOwnership,
            disability_status: this.selectedDisabilityStatus,
            industry_category: this.selectedIndustryCategory,
            target_audience: this.businessDetails.targetAudience,
          });
          this.brandCollectionExists = false;
          operationType = "create";
        }
        await this.postBrandInfoToEndpoint(operationType);
        const responseData = await this.postBrandInfoToEndpoint(operationType);
        await updateDoc(brandDocRef, {
          business_name: this.businessDetails.businessName,
          website: this.businessDetails.website,
          tagline: this.businessDetails.Tagline,
          purpose: this.businessDetails.purpose,
          highlights: this.businessDetails.highlights,
          brand_values: this.businessDetails.brandvalues,
          content_themes: this.businessDetails.contenttheme,
          tone_of_voice: this.businessDetails.tone_of_voice,
          unique_selling_proposition:
            this.businessDetails.unique_selling_proposition,
          pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
          place_business: this.businessDetails.placeOfBusiness,
          business_description: this.businessDetails.businessDescription,
          logo_original: this.businessDetails.logoOriginal,
          logo_dark: this.businessDetails.logoDark,
          logo_transparent: this.businessDetails.logoTransparent,
          primary_color: this.businessDetails.primaryColor,
          secondary_color: this.businessDetails.secondaryColor,
          primary_font: this.businessDetails.primaryFont,
          secondary_font: this.businessDetails.secondaryFont,
          products_services_catalogue:
            this.businessDetails.productsServicesCatalogue,
          age_groups: this.selectedAgeGroups,
          genders: this.selectedGenders,
          income_levels: this.selectedIncomeLevels,
          education_levels: this.selectedEducationLevels,
          employment_statuses: this.selectedEmploymentStatuses,
          marital_statuses: this.selectedMaritalStatuses,
          ethnicities_races: this.selectedEthnicitiesRaces,
          interests_hobbies: this.selectedInterestsHobbies,
          household_sizes: this.selectedHouseholdSizes,
          number_of_children: this.selectedNumberOfChildren,
          home_ownership: this.selectedHomeOwnership,
          disability_status: this.selectedDisabilityStatus,
          industry_category: this.selectedIndustryCategory,
          target_audience: this.businessDetails.targetAudience,
          country: this.selectedCountry,
          profile: responseData.profile,
        });

        alert(
          `Brand information updated successfully with profile: ${responseData.profile}`
        );

        // alert(`Brand information ${operationType}d successfully and posted to the endpoint!`);
        if (!this.brandCollectionExists) {
          alert("Brand collection created successfully!");
        } else {
          alert("Brand collection updated successfully!");
        }
      } catch (error) {
        console.error("Error saving brand collection in Firestore:", error);
        alert("An error occurred while saving the brand collection.");
      }
    },

    //     async submitBusinessInfo() {
    //       try {
    //         const userId = auth.currentUser.uid;
    //         console.log("WID:", this.WID);
    //         const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
    //         const brandCollectionRef = collection(userDocRef, "brand");

    //         // Check if the user already has a businessDetails document
    //         const existingBrandDocs = await getDocs(brandCollectionRef);

    //         if (!existingBrandDocs.empty) {
    //           // If documents already exist, inform the user or handle it as needed
    //           alert(
    //             "You have already added business details.you can add multiple Products/Services Catalogue."
    //           );
    //           return;
    //         }

    //         // Continue with adding business details if no existing documents are found

    //         const logoOriginalFile = this.businessDetails.logoOriginal;
    //         // console.log("logoOriginalFile: ", logoOriginalFile);
    //         if (logoOriginalFile) {
    //           const storageRef = ref(
    //             storage,
    //             `users/${userId}/logo_original/${new Date().getTime()}.png`
    //           );
    //           if (logoOriginalFile instanceof File) {
    //             await uploadBytes(storageRef, logoOriginalFile);
    //           } else {
    //             await uploadString(storageRef, logoOriginalFile, "data_url");
    //           }
    //           this.businessDetails.logoOriginal = await getDownloadURL(storageRef);
    //           // console.log('getDownloadURL:', this.businessDetails.logoOriginal)
    //         }

    //         if (this.businessDetails.logoDark) {
    //           const storageRef = ref(
    //             storage,
    //             `users/${userId}/logo_dark/${new Date().getTime()}.png`
    //           );
    //           if (this.businessDetails.logoDark instanceof File) {
    //             await uploadBytes(storageRef, this.businessDetails.logoDark);
    //           } else {
    //             await uploadString(storageRef, this.businessDetails.logoDark, "data_url");
    //           }
    //           this.businessDetails.logoDark = await getDownloadURL(storageRef);
    //         }
    //         if (this.businessDetails.logoTransparent) {
    //           const storageRef = ref(
    //             storage,
    //             `users/${userId}/logo_transparent/${new Date().getTime()}.png`
    //           );
    //           if (this.businessDetails.logoTransparent instanceof File) {
    //             await uploadBytes(storageRef, this.businessDetails.logoTransparent);
    //           } else {
    //             await uploadString(storageRef, this.businessDetails.logoTransparent, "data_url");
    //           }

    //           this.businessDetails.logoTransparent = await getDownloadURL(
    //             storageRef
    //           );
    //         }
    //         const uploadedImages = await Promise.all(
    //   this.businessDetails.pictures_sample_flyers.map(async (imageFile) => {
    //     const storageRef = ref(
    //       storage,
    //       `users/${auth.currentUser.uid}/catalogue/${new Date().getTime()}_${Math.random()}.png`
    //     );
    //     if (imageFile instanceof File) {
    //       await uploadBytes(storageRef, imageFile);
    //     } else {
    //       await uploadString(storageRef, imageFile, "data_url");
    //     }
    //     return await getDownloadURL(storageRef);
    //   })
    // );
    // this.businessDetails.pictures_sample_flyers = uploadedImages;
    //         await addDoc(brandCollectionRef, {
    //           business_name: this.businessDetails.businessName,
    //           website: this.businessDetails.website,
    //           tagline: this.businessDetails.Tagline,
    //           purpose: this.businessDetails.purpose,
    //           highlights: this.businessDetails.highlights,
    //           brand_values: this.businessDetails.brandvalues,
    //           content_themes: this.businessDetails.contenttheme,
    //           tone_of_voice: this.businessDetails.tone_of_voice,
    //           unique_selling_proposition:
    //             this.businessDetails.unique_selling_proposition,
    //             pictures_sample_flyers: this.businessDetails.pictures_sample_flyers,
    //           place_business: this.businessDetails.placeOfBusiness,
    //           business_description: this.businessDetails.businessDescription,
    //           logo_original: this.businessDetails.logoOriginal,
    //           logo_dark: this.businessDetails.logoDark,
    //           logo_transparent: this.businessDetails.logoTransparent,
    //           primary_color: this.businessDetails.primaryColor,
    //           secondary_color: this.businessDetails.secondaryColor,
    //           primary_font: this.businessDetails.primaryFont,
    //           secondary_font: this.businessDetails.secondaryFont,
    //           products_services_catalogue:
    //             this.businessDetails.productsServicesCatalogue,
    //             age_groups: this.selectedAgeGroups,
    //             genders: this.selectedGenders,
    //             income_levels: this.selectedIncomeLevels,
    //             education_levels: this.selectedEducationLevels,
    //             employment_statuses: this.selectedEmploymentStatuses,
    //             marital_statuses: this.selectedMaritalStatuses,
    //             ethnicities_races: this.selectedEthnicitiesRaces,
    //             interests_hobbies: this.selectedInterestsHobbies,
    //             household_sizes: this.selectedHouseholdSizes,
    //             number_of_children: this.selectedNumberOfChildren,
    //             home_ownership: this.selectedHomeOwnership,
    //             disability_status: this.selectedDisabilityStatus,
    //             industry_category: this.selectedIndustryCategory,
    //         });
    //         this.brandCollectionExists = true;

    //         alert("Business information saved successfully!");
    //       } catch (error) {
    //         console.error("Error saving business information in Firestore:", error);
    //         alert("An error occurred while saving the business information.");
    //       }
    //     },
    async getbrand() {
      try {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(colUsers, userId, "fly_workspaces", this.WID);
        const brandCollectionRef = collection(userDocRef, "brand");
        const brandDocSnapshot = await getDocs(brandCollectionRef);

        if (brandDocSnapshot.empty) {
          this.brandCollectionExists = false;
          console.error("Brand document not found");
          // Clear businessDetails object properties
          this.businessDetails.businessName = "";
          this.businessDetails.Tagline = "";
          this.businessDetails.website = "";
          this.bussinessDetails.highlights = [];
          this.businessDetails.brandvalues = [];
          this.businessDetails.contenttheme = [];
          this.businessDetails.tone_of_voice = [];
          this.businessDetails.unique_selling_proposition = "";
          this.businessDetails.purpose = "";
          this.businessDetails.placeOfBusiness = "";
          this.businessDetails.businessDescription = "";
          this.businessDetails.logoOriginal = null;
          this.businessDetails.logoDark = null;
          this.businessDetails.logoTransparent = null;
          this.businessDetails.primaryColor = "";
          this.businessDetails.secondaryColor = "";
          this.businessDetails.primaryFont = "";
          this.businessDetails.secondaryFont = "";
          this.businessDetails.productsServicesCatalogue = [];
          this.businessDetails.targetAudience = "";
          return;
        }
        this.brandCollectionExists = true;
        const brandDoc = brandDocSnapshot.docs[0].data();
        // console.log("brnad", brandDoc);
        this.businessDetails.businessName = brandDoc.business_name || "";
        this.businessDetails.Tagline = brandDoc.tagline || "";
        this.businessDetails.purpose = brandDoc.purpose || "";
        this.businessDetails.highlights = brandDoc.highlights || [];
        this.businessDetails.brandvalues = brandDoc.brand_values || [];
        this.businessDetails.contenttheme = brandDoc.content_themes || [];
        this.businessDetails.tone_of_voice = brandDoc.tone_of_voice || [];
        this.businessDetails.pictures_sample_flyers =
          brandDoc.pictures_sample_flyers || [];
        this.businessDetails.unique_selling_proposition =
          brandDoc.unique_selling_proposition || "";
        this.businessDetails.website = brandDoc.website || "";
        this.businessDetails.placeOfBusiness = brandDoc.place_business || "";
        this.businessDetails.businessDescription =
          brandDoc.business_description || "";
        this.businessDetails.logoOriginal = brandDoc.logo_original || null;
        this.businessDetails.logoDark = brandDoc.logo_dark || null;
        this.businessDetails.logoTransparent =
          brandDoc.logo_transparent || null;
        this.businessDetails.primaryColor = brandDoc.primary_color || "";
        this.businessDetails.secondaryColor = brandDoc.secondary_color || "";
        this.businessDetails.primaryFont = brandDoc.primary_font || "";
        this.businessDetails.secondaryFont = brandDoc.secondary_font || "";
        this.businessDetails.productsServicesCatalogue =
          brandDoc.products_services_catalogue || [];
        this.businessDetails.productsServicesCatalogue =
          brandDoc.products_services_catalogue || [];
        this.selectedAgeGroups = brandDoc.age_groups || [];
        this.selectedGenders = brandDoc.genders || [];
        this.selectedIncomeLevels = brandDoc.income_levels || [];
        this.selectedEducationLevels = brandDoc.education_levels || [];
        this.selectedEmploymentStatuses = brandDoc.employment_statuses || [];
        this.selectedMaritalStatuses = brandDoc.marital_statuses || [];
        this.selectedEthnicitiesRaces = brandDoc.ethnicities_races || [];
        this.selectedInterestsHobbies = brandDoc.interests_hobbies || [];
        this.selectedHouseholdSizes = brandDoc.household_sizes || [];
        this.selectedNumberOfChildren = brandDoc.number_of_children || [];
        this.selectedHomeOwnership = brandDoc.home_ownership || [];
        this.selectedDisabilityStatus = brandDoc.disability_status || [];
        this.selectedIndustryCategory = brandDoc.industry_category || [];
        this.businessDetails.targetAudience = brandDoc.target_audience || "";
        this.selectedCountry = brandDoc.country || "";
        this.businessDetails.profile = brandDoc.profile || "";
        console.log("jsjsj", this.businessDetails.profile);
      } catch (error) {
        console.error("Error fetching business details from Firestore:", error);
      }
      console.log(
        "businessDetails.logoOriginal: " + this.businessDetails.logoOriginal
      );
    },
    async fetchBBCategories() {
      try {
        this.isBBCategoryLoading = true;
        let listQuery = query(bb_categories, orderBy("name", "asc"));
        this.bbCategories = mapDocs((await getDocs(listQuery)).docs).map(
          (i) => {
            if (!i.icon_url) {
              i.icon_url = require("@/assets/img/image-template.png");
            }
            return i;
          }
        );
        this.isBBCategoryLoading = false;
      } catch (error) {
        this.isBBCategoryLoading = false;
        this.handleError(error);
      }
    },
  },
  mounted() {
    // this.getbrand();
    // this.fetchBrandDetailsAndConvertToJson();
    this.fetchBBCategories();
    // this.fetchSuggestions();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style scoped>
.beta-indicator {
  margin-left: 3px;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s ease-in-out forwards;
}
.shaking {
  animation: shake 0.5s ease-in-out forwards;
}
.mobile-only {
  display: none; /* Default to not display */
}

@media (max-width: 768px) {
  .mobile-only {
    display: block; /* Only display on small screens */
    cursor: pointer;
    background-color: #ccc;
    border: none;
    padding: 2px 4px;
    margin: 5px;
    margin-bottom: -40px;
    border-radius: 5px;
  }
}

.nav-button {
  background-color: #ccc;
  border: none;
  padding: 2px 4px;
  margin: 5px;
  margin-bottom: -40px;

  border-radius: 5px;
  cursor: pointer;
}

.prev-button {
  margin-right: auto;
}

.next-button {
  margin-left: auto;
}
.icon-and-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-and-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text-and-icon v-icon {
  display: none; /* Default to hide */
}

@media (max-width: 768px) {
  .icon-and-text img {
    display: none; /* Hide images on mobile */
  }
  .text-and-icon v-icon {
    display: block; /* Show chevron icon on mobile */
  }
}
.text-and-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text-and-icon v-icon {
  display: none; /* Default to hide */
}

@media (max-width: 768px) {
  .text-and-icon v-icon {
    display: block; /* Show chevron icon on mobile */
  }
}
</style>
