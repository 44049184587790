<template>
  <div class="p-4 bg-white shadow-lg rounded-lg max-w-[1400px] w-[98%] mt-4 relative">
    <div class="flex justify-between items-center mb-4">
      <div class="flex space-x-2"></div>
    </div>
    <div class="overflow-x-auto">
      <div v-if="loading">
        <v-skeleton-loader type="table" class="mb-4" boilerplate></v-skeleton-loader>
      </div>
      <div v-else>
        <div class="bg-gray-100 p-2 rounded-t-lg text-xs sticky-header">
          <div class="flex justify-between text-gray-600 leading-tight">
            <div class="w-1/6 text-center">Platform</div>
            <div class="w-1/6 text-center">Total Posts</div>
            <div class="w-1/6 text-center">Engagement</div>
            <div class="w-1/6 text-center">Reactions</div>
            <div class="w-1/6 text-center">Comments</div>
            <div class="w-1/6 text-center">Shares</div>
          </div>
        </div>
        <div v-for="platform in enrichedMetricsData" :key="platform.platform" class="border-b border-gray-200">
          <div
            @click="toggle(platform)"
            class="flex justify-between items-center w-full text-xs cursor-pointer bg-white hover:bg-gray-100"
          >
            <div class="flex items-center w-1/6 gap-1">
              <v-icon class="ml-2 text-sm">{{ platform.open ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
              <div class="w-7 h-7 my-2">
                <img
                  v-if="platform.logo_url"
                  :src="platform.logo_url"
                  alt="Platform Logo"
                  class="rounded-full"
                />
              </div>
              <v-list-item-content>
                <v-list-item-title class="font-medium">{{ capitalize(platform.platform) }}</v-list-item-title>
              </v-list-item-content>
            </div>
            <div class="w-1/6 text-center font-bold">{{ platform.total_posts }}</div>
            <div class="w-1/6 text-center font-bold">{{ calculatePlatformEngagement(platform) }}</div>
            <div class="w-1/6 text-center font-bold">{{ platform.total_likes }}</div>
            <div class="w-1/6 text-center font-bold">{{ platform.total_comments }}</div>
            <div class="w-1/6 text-center font-bold">{{ platform.total_shares }}</div>
          </div>
          <div v-if="platform.open" class="text-xs bg-gray-50">
            <div v-for="profile in platform.profiles" :key="profile.profile_id" class="flex justify-between items-center border-b border-gray-200 -p-4">
              <div class="flex items-center w-1/6">
                <img
                  v-if="profile.profile_image_url"
                  :src="profile.profile_image_url"
                  alt="Profile Icon"
                  class="rounded-full w-6 h-6 -mr-8 ml-14"
                />
                <img
                  class="rounded-full w-6 h-6 -mr-8 ml-14"
                  v-else
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi1p8_WaFcY_wt5qn3Vd0_VpkTtyo9AFeSB0PT3iVz1A&s"
                  alt="profile"
                />
                <v-list-item-content class="pl-10 my-2">
                  <v-list-item-title>{{ profile.profile_name }}</v-list-item-title>
                </v-list-item-content>
              </div>
              <div class="w-1/6 text-center">{{ profile.posts }}</div>
              <div class="w-1/6 text-center">{{ calculateProfileEngagement(profile) }}</div>
              <div class="w-1/6 text-center">{{ profile.likes }}</div>
              <div class="w-1/6 text-center">{{ profile.comments }}</div>
              <div class="w-1/6 text-center">{{ profile.shares }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, getDocs } from "firebase/firestore";
import { db } from "@/utils/firebase.utils.js";

export default {
  name: "TotalMetrics",
  props: {
    metricsData: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      enrichedMetricsData: [],
    };
  },
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async fetchAllPlatforms() {
      const platformsRef = collection(db, "fly_platforms");
      const snapshot = await getDocs(platformsRef);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    async enrichMetricsData() {
      try {
        const allPlatforms = await this.fetchAllPlatforms();
        this.enrichedMetricsData = this.metricsData.map((platform) => {
          const foundPlatform = allPlatforms.find((p) => p.alias === platform.platform);
          return foundPlatform
            ? {
                ...platform,
                logo_url: foundPlatform.logo_url,
                open: false,
              }
            : { ...platform, open: false };
        });
      } catch (error) {
        console.error("Error enriching metrics data:", error);
      } finally {
        // Set loading to false after data enrichment is complete
        this.$emit('update:loading', false);
      }
    },
    toggle(platform) {
      platform.open = !platform.open;
    },
    calculatePlatformEngagement(platform) {
      return (
        (platform.total_comments || 0) +
        (platform.total_likes || 0) +
        (platform.total_shares || 0) +
        (platform.total_views || 0)
      );
    },
    calculateProfileEngagement(profile) {
      return (
        (profile.comments || 0) +
        (profile.likes || 0) +
        (profile.shares || 0) +
        (profile.views || 0)
      );
    },
  },
  watch: {
    metricsData: {
      handler() {
        this.enrichMetricsData();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.enrichMetricsData();
  },
};
</script>

<style scoped>
.text-xs {
  font-size: 0.75rem;
}
.text-center {
  text-align: center;
}
img {
  object-fit: cover;
}
.cursor-pointer {
  cursor: pointer;
}
.hover\:bg-gray-100:hover {
  background-color: #f7fafc;
}
.leading-tight {
  line-height: 1.25;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>
