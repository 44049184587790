import { initializeApp } from "firebase/app";
import { collection, doc, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getMessaging, onMessage } from "firebase/messaging";

// Import the appropriate config based on your environment
import { firebaseConfigUat } from "./firebaseConfigUat";
import { firebaseConfigDev } from "./firebaseConfigDev";
import { firebaseConfigProd } from "./firebaseConfigProd";

const firebaseConfig =
  process.env.VUE_APP_ENV === "production"
    ? firebaseConfigProd
    : process.env.VUE_APP_ENV === "uat"
    ? firebaseConfigUat
    : firebaseConfigDev;

const googleProjectId = process.env.VUE_APP_PROJECT_ID || "around-dev-1";
const googleRegion = process.env.VUE_APP_GCP_REGION || "asia-south1";
const vapidKey = firebaseConfig.vapidKey;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
const storage = getStorage(app);
const functions = getFunctions(app, googleRegion);

// Collections
const colPosts = collection(db, "userposts");
const colUsers = collection(db, "users");
const colScheduledPostsDelete = collection(
  db,
  "fly_scheduled_posts_delete_requests"
);
const colSocialPostsDelete = collection(
  db,
  "fly_published_posts_delete_requests"
);
const colAdmins = collection(db, "admins");
const colGallery = collection(db, "fly_gallery");
const widget_keys = collection(db, "widget_keys");
const platforms = collection(db, "fly_platforms");
const waitlist = collection(db, "fly_waitlist");
const subscriptions = collection(db, "fly_subscriptions");
const flyResources = collection(db, "fly_resources");
const flyplans = collection(db, "fly_plans");
const flyofferplans = collection(db, "fly_offer_plans");
const fly_addons = collection(db, "fly_addons");
const bb_templates = collection(db, "bb_templates");
const bb_categories = collection(db, "bb_categories");
const getWorkspaceRef = (uid) => {
  return collection(colUsers, uid, "fly_workspaces");
};
const getWorkspaceDocRef = (uid, wid) => {
  return doc(getWorkspaceRef(uid), wid);
};

const getSharedWorkspaceRef = (uid) => {
  return collection(colUsers, uid, "workspaces_granted");
};
const getSharedWorkspaceDocRef = (uid, wid) => {
  return doc(getSharedWorkspaceRef(uid), wid);
};

// Messaging
const messaging = getMessaging(app);

const isLocalHost = location.hostname === "localhost";
if (isLocalHost) {
  // connectFunctionsEmulator(functions, "localhost", "5001");
}

// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
//   // Handle foreground notifications here
//   new Notification(payload.notification.title, {
//     body: payload.notification.body,
//     icon: payload.notification.icon
//   });
// });

export {
  db,
  auth,
  colUsers,
  colPosts,
  storage,
  functions,
  platforms,
  waitlist,
  colGallery,
  isLocalHost,
  colAdmins,
  googleProjectId,
  googleRegion,
  subscriptions,
  flyResources,
  flyplans,
  flyofferplans,
  fly_addons,
  colScheduledPostsDelete,
  colSocialPostsDelete,
  messaging,
  vapidKey,
  bb_templates,
  bb_categories,
  widget_keys,
  getWorkspaceRef,
  getWorkspaceDocRef,
  getSharedWorkspaceRef,
  getSharedWorkspaceDocRef,
};
