<template>
    <div class="flex items-center md:pb-[50px] justify-center h-screen bg-[#F2F6FA]"
      style="font-family: Poppins, sans-serif">
      <div class="mt-5 md:mt-[40px] justify-center items-center">
        <div class="flex xl:w-[700px] w-full rounded-3xl shadow-[20px_20px_20px_0px_rgba(0,_0,_0,_0.1)]">
          <div
            class="flex flex-col px-4 lg:px-0 py-28   md:rounded-2xl  w-full items-center gap-2 xl:w-[585px] lg:w-[450px] border bg-white">
            <div class="flex flex-col items-center w-full gap-6">
              <div class="flex flex-col items-center gap-2 mb-px">
                <div class="flex flex-col items-center w-full gap-1">
                  <template v-if="!isOTPSent">
                    <div class="flex flex-col items-center w-full md:flex-col mb-5">
                      <a :href="getLandingPageURL()">
                        <img src="../assets/img/fly-new-logo.png" alt="Flynewlogo" id="Flynewlogo"
                          class="w-20 cursor-pointer" />
                      </a>
                      <p>Insta Everywhere</p>
                      <div>
                        <div class="text-xl font-semibold text-center lg:text-start w-full text-[#121212]">
                            Share Once, Post Everywhere
                        </div>
                        <div class="relative flex flex-row items-start justify-center w-full gap-4">
                          <!-- <div
                            class="text-xs text-[#636b8c] max-w-full text-start lg:text-start"
                          > 
                            Welcome Back! Select method to log in:
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <template v-if="isPhoneAuth">
                      <vue-tel-input-vuetify outlined label="" @input="onTelInput" hide-details
                        class="!bg-[#f2f6fa] !text-[#636b8c] rounded-xl" mode="international" v-model="phone" />
                      <div class="red--text text-caption text-left pl-5" style="width: 100%; height: 20px">
                        <template v-if="!isValidPhone && isPhoneFocused">
                          Please enter valid phone number
                        </template>
                      </div>
                      <v-btn block class="!bg-[#8056de] rounded-xl mt-3 !text-white" large @click="signInWithPhone"
                        :disabled="loading" :loading="loading && authType == 'phone'" depressed>
                        Log in
                      </v-btn>
                      <v-btn block depressed large @click="GoBack()" :disabled="loading"
                        class="rounded-xl !text-[#636B8C] !bg-[#F2F6FA] mt-1">Go Back</v-btn>
                    </template>
  
                    <!-- <div
                      class="text-slate-500 text-sm text-center w-full font-normal"
                    >
                      Or continue with social media
                    </div> -->
                    <template v-else>
                      <v-btn @click="signInWithGoogle" block large :disabled="loading" depressed
                        :loading="loading && authType == 'google'"
                        class="mt-3 rounded-xl !bg-[#f2f6fa] !text-[#636b8c] d-flex justify-start">
                        <img src="@/assets/img/google.svg" alt="Google Logo" width="30" height="30" class="mr-3" />
                        Continue with Google
                      </v-btn>
                      <!-- <v-btn @click="signInWithMicrosoft" block large :disabled="loading" depressed
                        :loading="loading && authType == 'microsoft'"
                        class="mt-3 rounded-xl !bg-[#f2f6fa] !text-[#636b8c] d-flex justify-start">
                        <img src="@/assets/img/microsoft.svg" alt="Microsoft Logo" width="25" height="25" class="mr-4" />
                        Continue with Microsoft
                      </v-btn> -->
                      <!-- <v-btn @click="signInWithFacebook" block large :disabled="loading" depressed
                        :loading="loading && authType == 'facebook'"
                        class="mt-3 rounded-xl !bg-[#f2f6fa] !text-[#636b8c] d-flex justify-start">
                        <img src="@/assets/img/home-page/facebook.svg" alt="Facebook Logo" width="30" class="mr-3"
                          height="30" />
                        Continue with Facebook
                      </v-btn> -->
                      <v-btn @click="navigateToLogin" block large :disabled="loading" depressed
                        class="mt-3 rounded-xl !bg-[#f2f6fa] !text-[#636b8c] d-flex justify-start">
                        <img src="@/assets/img/ph.png" alt="Phone Logo" width="25" height="25" class="mr-4" />
                        Continue with Phone
                      </v-btn>
                      <!-- <v-btn @click="navigateToEmailLogin" block large :disabled="loading" depressed
                        class="mt-3 rounded-xl !bg-[#f2f6fa] !text-[#636b8c] d-flex justify-start">
                        <img src="@/assets/img/email.png" alt="Phone Logo" width="25" height="25" class="mr-4" />
                        Continue with Email
                      </v-btn> -->
                      <!-- <v-btn
                      @click="signInWithApple"
                      block
                      large
                      :disabled="loading"
                      depressed
                      :loading="loading && authType == 'apple'"
                      class="mt-3 rounded-xl !bg-[#f2f6fa] !text-[#636b8c] d-flex justify-start"
                    >
                      <img
                        src="@/assets/img/apple.png"
                        alt="Apple Logo"
                        width="25"
                        height="25"
                        class="mr-4"
                      />
                      Sign in with Apple
                    </v-btn> -->
                    </template>
                  </template>
                  <template v-if="isOTPSent">
                    <div class="flex flex-col" style="width: 320px">
                      <img src="../assets/img/fly-new-logo.png" alt="Flynewlogo" id="Flynewlogo" class="w-20 -ml-4" />
                      <div class="text-xl font-semibold mt-1 text-[#121212]">
                        Enter OTP
                      </div>
                      <div class="text-xs text-[#636b8c] mt-[7px] h-8 md:w-[275px] max-w-full text-start">
                        An OTP was sent at your phone number.
                      </div>
                      <div class="flex pr-3 pb-2">
                        <div class="flex-1"></div>
                        <span v-if="countdown > 0" class="text-[#636b8c] text-xs">Resend in {{ countdown }}s</span>
                        <span v-else @click="signInWithPhone()"
                          class="text-[#8056DE] font-bold cursor-pointer text-xs">Resent OTP</span>
                      </div>
                      <v-otp-input type="number" length="6" @input="isOTPFocused = true" hide-details
                        class="w-full outline-[#8056DE] rounded-[26px]" v-model="otp"></v-otp-input>
                      <div class="red--text text-caption text-left mb-3" style="width: 100%; height: 20px">
                        <template v-if="this.otp.length != 6 && isOTPFocused">
                          Please enter valid otp
                        </template>
                      </div>
  
                      <v-btn depressed dark large @click="submitOTP()" :loading="loading"
                        class="mb-3 rounded-xl !bg-[#8056de]">Continue</v-btn>
                      <v-btn depressed large @click="goBack()" :disabled="loading"
                        class="rounded-xl !text-[#636B8C] !bg-[#F2F6FA]">Go
                        Back</v-btn>
                    </div>
                  </template>
                  <div id="recaptcha-container"></div>
                </div>
              </div>
            </div>
          </div>
  
        
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    signInWithPopup,
  } from "firebase/auth";
  import { useGtm } from "@gtm-support/vue2-gtm";
  import { auth } from "@/utils/firebase.utils";
  import authMixin from "@/mixins/auth.mixin.js";
  
  export default {
    data() {
      return {
        otp: "",
        isOTPSent: false,
        loading: false,
        isValidPhone: false,
        authType: "",
        phone: "",
        isPhoneFocused: false,
        isOTPFocused: false,
        countdown: null,
        countdownInterval: null,
        isPhoneAuth: false,
      };
    },
    mixins: [authMixin],
    watch: {
      $route() {
        this.isPhoneAuth = this.$route.name == "login-phone";
      },
    },
    methods: {
      navigateToEmailLogin() {
        const gtm = useGtm();
        gtm.trackEvent({
          event: "Continue with Email",
          category: "user",
          action: "sign-up-with-email",
          label: "Email",
          value: 0,
          noninteraction: false,
        });
        this.$router.push("/login-email");
      },
      navigateToLogin() {
        const gtm = useGtm();
        gtm.trackEvent({
          event: "Continue with Phone",
          category: "user",
          action: "sign-up-with-phone",
          label: "Phone",
          value: 0,
          noninteraction: false,
        });
        this.$router.push("/login-phone");
      },
      GoBack() {
        this.$router.push("/login");
      },
      onTelInput(e, { valid }) {
        this.isPhoneFocused = true;
        this.isValidPhone = valid;
      },
      signInWithApple() {
        const provider = new OAuthProvider("apple.com");
        provider.addScope("email");
        provider.addScope("name");
        this.authType = "apple";
        console.log("apple");
        this.$mixpanel.track("Continue with Apple");
        const gtm = useGtm();
        gtm.trackEvent({
          event: "Continue with Apple",
          category: "user",
          action: "sign-up-with-apple",
          label: "Apple",
          value: 0,
          noninteraction: false,
        });
        this.signInWithProvider(provider);
      },
      signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        this.authType = "google";
        this.$mixpanel.track("Continue with Google");
        const gtm = useGtm();
        gtm.trackEvent({
          event: "Continue with Google",
          category: "user",
          action: "sign-up-with-google",
          label: "Google",
          value: 0,
          noninteraction: false,
        });
        this.signInWithProvider(provider);
      },
      signInWithMicrosoft() {
        const provider = new OAuthProvider("microsoft.com");
        this.authType = "microsoft";
        this.$mixpanel.track("Continue with Microsoft");
        const gtm = useGtm();
        gtm.trackEvent({
          event: "Continue with Microsoft",
          category: "user",
          action: "sign-up-with-microsoft",
          label: "Microsoft",
          value: 0,
          noninteraction: false,
        });
        this.signInWithProvider(provider);
      },
      signInWithFacebook() {
        const provider = new FacebookAuthProvider();
        this.authType = "facebook";
        this.$mixpanel.track("Continue with Facebook");
        const gtm = useGtm();
        gtm.trackEvent({
          event: "Continue with Facebook",
          category: "user",
          action: "sign-up-with-facebook",
          label: "Facebook",
          value: 0,
          noninteraction: false,
        });
        this.signInWithProvider(provider);
      },
      startCountdown() {
        if (this.countdownInterval) {
          clearInterval(this.countdownInterval);
        }
        this.countdown = 60;
        this.countdownInterval = setInterval(() => {
          this.countdown--;
          if (this.countdown <= 0) {
            clearInterval(this.countdownInterval);
            this.countdown = null; // Set countdown to null when it's finished
          }
        }, 1000);
      },
      goBack() {
        this.phone = "";
        this.otp = "";
        this.isOTPFocused = false;
        this.isPhoneFocused = false;
        this.countdown = null;
        this.isOTPSent = false;
        if (this.countdownInterval) {
          clearInterval(this.countdownInterval);
        }
      },
      async submitOTP() {
        const vm = this;
        if (vm.otp.length != 6) return;
        let result = window.confirmationResult;
        try {
          vm.loading = true;
          let { user } = await result.confirm(vm.otp);
          
          await vm.completeSignup(user);
          vm.$mixpanel.identify(user.phoneNumber);
          vm.$mixpanel.track("Login with Phone - Completed");
          if (!this.authUser) {
            // Redirect to the calendar page after successful sign-up
            this.$router.push('/calendar');
          }
          vm.loading = false;
        } catch (e) {
          console.error(e);
          let message = this.getErrorMessage(e);
          this.$alert.show(message);
          vm.loading = false;
        }
      },
      async signInWithPhone() {
        this.isPhoneFocused = true;
        if (this.loading) return;
        if (!this.isValidPhone) return;
        const phoneWithCode = this.phone.toString().trim().split(" ").join("");
        var appVerifier = window.recaptchaVerifier;
        this.loading = true;
        try {
          this.loading = true;
          this.otp = "";
          this.authType = "phone";
          let result = await signInWithPhoneNumber(
            auth,
            phoneWithCode,
            appVerifier
          );
          this.loading = false;
          window.confirmationResult = result;
          this.isOTPSent = true;
          this.startCountdown();
          this.$mixpanel.track("Login OTP Sent");
        } catch (error) {
          this.loading = false;
          this.$alert.show(error.message);
        }
      },
      async signInWithProvider(provider) {
        try {
          this.loading = true;
          let user = (await signInWithPopup(auth, provider)).user;
          console.log(user);
          await this.completeSignup(user);
          this.loading = false;
        } catch (error) {
          console.error(error);
          this.loading = false;
          let message = this.getErrorMessage(error);
          this.$alert.show(message);
        }
      },
  
      initCaptcha() {
        this.loading = true;
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function (response) {
              console.log(response);
            },
          },
          auth
        );
        window.recaptchaVerifier.render().then((widgetId) => {
          window.recaptchaWidgetId = widgetId;
          this.loading = false;
        });
  
        // }
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.initCaptcha();
      });
    },
    beforeMount() {
      if(this.$route.query){
        sessionStorage.setItem("ref_code",JSON.stringify(this.$route.query));
      }
      this.isPhoneAuth = this.$route.name == "login-phone";
    },
  };
  </script>
  
  <style lang="scss">
  .vue-tel-input-vuetify {
    width: 100%;
  
    .country-code {
      width: 44px !important;
      padding-left: 10px !important;
    }
  
    .v-text-field__details {
      display: none;
    }
  
    .v-input__slot {
      min-height: 44px !important;
    }
  
    .v-input__slot fieldset {
      border: 0px !important;
    }
  
    .v-select {
      .v-input__slot {
        margin-bottom: 0px !important;
      }
    }
  }
  </style>
  