<template>
    <div>
      <v-select
        v-model="input"
        :items="items"
        :dense="dense"
        item-value="value"
        item-text="name"
       :label="label"
        :multiple="multiple"
        :class="label ? 'mt-1' : ''"
        append-icon="mdi-chevron-down"
        @input="$emit('input', input)"
        @blur="$emit('blur')"
        v-bind="$attrs"
        :rules="getRules"
        class="  w-full relative pr-2 rounded bg-gray-100 box-border h-[46px] border-[1px] border-solid border-stroke pl-2 pt-2"
      >
        <template v-slot:item="{ item }">
          <div class="justify-between w-full d-flex align-center">
            <span v-if="item.name || item.user_name || item.user_id">{{
              item.name
                ? item.name
                : item.user_name
                ? item.user_name
                : item.user_id
            }}</span>
            <span v-else>{{ item }}</span>
            <span
              v-if="item.account_type"
              class="ml-2 font-semibold text-blue-600 capitalize"
              >{{ item.account_type }}
              <v-btn icon small @click.stop="editAccountType(item)" class="ml-1">
                <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>
            </span>
          </div>
        </template>
        <template v-slot:selection="{ item, index }" v-if="isMultiple">
          <template v-if="isObject(item)">
            <template v-if="index === 0">{{
              item.name || item.user_name || item.user_id
            }}</template>
            <span v-if="index === 1" class="grey--text caption">
              (+{{ value.length - 1 }} others)
            </span>
          </template>
          <template v-else>
            <template v-if="index === 0">{{ item }}</template>
            <span v-if="index === 1" class="grey--text caption">
              (+{{ value.length - 1 }} others)
            </span>
          </template>
        </template>
        <div slot="append" class="d-flex">
          <v-icon> mdi-chevron-down</v-icon>
          <v-tooltip bottom v-if="help">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="" x-small>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ help }}</span>
          </v-tooltip>
        </div>
      </v-select>
    </div>
  </template>
  
  <script>
  import inputMixin from "@/mixins/input.mixin.js";
  export default {
    props: {
      value: {
        type: [String, Array, Number],
        default: "",
      },
      multiple: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        input: "",
      };
    },
    mixins: [inputMixin],
    computed: {
      isMultiple() {
        return this.input instanceof Array;
      },
    },
    methods: {
      isObject(item) {
        return item instanceof Object;
      },
      editAccountType(user) {
        this.$emit("editAccountType", user);
      },
    },
  };
  </script>
  
  <style></style>
  