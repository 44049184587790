<template>
  <div></div>
</template>
  
  <script>
export default {
  mounted() {
  
    window.close();
  },
};
</script>
  
  <style>
</style>