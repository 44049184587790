<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px" scrollable persistent>
      <template v-slot:activator="{ on }">
        
        <v-list-item v-on="on" class="w-[293px] h-12 bg-slate-100 rounded-3xl"> <img
              src="@/assets/img/webai.svg"
              :width="isSmallAndUp ? '12px' : '10px'"
              :height="isSmallAndUp ? '12px' : '10px'"
              max-height="50%"
              class="mr-2"
            /> Generate post for a web page </v-list-item>
      </template>
      <ai-dialog
        title="Enter web page url below"
        submitText="Generate"
        @close="handleDialogClose()"
        :loading="loading"
        @submit="postFromURL()"
        v-if="currentUser.fly_ai_text_credits > 0"
      >
        <!-- <input-field
          rules="required"
          v-model="url"
          placeholder="Enter web page url"
        /> -->
        <ai-input  rules="required"
          v-model="url"
          placeholder="Web Page url..."/>
        <div v-if="detail">
          <div>
            <v-btn class="primary my-1" depressed @click="applyContent()"
              >Apply Content</v-btn
            >
          </div>
        </div>
      </ai-dialog>
      <v-card class="pa-2" v-else>
        <v-card-title>
          You have run out of Fly AI Writer credits. Please renew or upgrade to
          <br />
          proceed.
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="upgradeAndCloseDialog">Upgrade</v-btn>
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="outputDialog" max-width="800px" scrollable persistent>
      <ai-dialog
        title="AI generated post"
        submitText="Apply Content"
        @close="handleOutputDialogClose()"
        :loading="loading"
        @submit="applyContent()"
        v-if="currentUser.fly_ai_text_credits > 0"
      >
        <v-card v-if="detail" class="p-3 my-2">
          <v-card-subtitle>
            <div class="body-2 mb-1">{{ detail.title }}</div>
          </v-card-subtitle>
          <div v-if="detail.image" class="px-3">
            <img
              :src="detail.image"
              v-if="detail.image"
              style="
                height: 300px;
                object-fit: contain;
                width: 100%;
                background: black;
              "
            />
          </div>
          <v-card-text>
            <div class="caption mt-1">{{ detail.description }}</div>
            <div class="d-flex align-center gap-1">
              <v-checkbox v-model="addedUrl" class="" /><span
                @click="toggleUrlCheckbox"
                >Add URL to post</span
              >
            </div>
          </v-card-text>
        </v-card>
      </ai-dialog>
    </v-dialog>
  </div>
</template>

<script>
import rulesConstants from "@/assets/constants/rules.constants";
import DialogCard from "../ui/DialogCard.vue";
import openaiMixin from "@/mixins/openai.mixin";
import InputField from "../ui/form/InputField.vue";
import AiInput from "../ui/form/AiInput.vue";
import AiDialog from '@/components/ui/AiDialog.vue'
export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { DialogCard, InputField,AiInput,AiDialog },
  data() {
    return {
      dialog: false,
      outputDialog: false,
      loading: false,
      rules: [rulesConstants.required],
      url: "",
      detail: null,
      addedUrl: true,
    };
  },
  mixins: [openaiMixin],
  methods: {
    upgradeAndCloseDialog() {
      this.dialog = false; // Close the current dialog
      // Navigate to the subscription page
      this.$router.push("/subscription");
    },
    closeDialog() {
      this.dialog = false;
    },
    async applyContent() {
      this.$mixpanel.track("Shorthand1 Done");
      const vm = this;
      vm.outputDialog = true;

      if (vm.addedUrl && vm.detail.description) {
        vm.detail.description += `\n${vm.url}`;
      }
      vm.url = "";
      vm.dialog = false;
      vm.$emit("updatePostContent", vm.detail);
      vm.detail = null;
    },
    handleDialogClose() {
      this.url = "";
      this.dialog = false;
      this.detail = null;
    },
    handleOutputDialogClose() {
      this.url = "";
      this.detail = null;
      this.outputDialog = false;
    },
    toggleUrlCheckbox() {
      this.addedUrl = !this.addedUrl;
    },
    trackMixpanel() {
      this.$mixpanel.track("Shorthand1 Initiated");
    }
  },
  mounted() {
    // Call the trackMixpanel method when the component is mounted
    this.trackMixpanel();
  }

};
</script>

<style></style>
