<template>
  <div style="font-family: 'Inter', sans-serif">
    <please-wait v-if="isLoading" class="mt-5" />
    <section class="bg-white" v-else>
      <div class="">
        <div class="mt-10 sm:flex sm:items-center sm:justify-between">
          <div class="w-full">
            <h2
              class="text-2xl font-bold text-center text-transparent text-gray-800 lg:text-3xl bg-clip-text bg-gradient-to-r from-pink-500 to-blue-800"
            >
              Sign Up Package!
            </h2>
          </div>
        </div>
        <section
          class="flex flex-wrap justify-center gap-4 pb-10 mt-10 xl:gap-1 md:mt-12"
        >
          <div
            v-for="(plan, index) in plans"
            :key="index"
            class="p-4 md:p-2 border border-gray-200 rounded-2xl md:w-[300px]"
            :class="
              isActivePlan(plan, userData)
                ? 'bg-gray-700'
                : 'hover:bg-gray-200 border'
            "
          >
            <p
              class="text-lg font-bold"
              :class="
                isActivePlan(plan, userData) ? 'text-gray-100' : 'text-gray-800'
              "
            >
              {{ plan.name }}
            </p>

            <h1
              class="mt-4 text-sm text-[#59626F]"
              :class="
                isActivePlan(plan, userData) ? 'text-gray-100' : 'text-gray-800'
              "
            >
              {{ plan.short_desc }}
            </h1>
            <h4
              class="mt-2 text-3xl font-semibold"
              :class="
                isActivePlan(plan, userData)
                  ? 'text-gray-100 '
                  : 'text-gray-800'
              "
            >
              <template>
                ₹ {{ plan.first_time_price_inr }} for 3 months
              </template>
            </h4>
            <p class="text-xs" :class="isActivePlan(plan, userData) ? 'text-gray-100' : 'text-gray-800'">₹ {{ plan.monthly_price_inr }} / month afterwards</p>
            <div
              v-if="isActivePlan(plan, userData)"
              class=""
              :class="plan.name === 'Free' ? '' : 'hidden'"
            >
              <button
                class="w-full px-3 py-2 mt-6 mb-8 text-sm font-bold text-black bg-white rounded-md"
              >
                Free
              </button>
            </div>

            <div
              v-if="isActivePlan(plan, userData)"
              class=""
              :class="plan.name === 'Free' ? 'hidden' : ''"
            >
              <button
                class="w-full px-3 py-2 mt-4 mb-2 text-sm font-bold text-white bg-green-500 rounded-md"
              >
                <template v-if="userData.fly_plan_valid_until">
                  Plan valid until -
                  {{
                    userData.fly_plan_valid_until === null ||
                    userData.fly_plan_valid_until === undefined
                      ? ""
                      : new Date(
                          userData.fly_plan_valid_until.seconds * 1000
                        ).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })
                  }}
                </template>
                <span v-else> Subscribed </span>
              </button>
            </div>
            <div v-else>
              <v-btn
                :disabled="disableOffer"
                @click="plan.name === 'Free' ? null : subscribe(plan)"
                color="#8056DE"
                class="mb-8 rounded-md py-2 px-3 w-full text-white text-sm font-bold mt-6"
                :class="plan.name === 'Free' ? 'hidden' : ''"
              >
              <span class="text-white">
                Choose plan
              </span>
              </v-btn>
            </div>
            <div
              v-if="isActivePlan(plan, userData)"
              class="flex items-center justify-center"
              :class="plan.name === 'Free' ? 'hidden' : ''"
              @click="cancelSubscription"
            >
              <button
                v-if="!cancelLoading"
                :class="userData.fly_plan_valid_until == null ? '' : 'hidden'"
                class="w-full px-3 py-2 mb-8 text-sm font-medium text-white transition-colors duration-300 transform rounded-md"
              >
                Cancel
              </button>
              <img
                v-else
                src="../../assets/img/cancel-loading.gif"
                class="w-8"
                alt=""
              />
            </div>
            <div
              v-for="features in plan.features"
              :key="features"
              class="flex items-center mb-3"
            >
              <v-icon color="#8056DE">mdi-check</v-icon>

              <span
                class="mx-4"
                :class="
                  isActivePlan(plan, userData)
                    ? 'text-gray-300'
                    : 'text-gray-700'
                "
              >
                {{ features }}</span
              >
            </div>
          </div>
        </section>
      </div>
    </section>

    <v-dialog v-model="showEmailDialog" persistent max-width="400px">
      <dialog-card
        title="We need your email address to proceed"
        submitText="Continue"
        @close="showEmailDialog = false"
        @submit="saveUserEmailAddress()"
      >
        <v-row>
          <v-col cols="12" class="pb-0">
            <input-field
              placeholder="Your email address"
              v-model="emailAddress"
              rules="email"
            />
          </v-col>
        </v-row>
      </dialog-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  limit,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import PleaseWait from "./PleaseWait.vue";
import { colUsers, db, fly_addons, functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import _ from "lodash";
import DialogCard from "@/components/ui/DialogCard.vue";
import InputField from "@/components/ui/form/InputField.vue";
export default {
  components: { PleaseWait, DialogCard, InputField },
  data() {
    return {
      addons: [],
      userData: {},
      cancelLoading: false,
      dialog: false,
      plans: [],
      currentSubscription: {},
      isLoading: true,
      fly_plan_valid_until: null,
      selectedPlan: null,
      addonForm: {},
      activeAddons: [],
      activeAddonForm: {},
      showEmailDialog: false,
      emailAddress: "",
      plan: null,
    };
  },
  computed: {
    disableOffer() {
      let user_created_time = this.authUser.created_time.seconds
        ? this.authUser.created_time.seconds * 1000
        : this.authUser.created_time;
      const currentDate = new Date();
      user_created_time = new Date(user_created_time);

      // Calculate the difference in time between the current date and the user created date
      const dayDifference =
        (currentDate.getTime() - user_created_time.getTime()) /
        (1000 * 3600 * 24);
      // Check if the difference is greater than 30 days
      if (dayDifference > 30) {
        return true;
      }

      if (this.authUser.offer_availed) {
        return true;
      }
      return false;
    },
  },
  methods: {
    isActivePlan(plan, userData) {
      // let { status, plan_id } = this.currentSubscription;
      if (this.authUser.fly_plan == "free" && plan.name == "Free") {
        return true;
      }
      return plan.id == userData.fly_plan && userData.signup_offer_subscribed;
    },

    async initPlan() {
      const vm = this;
      try {
        vm.isLoading = true;
        let userRef = doc(db, "users", vm.uid);
        let user = (await getDoc(userRef)).data();

        vm.userData = user;
        let planQuery = query(collection(db, "fly_offer_plans"), orderBy("monthly_price_inr", "asc"));
        vm.plans = (await getDocs(planQuery)).docs.map((i) => ({
          ...i.data(),
          id: i.id,
        }));
        let subscriptionRef = doc(collection(db, "fly_subscriptions"), vm.uid);
        vm.currentSubscription = (await getDoc(subscriptionRef)).data() || {};
        // console.log("sub", vm.currentSubscription.plan_id);
        vm.isLoading = false;
      } catch (error) {
        console.log(error);
        vm.handleError(error);
      }
    },

    calculateDiscountPrice({ monthly_price_inr }) {
      let discount = monthly_price_inr * 0.3;
      return Math.round(monthly_price_inr - discount);
    },

    async saveUserEmailAddress() {
      const vm = this;
      try {
        let userRef = doc(db, "users", vm.uid);
        await updateDoc(userRef, {
          email: vm.emailAddress,
        });
        vm.showEmailDialog = false;
        await this.subscribePlan(this.plan);
      } catch (error) {
        vm.handleError(error);
      }
    },

    async subscribe(plan) {
      const vm = this;
      vm.plan = plan;
      let userRef = doc(db, "users", vm.uid);
      let user = (await getDoc(userRef)).data();
      if (!user.email || user.email == undefined) {
        vm.showEmailDialog = true;
        // vm.$alert.show("Please update your email address in your profile to proceed with subscription.");
        return;
      }
      vm.$confirm.show({
        message: `Are you sure you wish to subscribe to ${plan.name} package?`,
        onConfirm: async () => {
          await this.subscribePlan(plan);
        },
      });
    },
    async subscribePlan(plan) {
      const vm = this;
      try {
        vm.$loader.show();
        let data = { plan: plan.id, offer: true };

        let subscribe = httpsCallable(functions, "razorpaySubscribe");
        let result = (await subscribe(data)).data;
        if (result.status == "authenticate_payment") {
          vm.openRazorPay(result.subscription_id);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.$loader.hide();
        vm.handleError(error);
      }
    },
    openRazorPay(id) {
      const vm = this;
      var options = {
        key: process.env.VUE_APP_RP_KEY,
        subscription_id: id,
        name: "ZoomLocal",
        description: "Monthly Plan",
        handler: function (response) {
          vm.handlePayment(response);
          console.log(JSON.stringify(response));
          // Redirect to /account page after successful payment
          vm.$router.push("/account"); // If using Vue Router
          // Or use window.location for a hard redirect:
          // window.location.href = '/account';
        },
        prefill: {
          name: this.authUser.display_name,
          email: this.authUser.email,
          //contact: this.authUser.phone_number,
        },
        theme: {
          color: "#1773EA",
        },
      };

      // Code to open Razorpay payment modal
      var rzp = new Razorpay(options);
      rzp.open();
    },
    async handlePayment(json) {
      const vm = this;
      try {
        vm.$loader.show();
        let userRef = doc(db, "users", vm.uid);
        let subscribe = httpsCallable(functions, "validateRazorpayPayment");
        (await subscribe(json)).data;
        await updateDoc(userRef, {
          fly_subscription_cancelled: false,
        });
        this.initPlan();
        vm.$loader.hide();
      } catch (error) {
        vm.handleError(error);
      }
    },

    isIndianPhoneNumber() {
      return this.userData.country_code && this.userData.country_code === "IN";
    },

    sleep(s) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, s || 1000);
      });
    },

    async cancelSubscription() {
      this.$confirm.show({
        message: "Are you sure you want to cancel?",
        onConfirm: async () => {
          try {
            let userRef = doc(db, "users", this.uid);
            this.cancelLoading = true;
            await updateDoc(userRef, {
              fly_subscription_cancelled: true,
            });
            let subscriptionRef = doc(
              collection(db, "fly_subscriptions"),
              this.uid
            );
            let currentSubscription =
              (await getDoc(subscriptionRef)).data() || {};
            console.log(currentSubscription);
            console.log(
              "currentSubscription:",
              currentSubscription.subscription_id
            );

            let data = { subscription: currentSubscription.subscription_id };
            let subscribe = httpsCallable(functions, "razorpaySubscribe");
            (await subscribe(data)).data;

            location.reload();
            this.cancelLoading = false;
          } catch (error) {
            this.cancelLoading = false;
            this.$alert.show(error.message);
          }
        },
      });
    },
  },
  mounted() {
    this.initPlan();
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.addon-input {
  .v-input__slot {
    background: #f2f6fa !important;
    border-radius: 50px !important;
  }
}
</style>
