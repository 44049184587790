<template>
  <div class="profile-upload">
    <img :src="image" v-if="image" @click="openImageInput" />
    <input type="file" accept="image/*" @change="handleImageUpload" ref="imageInput" style="display: none" />
    <v-btn icon tile class="profile-btn primary" dark @click="openImageInput">
      <v-icon small>mdi-camera</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: String, // Assuming the value is a URL of the image
  },
  data() {
    return {
      image: "",
    };
  },
  watch: {
    value(val) {
      this.image = val;
    },
  },
  methods: {
    openImageInput() {
      this.$refs.imageInput.click();
    },
    handleImageUpload() {
      const input = this.$refs.imageInput;
      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.image = e.target.result;
          this.$emit("input", this.image);
        };

        reader.readAsDataURL(input.files[0]);
      }
    },
  },
  mounted() {
    this.image = this.value;
  },
};
</script>

<style lang="scss">
.profile-upload {
  height: 150px;
  width: 150px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 100px 100px;
  background-position: center;
  border-radius: 100px;
  border: 5px solid rgba(206, 194, 194, 0.5);
  position: relative;
  background-color: rgba(254, 247, 247, 0.2);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  img {
    background: lightgray;
    border-radius: 100px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profile-btn {
  position: absolute;
  z-index: 1;
  bottom: 1px;
  right: 1px;
}

</style>