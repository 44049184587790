<template>
  <div ref="div"></div>
</template>

<script>
import imageOverlayMixin from "@/mixins/image.overlay.mixin.js";

export default {
  props: {
    id: {
      type: String,
      default: "container",
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    url: {
      type: String,
      default: "",
    },
    highlights: {
      type: Array,
      default: () => [],
    },
    logoUrl: {
      type: String,
      default: "",
    },
  },
  mixins: [imageOverlayMixin],
  mounted() {
    this.getOverlay({
      container: this.$refs.div,
      logoUrl: this.logoUrl,
      form: this.form,
      highlights: this.highlights,
      url: this.url,
    });
  },
};
</script>

<style></style>
