<template>
  <v-menu offset-y v-model="menu">
    <template v-slot:activator="{ on }">
      <v-btn :icon="!isSmallAndUp" :outlined="isSmallAndUp"  color="#8056DE" v-on="on" >
          <img src="../../assets/img/vista-logo.png" width="20px" :class="{'mr-2': $vuetify.breakpoint.smAndUp}" />
        <span class="d-none d-sm-flex" v-if="btnText">
          {{ btnText }}
        </span>
      </v-btn>
    </template>
    <v-list class="py-0" dense>
      <v-list-item @click="openVistaCreateEditor('facebookSM')"
        >Facebook Post</v-list-item
      >
      <v-list-item @click="openVistaCreateEditor('instagramSM')"
        >Instagram Post</v-list-item
      >
      <v-list-item @click="openVistaCreateEditor('twitterSM')"
        >Twitter Post</v-list-item
      >
      <!-- nopt working linkedinSM, linkedinPost -->
      <!-- <v-list-item @click="openVistaCreateEditor('linkedinSM')">Linkedin Post</v-list-item>  -->
      <v-list-item @click="openVistaCreateEditor('pinterestSM')"
        >Pinterest Post</v-list-item
      >
      <!-- nopt working tumblrpost -->
      <!-- nopt working redditSM, redditpost -->
      <v-list-item @click="openVistaCreateEditor('tumblrSM')"
        >Tumblr Post</v-list-item
      >
      <!-- <v-list-item @click="openVistaCreateEditor('redditSM')">Reddit Post</v-list-item> -->
      <v-list-item @click="openVistaCreateEditor('facebookVideoStoryAN')"
        >Facebook Video Story</v-list-item
      >
      <v-list-item @click="openVistaCreateEditor('instagramVideoStoryAN')"
        >Instagram Video Story</v-list-item
      >
    </v-list>
  </v-menu>
</template>

<script>
import { getDate } from "@/utils/common.utils";
import {
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import {
  colGallery,
  storage,
} from "@/utils/firebase.utils";
import {
  doc,
  setDoc,
} from "firebase/firestore";

export default {
  data() {
    return {
      menu: false,
    };
  },
  props: {
    btnText: {
      type: String,
      default: "",
    },
  },
  methods: {
    async openVistaCreateEditor(designtype) {
      // console.log("url: ", url)
      let assetUrl = null;
      // Initialize VistaCreate Editor
      const vistaCreateApi = await window.VistaCreateEditor.init({
        apiKey: "7W1QSZ7-6ZEMD13-PGF4YZF-CC94SAR",
        designType: designtype,
        sidebarDesignType: designtype,
        onPublishAction: async (output) => {
          // console.log("VistaCreate Editor completed:", output);
          assetUrl = output.url;
          const extension = output.extension;
          const designName = output.projectName;
          let type = "";
          if (extension && extension.toLowerCase() === "mp4") {
            type = "video";
          } else if (
            extension &&
            (extension.toLowerCase() === "jpg" ||
              extension.toLowerCase() === "jpeg" ||
              extension.toLowerCase() === "png" ||
              extension.toLowerCase() === "gif")
          ) {
            type = "image";
          }

          let id = new Date().getTime().toString();

          const response = await fetch(assetUrl);
          const blob = await response.blob();
          let data = {
            created_at: getDate(),
            updated_at: getDate(),
            type: type,
            size: blob.size,
            uid: this.currentUID,
            name: designName,
            json: {},
          };
          let fileExtension = type === "image" ? ".png" : ".mp4";
          let storageRef = ref(
            storage,
            `gallery/${this.currentUID}/${id}${fileExtension}`
          );
          await uploadBytes(storageRef, blob);
          data.url = await getDownloadURL(storageRef);
          data.thumbnail = data.url;
          await setDoc(doc(colGallery, id), data);
          this.$snackbar.show(`${type} saved successfully!`);
          this.$emit("selectedVistaAsset", { ...data, id });

        },
      });
    },
  },
};
</script>

<style></style>
