<template>
      <v-container style="max-width: 500px">
      <div>
        <div align="center" style="text-align: left; line-height: 1">
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div
              align="center"
              class="MsoNormal"
              style="text-align: center; line-height: 150%"
            >
              <a name="_2cipo4yr3w5d" id="_2cipo4yr3w5d"></a>
              <div align="center" class="MsoNormal" style="line-height: 22.5px">
                <div align="center" class="MsoNormal" style="line-height: 150%">
                  <a name="_gm5sejt4p02f" id="_gm5sejt4p02f"></a>
                  <div
                    align="center"
                    class="MsoNormal"
                    data-custom-class="title"
                    style="text-align: left; line-height: 1.5"
                  >
                    <strong
                      ><span style="line-height: 22.5px; font-size: 26px"
                        >TERMS AND CONDITIONS</span
                      ></strong
                    >
                  </div>
                  <div
                    align="center"
                    class="MsoNormal"
                    style="line-height: 22.5px; text-align: left"
                  >
                    <a name="_7m5b3xg56u7y" id="_7m5b3xg56u7y"></a>
                  </div>
                  <div
                    align="center"
                    class="MsoNormal"
                    data-custom-class="subtitle"
                    style="text-align: left; line-height: 22.5px"
                  >
                    <br />
                  </div>
                  <div
                    align="center"
                    class="MsoNormal"
                    data-custom-class="subtitle"
                    style="text-align: left; line-height: 1.5"
                  >
                    <span
                      style="
                        color: rgb(89, 89, 89);
                        font-size: 14.6667px;
                        text-align: justify;
                      "
                      ><strong>Last updated Sep 27, 2023</strong></span
                    >
                  </div>
                </div>
              </div>
              <div
                align="center"
                class="MsoNormal"
                style="line-height: 17.25px; text-align: left"
              >
                <br />
              </div>
              <div
                align="center"
                class="MsoNormal"
                style="line-height: 17.25px; text-align: left"
              >
                <br />
              </div>
              <div
                align="center"
                class="MsoNormal"
                style="line-height: 17.25px; text-align: left"
              >
                <br />
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(127, 127, 127)"
                    ><span style="color: rgb(0, 0, 0)"
                      ><strong
                        ><span data-custom-class="heading_1"
                          >TABLE OF CONTENTS</span
                        ></strong
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <br />
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#agreement"
                    ><span style="color: rgb(89, 89, 89)"
                      >1. AGREEMENT TO TERMS</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#ip"
                    ><span style="color: rgb(89, 89, 89)"
                      >2. INTELLECTUAL PROPERTY RIGHTS</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><a data-custom-class="link" href="#userreps"
                      >3. USER REPRESENTATIONS</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#userreg"
                    ><span style="color: rgb(89, 89, 89)"
                      >4. USER REGISTRATION</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#prohibited"
                    ><span style="color: rgb(89, 89, 89)"
                      >5. PROHIBITED ACTIVITIES</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#ugc"
                    ><span style="color: rgb(89, 89, 89)"
                      >6. USER GENERATED CONTRIBUTIONS</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><a data-custom-class="link" href="#license"
                      >7. CONTRIBUTION LICENSE</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#reviews"
                    ><span style="color: rgb(89, 89, 89)"
                      >8. GUIDELINES FOR REVIEWS</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#mobile"
                    ><span style="color: rgb(89, 89, 89)"
                      >9. MOBILE APPLICATION LICENSE</span
                    ></a
                  >
                  <a data-custom-class="link" href="#agreement"></a
                ></span>
              </div>
              <div align="center" style="text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><a data-custom-class="link" href="#socialmedia"
                      >10. SOCIAL MEDIA</a
                    >
                    <a data-custom-class="link" href="#agreement"></a></span
                ></span>
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><a data-custom-class="link" href="#submissions"
                      >11. SUBMISSIONS</a
                    ></span
                  ></span
                >
              </div>
              <div align="center" style="text-align: left"></div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#advertisers"
                    ><span style="color: rgb(89, 89, 89)"
                      >12. ADVERTISERS</span
                    ></a
                  >
                  <a data-custom-class="link" href="#agreement"></a
                ></span>
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><a data-custom-class="link" href="#sitemanage"
                      >13. SITE MANAGEMENT</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#privacypolicy1"
                    ><span style="color: rgb(89, 89, 89)"
                      >14. PRIVACY POLICY</span
                    ></a
                  >
                  <a data-custom-class="link" href="#advertisers"></a>
                  <a data-custom-class="link" href="#agreement"></a
                ></span>
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#dmca"></a
                ></span>
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#copyright1"></a
                  ><a data-custom-class="link" href="#copyright3"
                    ><span style="color: rgb(89, 89, 89)"
                      >15. COPYRIGHT INFRINGEMENTS</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#terms"></a
                  ><a data-custom-class="link" href="#terms"
                    ><span style="color: rgb(89, 89, 89)"
                      >16. TERM AND TERMINATION</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#modifications"
                    ><span style="color: rgb(89, 89, 89)"
                      >17. MODIFICATIONS AND INTERRUPTIONS</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#law"
                    ><span style="color: rgb(89, 89, 89)"
                      >18. GOVERNING LAW</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#disputes"
                    ><span style="color: rgb(89, 89, 89)"
                      >19. DISPUTE RESOLUTION</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><a data-custom-class="link" href="#corrections"
                      >20. CORRECTIONS</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#disclaimer"
                    ><span style="color: rgb(89, 89, 89)">21. DISCLAIMER</span></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#liability"
                    ><span style="color: rgb(89, 89, 89)"
                      >22. LIMITATIONS OF LIABILITY</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><a data-custom-class="link" href="#indemnification"
                      >23. INDEMNIFICATION</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#userdata"
                    ><span style="color: rgb(89, 89, 89)">24. USER DATA</span></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#electronic"
                    ><span style="color: rgb(89, 89, 89)"
                      >25. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                      SIGNATURES</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#third"
                    ><span style="color: rgb(89, 89, 89)"
                      >26. THIRD PARTY SERVICE PROVIDERS</span
                    ></a
                  ></span
                >
              </div>
              <div
                class="MsoNormal"
                style="line-height: 1.5; text-align: left"
              ></div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><a data-custom-class="link" href="#misc"
                    ><span style="color: rgb(89, 89, 89)"
                      >27. MISCELLANEOUS</span
                    ></a
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><a data-custom-class="link" href="#refund"
                      >28. REFUND POLICY</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><a data-custom-class="link" href="#shipping"
                      >29. SHIPPING POLICY IS NOT APPLICABLE TO THIS PRODUCT</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><a data-custom-class="link" href="#contact"
                      >30. CONTACT US</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89); text-transform: uppercase;"
                    ><a data-custom-class="link" href="#cancellation"
                      >31. Cancellation Policy</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left; margin-left: 14px">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89); text-transform: uppercase;"
                    ><a data-custom-class="link" href="#cancellation1"
                      >31.1. Cancellation of Service</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left; margin-left: 14px">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89); text-transform: uppercase;"
                    ><a data-custom-class="link" href="#cancellation2"
                      >31.2. Effective Date of Cancellation</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left; margin-left: 14px">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89); text-transform: uppercase;"
                    ><a data-custom-class="link" href="#cancellation3"
                      >31.3. No Refunds</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left; margin-left: 14px">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89); text-transform: uppercase;"
                    ><a data-custom-class="link" href="#cancellation4"
                      >31.4. Future Renewals</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left; margin-left: 14px">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89); text-transform: uppercase;"
                    ><a data-custom-class="link" href="#cancellation5"
                      >31.5. Reactivation</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left; margin-left: 14px">
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89); text-transform: uppercase;"
                    ><a data-custom-class="link" href="#cancellation6"
                      >31.6. Changes to Policy</a
                    ></span
                  ></span
                >
              </div>
              <div style="line-height: 1.5; text-align: left">
                <br />
              </div>
              <div style="line-height: 1.5; text-align: left">
                <br />
              </div>
            </div>
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="agreement"
              style="line-height: 115%"
            >
              <a name="_a7mwfgcrtsqn" id="_a7mwfgcrtsqn"></a
              ><strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  >1. AGREEMENT TO TERMS</span
                ></strong
              >
            </div>
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 1"
          >
            <br />
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span
                style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                >These Terms of Use constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity (“you”)
                and fly-social.com (" <strong>Company</strong> ,"
                “<strong>we</strong>," “<strong>us</strong>," or
                “<strong>our</strong>”), concerning your access to and use of the
                <a
                  href="https://fly-social.com"
                  target="_blank"
                  data-custom-class="link"
                  >https://fly-social.com</a
                >
                website as well as any other media form, media channel, mobile
                website or mobile application related, linked, or otherwise
                connected thereto (collectively, the “Site”).
                <span
                  style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                  ><span
                    style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                    >We are registered in
                    <span
                      style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                      ><span
                        style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                        >India</span
                      ></span
                    >
                    and have our registered office at A-806, Sapphire Park, Park
                    Street, Wakad</span
                  ></span
                >
                , Pune</span
              >
              <span style="font-size: 15px"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><span style="color: rgb(89, 89, 89)"
                        >, Maharashtra 411057</span
                      ></span
                    ></span
                  ></span
                ></span
              >
              <span
                style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                ><span
                  style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                  >.</span
                ></span
              >
              You agree that by accessing the Site, you have read, understood, and
              agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE
              WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED
              FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
            </div>
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 1"
          >
            <br />
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span
                style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                >Supplemental terms and conditions or documents that may be posted
                on the Site from time to time are hereby expressly incorporated
                herein by reference. We reserve the right, in our sole discretion,
                to make changes or modifications to these Terms of Use at any time
                and for any reason. We will alert you about any changes by
                updating the “Last updated” date of these Terms of Use, and you
                waive any right to receive specific notice of each such change.
                Please ensure that you check the applicable Terms every time you
                use our Site so that you understand which Terms apply. You will be
                subject to, and will be deemed to have been made aware of and to
                have accepted, the changes in any revised Terms of Use by your
                continued use of the Site after the date such revised Terms of Use
                are posted.</span
              >
            </div>
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 1"
          >
            <br />
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span
                style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                >The information provided on the Site is not intended for
                distribution to or use by any person or entity in any jurisdiction
                or country where such distribution or use would be contrary to law
                or regulation or which would subject us to any registration
                requirement within such jurisdiction or country. Accordingly,
                those persons who choose to access the Site from other locations
                do so on their own initiative and are solely responsible for
                compliance with local laws, if and to the extent local laws are
                applicable.</span
              >
            </div>
            <div class="MsoNormal" style="line-height: 1.5"></div>
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 1"
          >
            <br />
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span style="font-size: 15px"
                ><span style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                  >The Site is intended for users who are at least 18 years old.
                  Persons under the age of 18 are not permitted to use or register
                  for the Site.</span
                ></span
              >
            </div>
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 1.5"
          >
            <br />
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div class="MsoNormal" style="line-height: 1.5">
              <br />
            </div>
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="ip"
              style="line-height: 1.5"
            >
              <a name="_4rd71iod99ud" id="_4rd71iod99ud"></a
              ><strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      >2.</span
                    ></strong
                  >
                  INTELLECTUAL PROPERTY RIGHTS</span
                ></strong
              >
            </div>
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 1"
          >
            <br />
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span
                style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                >Unless otherwise indicated, the Site is our proprietary property
                and all source code, databases, functionality, software, website
                designs, audio, video, text, photographs, and graphics on the Site
                (collectively, the “Content”) and the trademarks, service marks,
                and logos contained therein (the “Marks”) are owned or controlled
                by us or licensed to us, and are protected by copyright and
                trademark laws and various other intellectual property rights and
                unfair competition laws of the United States, international
                copyright laws, and international conventions. The Content and the
                Marks are provided on the Site “AS IS” for your information and
                personal use only. Except as expressly provided in these Terms of
                Use, no part of the Site and no Content or Marks may be copied,
                reproduced, aggregated, republished, uploaded, posted, publicly
                displayed, encoded, translated, transmitted, distributed, sold,
                licensed, or otherwise exploited for any commercial purpose
                whatsoever, without our express prior written permission.</span
              >
            </div>
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 1"
          >
            <br />
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span
                style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                >Provided that you are eligible to use the Site, you are granted a
                limited license to access and use the Site and to download or
                print a copy of any portion of the Content to which you have
                properly gained access solely for your personal, non-commercial
                use. We reserve all rights not expressly granted to you in and to
                the Site, the Content and the Marks.</span
              >
            </div>
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 1.5"
          >
            <br />
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div class="MsoNormal" style="line-height: 1.5">
              <br />
            </div>
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="userreps"
              style="line-height: 1.5"
            >
              <a name="_vhkegautf00d" id="_vhkegautf00d"></a
              ><strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          >3.</span
                        ></strong
                      ></span
                    ></strong
                  >
                  USER REPRESENTATIONS</span
                ></strong
              >
            </div>
            <div class="MsoNormal" style="line-height: 1">
              <br />
            </div>
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div
              class="MsoNormal"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; text-align: left"
              >
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >By using the Site, you represent and warrant that:</span
                >
                <span style="color: rgb(89, 89, 89); font-size: 11pt">(</span
                ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                  >1</span
                ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                  >) all registration information you submit will be true,
                  accurate, current, and complete; (</span
                ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                  >2</span
                ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                  >) you will maintain the accuracy of such information and
                  promptly update such registration information as necessary
                  <span style="color: rgb(89, 89, 89); font-size: 11pt">;</span>
                  <span style="color: rgb(89, 89, 89); font-size: 11pt">(</span
                  ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                    >3</span
                  ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                    >) you have the legal capacity and you agree to comply with
                    these Terms of Use;</span
                  ></span
                >
                <span style="color: rgb(89, 89, 89); font-size: 11pt">(</span
                ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                  >4</span
                ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                  >) you are not a minor in the jurisdiction in which you reside ;
                  (</span
                ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                  >5</span
                ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                  >) you will not access the Site through automated or non-human
                  means, whether through a bot, script, or otherwise; (</span
                ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                  >6</span
                ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                  >) you will not use the Site for any illegal or unauthorized
                  purpose; and (</span
                ><span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                  >7</span
                ><span style="color: rgb(89, 89, 89); font-size: 11pt"
                  >) your use of the Site will not violate any applicable law or
                  regulation.</span
                >
              </div>
            </div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1">
              <br />
            </div>
            <div
              class="MsoNormal"
              style="
                text-align: justify;
                text-justify: inter-ideograph;
                line-height: 115%;
              "
            >
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; text-align: left"
              >
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >If you provide any information that is untrue, inaccurate, not
                  current, or incomplete, we have the right to suspend or
                  terminate your account and refuse any and all current or future
                  use of the Site (or any portion thereof).</span
                >
              </div>
            </div>
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 1.5"
          >
            <br />
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div class="MsoNormal" style="line-height: 1.5">
              <br />
            </div>
            <div class="MsoNormal" style="line-height: 1">
              <a name="_esuoutkhaf53" id="_esuoutkhaf53"></a>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="userreg"
                style="line-height: 17.25px"
              >
                <strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >4.</span
                          ></strong
                        ></span
                      ></strong
                    >
                    USER REGISTRATION</span
                  ></strong
                >
              </div>
            </div>
            <div class="MsoNormal" style="line-height: 1">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >You may be required to register with the Site. You agree to
                  keep your password confidential and will be responsible for all
                  use of your account and password. We reserve the right to
                  remove, reclaim, or change a username you select if we
                  determine, in our sole discretion, that such username is
                  inappropriate, obscene, or otherwise objectionable.</span
                >
              </div>
            </div>
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 1.5"
          >
            <br />
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 1.5"
          >
            <br />
          </div>
          <div
            align="center"
            class="MsoNormal"
            style="text-align: left; line-height: 150%"
          >
            <div class="MsoNormal" style="line-height: 1.5"></div>
            <div class="MsoNormal" style="line-height: 115%">
              <a name="_1voziltdxegg" id="_1voziltdxegg"></a>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="prohibited"
                style="line-height: 17.25px"
              >
                <strong
                  ><span style="line-height: 24.5333px; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >5.</span
                          ></strong
                        ></span
                      ></strong
                    >
                    PROHIBITED ACTIVITIES</span
                  ></strong
                >
              </div>
            </div>
            <div class="MsoNormal" style="line-height: 1">
              <br />
            </div>
            <div class="MsoNormal" style="line-height: 115%">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >You may not access or use the Site for any purpose other than
                  that for which we make the Site available. The Site may not be
                  used in connection with any commercial endeavors except those
                  that are specifically endorsed or approved by us.</span
                >
              </div>
            </div>
            <div class="MsoNormal" style="line-height: 1">
              <br />
            </div>
            <div class="MsoNormal" style="line-height: 115%">
              <div
                class="MsoNormal"
                style="text-align: justify; line-height: 17.25px"
              >
                <div class="MsoNormal" style="line-height: 17.25px">
                  <div
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      >As a user of the Site, you agree not to:</span
                    >
                  </div>
                </div>
                <ul>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        style="
                          font-family: sans-serif;
                          font-size: 15px;
                          font-style: normal;
                          font-variant-ligatures: normal;
                          font-variant-caps: normal;
                          font-weight: 400;
                          letter-spacing: normal;
                          orphans: 2;
                          text-align: justify;
                          text-indent: -29.4px;
                          text-transform: none;
                          white-space: normal;
                          widows: 2;
                          word-spacing: 0px;
                          -webkit-text-stroke-width: 0px;
                          background-color: rgb(255, 255, 255);
                          text-decoration-style: initial;
                          text-decoration-color: initial;
                          color: rgb(89, 89, 89);
                        "
                        >Systematically retrieve data or other content from the
                        Site to create or compile, directly or indirectly, a
                        collection, compilation, database, or directory without
                        written permission from us.</span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Trick, defraud, or mislead us and other users,
                              especially in any attempt to learn sensitive account
                              information such as user passwords.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Circumvent, disable, or otherwise interfere with
                              security-related features of the Site, including
                              features that prevent or restrict the use or copying
                              of any Content or enforce limitations on the use of
                              the Site and/or the Content contained therein.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Disparage, tarnish, or otherwise harm, in our
                              opinion, us and/or the Site.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Use any information obtained from the Site in order
                              to harass, abuse, or harm another person.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Make improper use of our support services or submit
                              false reports of abuse or misconduct.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Use the Site in a manner inconsistent with any
                              applicable laws or regulations.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Engage in unauthorized framing of or linking to the
                              Site.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Upload or transmit (or attempt to upload or to
                              transmit) viruses, Trojan horses, or other material,
                              including excessive use of capital letters and
                              spamming (continuous posting of repetitive text),
                              that interferes with any party’s uninterrupted use
                              and enjoyment of the Site or modifies, impairs,
                              disrupts, alters, or interferes with the use,
                              features, functions, operation, or maintenance of
                              the Site.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Engage in any automated use of the system, such as
                              using scripts to send comments or messages, or using
                              any data mining, robots, or similar data gathering
                              and extraction tools.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Delete the copyright or other proprietary rights
                              notice from any Content.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Attempt to impersonate another user or person or
                              use the username of another user.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Upload or transmit (or attempt to upload or to
                              transmit) any material that acts as a passive or
                              active information collection or transmission
                              mechanism, including without limitation, clear
                              graphics interchange formats (“gifs”), 1×1 pixels,
                              web bugs, cookies, or other similar devices
                              (sometimes referred to as “spyware” or “passive
                              collection mechanisms” or “pcms”).</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Interfere with, disrupt, or create an undue burden
                              on the Site or the networks or services connected to
                              the Site.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Harass, annoy, intimidate, or threaten any of our
                              employees or agents engaged in providing any portion
                              of the Site to you.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Attempt to bypass any measures of the Site designed
                              to prevent or restrict access to the Site, or any
                              portion of the Site.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Copy or adapt the Site’s software, including but
                              not limited to Flash, PHP, HTML, JavaScript, or
                              other code.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Except as permitted by applicable law, decipher,
                              decompile, disassemble, or reverse engineer any of
                              the software comprising or in any way making up a
                              part of the Site.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Except as may be the result of standard search
                              engine or Internet browser usage, use, launch,
                              develop, or distribute any automated system,
                              including without limitation, any spider, robot,
                              cheat utility, scraper, or offline reader that
                              accesses the Site, or using or launching any
                              unauthorized script or other software.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Use a buying agent or purchasing agent to make
                              purchases on the Site.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Make any unauthorized use of the Site, including
                              collecting usernames and/or email addresses of users
                              by electronic or other means for the purpose of
                              sending unsolicited email, or creating user accounts
                              by automated means or under false pretenses.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Use the Site as part of any effort to compete with
                              us or otherwise use the Site and/or the Content for
                              any revenue-generating endeavor or commercial
                              enterprise.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      ><span
                        style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                        ><span
                          style="
                            font-family: sans-serif;
                            font-style: normal;
                            font-variant-ligatures: normal;
                            font-variant-caps: normal;
                            font-weight: 400;
                            letter-spacing: normal;
                            orphans: 2;
                            text-align: justify;
                            text-indent: -29.4px;
                            text-transform: none;
                            white-space: normal;
                            widows: 2;
                            word-spacing: 0px;
                            -webkit-text-stroke-width: 0px;
                            background-color: rgb(255, 255, 255);
                            text-decoration-style: initial;
                            text-decoration-color: initial;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89)"
                            ><span
                              style="
                                font-family: sans-serif;
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                font-weight: 400;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: justify;
                                text-indent: -29.4px;
                                text-transform: none;
                                white-space: normal;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                background-color: rgb(255, 255, 255);
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                color: rgb(89, 89, 89);
                              "
                              >Sell or otherwise transfer your profile.</span
                            ></span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      >Posting of messages, mails, comments which can be offensive
                      or unwarranted</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      >Posting messages that incite communal violence /
                      hatred</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px">Posting obscene content</span>
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      >Creating fake user accounts</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      >Posting wrong/fake information on the profile or on the
                      public boards</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 15px"
                      >Representing false information in one to one or group chats
                      on the website or the app</span
                    >
                  </li>
                </ul>
                <div class="MsoNormal" style="text-align: left; line-height: 1.5">
                  <a name="_zbbv9tgty199" id="_zbbv9tgty199"></a>
                </div>
              </div>
              <div
                class="MsoNormal"
                style="text-align: justify; line-height: 1.5"
              >
                <br />
              </div>
              <div
                class="MsoNormal"
                style="text-align: justify; line-height: 1.5"
              >
                <br />
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="heading_1"
                  id="ugc"
                  style="line-height: 17.25px; text-align: left"
                >
                  <strong
                    ><span style="line-height: 24.5333px; font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >6.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      USER GENERATED CONTRIBUTIONS</span
                    ></strong
                  >
                </div>
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >The Site may invite you to chat, contribute to, or
                    participate in blogs, message boards, online forums, and other
                    functionality, and may provide you with the opportunity to
                    create, submit, post, display, transmit, perform, publish,
                    distribute, or broadcast content and materials to us or on the
                    Site, including but not limited to text, writings, video,
                    audio, photographs, graphics, comments, suggestions, or
                    personal information or other material (collectively,
                    "Contributions"). Contributions may be viewable by other users
                    of the Site and through third-party websites. As such, any
                    Contributions you transmit may be treated as non-confidential
                    and non-proprietary. When you create or make available any
                    Contributions, you thereby represent and warrant that:</span
                  >
                </div>
                <ul>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >The creation,
                      <span style="font-size: 14px; color: rgb(89, 89, 89)"
                        >distribution, transmission, public display, or
                        performance, and the accessing, downloading, or copying of
                        your Contributions do not and will not infringe the
                        proprietary rights, including but not limited to the
                        copyright, patent, trademark, trade secret, or moral
                        rights of any third party.</span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 14px; color: rgb(89, 89, 89)"
                        >You are the creator and owner of or have the necessary
                        licenses, rights, consents, releases, and permissions to
                        use and to authorize us, the Site, and other users of the
                        Site to use your Contributions in any manner contemplated
                        by the Site and these Terms of Use.</span
                      ></span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >You have the written consent, release, and/or permission of
                      each and every identifiable individual person in your
                      Contributions to use the name or likeness of each and every
                      such identifiable individual person to enable inclusion and
                      use of your Contributions in any manner contemplated by the
                      Site and these Terms of Use.</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Your Contributions are not false, inaccurate, or
                      misleading.</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Your Contributions are not unsolicited or unauthorized
                      advertising, promotional materials, pyramid schemes, chain
                      letters, spam, mass mailings, or other forms of
                      solicitation.</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Your Contributions are not obscene, lewd, lascivious,
                      filthy, violent, harassing, libelous, slanderous, or
                      otherwise objectionable (as determined by us).</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Your Contributions do not ridicule, mock, disparage,
                      intimidate, or abuse anyone.</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Your Contributions are not used to harass or threaten (in
                      the legal sense of those terms) any other person and to
                      promote violence against a specific person or class of
                      people.</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Your Contributions do not violate any applicable law,
                      regulation, or rule.</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Your Contributions do not violate the privacy or publicity
                      rights of any third party.</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Your Contributions do not violate any applicable law
                      concerning child pornography, or otherwise intended to
                      protect the health or well-being of minors.</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Your Contributions do not include any offensive comments
                      that are connected to race, national origin, gender, sexual
                      preference, or physical handicap.</span
                    >
                  </li>
                  <li
                    class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left"
                  >
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Your Contributions do not otherwise violate, or link to
                      material that violates, any provision of these Terms of Use,
                      or any applicable law or regulation.</span
                    >
                  </li>
                </ul>
                <div
                  class="MsoNormal"
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >Any use of the Site in violation of the foregoing violates
                    these Terms of Use and may result in, among other things,
                    termination or suspension of your rights to use the
                    Site.</span
                  >
                </div>
              </div>
            </div>
            <div class="MsoNormal" style="line-height: 1.5">
              <br />
            </div>
            <div class="MsoNormal" style="line-height: 1.5">
              <br />
            </div>
            <div class="MsoNormal" style="line-height: 115%">
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="heading_1"
                  id="license"
                  style="line-height: 1.5; text-align: left"
                >
                  <strong
                    ><span style="line-height: 24.5333px; font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >7.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      CONTRIBUTION LICENSE</span
                    ></strong
                  >
                </div>
                <div
                  class="MsoNormal"
                  style="line-height: 17.25px; text-align: left"
                ></div>
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >By posting your Contributions to any part of the Site or
                    making Contributions accessible to the Site by linking your
                    account from the Site to any of your social networking
                    accounts , you automatically grant, and you represent and
                    warrant that you have the right to grant, to us an
                    unrestricted, unlimited, irrevocable, perpetual,
                    non-exclusive, transferable, royalty-free, fully-paid,
                    worldwide right, and license to host, use, copy, reproduce,
                    disclose, sell, resell, publish, broadcast, retitle, archive,
                    store, cache, publicly perform, publicly display, reformat,
                    translate, transmit, excerpt (in whole or in part), and
                    distribute such Contributions (including, without limitation,
                    your image and voice) for any purpose, commercial,
                    advertising, or otherwise, and to prepare derivative works of,
                    or incorporate into other works, such Contributions, and grant
                    and authorize sublicenses of the foregoing. The use and
                    distribution may occur in any media formats and through any
                    media channels.</span
                  >
                </div>
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >This license will apply to any form, media, or technology now
                    known or hereafter developed, and includes our use of your
                    name, company name, and franchise name, as applicable, and any
                    of the trademarks, service marks, trade names, logos, and
                    personal and commercial images you provide. You waive all
                    moral rights in your Contributions, and you warrant that moral
                    rights have not otherwise been asserted in your
                    Contributions.</span
                  >
                </div>
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="body_text"
                  style="line-height: 1.5; text-align: left"
                >
                  <span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >We do not assert any ownership over your Contributions. You
                    retain full ownership of all of your Contributions and any
                    intellectual property rights or other proprietary rights
                    associated with your Contributions. We are not liable for any
                    statements or representations in your Contributions provided
                    by you in any area on the Site. You are solely responsible for
                    your Contributions to the Site and you expressly agree to
                    exonerate us from any and all responsibility and to refrain
                    from any legal action against us regarding your
                    Contributions.</span
                  >
                </div>
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; text-align: left"
              >
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >We have the right, in our sole and absolute discretion, (1) to
                  edit, redact, or otherwise change any Contributions; (2) to
                  re-categorize any Contributions to place them in more
                  appropriate locations on the Site; and (3) to pre-screen or
                  delete any Contributions at any time and for any reason, without
                  notice. We have no obligation to monitor your
                  Contributions.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1"></div>
              <div class="MsoNormal" style="line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="heading_1"
                  id="reviews"
                  style="line-height: 115%"
                >
                  <strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >8.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      GUIDELINES FOR REVIEWS</span
                    ></strong
                  >
                </div>
              </div>
              <div class="MsoNormal" style="line-height: 1">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; text-align: left"
              >
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >We may provide you areas on the Site to leave reviews or
                  ratings. When posting a review, you must comply with the
                  following criteria: (1) you should have firsthand experience
                  with the person/entity being reviewed; (2) your reviews should
                  not contain offensive profanity, or abusive, racist, offensive,
                  or hate language; (3) your reviews should not contain
                  discriminatory references based on religion, race, gender,
                  national origin, age, marital status, sexual orientation, or
                  disability; (4) your reviews should not contain references to
                  illegal activity; (5) you should not be affiliated with
                  competitors if posting negative reviews; (6) you should not make
                  any conclusions as to the legality of conduct; (7) you may not
                  post any false or misleading statements; and (8) you may not
                  organize a campaign encouraging others to post reviews, whether
                  positive or negative.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; text-align: left"
              >
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >We may accept, reject, or remove reviews in our sole
                  discretion. We have absolutely no obligation to screen reviews
                  or to delete reviews, even if anyone considers reviews
                  objectionable or inaccurate. Reviews are not endorsed by us, and
                  do not necessarily represent our opinions or the views of any of
                  our affiliates or partners. We do not assume liability for any
                  review or for any claims, liabilities, or losses resulting from
                  any review. By posting a review, you hereby grant to us a
                  perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
                  assignable, and sublicensable right and license to reproduce,
                  modify, translate, transmit by any means, display, perform,
                  and/or distribute all content relating to reviews.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5"></div>
            </div>
            <div class="MsoNormal" style="line-height: 115%">
              <span style="font-size: 15px"
                ><a name="_6nl7u6ag6use" id="_6nl7u6ag6use"></a
              ></span>
            </div>
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="mobile"
              style="line-height: 115%"
            >
              <strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          >9.</span
                        ></strong
                      ></span
                    ></strong
                  >
                  MOBILE APPLICATION LICENSE</span
                ></strong
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <div class="MsoNormal" style="line-height: 115%">
              <a name="_4p0l96wt8fhj" id="_4p0l96wt8fhj"></a>
            </div>
            <div
              class="MsoNormal"
              data-custom-class="heading_2"
              style="line-height: 115%"
            >
              <strong
                ><span style="line-height: 115%; font-family: Arial"
                  >Use License</span
                ></strong
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >If you access the Site via a mobile application, then we grant
                you a revocable, non-exclusive, non-transferable, limited right to
                install and use the mobile application on wireless electronic
                devices owned or controlled by you, and to access and use the
                mobile application on such devices strictly in accordance with the
                terms and conditions of this mobile application license contained
                in these Terms of Use. You shall not: (1) except as permitted by
                applicable law, decompile, reverse engineer, disassemble, attempt
                to derive the source code of, or decrypt the application; (2) make
                any modification, adaptation, improvement, enhancement,
                translation, or derivative work from the application; (3) violate
                any applicable laws, rules, or regulations in connection with your
                access or use of the application; (4) remove, alter, or obscure
                any proprietary notice (including any notice of copyright or
                trademark) posted by us or the licensors of the application; (5)
                use the application for any revenue generating endeavor,
                commercial enterprise, or other purpose for which it is not
                designed or intended; (6) make the application available over a
                network or other environment permitting access or use by multiple
                devices or users at the same time; (7) use the application for
                creating a product, service, or software that is, directly or
                indirectly, competitive with or in any way a substitute for the
                application; (8) use the application to send automated queries to
                any website or to send any unsolicited commercial e-mail; or (9)
                use any proprietary information or any of our interfaces or our
                other intellectual property in the design, development,
                manufacture, licensing, or distribution of any applications,
                accessories, or devices for use with the application.</span
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <div
              class="MsoNormal"
              data-custom-class="heading_2"
              style="line-height: 115%"
            >
              <a name="_vzf0b5xscg" id="_vzf0b5xscg"></a
              ><strong
                ><span style="line-height: 115%; font-family: Arial"
                  >Apple and Android Devices</span
                ></strong
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >The following terms apply when you use a mobile application
                obtained from either the Apple Store or Google Play (each an “App
                Distributor”) to access the Site: (1) the license granted to you
                for our mobile application is limited to a non-transferable
                license to use the application on a device that utilizes the Apple
                iOS or Android operating systems, as applicable, and in accordance
                with the usage rules set forth in the applicable App Distributor’s
                terms of service; (2) we are responsible for providing any
                maintenance and support services with respect to the mobile
                application as specified in the terms and conditions of this
                mobile application license contained in these Terms of Use or as
                otherwise required under applicable law, and you acknowledge that
                each App Distributor has no obligation whatsoever to furnish any
                maintenance and support services with respect to the mobile
                application; (3) in the event of any failure of the mobile
                application to conform to any applicable warranty, you may notify
                the applicable App Distributor, and the App Distributor, in
                accordance with its terms and policies, may refund the purchase
                price, if any, paid for the mobile application, and to the maximum
                extent permitted by applicable law, the App Distributor will have
                no other warranty obligation whatsoever with respect to the mobile
                application; (4) you represent and warrant that (i) you are not
                located in a country that is subject to a U.S. government embargo,
                or that has been designated by the U.S. government as a “terrorist
                supporting” country and (ii) you are not listed on any U.S.
                government list of prohibited or restricted parties; (5) you must
                comply with applicable third-party terms of agreement when using
                the mobile application, e.g., if you have a VoIP application, then
                you must not be in violation of their wireless data service
                agreement when using the mobile application; and (6) you
                acknowledge and agree that the App Distributors are third-party
                beneficiaries of the terms and conditions in this mobile
                application license contained in these Terms of Use, and that each
                App Distributor will have the right (and will be deemed to have
                accepted the right) to enforce the terms and conditions in this
                mobile application license contained in these Terms of Use against
                you as a third-party beneficiary thereof.</span
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1.5">
            <br />
          </div>
          <div align="center" style="text-align: left; line-height: 1.5">
            <br />
          </div>
          <div align="center"></div>
          <div align="center"></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="socialmedia"
            style="line-height: 115%"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >10.</span
                      ></strong
                    ></span
                  ></strong
                >
                SOCIAL MEDIA</span
              ></strong
            >
          </div>
          <div style="line-height: 1">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              >As part of the functionality of the Site, you may link your account
              with online accounts you have with third-party service providers
              (each such account, a “Third-Party Account”) by either: (1)
              providing your Third-Party Account login information through the
              Site; or (2) allowing us to access your Third-Party Account, as is
              permitted under the applicable terms and conditions that govern your
              use of each Third-Party Account. You represent and warrant that you
              are entitled to disclose your Third-Party Account login information
              to us and/or grant us access to your Third-Party Account, without
              breach by you of any of the terms and conditions that govern your
              use of the applicable Third-Party Account, and without obligating us
              to pay any fees or making us subject to any usage limitations
              imposed by the third-party service provider of the Third-Party
              Account. By granting us access to any Third-Party Accounts, you
              understand that (1) we may access, make available, and store (if
              applicable) any content that you have provided to and stored in your
              Third-Party Account (the “Social Network Content”) so that it is
              available on and through the Site via your account, including
              without limitation any friend lists and (2) we may submit to and
              receive from your Third-Party Account additional information to the
              extent you are notified when you link your account with the
              Third-Party Account. Depending on the Third-Party Accounts you
              choose and subject to the privacy settings that you have set in such
              Third-Party Accounts, personally identifiable information that you
              post to your Third-Party Accounts may be available on and through
              your account on the Site. Please note that if a Third-Party Account
              or associated service becomes unavailable or our access to such
              Third Party Account is terminated by the third-party service
              provider, then Social Network Content may no longer be available on
              and through the Site. You will have the ability to disable the
              connection between your account on the Site and your Third-Party
              Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE
              THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY
              ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
              THIRD-PARTY SERVICE PROVIDERS. We make no effort to review any
              Social Network Content for any purpose, including but not limited
              to, for accuracy, legality, or non-infringement, and we are not
              responsible for any Social Network Content. You acknowledge and
              agree that we may access your email address book associated with a
              Third-Party Account and your contacts list stored on your mobile
              device or tablet computer solely for purposes of identifying and
              informing you of those contacts who have also registered to use the
              Site. You can deactivate the connection between the Site and your
              Third-Party Account by contacting us using the contact information
              below or through your account settings (if applicable). We will
              attempt to delete any information stored on our servers that was
              obtained through such Third-Party Account, except the username and
              profile picture that become associated with your account.</span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <br />
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <br />
          </div>
          <div align="center" style="line-height: 1.5"></div>
          <div
            class="MsoNormal"
            data-custom-class="heading_1"
            id="submissions"
            style="line-height: 115%"
          >
            <strong
              ><span
                style="line-height: 115%; font-family: Arial; font-size: 19px"
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        >11.</span
                      ></strong
                    ></span
                  ></strong
                >
                SUBMISSIONS</span
              ></strong
            >
          </div>
          <div style="line-height: 1">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5"
          >
            <span
              style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
              ><span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >You acknowledge and agree that any questions, comments,
                suggestions, ideas, feedback, or other information regarding the
                Site ("Submissions") provided by you to us are non-confidential
                and shall become our sole property. We shall own exclusive rights,
                including all intellectual property rights, and shall be entitled
                to the unrestricted use and dissemination of these Submissions for
                any lawful purpose, commercial or otherwise, without
                acknowledgment or compensation to you. You hereby waive all moral
                rights to any such Submissions, and you hereby warrant that any
                such Submissions are original with you or that you have the right
                to submit such Submissions. You agree there shall be no recourse
                against us for any alleged or actual infringement or
                misappropriation of any proprietary right in your
                Submissions.</span
              ></span
            >
          </div>
          <div class="MsoNormal" style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div align="center"></div>
          <div align="center" style="text-align: left; line-height: 1">
            <div class="MsoNormal" style="line-height: 115%">
              <span style="font-size: 15px"
                ><a name="_29ce8o9pbtmi" id="_29ce8o9pbtmi"></a
              ></span>
            </div>
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="advertisers"
              style="line-height: 115%"
            >
              <strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          >12.</span
                        ></strong
                      ></span
                    ></strong
                  >
                  ADVERTISERS</span
                ></strong
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >We allow advertisers to display their advertisements and other
                information in certain areas of the Site, such as sidebar
                advertisements or banner advertisements. If you are an advertiser,
                you shall take full responsibility for any advertisements you
                place on the Site and any services provided on the Site or
                products sold through those advertisements. Further, as an
                advertiser, you warrant and represent that you possess all rights
                and authority to place advertisements on the Site, including, but
                not limited to, intellectual property rights, publicity rights,
                and contractual rights. We simply provide the space to place such
                advertisements, and we have no other relationship with
                advertisers.</span
              >
            </div>
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div align="center" style="text-align: left; line-height: 1.5">
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="sitemanage"
              style="line-height: 115%"
            >
              <a name="_wj13r09u8u3u" id="_wj13r09u8u3u"></a
              ><strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          >13.</span
                        ></strong
                      ></span
                    ></strong
                  >
                  SITE MANAGEMENT</span
                ></strong
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <br />
          </div>
          <div align="center" style="text-align: left; line-height: 1.5">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >We reserve the right, but not the obligation, to: (1) monitor the
                Site for violations of these Terms of Use; (2) take appropriate
                legal action against anyone who, in our sole discretion, violates
                the law or these Terms of Use, including without limitation,
                reporting such user to law enforcement authorities; (3) in our
                sole discretion and without limitation, refuse, restrict access
                to, limit the availability of, or disable (to the extent
                technologically feasible) any of your Contributions or any portion
                thereof; (4) in our sole discretion and without limitation,
                notice, or liability, to remove from the Site or otherwise disable
                all files and content that are excessive in size or are in any way
                burdensome to our systems; and (5) otherwise manage the Site in a
                manner designed to protect our rights and property and to
                facilitate the proper functioning of the Site.</span
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1.5">
            <br />
          </div>
          <div align="center" style="text-align: left; line-height: 1.5">
            <div class="MsoNormal" style="line-height: 1.5">
              <br />
            </div>
            <div class="MsoNormal" style="line-height: 1.5">
              <a name="_jugvcvcw0oj9" id="_jugvcvcw0oj9"></a>
            </div>
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="privacypolicy1"
              style="line-height: 1.5"
            >
              <strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          >14.</span
                        ></strong
                      ></span
                    ></strong
                  >
                  PRIVACY POLICY</span
                ></strong
              >
            </div>
            <div class="MsoNormal" style="line-height: 1">
              <br />
            </div>
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >We care about data privacy and security.</span
              >
              <span style="color: rgb(89, 89, 89); font-size: 14.6667px"
                >Please review our Privacy Policy:</span
              >
              <b style="color: rgb(89, 89, 89); font-size: 14.6667px"
                ><a
                  href="https://fly-social.com/privacy-policy"
                  target="_blank"
                  data-custom-class="link"
                  >https://fly-social.com/privacy-policy</a
                ></b
              >
              <span style="color: rgb(89, 89, 89); font-size: 14.6667px">.</span>
              <span style="color: rgb(89, 89, 89); font-size: 11pt"
                >By using the Site, you agree to be bound by our Privacy Policy,
                which is incorporated into these Terms of Use. Please be advised
                the Site is hosted in
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >India</span
                >
                . If you access the Site from any other region of the world with
                laws or other requirements governing personal data collection,
                use, or disclosure that differ from applicable laws in
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >India</span
                >
                , then through your continued use of the Site, you are
                transferring your data to
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >India</span
                >
                , and you agree to have your data transferred to and processed in
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  >India</span
                >
                .</span
              >
            </div>
          </div>
          <div align="center" style="text-align: left; line-height: 1.5">
            <br />
          </div>
          <div align="center" style="text-align: left; line-height: 1.5">
            <br />
          </div>
          <div align="center" style="text-align: left; line-height: 1">
            <div style="text-align: justify; line-height: 1.5"></div>
            <div class="MsoNormal" style="line-height: 115%">
              <span style="font-size: 15px"
                ><a name="_n081pott8yce" id="_n081pott8yce"></a
              ></span>
            </div>
            <div class="MsoNormal" style="line-height: 1">
              <span style="font-size: 15px"
                ><a name="_sg28ikxq3swh" id="_sg28ikxq3swh"></a
              ></span>
            </div>
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="copyright3"
              style="line-height: 115%"
            >
              <strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px"
                  ><strong
                    ><span
                      style="
                        line-height: 115%;
                        font-family: Arial;
                        font-size: 19px;
                      "
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          >15.</span
                        ></strong
                      ></span
                    ></strong
                  >
                  COPYRIGHT INFRINGEMENTS</span
                ></strong
              >
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div
              align="center"
              data-custom-class="body_text"
              style="text-align: left; line-height: 1.5"
            >
              <span style="font-size: 15px"
                >We respect the intellectual property rights of others. If you
                believe that any material available on or through the Site
                infringes upon any copyright you own or control, please
                immediately notify us using the contact information provided below
                (a “Notification”). A copy of your Notification will be sent to
                the person who posted or stored the material addressed in the
                Notification. Please be advised that pursuant to applicable law
                you may be held liable for damages if you make material
                misrepresentations in a Notification. Thus, if you are not sure
                that material located on or linked to by the Site infringes your
                copyright, you should consider first contacting an attorney.</span
              >
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left; line-height: 1.5"></div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="terms"
                style="line-height: 115%"
              >
                <a name="_k3mndam4w6w1" id="_k3mndam4w6w1"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >16.</span
                          ></strong
                        ></span
                      ></strong
                    >
                    TERM AND TERMINATION</span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="
                    font-size: 15px;
                    line-height: 115%;
                    font-family: Arial;
                    color: rgb(89, 89, 89);
                  "
                  >These Terms of Use shall remain in full force and effect while
                  you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
                  TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION
                  AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
                  SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON
                  FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION
                  FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
                  CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
                  REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE
                  SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT
                  YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                  DISCRETION.</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >If we terminate or suspend your account for any reason, you are
                  prohibited from registering and creating a new account under
                  your name, a fake or borrowed name, or the name of any third
                  party, even if you may be acting on behalf of the third party.
                  In addition to terminating or suspending your account, we
                  reserve the right to take appropriate legal action, including
                  without limitation pursuing civil, criminal, and injunctive
                  redress.</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="modifications"
                style="line-height: 1.5"
              >
                <a name="_e2dep1hfgltt" id="_e2dep1hfgltt"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >17.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      MODIFICATIONS AND INTERRUPTIONS</span
                    ></span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >We reserve the right to change, modify, or remove the contents
                  of the Site at any time or for any reason at our sole discretion
                  without notice. However, we have no obligation to update any
                  information on our Site. We also reserve the right to modify or
                  discontinue all or part of the Site without notice at any time.
                  We will not be liable to you or any third party for any
                  modification, price change, suspension, or discontinuance of the
                  Site.</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >We cannot guarantee the Site will be available at all times. We
                  may experience hardware, software, or other problems or need to
                  perform maintenance related to the Site, resulting in
                  interruptions, delays, or errors. We reserve the right to
                  change, revise, update, suspend, discontinue, or otherwise
                  modify the Site at any time or for any reason without notice to
                  you. You agree that we have no liability whatsoever for any
                  loss, damage, or inconvenience caused by your inability to
                  access or use the Site during any downtime or discontinuance of
                  the Site. Nothing in these Terms of Use will be construed to
                  obligate us to maintain and support the Site or to supply any
                  corrections, updates, or releases in connection therewith.</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="law"
                style="line-height: 1.5"
              >
                <a name="_p6vbf8atcwhs" id="_p6vbf8atcwhs"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >18.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      GOVERNING LAW</span
                    ></span
                  ></strong
                >
              </div>
              <div class="MsoNormal" style="line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 115%"></div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >These Terms shall be governed by and defined following the
                    laws of
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        style="
                          font-size: 11pt;
                          line-height: 16.8667px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            font-size: 11pt;
                            line-height: 16.8667px;
                            color: rgb(89, 89, 89);
                          "
                          ><span
                            style="
                              font-size: 11pt;
                              line-height: 16.8667px;
                              color: rgb(89, 89, 89);
                            "
                            ><span
                              style="
                                font-size: 11pt;
                                line-height: 16.8667px;
                                color: rgb(89, 89, 89);
                              "
                              ><span
                                style="
                                  font-size: 11pt;
                                  line-height: 16.8667px;
                                  color: rgb(89, 89, 89);
                                "
                                >India</span
                              ></span
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                >
                . fly-social.com and yourself irrevocably consent that the courts of
                <span
                  style="
                    font-size: 11pt;
                    line-height: 16.8667px;
                    color: rgb(89, 89, 89);
                  "
                  ><span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        style="
                          font-size: 11pt;
                          line-height: 16.8667px;
                          color: rgb(89, 89, 89);
                        "
                        >India</span
                      ></span
                    ></span
                  ></span
                >
                shall have exclusive jurisdiction to resolve any dispute which may
                arise in connection with these terms.
              </div>
              <div class="MsoNormal" style="line-height: 1.5"></div>
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="disputes"
                style="line-height: 1.5"
              >
                <a name="_v5i5tjw62cyw" id="_v5i5tjw62cyw"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            ><strong
                              ><span
                                style="
                                  line-height: 115%;
                                  font-family: Arial;
                                  font-size: 19px;
                                "
                                >19.</span
                              ></strong
                            ></span
                          ></strong
                        ></span
                      ></strong
                    >
                    DISPUTE RESOLUTION</span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 115%">
                <div
                  class="MsoNormal"
                  data-custom-class="heading_2"
                  style="line-height: 17.25px; text-align: left"
                >
                  <strong>Informal Negotiations</strong>
                </div>
              </div>
              <div class="MsoNormal" style="line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="body_text"
                  style="text-align: left; line-height: 1.5"
                >
                  <span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >To expedite resolution and control the cost of any dispute,
                    controversy, or claim related to these Terms of Use (each
                    "Dispute" and collectively, the “Disputes”) brought by either
                    you or us (individually, a “Party” and collectively, the
                    “Parties”), the Parties agree to first attempt to negotiate
                    any Dispute (except those Disputes expressly provided below)
                    informally for at least thirty (30) days before initiating
                    arbitration. Such informal negotiations commence upon written
                    notice from one Party to the other Party.</span
                  >
                </div>
              </div>
              <div class="MsoNormal" style="line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="heading_2"
                  style="line-height: 17.25px; text-align: left"
                >
                  <a name="_p2enhfx5sw3j" id="_p2enhfx5sw3j"></a
                  ><strong>Binding Arbitration</strong>
                </div>
              </div>
              <div class="MsoNormal" style="line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1"></div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="body_text"
                  style="text-align: left; line-height: 1.5"
                >
                  <span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >Any dispute arising out of or in connection with this
                    contract, including any question regarding its existence,
                    validity, or termination, shall be referred to and finally
                    resolved by the International Commercial Arbitration Court
                    under the European Arbitration Chamber (Belgium, Brussels,
                    Avenue Louise, 146) according to the Rules of this ICAC,
                    which, as a result of referring to it, is considered as the
                    part of this clause. The number of arbitrators shall be one
                    (1). The seat, or legal place, of arbitration shall be
                    <span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      >Pune, India, India</span
                    ></span
                  >
                  .
                  <span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      >The language of the proceedings shall be Hindi / English
                      .</span
                    ></span
                  >
                  The governing law of the contract shall be the substantive law
                  of
                  <span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    ><span
                      style="
                        font-size: 11pt;
                        line-height: 16.8667px;
                        color: rgb(89, 89, 89);
                      "
                      ><span
                        style="
                          font-size: 11pt;
                          line-height: 16.8667px;
                          color: rgb(89, 89, 89);
                        "
                        ><span
                          style="
                            font-size: 11pt;
                            line-height: 16.8667px;
                            color: rgb(89, 89, 89);
                          "
                          >India</span
                        ></span
                      ></span
                    ></span
                  >
                  .
                </div>
                <div
                  class="MsoNormal"
                  style="line-height: 17.25px; text-align: left"
                >
                  <a name="_xl7dsk6ndrpp" id="_xl7dsk6ndrpp"></a>
                </div>
              </div>
              <div
                class="MsoNormal"
                style="text-align: justify; line-height: 1"
              ></div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="heading_2"
                  style="line-height: 17.25px; text-align: left"
                >
                  <a name="_inlv5c77dhih" id="_inlv5c77dhih"></a
                  ><strong>Restrictions</strong>
                </div>
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="body_text"
                  style="text-align: left; line-height: 1.5"
                >
                  <span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >The Parties agree that any arbitration shall be limited to
                    the Dispute between the Parties individually. To the full
                    extent permitted by law, (a) no arbitration shall be joined
                    with any other proceeding; (b) there is no right or authority
                    for any Dispute to be arbitrated on a class-action basis or to
                    utilize class action procedures; and (c) there is no right or
                    authority for any Dispute to be brought in a purported
                    representative capacity on behalf of the general public or any
                    other persons.</span
                  >
                </div>
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="heading_2"
                  style="line-height: 17.25px; text-align: left"
                >
                  <a name="_mdjlt1af25uq" id="_mdjlt1af25uq"></a
                  ><strong
                    >Exceptions to Informal Negotiations and Arbitration</strong
                  >
                </div>
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <br />
              </div>
              <div class="MsoNormal" style="text-align: justify; line-height: 1">
                <div
                  class="MsoNormal"
                  data-custom-class="body_text"
                  style="text-align: left; line-height: 1.5"
                >
                  <span
                    style="
                      font-size: 11pt;
                      line-height: 16.8667px;
                      color: rgb(89, 89, 89);
                    "
                    >The Parties agree that the following Disputes are not subject
                    to the above provisions concerning informal negotiations and
                    binding arbitration: (a) any Disputes seeking to enforce or
                    protect, or concerning the validity of, any of the
                    intellectual property rights of a Party; (b) any Dispute
                    related to, or arising from, allegations of theft, piracy,
                    invasion of privacy, or unauthorized use; and (c) any claim
                    for injunctive relief. If this provision is found to be
                    illegal or unenforceable, then neither Party will elect to
                    arbitrate any Dispute falling within that portion of this
                    provision found to be illegal or unenforceable and such
                    Dispute shall be decided by a court of competent jurisdiction
                    within the courts listed for jurisdiction above, and the
                    Parties agree to submit to the personal jurisdiction of that
                    court.</span
                  >
                </div>
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="corrections"
                style="line-height: 1.5"
              >
                <a name="_mjgzo07ttzx5" id="_mjgzo07ttzx5"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >20.</span
                          ></strong
                        ></span
                      ></strong
                    >
                    CORRECTIONS</span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >There may be information on the Site that contains
                  typographical errors, inaccuracies, or omissions, including
                  descriptions, pricing, availability, and various other
                  information. We reserve the right to correct any errors,
                  inaccuracies, or omissions and to change or update the
                  information on the Site at any time, without prior notice.</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="disclaimer"
                style="line-height: 1.5"
              >
                <a name="_gvi74blrahf9" id="_gvi74blrahf9"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >21.</span
                          ></strong
                        ></span
                      ></strong
                    >
                    DISCLAIMER</span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
                  AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR
                  SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM
                  ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE
                  AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                  AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
                  ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE
                  CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
                  LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
                  INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
                  PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
                  ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
                  USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                  INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                  INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE,
                  (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                  TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR
                  (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
                  ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
                  OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
                  VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                  RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
                  BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR
                  ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
                  OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY
                  BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
                  ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
                  PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
                  ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
                  CAUTION WHERE APPROPRIATE.</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="liability"
                style="line-height: 1.5"
              >
                <a name="_4pjah3d0455q" id="_4pjah3d0455q"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >22.</span
                          ></strong
                        ></span
                      ></strong
                    >
                    LIMITATIONS OF LIABILITY</span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
                  LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                  CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
                  DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
                  OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
                  BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
                  ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU
                  FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
                  ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY,
                  BY YOU TO US
                  <span
                    style="
                      font-size: 11pt;
                      color: rgb(89, 89, 89);
                      text-transform: uppercase;
                    "
                    >DURING THE six (6) mONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
                    ARISING</span
                  >
                  . CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
                  LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
                  OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF
                  THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                  YOU MAY HAVE ADDITIONAL RIGHTS.</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="indemnification"
                style="line-height: 1.5"
              >
                <a name="_k5ap68aj1dd4" id="_k5ap68aj1dd4"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >23.</span
                          ></strong
                        ></span
                      ></strong
                    >
                    INDEMNIFICATION</span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >You agree to defend, indemnify, and hold us harmless, including
                  our subsidiaries, affiliates, and all of our respective
                  officers, agents, partners, and employees, from and against any
                  loss, damage, liability, claim, or demand, including reasonable
                  attorneys’ fees and expenses, made by any third party due to or
                  arising out of: (1) your Contributions; (<span
                    style="font-size: 14.6667px"
                    >2</span
                  >) use of the Site; (<span style="font-size: 14.6667px">3</span
                  >) breach of these Terms of Use; (<span
                    style="font-size: 14.6667px"
                    >4</span
                  >) any breach of your representations and warranties set forth
                  in these Terms of Use; (<span style="font-size: 14.6667px"
                    >5</span
                  >) your violation of the rights of a third party, including but
                  not limited to intellectual property rights; or (<span
                    style="font-size: 14.6667px"
                    >6</span
                  >) any overt harmful act toward any other user of the Site with
                  whom you connected via the Site. Notwithstanding the foregoing,
                  we reserve the right, at your expense, to assume the exclusive
                  defense and control of any matter for which you are required to
                  indemnify us, and you agree to cooperate, at your expense, with
                  our defense of such claims. We will use reasonable efforts to
                  notify you of any such claim, action, or proceeding which is
                  subject to this indemnification upon becoming aware of it.</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="userdata"
                style="line-height: 1.5"
              >
                <a name="_ftgg17oha0ep" id="_ftgg17oha0ep"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            ><strong
                              ><span
                                style="
                                  line-height: 115%;
                                  font-family: Arial;
                                  font-size: 19px;
                                "
                                >24.</span
                              ></strong
                            ></span
                          ></strong
                        ></span
                      ></strong
                    >
                    USER DATA</span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >We will maintain certain data that you transmit to the Site for
                  the purpose of managing the performance of the Site, as well as
                  data relating to your use of the Site. Although we perform
                  regular routine backups of data, you are solely responsible for
                  all data that you transmit or that relates to any activity you
                  have undertaken using the Site. You agree that we shall have no
                  liability to you for any loss or corruption of any such data,
                  and you hereby waive any right of action against us arising from
                  any such loss or corruption of such data.</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="electronic"
                style="line-height: 1.5"
              >
                <a name="_dkovrslqodui" id="_dkovrslqodui"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >25.</span
                          ></strong
                        ></span
                      ></strong
                    >
                    ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >Visiting the Site, sending us emails, and completing online
                  forms constitute electronic communications. You consent to
                  receive electronic communications, and you agree that all
                  agreements, notices, disclosures, and other communications we
                  provide to you electronically, via email and on the Site,
                  satisfy any legal requirement that such communication be in
                  writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                  CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY
                  OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR
                  COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or
                  requirements under any statutes, regulations, rules, ordinances,
                  or other laws in any jurisdiction which require an original
                  signature or delivery or retention of non-electronic records, or
                  to payments or the granting of credits by any means other than
                  electronic means.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="third"
                style="line-height: 1.5"
              >
                <a name="_dkovrslqodui" id="_dkovrslqodui"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >26.</span
                          ></strong
                        ></span
                      ></strong
                    >
                    THIRD PARTY SERVICE PROVIDERS</span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >Third Party Service Providers. The Service may contain features that enable various third party service providers (such as social media services like Facebook, Twitter and, LinkedIn) (“Third Party Service Providers”) to be directly integrated into your Fly account. To take advantage of these features, you will be required to register for or log into the Third Party Service Providers on their respective websites. By enabling Third Party Service Providers within the Service, you are allowing us to pass your login information to these Third Party Service Providers for this purpose.</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >You are also subject to the Terms of Service of the third-party apps you use via our services. For instance, should you connect Youtube to our services, you are to comply with Terms of Service for Fly and YouTube. We have listed the links to the terms of service for our supported platforms:
                </span>
              </div>
              <div align="center" style="text-align: left; line-height: 0.5">
                <br />
              </div>
              <ul>
                <li class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left">
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Youtube:
                      <span style="font-size: 14px; color: rgb(89, 89, 89)"
                        ><a
                        href="https://www.youtube.com/t/terms"
                        target="_blank"
                        data-custom-class="link"
                        >https://www.youtube.com/t/terms</a
                      ></span
                      ></span
                    >
                </li>
                <li class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left">
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Twitter:
                      <span style="font-size: 14px; color: rgb(89, 89, 89)"
                        ><a
                        href="https://twitter.com/en/tos"
                        target="_blank"
                        data-custom-class="link"
                        >https://twitter.com/en/tos</a
                      ></span
                      ></span
                    >
                </li>
                <li class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left">
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Instagram:
                      <span style="font-size: 14px; color: rgb(89, 89, 89)"
                        ><a
                        href="https://help.instagram.com/581066165581870"
                        target="_blank"
                        data-custom-class="link"
                        >https://help.instagram.com/581066165581870</a
                      ></span
                      ></span
                    >
                </li>
                <li class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left">
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Facebook:
                      <span style="font-size: 14px; color: rgb(89, 89, 89)"
                        ><a
                        href="https://www.facebook.com/terms.php"
                        target="_blank"
                        data-custom-class="link"
                        >https://www.facebook.com/terms.php</a
                      ></span
                      ></span
                    >
                </li>
                <li class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left">
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >Pinterest:
                      <span style="font-size: 14px; color: rgb(89, 89, 89)"
                        ><a
                        href="https://policy.pinterest.com/en/terms-of-service"
                        target="_blank"
                        data-custom-class="link"
                        >https://policy.pinterest.com/en/terms-of-service</a
                      ></span
                      ></span
                    >
                </li>
                <li class="MsoNormal"
                    data-custom-class="body_text"
                    style="line-height: 1.5; text-align: left">
                    <span style="font-size: 14px; color: rgb(89, 89, 89)"
                      >LinkedIn:
                      <span style="font-size: 14px; color: rgb(89, 89, 89)"
                        ><a
                        href="https://www.linkedin.com/legal/l/service-terms"
                        target="_blank"
                        data-custom-class="link"
                        >https://www.linkedin.com/legal/l/service-terms</a
                      ></span
                      ></span
                    >
                </li>
              </ul>
              <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >Fly’s use and transfer of information received from Google APIs to any other app will adhere to 
                  <a
                        href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                        target="_blank"
                        data-custom-class="link"
                        >Google API Services User Data Policy</a
                      >
                  , including the Limited Use requirements.
                </span>
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>

              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="misc"
                style="line-height: 115%"
              >
                <a name="_d4jvmcnxg0wt" id="_d4jvmcnxg0wt"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px"
                    ><strong
                      ><span
                        style="
                          line-height: 115%;
                          font-family: Arial;
                          font-size: 19px;
                        "
                        ><strong
                          ><span
                            style="
                              line-height: 115%;
                              font-family: Arial;
                              font-size: 19px;
                            "
                            >27.</span
                          ></strong
                        ></span
                      ></strong
                    >
                    MISCELLANEOUS</span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >These Terms of Use and any policies or operating rules posted
                  by us on the Site or in respect to the Site constitute the
                  entire agreement and understanding between you and us. Our
                  failure to exercise or enforce any right or provision of these
                  Terms of Use shall not operate as a waiver of such right or
                  provision. These Terms of Use operate to the fullest extent
                  permissible by law. We may assign any or all of our rights and
                  obligations to others at any time. We shall not be responsible
                  or liable for any loss, damage, delay, or failure to act caused
                  by any cause beyond our reasonable control. If any provision or
                  part of a provision of these Terms of Use is determined to be
                  unlawful, void, or unenforceable, that provision or part of the
                  provision is deemed severable from these Terms of Use and does
                  not affect the validity and enforceability of any remaining
                  provisions. There is no joint venture, partnership, employment
                  or agency relationship created between you and us as a result of
                  these Terms of Use or use of the Site. You agree that these
                  Terms of Use will not be construed against us by virtue of
                  having drafted them. You hereby waive any and all defenses you
                  may have based on the electronic form of these Terms of Use and
                  the lack of signing by the parties hereto to execute these Terms
                  of Use.</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="refund"
                style="line-height: 1.5"
              >
                <a name="_t4pq5cwn456q" id="_t4pq5cwn456q"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >28.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      Refund Policy</span
                    ></span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class=""
                style="line-height: 1.5"
              >
              <span>
                <span
                  style="font-size:14px;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >Your subscription fees would be refunded only if you reach out to us requesting a refund within 7 days of the most recent subscription renewal and have not used the service at all in the meantime. You may send your refund requests at 
                  </span>
                  <b style="color: rgb(23, 115, 234); font-size: 14px">
                    <a  style="font-size: 14px" href="mailto:fly@zoomlocal.in">fly@zoomlocal.in</a>
                </b>
                <span
                style="font-size:14px;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >
                  stating valid reasons for refunds of the subscription amount.</span>
              </span>
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="shipping"
                style="line-height: 1.5"
              >
                <a name="_t4pq5cwn456q" id="_t4pq5cwn456q"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >29.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      SHIPPING POLICY IS NOT APPLICABLE TO THIS PRODUCT</span
                    ></span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class=""
                style="line-height: 1.5"
              >
              <span>
                <span
                  style="font-size:14px;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >
                  Shipping policy is not applicable to this product
                  </span>
                  
              </span>
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="contact"
                style="line-height: 1.5"
              >
                <a name="_t4pq5cwn486q" id="_t4pq5cwn486q"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >30.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      CONTACT US</span
                    ></span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >In order to resolve a complaint regarding the Site or to
                  receive further information regarding use of the Site, please
                  contact us at:</span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="
                    font-size: 15px;
                    line-height: 115%;
                    font-family: Arial;
                    color: rgb(89, 89, 89);
                  "
                  ><strong>ZoomLocal Tech Private Limited</strong></span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span style="color: rgb(89, 89, 89)"
                  ><strong
                    ><span style="font-size: 15px"
                      >A-806, Sapphire Park, Park Street, Wakad</span
                    ></strong
                  ></span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              ></div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span style="color: rgb(89, 89, 89)"
                  ><strong
                    ><span style="font-size: 15px"
                      >Pune , Maharashtra</span
                    ></strong
                  ></span
                >
                <span style="color: rgb(89, 89, 89)"
                  ><strong
                    ><span style="font-size: 15px">411057</span></strong
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5"></div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span style="color: rgb(89, 89, 89)"
                  ><strong
                    ><span style="font-size: 15px">India</span></strong
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5"></div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span style="color: rgb(89, 89, 89)"
                  ><strong><span style="font-size: 15px">Phone:</span></strong>
                  <strong
                    ><span style="font-size: 15px"
                      ><span style="line-height: 115%; font-family: Arial"
                        >9881474184</span
                      ></span
                    ></strong
                  ></span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              ></div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5"
              >
                <span
                  style="
                    font-size: 15px;
                    line-height: 115%;
                    font-family: Arial;
                    color: rgb(89, 89, 89);
                  "
                  ><strong>fly@zoomlocal.in</strong></span
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="cancellation"
                style="line-height: 1.5"
              >
                <a name="_t4pq5cwn456q" id="_t4pq5cwn456q"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >31.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      Cancellation Policy</span
                    ></span
                  ></strong
                >
              </div>
            </div>

            <div align="center" style="text-align: left; margin-left: 10px;">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="cancellation1"
                style="line-height: 1.5"
              >
                <a name="_t4pq5cwn456q" id="_t4pq5cwn456q"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >31.1.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      Cancellation of Service</span
                    ></span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>

            <div align="center" style="text-align: left; margin-left: 10px;">
              <div
                class="MsoNormal"
                data-custom-class=""
                style="line-height: 1.5"
                >
                <span>
                  <span
                    style="font-size:14px;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                    >Your subscription to <strong>Fly</strong> can be canceled at any time. To cancel your subscription, please log in to your account and navigate to the account settings section. There you will find the option to cancel your subscription. 
                  </span>
                </span>
              </div>
            </div>
            <div align="center" style="text-align: left; margin-left: 10px;">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="cancellation2"
                style="line-height: 1.5"
              >
                <a name="_t4pq5cwn456q" id="_t4pq5cwn456q"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >31.2.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      Effective Date of Cancellation</span
                    ></span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left; margin-left: 10px;">
              <div
                class="MsoNormal"
                data-custom-class=""
                style="line-height: 1.5"
                >
                <span>
                  <span
                    style="font-size:14px;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                    >Cancellation will be effective immediately upon your request. Once the cancellation is processed, you will no longer have access to the premium features of <strong>Fly</strong> from the next billing cycle.
                  </span>
                </span>
              </div>
            </div>

            <div align="center" style="text-align: left; margin-left: 10px;">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="cancellation3"
                style="line-height: 1.5"
              >
                <a name="_t4pq5cwn456q" id="_t4pq5cwn456q"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >31.3.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      No Refunds</span
                    ></span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left; margin-left: 10px;">
              <div
                class="MsoNormal"
                data-custom-class=""
                style="line-height: 1.5"
                >
                <span>
                  <span
                    style="font-size:14px;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                    >Please note that we do not provide refunds for any remaining time on your subscription, including partial months or unused services. When you cancel, your current paid billing period will be your last.
                  </span>
                </span>
              </div>
            </div>

            <div align="center" style="text-align: left; margin-left: 10px;">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="cancellation4"
                style="line-height: 1.5"
              >
                <a name="_t4pq5cwn456q" id="_t4pq5cwn456q"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >31.4.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      Future Renewals</span
                    ></span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left; margin-left: 10px;">
              <div
                class="MsoNormal"
                data-custom-class=""
                style="line-height: 1.5"
                >
                <span>
                  <span
                    style="font-size:14px;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                    >Upon cancellation, all future renewals and associated charges will be discontinued. Your account will revert to the free version of <strong>Fly</strong>, if available, at the end of your current billing cycle.
                  </span>
                </span>
              </div>
            </div>

            <div align="center" style="text-align: left; margin-left: 10px;">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="cancellation5"
                style="line-height: 1.5"
              >
                <a name="_t4pq5cwn456q" id="_t4pq5cwn456q"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >31.5.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      Reactivation</span
                    ></span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left; margin-left: 10px;">
              <div
                class="MsoNormal"
                data-custom-class=""
                style="line-height: 1.5"
                >
                <span>
                  <span
                    style="font-size:14px;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                    >If you decide to reactivate your subscription in the future, you can do so by logging back into your account and updating your subscription preferences.
                  </span>
                </span>
              </div>
            </div>

            <div align="center" style="text-align: left; margin-left: 10px;">
              <div class="MsoNormal" style="line-height: 1.5">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="cancellation6"
                style="line-height: 1.5"
              >
                <a name="_t4pq5cwn456q" id="_t4pq5cwn456q"></a
                ><strong
                  ><span style="line-height: 115%; font-family: Arial"
                    ><span style="font-size: 19px"
                      ><strong
                        ><span
                          style="
                            line-height: 115%;
                            font-family: Arial;
                            font-size: 19px;
                          "
                          ><strong
                            ><span
                              style="
                                line-height: 115%;
                                font-family: Arial;
                                font-size: 19px;
                              "
                              >31.6.</span
                            ></strong
                          ></span
                        ></strong
                      >
                      Changes to Policy</span
                    ></span
                  ></strong
                >
              </div>
            </div>
            <div align="center" style="text-align: left; line-height: 1">
              <br />
            </div>
            <div align="center" style="text-align: left; margin-left: 10px;">
              <div
                class="MsoNormal"
                data-custom-class=""
                style="line-height: 1.5"
                >
                <span>
                  <span
                    style="font-size:14px;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                    >If you decide to reactivate your subscription in the future, you can do so by logging back into your account and updating your subscription preferences.
                  </span>
                </span>
              </div>
            </div>

            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
            <div align="center" style="text-align: left; line-height: 1.5">
              <br />
            </div>
          </div>
        </div>
      </div>
  
    </v-container>
  </template>
  <script>
  import AppLayout from "@/components/ui/AppLayout.vue";
  export default {
    components: {
      AppLayout,
    },
    mounted() {
    window.scrollTo(0, 0);
  },
  };
  </script>
  <style>
  [data-custom-class="body"],
  [data-custom-class="body"] * {
    background: transparent !important;
  }
  
  [data-custom-class="title"],
  [data-custom-class="title"] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
  }
  
  [data-custom-class="subtitle"],
  [data-custom-class="subtitle"] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
  }
  
  [data-custom-class="heading_1"],
  [data-custom-class="heading_1"] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
  }
  
  [data-custom-class="heading_2"],
  [data-custom-class="heading_2"] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
  }
  
  [data-custom-class="body_text"],
  [data-custom-class="body_text"] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
  }
  
  [data-custom-class="link"],
  [data-custom-class="link"] * {
    color: #3030f1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
  }
  </style>
  <style>
  ul {
    list-style-type: square;
  }
  
  ul > li > ul {
    list-style-type: circle;
  }
  
  ul > li > ul > li > ul {
    list-style-type: square;
  }
  
  ol li {
    font-family: Arial;
  }
  </style>
  