<template>
  <v-card flat tile max-width="500px" class="mx-auto mb-2">
    <v-list-item class="px-0">
      <v-list-item-avatar class="mr-2" size="40" @click="openProfile()">
        <v-img v-if="userData.picture" :src="userData.picture" width="30px"></v-img>
        <user-logo v-else :size="40" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          v-if="userData.name"
          class="text-sm md:text-lg d-flex align-center"
        >
          {{ userData.name }}
        </v-list-item-title>
        <v-list-item-title v-else> username </v-list-item-title>
        <v-list-item-subtitle class="text-xs md:text-sm">
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/around-a8790.appspot.com/o/fly_platforms%2Ficons%2Ftumblr.png?alt=media&token=ede65eca-41c8-4167-adbe-5031f4b35854"
          width="30px"
        ></v-img>
      </v-list-item-action>
    </v-list-item>
    <v-card-text class="pa-0" v-if="hasPreview">
      <div v-html="tumblr_text ? tumblr_text : content" class="mb-3" />
    </v-card-text>
    <v-card :href="url" target="_blank" v-if="hasPreview">
      <v-img :src="detail?.image" />
      <v-card-title>{{ detail?.title }}</v-card-title>
      <v-card-text>{{ detail?.description }}</v-card-text>
    </v-card>
    <v-card-text v-else class="pa-0">
      <div v-html="tumblr_text ? tumblr_text : content" class="mb-3" />
      <div v-html="hashtags" />
      <v-carousel
        hide-delimiters
        v-model="carousel"
        v-if="carousels.length"
        :show-arrows="carousels.length > 1"
        :height="isMobile ? '400px' : '500px'"
      >
        <v-carousel-item v-for="(item, i) in carousels" :key="i">
          <div class="h-full d-flex align-center black">
            <v-img :src="item.src" v-if="item.type == 'image'" />
            <video-auto-play
              v-if="item.type == 'video'"
              :autoplay="false"
              :videoSrc="item.src"
            />
          </div>
        </v-carousel-item>
      </v-carousel>
      <v-divider class="my-2"></v-divider>
      <div class="flex justify-between my-3 align-center">
        <div class="px-4 py-2 mr-2 border rounded-xl">
          <span class="font-bold">100</span> notes
        </div>

        <v-spacer></v-spacer>
        <!-- Add a spacer between "00 notes" and v-img elements -->

        <div class="d-flex align-center">
          <v-img
            max-width="25px"
            class="mt-1"
            src="https://img.icons8.com/fluency-systems-regular/48/forward-arrow--v1.png"
          ></v-img>
          <v-img
            max-width="23px"
            style=""
            class="mx-2 mt-1"
            src="https://img.icons8.com/fluency-systems-regular/48/speech-bubble--v1.png"
          ></v-img>
          <v-img
            max-width="23px"
            style=""
            class="mx-2 mt-1"
            src="https://img.icons8.com/material-outlined/24/retweet.png"
          ></v-img>
          <v-img
            max-width="25px"
            class="mt-1"
            src="https://img.icons8.com/fluency-systems-regular/48/like--v1.png"
          ></v-img>
        </div>
      </div>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import UserLogo from "@/components/UserLogo.vue";
import VideoAutoPlay from "@/components/public/VideoAutoPlay.vue";
import { doc, getDoc } from "firebase/firestore";
import { auth, colUsers } from "@/utils/firebase.utils";
export default {
  components: { UserLogo, VideoAutoPlay },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    userData: {
      type: Array,
      default: () => [],
    },
    detail: Object,
    hasPreview: Boolean,
    url: String,
    configuration: Object,
    tumblr_text: String,
  },
  data() {
    return {
      carousel: 0,
      blog: "",
      picture: "",
      name: "",
    };
  },
  watch: {
    "configuration.blog": {
      handler(val) {
        this.fetchTumblrData();
      },
    },
  },
  computed: {
    logoName() {
      let name = this.currentUser.display_name || "";
      return name.charAt(0).toUpperCase();
    },
    hashtags() {
      let hashtags = this.post.hashtags || [];
      return hashtags
        .map((i) => {
          if (!i.startsWith("#")) return `#${i}`;
          return i;
        })
        .toString()
        .split(",")
        .join(" ");
    },
    content() {
      let content = this.post.text || "";
      // content = content.toString().replace(/(?:\r\n|\r|\n)/g, " <br>");
      content = content.toString().replace(/(?:\r\n|\r|\n)+/g, "<br>");
      content = content.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" class="primary--text text-decoration-underline">$1</a>'
      );
      return content;
    },
    carousels() {
      let list = [];
      if (this.post.videos) {
        // console.log(this.post.videos);
        this.post.videos.forEach((item) => {
          list.push({
            src: item.url || item,
            type: "video",
          });
        });
      }
      if (this.post.images) {
        if (this.post.images.length > 10) {
          let images = this.post.images.slice(0, 10);
          images.forEach((item) => {
            list.push({
              src: item.thumbnail || item.url || item,
              type: "image",
            });
          });
        } else {
          this.post.images.forEach((item) => {
            list.push({
              src: item.thumbnail || item.url || item,
              type: "image",
            });
          });
        }
      }
      return list;
    },
  },
  methods: {
    async fetchTumblrData() {
      const connectedAppsRef = doc(
        colUsers,
        this.currentUID,
        "fly_workspaces",
        this.WID,
        "connected-apps",
        "tumblr"
      );
      const connectedAppsSnap = await getDoc(connectedAppsRef);
      const connectedAppsData = connectedAppsSnap.data();
      this.blog = this.configuration.blog;
      connectedAppsData?.blogs.map((blog) => {
        if (blog.name === this.blog) {
          if (blog.picture) {
            this.picture = blog.picture;
          }
          if (blog.name) {
            this.name = blog.name;
          }
          return true;
        }
      });
      return false;
    },
  },
};
</script>

<style></style>
