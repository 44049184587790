<template>
  <div>
    <label class="input-label" v-if="label">{{ label }}</label>
    <v-textarea
      v-model="input"
      :dense="dense"
      :class="label ? 'mt-1' : ''"
      v-bind="$attrs"
      rows="3"
      outlined
      :rules="getRules"
      @input="$emit('input', input)"
    />
  </div>
</template>

<script>
import inputMixin from "@/mixins/input.mixin.js";
export default {
  mixins: [inputMixin],
};
</script>
