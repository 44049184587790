import { collection, doc, setDoc,getDoc, getDocs } from "firebase/firestore";
import { db, messaging, vapidKey, functions } from "@/utils/firebase.utils";
import { getToken } from "firebase/messaging";
import { httpsCallable } from "firebase/functions";

const pushNotification = {
  install(Vue) {
    Vue.prototype.$pushNotification = async ({uid, wid, payload}) => {
      try {
        console.log(uid,"this is uid of notifier");
        const notificationsRef = collection(db, 'users', uid, 'notifications');
        const notificationDoc = doc(notificationsRef);
        await setDoc(notificationDoc, {
          icon: payload.icon || 'default',
          title: payload.title || 'Default Title',
          message: payload.message || 'default message',
          url: payload.url || null,
          date: new Date(),
          status: "unread",
          wid: wid || null,
          token: payload.token,
        });
        let newPayload;
        if(payload.isEmail){
          if(payload?.sendPostMail){
            const recieverRef = doc(db, 'users', payload.recieverUID);
            const recieverData = (await getDoc(recieverRef)).data();
            newPayload = {
              reply:payload.reply||false,
              sendPostMail:true,
              sender_name:payload.name||'',
              reciever_name:recieverData.display_name||'',
              email:recieverData.email,
              post_first_image:payload.image||'',
              post_caption:payload.text||'',
              comment:payload.comment||"No comments",
              post_url:payload.url||'',
              status:payload.status||false,
            }
          }
          const sendEmail = httpsCallable(functions,'sendEmailFunction');
          const response = await sendEmail(newPayload)
          if (response.data.success) {
            console.log("Push Notification sent")
          } else {
            console.log("failed to send Push Notification")
          }
        }


        // Send push notification if permission is granted
        getToken(messaging, { vapidKey }).then(async (currentToken) => {
          if (currentToken) {
            // Send the notification to the device
            const messagePayload = {
              notification: {
                title: payload.title,
                body: payload.message,
                icon: payload.icon
              },
              to: currentToken
            };

            const sendPushNotification = httpsCallable(functions, 'sendPushNotification');
            const response = await sendPushNotification( {
              uid,
              icon: payload.icon || 'default',
              title: payload.title || 'Default Title',
              message: payload.message || 'default message',
              url: payload.url || '',
              currentToken,
            });

            if (response.data.success) {
              console.log("Push Notification sent")
            } else {
              console.log("failed to send Push Notification")
            }

          } else {
            console.error("No registration token available. Request permission to generate one.");
          }
        }).catch((err) => {
          console.error("An error occurred while retrieving token. ", err);
        });

      } catch (error) {
        console.error("Error pushing notification: ", error);
      }
    };
  }
};

export default pushNotification;
