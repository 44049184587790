/*!
* Pintura Video Extension v1.8.1 
* (c) 2018-2023 PQINA Inc. - All Rights Reserved
* License: https://pqina.nl/pintura/license/
*/
/* eslint-disable */
const io = (e) => typeof e == "function";
let Nt = null;
const st = () => (Nt === null && (Nt = typeof window < "u" && typeof window.document < "u"), Nt), sn = (e) => st() ? RegExp(e).test(window.navigator.userAgent) : void 0;
let Ht = null;
const $o = () => (Ht === null && (Ht = sn(/Android/)), Ht), so = (e, t, n = []) => {
  const o = document.createElement(e), r = Object.getOwnPropertyDescriptors(o.__proto__);
  for (const i in t)
    i === "style" ? o.style.cssText = t[i] : r[i] && r[i].set || /textContent|innerHTML/.test(i) || typeof t[i] == "function" ? o[i] = t[i] : o.setAttribute(i, t[i]);
  return n.forEach((i) => o.appendChild(i)), o;
}, ao = (e) => {
  e.width = 1, e.height = 1;
  const t = e.getContext("2d");
  t && t.clearRect(0, 0, 1, 1);
}, er = (e, t = void 0, n = void 0) => new Promise((o, r) => {
  try {
    e.toBlob(
      (i) => {
        if (!i)
          return r(new Error("Failed to create blob"));
        o(i);
      },
      t,
      n
    );
  } catch (i) {
    r(i);
  }
}), Pn = "__pqina_webapi__", lo = (e) => window[Pn] ? window[Pn][e] : window[e], Se = (...e) => {
}, tr = {
  matroska: "mkv"
}, co = (e) => {
  const t = (e.match(/\/([a-z0-9]+)/) || [])[1];
  if (/^x/.test(t)) {
    const [, n = ""] = e.split("/x-");
    return tr[n];
  }
  return t;
}, nr = (e) => e.substr(0, e.lastIndexOf(".")) || e, or = (e) => e.split(".").pop(), rr = /avif|bmp|gif|jpg|jpeg|jpe|jif|jfif|png|svg|tiff|webp/, ir = (e) => rr.test(e) ? "image/" + (/jfif|jif|jpe|jpg/.test(e) ? "jpeg" : e === "svg" ? "svg+xml" : e) : "", sr = (e) => e && ir(or(e).toLowerCase()), ar = (e, t) => {
  const n = sr(e);
  if (n === t)
    return e;
  const o = co(t) || n;
  return `${nr(e)}.${o}`;
}, lr = (e, t, n) => {
  const o = (/* @__PURE__ */ new Date()).getTime(), r = e.type.length && !/null|text/.test(e.type), i = r ? e.type : n, s = ar(t, i);
  try {
    return new (lo("File"))([e], s, {
      lastModified: o,
      type: r ? e.type : i
    });
  } catch {
    const u = r ? e.slice() : e.slice(0, e.size, i);
    return u.lastModified = o, u.name = s, u;
  }
}, En = (e) => e, De = (e, t = 12) => parseFloat(e.toFixed(t)), cr = () => Ve(0, 0), Ve = (e, t) => ({ x: e, y: t }), ur = (e) => Ve(e.width, e.height), kn = (e) => Ve(e.x, e.y), fr = (e) => (e.x = -e.x, e.y = -e.y, e), uo = (e, t, n = cr()) => {
  const o = Math.cos(t), r = Math.sin(t), i = e.x - n.x, s = e.y - n.y;
  return e.x = n.x + o * i - r * s, e.y = n.y + r * i + o * s, e;
}, dr = (e, t) => (e.x = t(e.x), e.y = t(e.y), e), mr = (e, t) => (e.x += t.x, e.y += t.y, e), fo = (e, t) => (e.x -= t.x, e.y -= t.y, e), gr = (e, t) => ({ width: e, height: t }), hr = (e) => gr(e.width, e.height), _r = (e, t) => (e.width = t(e.width), e.height = t(e.height), e), It = (e, t, n, o) => ({
  x: e,
  y: t,
  width: n,
  height: o
}), mo = (e) => It(0, 0, e.width, e.height), pr = (e) => It(e.x || 0, e.y || 0, e.width || 0, e.height || 0), go = (e) => {
  let t = e[0].x, n = e[0].x, o = e[0].y, r = e[0].y;
  return e.forEach((i) => {
    t = Math.min(t, i.x), n = Math.max(n, i.x), o = Math.min(o, i.y), r = Math.max(r, i.y);
  }), It(t, o, n - t, r - o);
}, yr = (e, t, n, o) => It(e, t, n, o), ot = (e) => Ve(e.x + e.width * 0.5, e.y + e.height * 0.5), ho = (e, t, n) => (n || (n = ot(e)), br(e).map((o) => uo(o, t, n))), br = (e) => [
  Ve(e.x, e.y),
  Ve(e.x + e.width, e.y),
  Ve(e.x + e.width, e.y + e.height),
  Ve(e.x, e.y + e.height)
], wr = (e) => (e.x = 0, e.y = 0, e), Ft = (e) => typeof e == "string";
st() && Node.prototype.replaceChildren;
st() && so("div", {
  class: "PinturaMeasure",
  style: "position:absolute;left:0;top:0;width:99999px;height:0;pointer-events:none;contain:strict;margin:0;padding:0;"
});
const Rn = (e = 0, t = !0) => new (lo("ProgressEvent"))("progress", {
  loaded: e * 100,
  total: 100,
  lengthComputable: t
}), Mr = (e, t) => {
  const { headers: n = {}, credentials: o } = t || {};
  Object.entries(n).forEach(
    ([r, i]) => e.setRequestHeader(r, i)
  ), o && (e.withCredentials = o !== "omit");
};
let Wt = null;
const _o = () => (Wt === null && (Wt = st() && /^mac/i.test(navigator.platform)), Wt);
let qt = null;
const Jt = () => (qt === null && (qt = st() && (sn(/iPhone|iPad|iPod/) || _o() && navigator.maxTouchPoints >= 1)), qt), vr = (e) => typeof e == "object" && e.constructor == Object, Sr = (e) => vr(e) ? JSON.stringify(e) : e, Pr = (e, t, n) => new Promise((o, r) => {
  const { token: i = {}, beforeSend: s = Se, onprogress: l = Se } = n;
  i.cancel = () => u.abort();
  const u = new XMLHttpRequest();
  u.upload.onprogress = l, u.onload = () => u.status >= 200 && u.status < 300 ? o(u) : r(u), u.onerror = () => r(u), u.ontimeout = () => r(u), u.open("POST", encodeURI(e)), s(u), u.send(
    // if is FormData, we use that
    t instanceof FormData ? t : (
      // reduce the dataset to FormData
      t.reduce((c, f) => (c.append(...f.map(Sr)), c), new FormData())
    )
  );
}), ht = (e) => typeof e == "number", Er = (e) => Array.isArray(e);
let Xt = null;
const Dt = () => (Xt === null && (Xt = sn(/Firefox/)), Xt), kr = (e, t) => {
  const n = new Array(20);
  return n[0] = e[0] * t[0] + e[1] * t[5] + e[2] * t[10] + e[3] * t[15], n[1] = e[0] * t[1] + e[1] * t[6] + e[2] * t[11] + e[3] * t[16], n[2] = e[0] * t[2] + e[1] * t[7] + e[2] * t[12] + e[3] * t[17], n[3] = e[0] * t[3] + e[1] * t[8] + e[2] * t[13] + e[3] * t[18], n[4] = e[0] * t[4] + e[1] * t[9] + e[2] * t[14] + e[3] * t[19] + e[4], n[5] = e[5] * t[0] + e[6] * t[5] + e[7] * t[10] + e[8] * t[15], n[6] = e[5] * t[1] + e[6] * t[6] + e[7] * t[11] + e[8] * t[16], n[7] = e[5] * t[2] + e[6] * t[7] + e[7] * t[12] + e[8] * t[17], n[8] = e[5] * t[3] + e[6] * t[8] + e[7] * t[13] + e[8] * t[18], n[9] = e[5] * t[4] + e[6] * t[9] + e[7] * t[14] + e[8] * t[19] + e[9], n[10] = e[10] * t[0] + e[11] * t[5] + e[12] * t[10] + e[13] * t[15], n[11] = e[10] * t[1] + e[11] * t[6] + e[12] * t[11] + e[13] * t[16], n[12] = e[10] * t[2] + e[11] * t[7] + e[12] * t[12] + e[13] * t[17], n[13] = e[10] * t[3] + e[11] * t[8] + e[12] * t[13] + e[13] * t[18], n[14] = e[10] * t[4] + e[11] * t[9] + e[12] * t[14] + e[13] * t[19] + e[14], n[15] = e[15] * t[0] + e[16] * t[5] + e[17] * t[10] + e[18] * t[15], n[16] = e[15] * t[1] + e[16] * t[6] + e[17] * t[11] + e[18] * t[16], n[17] = e[15] * t[2] + e[16] * t[7] + e[17] * t[12] + e[18] * t[17], n[18] = e[15] * t[3] + e[16] * t[8] + e[17] * t[13] + e[18] * t[18], n[19] = e[15] * t[4] + e[16] * t[9] + e[17] * t[14] + e[18] * t[19] + e[19], n;
}, Rr = (e) => e.length ? e.reduce(
  (t, n) => kr([...t], n),
  e.shift()
) : [];
function ue() {
}
function _t(e, t) {
  for (const n in t)
    e[n] = t[n];
  return e;
}
function po(e) {
  return e();
}
function Tn() {
  return /* @__PURE__ */ Object.create(null);
}
function Me(e) {
  e.forEach(po);
}
function Oe(e) {
  return typeof e == "function";
}
function ke(e, t) {
  return e != e ? t == t : e !== t || e && typeof e == "object" || typeof e == "function";
}
function Tr(e) {
  return Object.keys(e).length === 0;
}
function Lt(e, ...t) {
  if (e == null)
    return ue;
  const n = e.subscribe(...t);
  return n.unsubscribe ? () => n.unsubscribe() : n;
}
function le(e, t, n) {
  e.$$.on_destroy.push(Lt(t, n));
}
function We(e, t, n, o) {
  if (e) {
    const r = yo(e, t, n, o);
    return e[0](r);
  }
}
function yo(e, t, n, o) {
  return e[1] && o ? _t(n.ctx.slice(), e[1](o(t))) : n.ctx;
}
function qe(e, t, n, o) {
  if (e[2] && o) {
    const r = e[2](o(n));
    if (t.dirty === void 0)
      return r;
    if (typeof r == "object") {
      const i = [], s = Math.max(t.dirty.length, r.length);
      for (let l = 0; l < s; l += 1)
        i[l] = t.dirty[l] | r[l];
      return i;
    }
    return t.dirty | r;
  }
  return t.dirty;
}
function Xe(e, t, n, o, r, i) {
  if (r) {
    const s = yo(t, n, o, i);
    e.p(s, r);
  }
}
function Ge(e) {
  if (e.ctx.length > 32) {
    const t = [], n = e.ctx.length / 32;
    for (let o = 0; o < n; o++)
      t[o] = -1;
    return t;
  }
  return -1;
}
function Cn(e) {
  const t = {};
  for (const n in e)
    n[0] !== "$" && (t[n] = e[n]);
  return t;
}
function Ke(e) {
  return e && Oe(e.destroy) ? e.destroy : ue;
}
const Cr = typeof window < "u";
let bo = Cr ? (e) => requestAnimationFrame(e) : ue;
const rt = /* @__PURE__ */ new Set();
function wo(e) {
  rt.forEach((t) => {
    t.c(e) || (rt.delete(t), t.f());
  }), rt.size !== 0 && bo(wo);
}
function Ar(e) {
  let t;
  return rt.size === 0 && bo(wo), {
    promise: new Promise((n) => {
      rt.add(t = { c: e, f: n });
    }),
    abort() {
      rt.delete(t);
    }
  };
}
const Mo = typeof window < "u" ? window : typeof globalThis < "u" ? globalThis : global;
"WeakMap" in Mo;
function $(e, t) {
  e.appendChild(t);
}
function oe(e, t, n) {
  e.insertBefore(t, n || null);
}
function ne(e) {
  e.parentNode && e.parentNode.removeChild(e);
}
function vo(e, t) {
  for (let n = 0; n < e.length; n += 1)
    e[n] && e[n].d(t);
}
function ee(e) {
  return document.createElement(e);
}
function bt(e) {
  return document.createElementNS("http://www.w3.org/2000/svg", e);
}
function pt(e) {
  return document.createTextNode(e);
}
function ce() {
  return pt(" ");
}
function So() {
  return pt("");
}
function me(e, t, n, o) {
  return e.addEventListener(t, n, o), () => e.removeEventListener(t, n, o);
}
function y(e, t, n) {
  n == null ? e.removeAttribute(t) : e.getAttribute(t) !== n && e.setAttribute(t, n);
}
function Fr(e) {
  return Array.from(e.childNodes);
}
function Qt(e, t) {
  t = "" + t, e.data !== t && (e.data = t);
}
function Lr(e, t, { bubbles: n = !1, cancelable: o = !1 } = {}) {
  const r = document.createEvent("CustomEvent");
  return r.initCustomEvent(e, n, o, t), r;
}
class Br {
  constructor(t = !1) {
    this.is_svg = !1, this.is_svg = t, this.e = this.n = null;
  }
  c(t) {
    this.h(t);
  }
  m(t, n, o = null) {
    this.e || (this.is_svg ? this.e = bt(n.nodeName) : this.e = ee(n.nodeType === 11 ? "TEMPLATE" : n.nodeName), this.t = n.tagName !== "TEMPLATE" ? n : n.content, this.c(t)), this.i(o);
  }
  h(t) {
    this.e.innerHTML = t, this.n = Array.from(this.e.nodeName === "TEMPLATE" ? this.e.content.childNodes : this.e.childNodes);
  }
  i(t) {
    for (let n = 0; n < this.n.length; n += 1)
      oe(this.t, this.n[n], t);
  }
  p(t) {
    this.d(), this.h(t), this.i(this.a);
  }
  d() {
    this.n.forEach(ne);
  }
}
let yt;
function gt(e) {
  yt = e;
}
function an() {
  if (!yt)
    throw new Error("Function called outside component initialization");
  return yt;
}
function Ir(e) {
  an().$$.on_mount.push(e);
}
function Dr() {
  const e = an();
  return (t, n, { cancelable: o = !1 } = {}) => {
    const r = e.$$.callbacks[t];
    if (r) {
      const i = Lr(t, n, { cancelable: o });
      return r.slice().forEach((s) => {
        s.call(e, i);
      }), !i.defaultPrevented;
    }
    return !0;
  };
}
function xe(e) {
  return an().$$.context.get(e);
}
function ln(e, t) {
  const n = e.$$.callbacks[t.type];
  n && n.slice().forEach((o) => o.call(this, t));
}
const nt = [], je = [];
let it = [];
const xt = [], Or = /* @__PURE__ */ Promise.resolve();
let $t = !1;
function zr() {
  $t || ($t = !0, Or.then(Re));
}
function en(e) {
  it.push(e);
}
function Ur(e) {
  xt.push(e);
}
const Gt = /* @__PURE__ */ new Set();
let et = 0;
function Re() {
  if (et !== 0)
    return;
  const e = yt;
  do {
    try {
      for (; et < nt.length; ) {
        const t = nt[et];
        et++, gt(t), Vr(t.$$);
      }
    } catch (t) {
      throw nt.length = 0, et = 0, t;
    }
    for (gt(null), nt.length = 0, et = 0; je.length; )
      je.pop()();
    for (let t = 0; t < it.length; t += 1) {
      const n = it[t];
      Gt.has(n) || (Gt.add(n), n());
    }
    it.length = 0;
  } while (nt.length);
  for (; xt.length; )
    xt.pop()();
  $t = !1, Gt.clear(), gt(e);
}
function Vr(e) {
  if (e.fragment !== null) {
    e.update(), Me(e.before_update);
    const t = e.dirty;
    e.dirty = [-1], e.fragment && e.fragment.p(e.ctx, t), e.after_update.forEach(en);
  }
}
function jr(e) {
  const t = [], n = [];
  it.forEach((o) => e.indexOf(o) === -1 ? t.push(o) : n.push(o)), n.forEach((o) => o()), it = t;
}
const At = /* @__PURE__ */ new Set();
let Qe;
function Be() {
  Qe = {
    r: 0,
    c: [],
    p: Qe
    // parent group
  };
}
function Ie() {
  Qe.r || Me(Qe.c), Qe = Qe.p;
}
function I(e, t) {
  e && e.i && (At.delete(e), e.i(t));
}
function N(e, t, n, o) {
  if (e && e.o) {
    if (At.has(e))
      return;
    At.add(e), Qe.c.push(() => {
      At.delete(e), o && (n && e.d(1), o());
    }), e.o(t);
  } else
    o && o();
}
function An(e, t) {
  const n = {}, o = {}, r = { $$scope: 1 };
  let i = e.length;
  for (; i--; ) {
    const s = e[i], l = t[i];
    if (l) {
      for (const u in s)
        u in l || (o[u] = 1);
      for (const u in l)
        r[u] || (n[u] = l[u], r[u] = 1);
      e[i] = l;
    } else
      for (const u in s)
        r[u] = 1;
  }
  for (const s in o)
    s in n || (n[s] = void 0);
  return n;
}
function Ct(e) {
  return typeof e == "object" && e !== null ? e : {};
}
const Kr = [
  "allowfullscreen",
  "allowpaymentrequest",
  "async",
  "autofocus",
  "autoplay",
  "checked",
  "controls",
  "default",
  "defer",
  "disabled",
  "formnovalidate",
  "hidden",
  "inert",
  "ismap",
  "loop",
  "multiple",
  "muted",
  "nomodule",
  "novalidate",
  "open",
  "playsinline",
  "readonly",
  "required",
  "reversed",
  "selected"
];
[...Kr];
function Nr(e, t, n) {
  const o = e.$$.props[t];
  o !== void 0 && (e.$$.bound[o] = n, n(e.$$.ctx[o]));
}
function be(e) {
  e && e.c();
}
function _e(e, t, n, o) {
  const { fragment: r, after_update: i } = e.$$;
  r && r.m(t, n), o || en(() => {
    const s = e.$$.on_mount.map(po).filter(Oe);
    e.$$.on_destroy ? e.$$.on_destroy.push(...s) : Me(s), e.$$.on_mount = [];
  }), i.forEach(en);
}
function pe(e, t) {
  const n = e.$$;
  n.fragment !== null && (jr(n.after_update), Me(n.on_destroy), n.fragment && n.fragment.d(t), n.on_destroy = n.fragment = null, n.ctx = []);
}
function Hr(e, t) {
  e.$$.dirty[0] === -1 && (nt.push(e), zr(), e.$$.dirty.fill(0)), e.$$.dirty[t / 31 | 0] |= 1 << t % 31;
}
function Te(e, t, n, o, r, i, s, l = [-1]) {
  const u = yt;
  gt(e);
  const c = e.$$ = {
    fragment: null,
    ctx: [],
    // state
    props: i,
    update: ue,
    not_equal: r,
    bound: Tn(),
    // lifecycle
    on_mount: [],
    on_destroy: [],
    on_disconnect: [],
    before_update: [],
    after_update: [],
    context: new Map(t.context || (u ? u.$$.context : [])),
    // everything else
    callbacks: Tn(),
    dirty: l,
    skip_bound: !1,
    root: t.target || u.$$.root
  };
  s && s(c.root);
  let f = !1;
  if (c.ctx = n ? n(e, t.props || {}, (d, M, ..._) => {
    const v = _.length ? _[0] : M;
    return c.ctx && r(c.ctx[d], c.ctx[d] = v) && (!c.skip_bound && c.bound[d] && c.bound[d](v), f && Hr(e, d)), M;
  }) : [], c.update(), f = !0, Me(c.before_update), c.fragment = o ? o(c.ctx) : !1, t.target) {
    if (t.hydrate) {
      const d = Fr(t.target);
      c.fragment && c.fragment.l(d), d.forEach(ne);
    } else
      c.fragment && c.fragment.c();
    t.intro && I(e.$$.fragment), _e(e, t.target, t.anchor, t.customElement), Re();
  }
  gt(u);
}
class Ce {
  $destroy() {
    pe(this, 1), this.$destroy = ue;
  }
  $on(t, n) {
    if (!Oe(n))
      return ue;
    const o = this.$$.callbacks[t] || (this.$$.callbacks[t] = []);
    return o.push(n), () => {
      const r = o.indexOf(n);
      r !== -1 && o.splice(r, 1);
    };
  }
  $set(t) {
    this.$$set && !Tr(t) && (this.$$.skip_bound = !0, this.$$set(t), this.$$.skip_bound = !1);
  }
}
const tt = [];
function Wr(e, t) {
  return {
    subscribe: Po(e, t).subscribe
  };
}
function Po(e, t = ue) {
  let n;
  const o = /* @__PURE__ */ new Set();
  function r(l) {
    if (ke(e, l) && (e = l, n)) {
      const u = !tt.length;
      for (const c of o)
        c[1](), tt.push(c, e);
      if (u) {
        for (let c = 0; c < tt.length; c += 2)
          tt[c][0](tt[c + 1]);
        tt.length = 0;
      }
    }
  }
  function i(l) {
    r(l(e));
  }
  function s(l, u = ue) {
    const c = [l, u];
    return o.add(c), o.size === 1 && (n = t(r) || ue), l(e), () => {
      o.delete(c), o.size === 0 && n && (n(), n = null);
    };
  }
  return { set: r, update: i, subscribe: s };
}
function qr(e, t, n) {
  const o = !Array.isArray(e), r = o ? [e] : e, i = t.length < 2;
  return Wr(n, (s) => {
    let l = !1;
    const u = [];
    let c = 0, f = ue;
    const d = () => {
      if (c)
        return;
      f();
      const _ = t(o ? u[0] : u, s);
      i ? s(_) : f = Oe(_) ? _ : ue;
    }, M = r.map((_, v) => Lt(_, (m) => {
      u[v] = m, c &= ~(1 << v), l && d();
    }, () => {
      c |= 1 << v;
    }));
    return l = !0, d(), function() {
      Me(M), f(), l = !1;
    };
  });
}
const Xr = (e, t, n) => {
  const o = mo(e), r = ot(o), i = ho(o, n, r), s = ot(
    wr(go(i))
  ), l = ot(t), u = uo(
    l,
    -n,
    s
  ), c = fo(
    u,
    s
  ), f = dr(
    mr(r, c),
    De
  );
  return yr(
    f.x - t.width * 0.5,
    f.y - t.height * 0.5,
    t.width,
    t.height
  );
}, Bt = (e, t, n) => Math.max(t, Math.min(e, n)), cn = (e, t = (...o) => o, n) => async (o, r, i) => {
  i(Rn(0, !1));
  let s = !1;
  const l = await e(
    ...t(o, r, (u) => {
      s = !0, i(u);
    })
  );
  return n && n(o, l), s || i(Rn(1, !1)), o;
}, Gr = ({
  renameFile: e = void 0,
  srcBlob: t = "blob",
  srcFile: n = "src",
  destFile: o = "dest",
  defaultFilename: r = void 0
} = {}) => [
  cn(
    lr,
    (i) => [
      i[t],
      e ? e(i[n]) : i[n].name || `${r}.${co(i[t].type)}`
    ],
    (i, s) => i[o] = s
  ),
  "blob-to-file"
], Fn = ({
  url: e = "./",
  dataset: t = (i) => [
    ["dest", i.dest, i.dest.name],
    ["imageState", i.imageState]
  ],
  destStore: n = "store",
  credentials: o,
  headers: r = {}
}) => [
  cn(
    // upload function
    async (i, s) => await Pr(e, i, {
      onprogress: s,
      beforeSend: (l) => Mr(l, { headers: r, credentials: o })
    }),
    // get state values
    (i, s, l) => [
      t(i),
      l
    ],
    // set state values
    (i, s) => i[n] = s
    // logs XHR request returned by `post`
  ),
  "store"
], Zr = (e) => [
  cn((t) => (!e || !e.length || Object.keys(t).forEach((n) => {
    e.includes(n) || delete t[n];
  }), t)),
  "prop-filter"
], Yr = (e) => e && (Ft(e) ? (
  // a basic store to post to
  Fn({ url: e })
) : (
  // see if is fully custom or store config
  io(e) ? (
    // fully custom store function
    [e, "store"]
  ) : (
    // a store configuration object
    Fn(e)
  )
)), hs = (e) => (t, n, o = {}) => {
  const {
    encoder: r = void 0,
    renameFile: i = void 0,
    store: s = void 0,
    targetSize: l = void 0,
    outputProps: u = ["src", "dest", "imageState", "store"],
    filter: c = void 0
  } = { ...o, ...e };
  if (/video/.test(t.type)) {
    if (r === void 0) {
      console.warn("No encoder supplied.");
      return;
    }
    if (!(io(c) && !c(t, n)))
      return [
        // encode video
        [
          r({
            targetSize: l
          }),
          "encode-video"
        ],
        // convert to file
        Gr({ defaultFilename: "video", renameFile: i }),
        // store video
        Yr(s),
        // remove unwanted props
        Zr(u)
      ].filter(Boolean);
  }
}, un = (e) => !!e.mozHasAudio || !!e.webkitAudioDecodedByteCount || !!(e.audioTracks && e.audioTracks.length), Jr = (e, t) => e[(e.captureStream ? "c" : "mozC") + "aptureStream"](t), Qr = [
  // prefer mp4
  "video/mp4;codecs=avc1,aac",
  "video/mp4;codecs=avc,aac",
  "video/mp4;codecs=mp4a,aac",
  "video/mp4;codecs=h264,aac",
  "video/mp4",
  // webm usually possible
  "video/webm;codecs=vp9,opus",
  "video/webm;codecs=vp8,opus",
  "video/webm;codecs=h264,opus",
  "video/webm",
  // matroska for Linux
  "video/x-matroska;codecs=avc1,opus",
  "video/x-matroska;codecs=vp9,opus",
  "video/x-matroska;codecs=vp8,opus",
  "video/x-matroska"
], Ln = (e, t) => {
  const n = e.createMediaStreamDestination();
  return e.createMediaElementSource(t).connect(n), n.stream;
}, xr = (e, t, n, o, r) => new Promise((i, s) => {
  const {
    trim: l = [[0, 1]],
    volume: u,
    framesPerSecond: c = 24,
    mimeType: f,
    setupCanvas: d,
    prepareCanvas: M,
    updateCanvas: _,
    audioBitrate: v,
    videoBitrate: m,
    mimeTypes: S = Qr
  } = t, w = f || S.find((b) => MediaRecorder.isTypeSupported(b));
  let h, k = u === 0, P = !0, A = !1;
  o.cancel = () => {
    A = !0, r("cancelled"), i();
  };
  const B = new AudioContext();
  Jt() && B.resume();
  let L = !1, O;
  const H = (b) => {
    if (!n)
      return;
    const z = Math.round(b * 100);
    z !== O && (O = z, n(
      new ProgressEvent("progress", {
        loaded: z,
        total: 100,
        lengthComputable: !0
      })
    ));
  };
  r("recordVideo", w), H(0);
  const V = () => {
    if (r("detectVideoReady", a.readyState), !A && !L) {
      if (a.readyState === 4) {
        P = un(a), H(0.25), D();
        return;
      }
      setTimeout(V, 16);
    }
  }, W = (b, z) => new Promise((R) => {
    r("drawRange", b);
    let T = 0;
    const [j, G] = b, Z = a.duration, q = Math.max(j, 0) * Z, K = Math.min(G, 1) * Z, x = K - q, ge = () => {
      if (A)
        return;
      const fe = a.currentTime;
      if (r("draw", fe), _(a), z(Math.min(1 - (K - fe) / x, 1)), T++, fe >= K) {
        r("draw calls", T), R();
        return;
      }
      requestAnimationFrame(ge);
    };
    ge();
  }), D = async () => {
    if (r("videoReady"), A)
      return;
    const b = d(a);
    r("captureVideo", !0);
    const R = [...Jr(b, c).getTracks()];
    r("captureAudio", P && !k);
    const T = (K, x) => {
      const ge = B.createMediaStreamSource(K), fe = B.createMediaStreamDestination(), ae = B.createGain();
      return ae.gain.value = x, ge.connect(ae), ae.connect(fe), fe.stream.getTracks();
    };
    if (P && !k) {
      const K = Ln(B, a), x = u ? T(K, u) : K.getTracks();
      R.push(...x);
    } else {
      if (Dt()) {
        const K = Ln(B, a);
        R.push(
          ...K.getTracks().map((x) => (x.enabled = !1, x))
        );
      }
      a.muted = !0;
    }
    r("create MediaStream");
    const j = new MediaStream(R.filter(Boolean)), G = P && !k ? v || 48e3 : 0, Z = m || 384e3;
    r("create MediaRecorder"), h = new MediaRecorder(j, {
      mimeType: w,
      audioBitsPerSecond: G,
      videoBitsPerSecond: Z
    });
    const q = [];
    h.ondataavailable = ({ data: K }) => {
      if (r("ondataavailable", K), K.size === 0 && q.every((x) => x.size === 0)) {
        A = !0, q.length = 0, h.stop();
        return;
      }
      q.push(K);
    }, h.onstop = () => {
      r("onstop"), ao(b);
      const K = a.src;
      if (a.src = "", URL.revokeObjectURL(K), H(1), !q.length)
        return s(new Error("No data received"));
      i(new Blob(q, { type: w.split(";")[0] }));
    }, await M(), te();
  }, Y = (b, z) => new Promise((R, T) => {
    r("startRecording"), a.play().then(() => {
      r("startDrawing"), W(b, z).then(R).catch(T), h.state === "paused" ? h.resume() : h.start(500);
    }).catch(T);
  }), Q = () => {
    r("pauseRecording"), a.pause(), h.state === "recording" && h.pause();
  }, U = () => {
    r("stopRecording"), a.pause(), h.stop();
  }, te = async () => {
    const b = l.reduce((T, j) => {
      const [G, Z] = j;
      return T + (Z - G) * a.duration;
    }, 0);
    let z = 0;
    r("record", b);
    const R = l.entries();
    for (const [T, j] of R) {
      const [G, Z] = j, q = (Z - G) * a.duration;
      try {
        await se(j, (K) => {
          const x = z + K * q;
          H(0.25 + x / b * 0.75);
        });
      } catch (K) {
        s(K);
        return;
      }
      z += q, T < l.length && Q();
    }
    U();
  }, se = (b, z) => new Promise((R, T) => {
    r("recordRange", b);
    const [j] = b;
    a.onseeked = void 0;
    let G = !1;
    const Z = () => {
      if (r("detectRangeReady", G), !A) {
        if (G) {
          q();
          return;
        }
        a.onseeked || (a.onseeked = () => G = !0, a.currentTime = a.duration * j), setTimeout(Z, 16);
      }
    }, q = () => {
      r("rangeReady"), _(a), setTimeout(() => {
        Y(b, z).then(R).catch(T);
      }, 500);
    };
    Z();
  });
  r("create video");
  const a = document.createElement("video");
  a.preload = "auto", a.onerror = (b) => {
    L = !0, s(b);
  }, a.src = URL.createObjectURL(e), a.playsInline = !0, a.load(), V();
}), Eo = async (e) => {
  const t = await $r(e), n = new DataView(t);
  return ii(n);
}, $r = (e) => new Promise((t) => {
  const n = new FileReader();
  n.onload = () => t(n.result), n.readAsArrayBuffer(e);
}), ei = (e, t, n) => {
  let i = !1, s = -1;
  for (let d = t; d < n; d++) {
    if (!i && e.getUint32(d) === 1953653099 && (i = !0, d += 4), !i)
      continue;
    if (e.getUint8(d) == 64) {
      s = d;
      break;
    }
  }
  if (s < 0)
    return 0;
  const l = e.getUint32(s - 28), u = e.getUint32(s - 20), c = e.getUint32(s - 32), f = e.getUint32(s - 16);
  return l === 65536 && u === 4294901760 ? 90 : c === 4294901760 && f === 4294901760 ? 180 : l === 4294901760 && u === 65536 ? 270 : 0;
}, ti = 1836019574, Bn = 1836476516, ni = 1953196132, oi = 1953653099, Zt = (e, t = 0, n = e.byteLength) => {
  const o = [], r = t + n;
  for (; t < r; ) {
    const i = e.getUint32(t);
    if (i < 0)
      break;
    const s = e.getUint32(t + 4);
    if (o.push({
      type: s,
      size: i,
      offset: t,
      bodyOffset: t + 8,
      bodySize: i - 8
    }), i < 8)
      break;
    t += i;
  }
  return o;
}, ri = (e) => {
  const n = Zt(e).find((s) => s.type === ti);
  if (!n)
    return 0;
  const o = Zt(e, n.bodyOffset, n.bodySize);
  o.filter((s) => s.type === oi).forEach((s) => {
    o.push(...Zt(e, s.bodyOffset, s.bodySize));
  });
  const r = [Bn, ni], i = o.filter((s) => r.includes(s.type));
  for (const { type: s, bodyOffset: l } of i) {
    const u = e.getUint8(l);
    let c = l + 4;
    c += s === Bn ? 32 : 36, c += u === 1 ? 12 : 0;
    const f = e.getInt32(c), d = e.getInt32(c + 4);
    if (f === 0 && d > 0)
      return 90;
    if (f < 0 && d === 0)
      return 180;
    if (f === 0 && d < 0)
      return 270;
  }
  return 0;
}, ii = (e, { limit: t = 1024 } = {}) => {
  let i = 0;
  if (i += 4, e.getUint32(i) !== 1718909296)
    return 0;
  i += 4;
  const l = e.getUint32(i);
  return l === 1836069938 ? ei(e, i, t) : l === 1903435808 ? ri(e) : 0;
}, _s = (e) => (t) => async (n, o, r) => {
  const {
    imageStateToCanvas: i,
    framesPerSecond: s,
    audioBitrate: l,
    videoBitrate: u,
    mimeTypes: c,
    mimeType: f,
    log: d = !1
  } = e || {};
  if (!i)
    throw new Error("createMediaStreamEncoder: imageStateToCanvas is a required parameter");
  const { src: M, imageState: _ } = n, v = await (Dt() ? Eo(M) : Promise.resolve()), { targetSize: m } = t || {};
  let S;
  const { taskCancelToken: w, shapePreprocessor: h } = o, k = (H) => (v && (H.dataset.rotation = `${v}`), S = i(H, _, { targetSize: m, shapePreprocessor: h }), S.canvas), P = () => S.redraw(), A = () => S.prepare(), { trim: B, volume: L } = _, O = Date.now();
  try {
    const H = await xr(
      M,
      {
        trim: Array.isArray(B) && ht(B[0]) ? [B] : B,
        volume: L,
        targetSize: m,
        framesPerSecond: s,
        audioBitrate: l,
        videoBitrate: u,
        mimeType: f,
        mimeTypes: c,
        setupCanvas: k,
        prepareCanvas: A,
        updateCanvas: P
      },
      r,
      w,
      d ? (...V) => console.log(Date.now() - O, ...V) : Se
    );
    return {
      ...n,
      // this is picked up by BlobToFile
      blob: H
    };
  } catch (H) {
    throw console.error(H), new Error("createMediaStreamEncoder: error during video recording");
  }
}, ko = (e) => new Promise((t, n) => {
  const o = new FileReader();
  o.onerror = () => n(o.error), o.onload = () => t(o.result), o.readAsArrayBuffer(e);
}), In = async (e) => {
  const t = await ko(e);
  return new Uint8Array(t);
}, si = (e, t) => new Promise((n, o) => {
  const r = document.createElement("script");
  r.onerror = () => o(new Error("Script error")), r.onload = () => n(t ? window[t] : void 0), r.src = e, document.head.append(r);
}), Dn = (e, t, n) => new File([e], t, n), ai = (e, t) => {
  let n;
  return (o) => {
    if (!e)
      return;
    const [r, i] = t || [0, 1], s = i - r, l = r + s * o, u = Math.round(l * 100);
    u !== n && (n = u, e(
      new ProgressEvent("progress", {
        loaded: u,
        total: 100,
        lengthComputable: !0
      })
    ));
  };
}, li = (e) => new Promise((t, n) => {
  const o = document.createElement("video");
  o.onerror = () => n(o.error), o.onloadeddata = () => {
    const r = {
      audio: un(o),
      size: {
        width: o.videoWidth,
        height: o.videoHeight
      },
      duration: o.duration
    };
    URL.revokeObjectURL(o.src), t(r);
  }, o.preload = "auto", o.src = URL.createObjectURL(e), o.playsInline = !0, o.load();
});
let Ze;
const ci = (e) => {
  const { log: t = !1, corePath: n } = e;
  if (window.FFmpeg)
    return Ze = 11, window.FFmpeg.createFFmpeg({ log: t, corePath: n });
  Ze = 12;
  const { FFmpeg: o } = window.FFmpegWASM, r = new o();
  return t && r.on("log", (i) => console.log(i.message)), r;
}, ui = async (e, t) => {
  if (Ze === 11)
    return await e.load();
  const { corePath: n } = t;
  return await e.load({ corePath: n });
}, fi = async (e) => Ze === 11 ? e.exit() : await e.terminate(), di = (e, t) => {
  if (Ze === 11)
    return e.setProgress(({ ratio: n }) => t(n));
  e.on("progress", ({ progress: n }) => t(n));
}, mi = async (e, t, n) => Ze === 11 ? Dn(e.FS("readFile", t), n.name, {
  type: n.type
}) : Dn(await e.readFile(t), n.name, { type: n.type }), On = async (e, t, n) => Ze === 11 ? e.FS("writeFile", t, await In(n)) : e.writeFile(t, await In(n)), gi = async (e, t) => Ze === 11 ? await e.run(...t) : await e.exec([...t]), hi = (e, t) => new Promise((n, o) => {
  si(e).then(() => {
    n(ci(t));
  }).catch(o);
}), ps = (e) => (t) => async (n, o, r) => {
  const {
    imageStateToCanvas: i,
    scriptPath: s,
    corePath: l,
    audioBitrate: u,
    videoBitrate: c,
    framesPerSecond: f,
    log: d
  } = e || {}, { shapePreprocessor: M, taskCancelToken: _ } = o;
  let v = !1;
  _.cancel = async () => {
    v = !0;
    try {
      V && await fi(V);
    } catch (W) {
      d && console.log("ffmpeg cancelled", W);
    }
  };
  const m = Date.now(), { src: S, imageState: w } = n, { volume: h } = w, { duration: k, size: P, audio: A } = await li(S), L = A && !(h === 0);
  if (v)
    return;
  const { targetSize: O } = t || {};
  let H, V;
  try {
    if (V = await hi(s, { corePath: l, log: d }), v || (await ui(V, { corePath: l }), v))
      return;
    const W = ai(r);
    if (di(V, (a) => {
      W(a);
    }), await On(V, "src.mp4", S), v)
      return;
    const D = [];
    D.push("-i", "src.mp4");
    const Y = [];
    if (w.annotation.length || w.decoration.length) {
      const { canvas: a, prepare: b } = i(
        so("canvas", P),
        w,
        { shapePreprocessor: M, targetSize: O }
      );
      await b();
      const z = await er(a);
      if (await On(V, "overlay.png", z), v)
        return;
      D.push("-i", "overlay.png"), Y.push("overlay=0:0");
    }
    typeof f == "number" && D.push("-r", f), c && D.push(
      "-b:v",
      typeof c == "number" ? Math.round(c / 1e3) + "k" : c
    ), u && D.push(
      "-b:a",
      typeof u == "number" ? Math.round(u / 1e3) + "k" : u
    );
    const U = [];
    {
      const { flipX: a, flipY: b } = w;
      (a || b) && (a && U.push("hflip"), b && U.push("vflip"));
    }
    {
      const { rotation: a } = w;
      if (a) {
        const b = _r(
          hr(
            go(ho(pr(P), a))
          ),
          Math.floor
        );
        U.push(
          `rotate=${a}:ow=${b.width}:oh=${b.height}`
        );
      }
    }
    {
      const { crop: a } = w;
      a.x === 0 && a.y === 0 && a.width === P.width && a.height === P.height || U.push(`crop=${a.width}:${a.height}:${a.x}:${a.y}`);
    }
    if (O) {
      const { crop: a } = w, { fit: b = "contain", upscale: z = !1 } = O;
      let { width: R, height: T } = O;
      if (R = R % 2 === 0 ? R : R + 1, T = T % 2 === 0 ? T : T + 1, !z) {
        const j = Math.min(
          (R || Number.MAX_SAFE_INTEGER) / a.width,
          (T || Number.MAX_SAFE_INTEGER) / a.height
        );
        j > 1 && (R && (R = Math.round(R / j)), T && (T = Math.round(R / j)));
      }
      R && !T ? U.push(`scale=${R}:trunc(ow/a/2)*2`) : T && !R ? U.push(`scale=trunc(oh/a/2)*2:${T}`) : R && T && (b === "contain" ? U.push(
        `scale=${R}:${T}:force_original_aspect_ratio=decrease`
      ) : b === "cover" ? U.push(
        `scale=${R}:${T}:force_original_aspect_ratio=increase,setsar=1`
      ) : b === "force" && U.push(`scale=${R}:${T},setsar=1`));
    }
    {
      const { convolutionMatrix: a } = w;
      a && a.clarity && U.push(`convolution='${a.clarity.join(" ")}'`);
    }
    {
      const { gamma: a } = w;
      a > 0 && U.push(`eq=gamma=${a}:gamma_weight=0.85`);
    }
    {
      const a = Object.values(w.colorMatrix || {}).filter(Boolean);
      if (a.length) {
        const b = Rr(a), z = [4, 9, 14, 19], R = b, T = b.filter((q, K) => !z.includes(K)), [j, G, Z] = [R[4] + R[3], R[9] + R[8], R[14] + R[13]];
        U.push(
          `colorchannelmixer=${T.join(":")}`,
          `lutrgb=r=val+(${j * 255}):g=val+(${G * 255}):b=val+(${Z * 255})`
        );
      }
    }
    const { trim: te } = w;
    if (te) {
      const a = (Array.isArray(te) && ht(te[0]) ? [te] : te).map((q, K) => {
        const x = q[0] * k, ge = q[1] * k, fe = U.length ? `,${U.join(",")}` : "", ae = `[0:v]trim=start=${x}:end=${ge},setpts=PTS-STARTPTS${fe}[${K}v];`, Pe = L ? `[0:a]${h !== 1 ? "volume=" + h + "," : ""}atrim=start=${x}:end=${ge},asetpts=PTS-STARTPTS[${K}a];` : "";
        return ae + Pe;
      }).join("");
      U.length = 0;
      const b = te.map((q, K) => `[${K}v]${L ? `[${K}a]` : ""}`).join(""), z = `n=${te.length}`, T = `${b}concat=v=1:${z}${L ? ":a=1" : ""}[video]${L ? "[audio]" : ""}`, j = `${a}${T}`, G = [], Z = [];
      G.push(j), Z.push("[video]"), Y.length && G.push(`[video][1:v]${Y}[video]`), D.push("-filter_complex", `${G.join(";")}`), Z.forEach((q) => {
        D.push("-map", q);
      }), L && D.push("-map", "[audio]");
    } else {
      const a = [], b = [];
      U.length && (a.push(`[0:v]${U.join(",")}[video]`), b.push("[video]")), Y.length && a.push(`[video][1:v]${Y}[video]`), L && h !== 1 && (a.push(`[0:a]volume=${h}[audio]`), b.push("[audio]")), a.length && D.push("-filter_complex", `${a.join(";")}`), b.forEach((z) => {
        D.push("-map", z);
      }), L && D.push("-map", "audio?");
    }
    D.push("dest.mp4"), d && console.log("ffmpeg arguments", D), H = D.join(" "), d && console.log("ffplay " + H), await gi(V, D);
    const se = await mi(V, "dest.mp4", S);
    return d && console.log("ffmpeg time", (Date.now() - m) / 1e3), {
      ...n,
      // This is picked up by BlobToFile
      blob: se
    };
  } catch (W) {
    throw H && console.log("ffplay " + H), /SharedArrayBuffer/.test(W.message) ? (console.error(W.message), console.error(
      `To enable SharedArrayBuffer your document needs to be in a secure context:
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/SharedArrayBuffer`
    )) : console.error(W), new Error("createFFmpegEncoder: error during video encoding");
  }
}, _i = {
  "video/webm": {
    muxer: {
      video: "V_VP9",
      audio: "A_OPUS"
    },
    encoder: {
      video: "vp09.00.10.08",
      audio: "opus"
    }
  },
  "video/mp4": {
    muxer: {
      video: "avc",
      // 'avc' | 'hevc' | 'vp9' | 'av1'
      audio: "aac"
      // 'opus'
    },
    encoder: {
      // https://developer.mozilla.org/en-US/docs/Web/Media/Formats/codecs_parameter#codec_options_by_container
      video: "avc1.640028",
      // this codec supports 1920x1080, avc1.42001f does not https://github.com/Vanilagy/mp4-muxer/issues/2
      audio: "mp4a.40.2"
    }
  }
}, ys = (e) => {
  if (!("VideoEncoder" in window) || !("AudioEncoder" in window)) {
    console.warn("createMuxerEncoder: Browser doesn't support WebCodecs API");
    return;
  }
  return (t) => (n, o, r) => new Promise(async (i, s) => {
    const { src: l, imageState: u } = n, {
      muxer: c,
      mimeType: f,
      imageStateToCanvas: d,
      framesPerSecond: M = 24,
      audioBitrate: _ = 128e3,
      videoBitrate: v = 384e3,
      log: m
    } = e;
    if (m && console.log("Init video muxer"), !d)
      return s(
        new Error("createMuxerEncoder: imageStateToCanvas is a required parameter")
      );
    if (!f)
      return s(
        new Error("createMuxerEncoder: mimeType is a required parameter")
      );
    if (!c)
      return s(new Error("createMuxerEncoder: muxer is a required parameter"));
    const S = Date.now(), w = _i[f];
    if (!w)
      return s(
        new Error(
          "createMuxerEncoder: Invalid MimeType, needs to be either 'video/webm' or 'video/mp4'"
        )
      );
    const { Muxer: h, ArrayBufferTarget: k } = c;
    if (!h || !k)
      return s(
        new Error(
          "createMuxerEncoder: muxerScript should export Muxer and ArrayBufferTarget objects"
        )
      );
    const { targetSize: P } = t || {}, { taskCancelToken: A, shapePreprocessor: B } = o, { trim: L = [[0, 1]], volume: O } = u;
    let H = !1;
    A.cancel = () => {
      H = !0, m && console.log("Cancelled"), i({
        ...n,
        blob: void 0
      });
    };
    const V = Array.isArray(L) && ht(L[0]) ? [L] : L, W = O === 0;
    let D;
    const Y = (re) => {
      if (!r)
        return;
      const g = Math.round(re * 100);
      g !== D && (D = g, r(
        new ProgressEvent("progress", {
          loaded: g,
          total: 100,
          lengthComputable: !0
        })
      ));
    };
    Y(0);
    let Q;
    try {
      Q = await yi(l);
    } catch (re) {
      s(re);
      return;
    }
    const U = un(Q);
    Y(0.1);
    const te = L.reduce((re, [g, C]) => re + (C - g) * Q.duration, 0), se = 2;
    let a, b, z, R;
    if (U && !W) {
      const re = new AudioContext(), g = await ko(l), C = await re.decodeAudioData(g);
      if (H)
        return;
      const { sampleRate: F, numberOfChannels: J } = C;
      R = F, a = Math.min(J, se), m && console.log("Audio channels", a);
      const X = new Float32Array(
        Math.round(te * F) * a
      );
      m && console.log("Audio data length", X.length);
      for (const [, ie] of V.entries()) {
        const [Ae, at] = ie, ze = Ae * Q.duration, Ye = at * Q.duration, E = Ye - ze, de = Math.round(ze * F), Ee = Math.round(Ye * F);
        m && console.log(
          "Trim audio data",
          ze,
          Ye,
          "data",
          de,
          Ee,
          "section",
          E
        );
        for (let ve = 0; ve < a; ve++) {
          const Fe = ve * E * F;
          let he = C.getChannelData(ve).subarray(de, Ee);
          O !== 1 && (he = he.map((Je) => Je * O)), m && console.log(
            "Set data for channel",
            ve,
            "at",
            Fe,
            he.length
          ), X.set(he, Fe);
        }
      }
      m && console.log("Create audio data"), b = new AudioData({
        format: "f32-planar",
        sampleRate: F,
        numberOfChannels: a,
        numberOfFrames: Math.round(te * F),
        timestamp: 0,
        data: X
      }), m && console.log("AudioData", b);
    }
    m && console.log("Prepare canvas");
    const T = d(Q, u, {
      targetSize: P,
      shapePreprocessor: B
    });
    if (await T.prepare(), H)
      return;
    Y(0.2);
    const { width: j, height: G } = T.canvas, Z = new h({
      target: new k(),
      video: {
        codec: w.muxer.video,
        width: j,
        height: G,
        frameRate: M
      },
      audio: U && !W ? {
        codec: w.muxer.audio,
        sampleRate: R,
        numberOfChannels: a
      } : void 0,
      firstTimestampBehavior: "offset",
      // MP4 creation is fastest if disabled -> disabled for now to keep things the same
      // https://github.com/Vanilagy/mp4-muxer#faststart-required
      fastStart: !1
    });
    if (U && !W) {
      z = new AudioEncoder({
        output: (C, F) => {
          Z.addAudioChunk(C, F);
        },
        error: (C) => {
          H = !0, s(new Error("AudioEncoder: " + C.message));
        }
      }), m && console.log("Configure audio encoder");
      const re = {
        codec: w.encoder.audio,
        numberOfChannels: a,
        sampleRate: R,
        bitrate: _
      }, g = await AudioEncoder.isConfigSupported(
        re
      );
      m && console.log(
        "AudioEncoderConfig support state",
        g
      ), z.configure(re), m && console.log("Encode audio data", b), z.encode(b);
    }
    const q = new VideoEncoder({
      output: (re, g) => {
        Z.addVideoChunk(re, g);
      },
      error: (re) => {
        H = !0, s(new Error("VideoEncoder: " + re.message));
      }
    });
    q.configure({
      codec: w.encoder.video,
      width: j,
      height: G,
      bitrate: v
    }), m && console.log("Start encoding frames");
    const K = Q.duration * 1e3;
    let x = -1 / 0;
    const ge = async (re, g) => {
      re = Math.max(0, Math.min(K, re)), await pi(Q, re), T.redraw(Q);
      const C = new VideoFrame(T.canvas, {
        timestamp: g * 1e3
      }), F = g - x >= 1e4;
      F && (x = g), m && console.log(F ? "Encode keyframe" : "Encode frame", g), q.encode(C, { keyFrame: F }), C.close(), Y(0.2 + 0.8 * (g / (te * 1e3)));
    }, fe = 1e3 / M;
    let ae = 0;
    for (const [, re] of V.entries()) {
      const [g, C] = re, F = C * K;
      let J = g * K;
      try {
        do {
          if (J = Math.min(F, J), await ge(J, ae), H)
            return;
          J += fe, ae += fe;
        } while (J < F + fe);
      } catch (X) {
        s(X);
        return;
      }
    }
    if (await (q == null ? void 0 : q.flush()), await (z == null ? void 0 : z.flush()), H)
      return;
    m && console.log("Encoding time in seconds", (Date.now() - S) / 1e3), Z.finalize();
    const { buffer: Pe } = Z.target;
    T.destroy(), URL.revokeObjectURL(Q.src), i({
      ...n,
      // this is picked up by BlobToFile
      blob: new Blob([Pe], { type: f })
    });
  });
}, pi = (e, t) => new Promise((n) => {
  e.onseeked = () => n(), e.currentTime = t / 1e3;
}), yi = (e) => new Promise((t, n) => {
  const o = document.createElement("video");
  o.preload = "auto", o.onloadeddata = async () => {
    const r = await (Dt() ? Eo(e) : Promise.resolve());
    r && (o.dataset.rotation = `${r}`), t(o);
  }, o.onerror = () => n(o.error), o.src = URL.createObjectURL(e), o.playsInline = !0, o.load();
});
function tn(e, t, n, o) {
  if (typeof n == "number") {
    const r = o - n, i = (n - t) / (e.dt || 1 / 60), s = e.opts.stiffness * r, l = e.opts.damping * i, u = (s - l) * e.inv_mass, c = (i + u) * e.dt;
    return Math.abs(c) < e.opts.precision && Math.abs(r) < e.opts.precision ? o : (e.settled = !1, n + c);
  } else {
    if (Er(n))
      return n.map(
        (r, i) => tn(e, t[i], n[i], o[i])
      );
    if (typeof n == "object") {
      const r = {};
      for (const i in n)
        r[i] = tn(e, t[i], n[i], o[i]);
      return r;
    } else
      throw new Error(`Cannot spring ${typeof n} values`);
  }
}
function Ro(e, t = {}) {
  const n = Po(e), { stiffness: o = 0.15, damping: r = 0.8, precision: i = 0.01 } = t;
  let s, l, u, c = e, f = e, d = 1, M = 0, _ = !1;
  function v(S, w = {}) {
    f = S;
    const h = u = {};
    if (e == null || w.hard || m.stiffness >= 1 && m.damping >= 1)
      return _ = !0, s = null, c = S, n.set(e = f), Promise.resolve();
    if (w.soft && (M = 1 / ((w.soft === !0 ? 0.5 : +w.soft) * 60), d = 0), !l) {
      s = null, _ = !1;
      const k = {
        inv_mass: void 0,
        opts: m,
        settled: !0,
        dt: void 0
      };
      l = Ar((P) => {
        if (s === null && (s = P), _)
          return _ = !1, l = null, !1;
        d = Math.min(d + M, 1), k.inv_mass = d, k.opts = m, k.settled = !0, k.dt = (P - s) * 60 / 1e3;
        const A = tn(k, c, e, f);
        return s = P, c = e, n.set(e = A), k.settled && (l = null), !k.settled;
      });
    }
    return new Promise((k) => {
      l.promise.then(() => {
        h === u && k();
      });
    });
  }
  const m = {
    set: v,
    update: (S, w) => v(S(f, e), w),
    subscribe: n.subscribe,
    stiffness: o,
    damping: r,
    precision: i
  };
  return m;
}
function bi(e) {
  let t, n, o;
  const r = (
    /*#slots*/
    e[5].default
  ), i = We(
    r,
    e,
    /*$$scope*/
    e[4],
    null
  );
  return {
    c() {
      t = bt("svg"), i && i.c(), y(
        t,
        "class",
        /*klass*/
        e[3]
      ), y(
        t,
        "style",
        /*style*/
        e[2]
      ), y(
        t,
        "width",
        /*width*/
        e[0]
      ), y(
        t,
        "height",
        /*height*/
        e[1]
      ), y(t, "viewBox", n = "0 0 " + /*width*/
      e[0] + `
    ` + /*height*/
      e[1]), y(t, "xmlns", "http://www.w3.org/2000/svg"), y(t, "aria-hidden", "true"), y(t, "focusable", "false"), y(t, "stroke-linecap", "round"), y(t, "stroke-linejoin", "round");
    },
    m(s, l) {
      oe(s, t, l), i && i.m(t, null), o = !0;
    },
    p(s, [l]) {
      i && i.p && (!o || l & /*$$scope*/
      16) && Xe(
        i,
        r,
        s,
        /*$$scope*/
        s[4],
        o ? qe(
          r,
          /*$$scope*/
          s[4],
          l,
          null
        ) : Ge(
          /*$$scope*/
          s[4]
        ),
        null
      ), (!o || l & /*klass*/
      8) && y(
        t,
        "class",
        /*klass*/
        s[3]
      ), (!o || l & /*style*/
      4) && y(
        t,
        "style",
        /*style*/
        s[2]
      ), (!o || l & /*width*/
      1) && y(
        t,
        "width",
        /*width*/
        s[0]
      ), (!o || l & /*height*/
      2) && y(
        t,
        "height",
        /*height*/
        s[1]
      ), (!o || l & /*width, height*/
      3 && n !== (n = "0 0 " + /*width*/
      s[0] + `
    ` + /*height*/
      s[1])) && y(t, "viewBox", n);
    },
    i(s) {
      o || (I(i, s), o = !0);
    },
    o(s) {
      N(i, s), o = !1;
    },
    d(s) {
      s && ne(t), i && i.d(s);
    }
  };
}
function wi(e, t, n) {
  let { $$slots: o = {}, $$scope: r } = t, { width: i = 24 } = t, { height: s = 24 } = t, { style: l = void 0 } = t, { class: u = void 0 } = t;
  return e.$$set = (c) => {
    "width" in c && n(0, i = c.width), "height" in c && n(1, s = c.height), "style" in c && n(2, l = c.style), "class" in c && n(3, u = c.class), "$$scope" in c && n(4, r = c.$$scope);
  }, [i, s, l, u, r, o];
}
class nn extends Ce {
  constructor(t) {
    super(), Te(this, t, wi, bi, ke, { width: 0, height: 1, style: 2, class: 3 });
  }
}
const Ue = (e, t = Boolean, n = " ") => e.filter(t).join(n), Mi = (e, t) => t === e.target || t.contains(e.target), vi = (e) => e.map((t) => t === "CMD" ? _o() ? "⌘" : "Ctrl" : t).join("+"), zn = (e, t, n) => (Ft(t) ? t : e) + (n ? ` (${vi(n)})` : "");
function Un(e) {
  let t, n;
  return t = new nn({
    props: {
      class: "PinturaButtonIcon",
      $$slots: { default: [Si] },
      $$scope: { ctx: e }
    }
  }), {
    c() {
      be(t.$$.fragment);
    },
    m(o, r) {
      _e(t, o, r), n = !0;
    },
    p(o, r) {
      const i = {};
      r & /*$$scope, icon*/
      536870920 && (i.$$scope = { dirty: r, ctx: o }), t.$set(i);
    },
    i(o) {
      n || (I(t.$$.fragment, o), n = !0);
    },
    o(o) {
      N(t.$$.fragment, o), n = !1;
    },
    d(o) {
      pe(t, o);
    }
  };
}
function Si(e) {
  let t;
  return {
    c() {
      t = bt("g");
    },
    m(n, o) {
      oe(n, t, o), t.innerHTML = /*icon*/
      e[3];
    },
    p(n, o) {
      o & /*icon*/
      8 && (t.innerHTML = /*icon*/
      n[3]);
    },
    d(n) {
      n && ne(t);
    }
  };
}
function Vn(e) {
  let t;
  return {
    c() {
      t = ee("span"), y(
        t,
        "class",
        /*elLabelClass*/
        e[11]
      );
    },
    m(n, o) {
      oe(n, t, o), t.innerHTML = /*label*/
      e[0];
    },
    p(n, o) {
      o & /*label*/
      1 && (t.innerHTML = /*label*/
      n[0]), o & /*elLabelClass*/
      2048 && y(
        t,
        "class",
        /*elLabelClass*/
        n[11]
      );
    },
    d(n) {
      n && ne(t);
    }
  };
}
function Pi(e) {
  let t, n, o, r = (
    /*icon*/
    e[3] && Un(e)
  ), i = (
    /*label*/
    e[0] && Vn(e)
  );
  return {
    c() {
      t = ee("span"), r && r.c(), n = ce(), i && i.c(), y(
        t,
        "class",
        /*elButtonInnerClass*/
        e[13]
      );
    },
    m(s, l) {
      oe(s, t, l), r && r.m(t, null), $(t, n), i && i.m(t, null), o = !0;
    },
    p(s, l) {
      /*icon*/
      s[3] ? r ? (r.p(s, l), l & /*icon*/
      8 && I(r, 1)) : (r = Un(s), r.c(), I(r, 1), r.m(t, n)) : r && (Be(), N(r, 1, 1, () => {
        r = null;
      }), Ie()), /*label*/
      s[0] ? i ? i.p(s, l) : (i = Vn(s), i.c(), i.m(t, null)) : i && (i.d(1), i = null), (!o || l & /*elButtonInnerClass*/
      8192) && y(
        t,
        "class",
        /*elButtonInnerClass*/
        s[13]
      );
    },
    i(s) {
      o || (I(r), o = !0);
    },
    o(s) {
      N(r), o = !1;
    },
    d(s) {
      s && ne(t), r && r.d(), i && i.d();
    }
  };
}
function Ei(e) {
  let t, n, o, r, i;
  const s = (
    /*#slots*/
    e[27].default
  ), l = We(
    s,
    e,
    /*$$scope*/
    e[29],
    null
  ), u = l || Pi(e);
  return {
    c() {
      t = ee("button"), u && u.c(), y(
        t,
        "type",
        /*type*/
        e[6]
      ), y(
        t,
        "style",
        /*style*/
        e[4]
      ), t.disabled = /*disabled*/
      e[5], y(
        t,
        "class",
        /*elButtonClass*/
        e[12]
      ), y(t, "title", n = zn(
        /*label*/
        e[0],
        /*title*/
        e[1],
        /*shortcut*/
        e[2]
      ));
    },
    m(c, f) {
      oe(c, t, f), u && u.m(t, null), e[28](t), o = !0, r || (i = [
        me(t, "keydown", function() {
          Oe(
            /*onkeydown*/
            e[8]
          ) && e[8].apply(this, arguments);
        }),
        me(t, "click", function() {
          Oe(
            /*onclick*/
            e[7]
          ) && e[7].apply(this, arguments);
        }),
        me(t, "pointerdown", function() {
          Oe(
            /*handleDown*/
            e[14]
          ) && e[14].apply(this, arguments);
        }),
        Ke(
          /*action*/
          e[9].call(null, t)
        )
      ], r = !0);
    },
    p(c, [f]) {
      e = c, l ? l.p && (!o || f & /*$$scope*/
      536870912) && Xe(
        l,
        s,
        e,
        /*$$scope*/
        e[29],
        o ? qe(
          s,
          /*$$scope*/
          e[29],
          f,
          null
        ) : Ge(
          /*$$scope*/
          e[29]
        ),
        null
      ) : u && u.p && (!o || f & /*elButtonInnerClass, elLabelClass, label, icon*/
      10249) && u.p(e, o ? f : -1), (!o || f & /*type*/
      64) && y(
        t,
        "type",
        /*type*/
        e[6]
      ), (!o || f & /*style*/
      16) && y(
        t,
        "style",
        /*style*/
        e[4]
      ), (!o || f & /*disabled*/
      32) && (t.disabled = /*disabled*/
      e[5]), (!o || f & /*elButtonClass*/
      4096) && y(
        t,
        "class",
        /*elButtonClass*/
        e[12]
      ), (!o || f & /*label, title, shortcut*/
      7 && n !== (n = zn(
        /*label*/
        e[0],
        /*title*/
        e[1],
        /*shortcut*/
        e[2]
      ))) && y(t, "title", n);
    },
    i(c) {
      o || (I(u, c), o = !0);
    },
    o(c) {
      N(u, c), o = !1;
    },
    d(c) {
      c && ne(t), u && u.d(c), e[28](null), r = !1, Me(i);
    }
  };
}
function ki(e, t, n) {
  let o, r, i, s, l, { $$slots: u = {}, $$scope: c } = t, { class: f = void 0 } = t, { label: d = void 0 } = t, { title: M = void 0 } = t, { shortcut: _ = void 0 } = t, { labelClass: v = void 0 } = t, { innerClass: m = void 0 } = t, { hideLabel: S = !1 } = t, { icon: w = void 0 } = t, { style: h = void 0 } = t, { disabled: k = void 0 } = t, { type: P = "button" } = t, { onclick: A = void 0 } = t, { onkeydown: B = void 0 } = t, { onhold: L = void 0 } = t, { action: O = () => {
  } } = t, { holdThreshold: H = 500 } = t, { holdSpeedUpFactor: V = 0.5 } = t, { holdSpeedMin: W = 20 } = t, D;
  const Y = (a) => {
    n(25, D = setTimeout(
      () => {
        L(), Y(Math.max(a * V, W));
      },
      a
    ));
  };
  let Q;
  const U = (a) => Mi(a, Q), te = () => Q;
  function se(a) {
    je[a ? "unshift" : "push"](() => {
      Q = a, n(10, Q);
    });
  }
  return e.$$set = (a) => {
    "class" in a && n(15, f = a.class), "label" in a && n(0, d = a.label), "title" in a && n(1, M = a.title), "shortcut" in a && n(2, _ = a.shortcut), "labelClass" in a && n(16, v = a.labelClass), "innerClass" in a && n(17, m = a.innerClass), "hideLabel" in a && n(18, S = a.hideLabel), "icon" in a && n(3, w = a.icon), "style" in a && n(4, h = a.style), "disabled" in a && n(5, k = a.disabled), "type" in a && n(6, P = a.type), "onclick" in a && n(7, A = a.onclick), "onkeydown" in a && n(8, B = a.onkeydown), "onhold" in a && n(19, L = a.onhold), "action" in a && n(9, O = a.action), "holdThreshold" in a && n(20, H = a.holdThreshold), "holdSpeedUpFactor" in a && n(21, V = a.holdSpeedUpFactor), "holdSpeedMin" in a && n(22, W = a.holdSpeedMin), "$$scope" in a && n(29, c = a.$$scope);
  }, e.$$.update = () => {
    e.$$.dirty & /*onhold, holdTimer, handleUp*/
    101187584 && n(26, r = L ? () => {
      D && (clearTimeout(D), n(25, D = void 0), document.documentElement.removeEventListener("pointerup", r));
    } : Se), e.$$.dirty & /*onhold, handleUp, holdThreshold*/
    68681728 && n(14, o = L ? () => {
      document.documentElement.addEventListener("pointerup", r), Y(H);
    } : Se), e.$$.dirty & /*innerClass*/
    131072 && n(13, i = Ue(["PinturaButtonInner", m])), e.$$.dirty & /*hideLabel, klass*/
    294912 && n(12, s = Ue(["PinturaButton", S && "PinturaButtonIconOnly", f])), e.$$.dirty & /*hideLabel, labelClass*/
    327680 && n(11, l = Ue([S ? "implicit" : "PinturaButtonLabel", v]));
  }, [
    d,
    M,
    _,
    w,
    h,
    k,
    P,
    A,
    B,
    O,
    Q,
    l,
    s,
    i,
    o,
    f,
    v,
    m,
    S,
    L,
    H,
    V,
    W,
    U,
    te,
    D,
    r,
    u,
    se,
    c
  ];
}
class fn extends Ce {
  constructor(t) {
    super(), Te(this, t, ki, Ei, ke, {
      class: 15,
      label: 0,
      title: 1,
      shortcut: 2,
      labelClass: 16,
      innerClass: 17,
      hideLabel: 18,
      icon: 3,
      style: 4,
      disabled: 5,
      type: 6,
      onclick: 7,
      onkeydown: 8,
      onhold: 19,
      action: 9,
      holdThreshold: 20,
      holdSpeedUpFactor: 21,
      holdSpeedMin: 22,
      isEventTarget: 23,
      getElement: 24
    });
  }
  get isEventTarget() {
    return this.$$.ctx[23];
  }
  get getElement() {
    return this.$$.ctx[24];
  }
}
function Ri(e) {
  let t, n, o, r;
  const i = (
    /*#slots*/
    e[5].default
  ), s = We(
    i,
    e,
    /*$$scope*/
    e[4],
    null
  );
  return {
    c() {
      t = ee("div"), s && s.c(), y(
        t,
        "class",
        /*klass*/
        e[0]
      );
    },
    m(l, u) {
      oe(l, t, u), s && s.m(t, null), n = !0, o || (r = [
        me(t, "measure", function() {
          Oe(
            /*didMount*/
            e[1] && /*handleResize*/
            e[3]
          ) && /*didMount*/
          (e[1] && /*handleResize*/
          e[3]).apply(this, arguments);
        }),
        Ke(
          /*measurable*/
          e[2].call(null, t)
        )
      ], o = !0);
    },
    p(l, [u]) {
      e = l, s && s.p && (!n || u & /*$$scope*/
      16) && Xe(
        s,
        i,
        e,
        /*$$scope*/
        e[4],
        n ? qe(
          i,
          /*$$scope*/
          e[4],
          u,
          null
        ) : Ge(
          /*$$scope*/
          e[4]
        ),
        null
      ), (!n || u & /*klass*/
      1) && y(
        t,
        "class",
        /*klass*/
        e[0]
      );
    },
    i(l) {
      n || (I(s, l), n = !0);
    },
    o(l) {
      N(s, l), n = !1;
    },
    d(l) {
      l && ne(t), s && s.d(l), o = !1, Me(r);
    }
  };
}
function Ti(e, t, n) {
  let { $$slots: o = {}, $$scope: r } = t;
  const i = Dr();
  let { class: s = null } = t;
  const l = xe("measurable");
  let u = !1;
  const c = ({ detail: f }) => i("measure", f);
  return Ir(() => n(1, u = !0)), e.$$set = (f) => {
    "class" in f && n(0, s = f.class), "$$scope" in f && n(4, r = f.$$scope);
  }, [s, u, l, c, r, o];
}
class Ci extends Ce {
  constructor(t) {
    super(), Te(this, t, Ti, Ri, ke, { class: 0 });
  }
}
const Ai = (e) => ({}), jn = (e) => ({}), Fi = (e) => ({}), Kn = (e) => ({}), Li = (e) => ({}), Nn = (e) => ({});
function Hn(e) {
  let t, n;
  const o = (
    /*#slots*/
    e[4].header
  ), r = We(
    o,
    e,
    /*$$scope*/
    e[3],
    Nn
  );
  return {
    c() {
      t = ee("div"), r && r.c(), y(t, "class", "PinturaUtilHeader");
    },
    m(i, s) {
      oe(i, t, s), r && r.m(t, null), n = !0;
    },
    p(i, s) {
      r && r.p && (!n || s & /*$$scope*/
      8) && Xe(
        r,
        o,
        i,
        /*$$scope*/
        i[3],
        n ? qe(
          o,
          /*$$scope*/
          i[3],
          s,
          Li
        ) : Ge(
          /*$$scope*/
          i[3]
        ),
        Nn
      );
    },
    i(i) {
      n || (I(r, i), n = !0);
    },
    o(i) {
      N(r, i), n = !1;
    },
    d(i) {
      i && ne(t), r && r.d(i);
    }
  };
}
function Bi(e) {
  let t, n;
  return t = new Ci({ props: { class: "PinturaStage" } }), t.$on(
    "measure",
    /*measure_handler*/
    e[5]
  ), {
    c() {
      be(t.$$.fragment);
    },
    m(o, r) {
      _e(t, o, r), n = !0;
    },
    p: ue,
    i(o) {
      n || (I(t.$$.fragment, o), n = !0);
    },
    o(o) {
      N(t.$$.fragment, o), n = !1;
    },
    d(o) {
      pe(t, o);
    }
  };
}
function Wn(e) {
  let t, n;
  const o = (
    /*#slots*/
    e[4].footer
  ), r = We(
    o,
    e,
    /*$$scope*/
    e[3],
    jn
  );
  return {
    c() {
      t = ee("div"), r && r.c(), y(t, "class", "PinturaUtilFooter");
    },
    m(i, s) {
      oe(i, t, s), r && r.m(t, null), n = !0;
    },
    p(i, s) {
      r && r.p && (!n || s & /*$$scope*/
      8) && Xe(
        r,
        o,
        i,
        /*$$scope*/
        i[3],
        n ? qe(
          o,
          /*$$scope*/
          i[3],
          s,
          Ai
        ) : Ge(
          /*$$scope*/
          i[3]
        ),
        jn
      );
    },
    i(i) {
      n || (I(r, i), n = !0);
    },
    o(i) {
      N(r, i), n = !1;
    },
    d(i) {
      i && ne(t), r && r.d(i);
    }
  };
}
function Ii(e) {
  let t, n, o, r, i, s, l = (
    /*hasHeader*/
    e[1] && Hn(e)
  );
  const u = (
    /*#slots*/
    e[4].main
  ), c = We(
    u,
    e,
    /*$$scope*/
    e[3],
    Kn
  ), f = c || Bi(e);
  let d = (
    /*hasFooter*/
    e[2] && Wn(e)
  ), M = !1;
  return {
    c() {
      l && l.c(), t = ce(), n = ee("div"), f && f.c(), o = ce(), d && d.c(), r = ce(), i = So(), y(n, "class", "PinturaUtilMain");
    },
    m(_, v) {
      l && l.m(_, v), oe(_, t, v), oe(_, n, v), f && f.m(n, null), e[6](n), oe(_, o, v), d && d.m(_, v), oe(_, r, v), oe(_, i, v), s = !0;
    },
    p(_, [v]) {
      /*hasHeader*/
      _[1] ? l ? (l.p(_, v), v & /*hasHeader*/
      2 && I(l, 1)) : (l = Hn(_), l.c(), I(l, 1), l.m(t.parentNode, t)) : l && (Be(), N(l, 1, 1, () => {
        l = null;
      }), Ie()), c && c.p && (!s || v & /*$$scope*/
      8) && Xe(
        c,
        u,
        _,
        /*$$scope*/
        _[3],
        s ? qe(
          u,
          /*$$scope*/
          _[3],
          v,
          Fi
        ) : Ge(
          /*$$scope*/
          _[3]
        ),
        Kn
      ), /*hasFooter*/
      _[2] ? d ? (d.p(_, v), v & /*hasFooter*/
      4 && I(d, 1)) : (d = Wn(_), d.c(), I(d, 1), d.m(r.parentNode, r)) : d && (Be(), N(d, 1, 1, () => {
        d = null;
      }), Ie());
    },
    i(_) {
      s || (I(l), I(f, _), I(d), I(M), s = !0);
    },
    o(_) {
      N(l), N(f, _), N(d), N(M), s = !1;
    },
    d(_) {
      l && l.d(_), _ && ne(t), _ && ne(n), f && f.d(_), e[6](null), _ && ne(o), d && d.d(_), _ && ne(r), _ && ne(i);
    }
  };
}
function Di(e, t, n) {
  let { $$slots: o = {}, $$scope: r } = t, { hasHeader: i = !!t.$$slots.header } = t, { hasFooter: s = !!t.$$slots.footer } = t, { root: l = void 0 } = t;
  function u(f) {
    ln.call(this, e, f);
  }
  function c(f) {
    je[f ? "unshift" : "push"](() => {
      l = f, n(0, l);
    });
  }
  return e.$$set = (f) => {
    n(7, t = _t(_t({}, t), Cn(f))), "hasHeader" in f && n(1, i = f.hasHeader), "hasFooter" in f && n(2, s = f.hasFooter), "root" in f && n(0, l = f.root), "$$scope" in f && n(3, r = f.$$scope);
  }, t = Cn(t), [l, i, s, r, o, u, c];
}
class Oi extends Ce {
  constructor(t) {
    super(), Te(this, t, Di, Ii, ke, { hasHeader: 1, hasFooter: 2, root: 0 });
  }
}
const zi = (e, t = {}) => {
  const {
    direction: n = void 0,
    shiftMultiplier: o = 10,
    bubbles: r = !1,
    preventDefault: i = !1,
    stopKeydownPropagation: s = !0
  } = t, l = n === "horizontal", u = n === "vertical", c = (f) => {
    const { key: d } = f, M = f.shiftKey, _ = /up|down/i.test(d), v = /left|right/i.test(d);
    if (!v && !_ || l && _ || u && v)
      return;
    const m = M ? o : 1;
    s && f.stopPropagation(), i && f.preventDefault(), e.dispatchEvent(
      new CustomEvent("nudge", {
        bubbles: r,
        detail: Ve(
          (/left/i.test(d) ? -1 : /right/i.test(d) ? 1 : 0) * m,
          (/up/i.test(d) ? -1 : /down/i.test(d) ? 1 : 0) * m
        )
      })
    );
  };
  return e.addEventListener("keydown", c), {
    destroy() {
      e.removeEventListener("keydown", c);
    }
  };
}, Ui = (e, t) => (t = 1 / t, Math.round(e * t) / t), Vi = (e, t, n) => (e - t) / (n - t), ji = (e) => ({}), qn = (e) => ({});
function Xn(e) {
  let t, n, o, r, i, s, l, u;
  return n = new nn({
    props: {
      $$slots: { default: [Ki] },
      $$scope: { ctx: e }
    }
  }), i = new nn({
    props: {
      $$slots: { default: [Ni] },
      $$scope: { ctx: e }
    }
  }), {
    c() {
      t = ee("button"), be(n.$$.fragment), o = ce(), r = ee("button"), be(i.$$.fragment), y(t, "type", "button"), y(t, "aria-label", "Increase"), y(r, "type", "button"), y(r, "aria-label", "Decrease");
    },
    m(c, f) {
      oe(c, t, f), _e(n, t, null), oe(c, o, f), oe(c, r, f), _e(i, r, null), s = !0, l || (u = [
        me(
          t,
          "pointerdown",
          /*handleUpdaterDown*/
          e[20](1)
        ),
        me(
          r,
          "pointerdown",
          /*handleUpdaterDown*/
          e[20](-1)
        )
      ], l = !0);
    },
    p(c, f) {
      const d = {};
      f[1] & /*$$scope*/
      128 && (d.$$scope = { dirty: f, ctx: c }), n.$set(d);
      const M = {};
      f[1] & /*$$scope*/
      128 && (M.$$scope = { dirty: f, ctx: c }), i.$set(M);
    },
    i(c) {
      s || (I(n.$$.fragment, c), I(i.$$.fragment, c), s = !0);
    },
    o(c) {
      N(n.$$.fragment, c), N(i.$$.fragment, c), s = !1;
    },
    d(c) {
      c && ne(t), pe(n), c && ne(o), c && ne(r), pe(i), l = !1, Me(u);
    }
  };
}
function Ki(e) {
  let t;
  return {
    c() {
      t = bt("path"), y(t, "d", "M8 12 h8 M12 8 v8");
    },
    m(n, o) {
      oe(n, t, o);
    },
    p: ue,
    d(n) {
      n && ne(t);
    }
  };
}
function Ni(e) {
  let t;
  return {
    c() {
      t = bt("path"), y(t, "d", "M9 12 h6");
    },
    m(n, o) {
      oe(n, t, o);
    },
    p: ue,
    d(n) {
      n && ne(t);
    }
  };
}
function Hi(e) {
  let t, n, o, r, i, s, l, u, c, f, d, M, _, v, m, S, w;
  const h = (
    /*#slots*/
    e[36].default
  ), k = We(
    h,
    e,
    /*$$scope*/
    e[38],
    null
  ), P = (
    /*#slots*/
    e[36].knob
  ), A = We(
    P,
    e,
    /*$$scope*/
    e[38],
    qn
  );
  let B = (
    /*enableSpinButtons*/
    e[9] && Xn(e)
  );
  return {
    c() {
      t = ee("div"), n = ee("div"), o = ee("input"), i = ce(), s = ee("div"), k && k.c(), u = ce(), c = ee("div"), f = ee("div"), A && A.c(), _ = ce(), B && B.c(), y(o, "type", "range"), y(
        o,
        "id",
        /*id*/
        e[3]
      ), y(
        o,
        "min",
        /*min*/
        e[0]
      ), y(
        o,
        "max",
        /*max*/
        e[1]
      ), y(
        o,
        "step",
        /*step*/
        e[2]
      ), o.value = /*numberValue*/
      e[14], y(o, "style", r = /*enableForceUseKnob*/
      e[10] ? "pointer-events:none" : ""), y(s, "class", l = Ue([
        "PinturaSliderTrack",
        /*trackClass*/
        e[5]
      ])), y(
        s,
        "style",
        /*trackStyle*/
        e[4]
      ), y(f, "class", d = Ue([
        "PinturaSliderKnob",
        /*knobClass*/
        e[7]
      ])), y(
        f,
        "style",
        /*knobStyle*/
        e[6]
      ), y(c, "class", "PinturaSliderKnobController"), y(
        c,
        "style",
        /*knobControllerStyle*/
        e[17]
      ), y(n, "class", "PinturaSliderControl"), y(n, "style", M = `--slider-position:${Math.round(
        /*position*/
        e[15]
      )}`), y(t, "class", v = Ue([
        "PinturaSlider",
        /*klass*/
        e[12]
      ])), y(
        t,
        "data-direction",
        /*direction*/
        e[8]
      );
    },
    m(L, O) {
      oe(L, t, O), $(t, n), $(n, o), e[37](o), $(n, i), $(n, s), k && k.m(s, null), $(n, u), $(n, c), $(c, f), A && A.m(f, null), $(t, _), B && B.m(t, null), m = !0, S || (w = [
        me(
          o,
          "input",
          /*handleInput*/
          e[18]
        ),
        me(
          o,
          "nudge",
          /*handleNudge*/
          e[19]
        ),
        Ke(zi.call(null, o)),
        me(n, "pointerdown", function() {
          Oe(
            /*enablePointerdownListener*/
            e[11] && /*handlePointerDown*/
            e[13]
          ) && /*enablePointerdownListener*/
          (e[11] && /*handlePointerDown*/
          e[13]).apply(this, arguments);
        })
      ], S = !0);
    },
    p(L, O) {
      e = L, (!m || O[0] & /*id*/
      8) && y(
        o,
        "id",
        /*id*/
        e[3]
      ), (!m || O[0] & /*min*/
      1) && y(
        o,
        "min",
        /*min*/
        e[0]
      ), (!m || O[0] & /*max*/
      2) && y(
        o,
        "max",
        /*max*/
        e[1]
      ), (!m || O[0] & /*step*/
      4) && y(
        o,
        "step",
        /*step*/
        e[2]
      ), (!m || O[0] & /*numberValue*/
      16384) && (o.value = /*numberValue*/
      e[14]), (!m || O[0] & /*enableForceUseKnob*/
      1024 && r !== (r = /*enableForceUseKnob*/
      e[10] ? "pointer-events:none" : "")) && y(o, "style", r), k && k.p && (!m || O[1] & /*$$scope*/
      128) && Xe(
        k,
        h,
        e,
        /*$$scope*/
        e[38],
        m ? qe(
          h,
          /*$$scope*/
          e[38],
          O,
          null
        ) : Ge(
          /*$$scope*/
          e[38]
        ),
        null
      ), (!m || O[0] & /*trackClass*/
      32 && l !== (l = Ue([
        "PinturaSliderTrack",
        /*trackClass*/
        e[5]
      ]))) && y(s, "class", l), (!m || O[0] & /*trackStyle*/
      16) && y(
        s,
        "style",
        /*trackStyle*/
        e[4]
      ), A && A.p && (!m || O[1] & /*$$scope*/
      128) && Xe(
        A,
        P,
        e,
        /*$$scope*/
        e[38],
        m ? qe(
          P,
          /*$$scope*/
          e[38],
          O,
          ji
        ) : Ge(
          /*$$scope*/
          e[38]
        ),
        qn
      ), (!m || O[0] & /*knobClass*/
      128 && d !== (d = Ue([
        "PinturaSliderKnob",
        /*knobClass*/
        e[7]
      ]))) && y(f, "class", d), (!m || O[0] & /*knobStyle*/
      64) && y(
        f,
        "style",
        /*knobStyle*/
        e[6]
      ), (!m || O[0] & /*knobControllerStyle*/
      131072) && y(
        c,
        "style",
        /*knobControllerStyle*/
        e[17]
      ), (!m || O[0] & /*position*/
      32768 && M !== (M = `--slider-position:${Math.round(
        /*position*/
        e[15]
      )}`)) && y(n, "style", M), /*enableSpinButtons*/
      e[9] ? B ? (B.p(e, O), O[0] & /*enableSpinButtons*/
      512 && I(B, 1)) : (B = Xn(e), B.c(), I(B, 1), B.m(t, null)) : B && (Be(), N(B, 1, 1, () => {
        B = null;
      }), Ie()), (!m || O[0] & /*klass*/
      4096 && v !== (v = Ue([
        "PinturaSlider",
        /*klass*/
        e[12]
      ]))) && y(t, "class", v), (!m || O[0] & /*direction*/
      256) && y(
        t,
        "data-direction",
        /*direction*/
        e[8]
      );
    },
    i(L) {
      m || (I(k, L), I(A, L), I(B), m = !0);
    },
    o(L) {
      N(k, L), N(A, L), N(B), m = !1;
    },
    d(L) {
      L && ne(t), e[37](null), k && k.d(L), A && A.d(L), B && B.d(), S = !1, Me(w);
    }
  };
}
function Wi(e, t, n) {
  let o, r, i, s, l, u, c, f, d, { $$slots: M = {}, $$scope: _ } = t, { min: v = 0 } = t, { max: m = 100 } = t, { step: S = 1 } = t, { id: w = void 0 } = t, { value: h = 0 } = t, { valueMin: k = void 0 } = t, { valueMax: P = void 0 } = t, { trackStyle: A = void 0 } = t, { trackClass: B = void 0 } = t, { knobStyle: L = void 0 } = t, { knobClass: O = void 0 } = t, { ongrab: H = Se } = t, { onchange: V = Se } = t, { onrelease: W = Se } = t, { onexceed: D = Se } = t, { direction: Y = "x" } = t, { getValue: Q = En } = t, { setValue: U = En } = t, { enableSpinButtons: te = !0 } = t, { enableForceUseKnob: se = !1 } = t, { enableStopPropagation: a = !0 } = t, { enablePointerdownListener: b = !0 } = t, { maxInteractionDistance: z = 6 } = t, { class: R = void 0 } = t, T, j, G, Z, q, K;
  const x = (E) => U(Ui(Bt(E, v, m), S), h), ge = (E, de, Ee = {}) => {
    const { grabbed: ve = !1, released: Fe = !1 } = Ee, he = x(v + E / de * r), Je = k || v, lt = P || m;
    n(21, h = Ft(he) ? he : Bt(he, Je, lt)), K !== he && (K = he, !Ft(he) && (he < Je || he > lt) && D(h, he), h !== q && (q = h, ve && H(h), V(h), Fe && W(h)));
  }, fe = (E) => {
    j || (n(21, h = U(parseFloat(E.target.value))), h !== q && (q = h, V(h)));
  };
  let ae;
  const Pe = (E) => {
    const de = T[c], Ee = o / r * de;
    ge(Ee + E.detail[Y], de), clearTimeout(ae), ae = setTimeout(
      () => {
        W(h);
      },
      250
    );
  }, re = (E) => {
    const de = T.getBoundingClientRect(), ve = E[f] - de[Y], Fe = T[c];
    return Math.abs(ve - i * Fe);
  }, g = (E) => {
    let de = !1;
    a && E.stopPropagation(), clearTimeout(ae);
    const Ee = T.getBoundingClientRect();
    if (j = T[c], Z = E[f], G = Z - Ee[Y], se) {
      if (Math.abs(G - i * j) > z)
        return;
      de = !0, E.stopPropagation();
    }
    return document.activeElement !== T && T.focus(), ge(G, j, { grabbed: !0 }), document.documentElement.addEventListener("pointermove", C), document.documentElement.addEventListener("pointerup", F), de;
  }, C = (E) => {
    const de = E[f] - Z;
    ge(G + de, j);
  }, F = (E) => {
    j = void 0, document.documentElement.removeEventListener("pointermove", C), document.documentElement.removeEventListener("pointerup", F), V(h), W(h);
  }, J = () => {
    n(21, h = x(o + ie * S)), V(h);
  };
  let X, ie = 1, Ae = !1;
  const at = (E) => (de) => {
    clearTimeout(ae), ie = E, Ae = !1, X = setInterval(
      () => {
        Ae = !0, J();
      },
      100
    ), document.addEventListener("pointercancel", ze), document.addEventListener("pointerup", ze);
  }, ze = (E) => {
    clearTimeout(X), Ae || J(), W(h), document.removeEventListener("pointerup", ze);
  };
  function Ye(E) {
    je[E ? "unshift" : "push"](() => {
      T = E, n(16, T);
    });
  }
  return e.$$set = (E) => {
    "min" in E && n(0, v = E.min), "max" in E && n(1, m = E.max), "step" in E && n(2, S = E.step), "id" in E && n(3, w = E.id), "value" in E && n(21, h = E.value), "valueMin" in E && n(22, k = E.valueMin), "valueMax" in E && n(23, P = E.valueMax), "trackStyle" in E && n(4, A = E.trackStyle), "trackClass" in E && n(5, B = E.trackClass), "knobStyle" in E && n(6, L = E.knobStyle), "knobClass" in E && n(7, O = E.knobClass), "ongrab" in E && n(24, H = E.ongrab), "onchange" in E && n(25, V = E.onchange), "onrelease" in E && n(26, W = E.onrelease), "onexceed" in E && n(27, D = E.onexceed), "direction" in E && n(8, Y = E.direction), "getValue" in E && n(28, Q = E.getValue), "setValue" in E && n(29, U = E.setValue), "enableSpinButtons" in E && n(9, te = E.enableSpinButtons), "enableForceUseKnob" in E && n(10, se = E.enableForceUseKnob), "enableStopPropagation" in E && n(30, a = E.enableStopPropagation), "enablePointerdownListener" in E && n(11, b = E.enablePointerdownListener), "maxInteractionDistance" in E && n(31, z = E.maxInteractionDistance), "class" in E && n(12, R = E.class), "$$scope" in E && n(38, _ = E.$$scope);
  }, e.$$.update = () => {
    e.$$.dirty[0] & /*value, getValue*/
    270532608 && n(14, o = h !== void 0 ? Q(h) : 0), e.$$.dirty[0] & /*max, min*/
    3 && (r = m - v), e.$$.dirty[0] & /*numberValue, min, max*/
    16387 && n(33, i = Vi(o, v, m)), e.$$.dirty[1] & /*fraction*/
    4 && n(15, s = i * 100), e.$$.dirty[0] & /*direction*/
    256 && n(34, l = Y.toUpperCase()), e.$$.dirty[0] & /*direction*/
    256 && n(35, u = Y === "x" ? "Width" : "Height"), e.$$.dirty[1] & /*dimension*/
    16 && (c = `offset${u}`), e.$$.dirty[1] & /*axis*/
    8, e.$$.dirty[1] & /*axis*/
    8 && (f = `page${l}`), e.$$.dirty[0] & /*position*/
    32768 | e.$$.dirty[1] & /*axis*/
    8 && n(17, d = `transform: translate${l}(${s}%)`);
  }, [
    v,
    m,
    S,
    w,
    A,
    B,
    L,
    O,
    Y,
    te,
    se,
    b,
    R,
    g,
    o,
    s,
    T,
    d,
    fe,
    Pe,
    at,
    h,
    k,
    P,
    H,
    V,
    W,
    D,
    Q,
    U,
    a,
    z,
    re,
    i,
    l,
    u,
    M,
    Ye,
    _
  ];
}
class on extends Ce {
  constructor(t) {
    super(), Te(
      this,
      t,
      Wi,
      Hi,
      ke,
      {
        min: 0,
        max: 1,
        step: 2,
        id: 3,
        value: 21,
        valueMin: 22,
        valueMax: 23,
        trackStyle: 4,
        trackClass: 5,
        knobStyle: 6,
        knobClass: 7,
        ongrab: 24,
        onchange: 25,
        onrelease: 26,
        onexceed: 27,
        direction: 8,
        getValue: 28,
        setValue: 29,
        enableSpinButtons: 9,
        enableForceUseKnob: 10,
        enableStopPropagation: 30,
        enablePointerdownListener: 11,
        maxInteractionDistance: 31,
        class: 12,
        eventDistanceToKnob: 32,
        handlePointerDown: 13
      },
      null,
      [-1, -1]
    );
  }
  get eventDistanceToKnob() {
    return this.$$.ctx[32];
  }
  get handlePointerDown() {
    return this.$$.ctx[13];
  }
}
const { Boolean: qi } = Mo;
function Gn(e, t, n) {
  const o = e.slice();
  return o[47] = t[n], o[48] = t, o[49] = n, o;
}
function Zn(e) {
  let t, n, o, r, i, s, l, u, c = (
    /*index*/
    e[49]
  ), f, d, M, _, v, m, S, w;
  function h() {
    return (
      /*func*/
      e[25](
        /*index*/
        e[49]
      )
    );
  }
  function k(...a) {
    return (
      /*func_1*/
      e[26](
        /*index*/
        e[49],
        /*range*/
        e[47],
        ...a
      )
    );
  }
  function P(...a) {
    return (
      /*func_2*/
      e[27](
        /*range*/
        e[47],
        /*index*/
        e[49],
        ...a
      )
    );
  }
  function A(...a) {
    return (
      /*func_3*/
      e[28](
        /*range*/
        e[47],
        /*index*/
        e[49],
        ...a
      )
    );
  }
  const B = [
    /*range*/
    e[47].defaults,
    /*range*/
    e[47].from,
    { ongrab: h },
    { onchange: k },
    { onexceed: P },
    { onrelease: A }
  ], L = () => (
    /*slider0_binding*/
    e[29](u, c)
  ), O = () => (
    /*slider0_binding*/
    e[29](null, c)
  );
  let H = {};
  for (let a = 0; a < B.length; a += 1)
    H = _t(H, B[a]);
  u = new on({ props: H }), L();
  function V() {
    return (
      /*func_4*/
      e[30](
        /*index*/
        e[49]
      )
    );
  }
  function W(...a) {
    return (
      /*func_5*/
      e[31](
        /*index*/
        e[49],
        /*range*/
        e[47],
        ...a
      )
    );
  }
  function D(...a) {
    return (
      /*func_6*/
      e[32](
        /*range*/
        e[47],
        /*index*/
        e[49],
        ...a
      )
    );
  }
  function Y(...a) {
    return (
      /*func_7*/
      e[33](
        /*range*/
        e[47],
        /*index*/
        e[49],
        ...a
      )
    );
  }
  const Q = [
    /*range*/
    e[47].defaults,
    /*range*/
    e[47].to,
    { ongrab: V },
    { onchange: W },
    { onexceed: D },
    { onrelease: Y }
  ], U = () => (
    /*slider1_binding*/
    e[34](d, c)
  ), te = () => (
    /*slider1_binding*/
    e[34](null, c)
  );
  let se = {};
  for (let a = 0; a < Q.length; a += 1)
    se = _t(se, Q[a]);
  return d = new on({ props: se }), U(), {
    c() {
      t = ee("div"), n = ee("div"), o = ee("div"), r = ce(), i = ee("div"), l = ce(), be(u.$$.fragment), f = ce(), be(d.$$.fragment), M = ce(), y(o, "class", "PinturaMediaRangeIndicatorShadow"), y(i, "class", "PinturaMediaRangeIndicatorOutline"), y(n, "class", "PinturaMediaRangeIndicator"), y(n, "style", s = `left: ${/*range*/
      e[47].indicator.left * 100}%;width:${De(
        /*range*/
        e[47].indicator.width * 100,
        6
      )}%`), y(t, "class", "PinturaMediaRangeClip"), y(t, "data-index", `range_${/*index*/
      e[49]}`), y(t, "data-merge", _ = /*mergeOption*/
      e[6][1] === /*index*/
      e[49] + 1 ? "right" : (
        /*mergeOption*/
        e[6][0] === /*index*/
        e[49] - 1 ? "left" : "none"
      )), y(t, "data-flex", v = /*mergeFlex*/
      e[7][0] || void 0), y(t, "data-state", m = [
        /*activeClipIndex*/
        e[5] === /*index*/
        e[49] && "active",
        /*range*/
        e[47].indicator.visible && "visible"
      ].filter(Boolean).join(" ")), y(t, "style", S = `${ht(
        /*mergeFlex*/
        e[7][1]
      ) ? "--flex:" + /*mergeFlex*/
      e[7][1] + ";" : ""}`);
    },
    m(a, b) {
      oe(a, t, b), $(t, n), $(n, o), $(n, r), $(n, i), $(t, l), _e(u, t, null), $(t, f), _e(d, t, null), $(t, M), w = !0;
    },
    p(a, b) {
      e = a, (!w || b[0] & /*sliderRanges*/
      4096 && s !== (s = `left: ${/*range*/
      e[47].indicator.left * 100}%;width:${De(
        /*range*/
        e[47].indicator.width * 100,
        6
      )}%`)) && y(n, "style", s), c !== /*index*/
      e[49] && (O(), c = /*index*/
      e[49], L());
      const z = b[0] & /*sliderRanges, activeClipIndex, updateRanges, ondragrange, mergeOption, mergeFlex, mergeRanges, onrelease*/
      53478 ? An(B, [
        b[0] & /*sliderRanges*/
        4096 && Ct(
          /*range*/
          e[47].defaults
        ),
        b[0] & /*sliderRanges*/
        4096 && Ct(
          /*range*/
          e[47].from
        ),
        b[0] & /*activeClipIndex*/
        32 && { ongrab: h },
        b[0] & /*updateRanges, sliderRanges, ondragrange, mergeOption, mergeFlex*/
        20674 && { onchange: k },
        b[0] & /*sliderRanges, mergeFlex, mergeOption*/
        4288 && { onexceed: P },
        b[0] & /*mergeFlex, sliderRanges, mergeOption, mergeRanges, onrelease*/
        37060 && { onrelease: A }
      ]) : {};
      u.$set(z), c !== /*index*/
      e[49] && (te(), c = /*index*/
      e[49], U());
      const R = b[0] & /*sliderRanges, activeClipIndex, updateRanges, ondragrange, ranges, mergeOption, mergeFlex, mergeRanges, onrelease*/
      53479 ? An(Q, [
        b[0] & /*sliderRanges*/
        4096 && Ct(
          /*range*/
          e[47].defaults
        ),
        b[0] & /*sliderRanges*/
        4096 && Ct(
          /*range*/
          e[47].to
        ),
        b[0] & /*activeClipIndex*/
        32 && { ongrab: V },
        b[0] & /*updateRanges, sliderRanges, ondragrange, ranges, mergeOption, mergeFlex*/
        20675 && { onchange: W },
        b[0] & /*sliderRanges, mergeFlex, mergeOption*/
        4288 && { onexceed: D },
        b[0] & /*mergeFlex, sliderRanges, mergeOption, mergeRanges, onrelease*/
        37060 && { onrelease: Y }
      ]) : {};
      d.$set(R), (!w || b[0] & /*mergeOption*/
      64 && _ !== (_ = /*mergeOption*/
      e[6][1] === /*index*/
      e[49] + 1 ? "right" : (
        /*mergeOption*/
        e[6][0] === /*index*/
        e[49] - 1 ? "left" : "none"
      ))) && y(t, "data-merge", _), (!w || b[0] & /*mergeFlex*/
      128 && v !== (v = /*mergeFlex*/
      e[7][0] || void 0)) && y(t, "data-flex", v), (!w || b[0] & /*activeClipIndex, sliderRanges*/
      4128 && m !== (m = [
        /*activeClipIndex*/
        e[5] === /*index*/
        e[49] && "active",
        /*range*/
        e[47].indicator.visible && "visible"
      ].filter(Boolean).join(" "))) && y(t, "data-state", m), (!w || b[0] & /*mergeFlex*/
      128 && S !== (S = `${ht(
        /*mergeFlex*/
        e[7][1]
      ) ? "--flex:" + /*mergeFlex*/
      e[7][1] + ";" : ""}`)) && y(t, "style", S);
    },
    i(a) {
      w || (I(u.$$.fragment, a), I(d.$$.fragment, a), w = !0);
    },
    o(a) {
      N(u.$$.fragment, a), N(d.$$.fragment, a), w = !1;
    },
    d(a) {
      a && ne(t), O(), pe(u), te(), pe(d);
    }
  };
}
function Xi(e) {
  let t, n, o, r, i, s = (
    /*sliderRanges*/
    e[12]
  ), l = [];
  for (let c = 0; c < s.length; c += 1)
    l[c] = Zn(Gn(e, s, c));
  const u = (c) => N(l[c], 1, 1, () => {
    l[c] = null;
  });
  return {
    c() {
      t = ee("div");
      for (let c = 0; c < l.length; c += 1)
        l[c].c();
      y(t, "class", "PinturaMediaClipper"), y(t, "style", n = `cursor:${/*cursor*/
      e[11]}`);
    },
    m(c, f) {
      oe(c, t, f);
      for (let d = 0; d < l.length; d += 1)
        l[d] && l[d].m(t, null);
      e[35](t), o = !0, r || (i = [
        me(
          t,
          "measure",
          /*measure_handler*/
          e[36]
        ),
        me(t, "pointerdown", function() {
          Oe(
            /*enablePointerdownListener*/
            e[3] && /*handlePointerDown*/
            e[4]
          ) && /*enablePointerdownListener*/
          (e[3] && /*handlePointerDown*/
          e[4]).apply(this, arguments);
        }),
        me(
          t,
          "pointermove",
          /*handlePointerMove*/
          e[17]
        ),
        me(
          t,
          "pointerleave",
          /*handlePointerLeave*/
          e[16]
        ),
        Ke(
          /*measurable*/
          e[13].call(null, t)
        )
      ], r = !0);
    },
    p(c, f) {
      if (e = c, f[0] & /*mergeOption, mergeFlex, activeClipIndex, sliderRanges, updateRanges, ondragrange, ranges, mergeRanges, onrelease, sliders*/
      54503) {
        s = /*sliderRanges*/
        e[12];
        let d;
        for (d = 0; d < s.length; d += 1) {
          const M = Gn(e, s, d);
          l[d] ? (l[d].p(M, f), I(l[d], 1)) : (l[d] = Zn(M), l[d].c(), I(l[d], 1), l[d].m(t, null));
        }
        for (Be(), d = s.length; d < l.length; d += 1)
          u(d);
        Ie();
      }
      (!o || f[0] & /*cursor*/
      2048 && n !== (n = `cursor:${/*cursor*/
      e[11]}`)) && y(t, "style", n);
    },
    i(c) {
      if (!o) {
        for (let f = 0; f < s.length; f += 1)
          I(l[f]);
        o = !0;
      }
    },
    o(c) {
      l = l.filter(qi);
      for (let f = 0; f < l.length; f += 1)
        N(l[f]);
      o = !1;
    },
    d(c) {
      c && ne(t), vo(l, c), e[35](null), r = !1, Me(i);
    }
  };
}
const Yn = 20;
function Gi(e, t, n) {
  let o, r, i, s;
  const l = xe("measurable");
  let { ranges: u } = t, { minSize: c = 0 } = t, { maxSize: f = 1 } = t, { precision: d } = t, { onchange: M = Se } = t, { ondragrange: _ = Se } = t, { onrelease: v = Se } = t, { enablePointerdownListener: m = !0 } = t, S, w = [], h = [];
  const k = (g, C) => {
    const F = [...u];
    F[g] = C, M(F);
  }, P = (g, C) => {
    const F = u[g][0], J = u[C][1], X = [F, J], ie = [...u];
    ie.splice(g, 2, X), M(ie);
  };
  let A, B;
  const L = (g, C = 0) => s.find(({ from: F, to: J }) => Math.abs(g - F.value) <= C || Math.abs(g - J.value) <= C), O = (g, C = 0) => s.find(({ from: F, to: J }) => g > F.value + C && g < J.value - C), H = (g) => s.findIndex(({ from: C, to: F }) => g > C.value && g < F.value);
  let V, W, D, Y, Q;
  const U = [], te = (g) => {
    if (!A.contains(g.target))
      return !1;
    const C = U.filter(Boolean).map((X) => ({
      slider: X,
      dist: X.eventDistanceToKnob(g)
    })).sort((X, ie) => X.dist < ie.dist ? -1 : X.dist > ie.dist ? 1 : 0).map(({ slider: X }) => X);
    let F = !1;
    for (const X of C)
      if (X.handlePointerDown(g)) {
        g.stopPropagation(), F = !0;
        break;
      }
    if (F)
      return !0;
    Y = g.pageX;
    const J = g.offsetX / B.width;
    if (D = H(J), D === -1)
      return !1;
    if (V = [...u[D]], !(V[0] === 0 && V[1] === 1))
      return n(11, b = "grabbing"), W = [
        s[D].from.valueMin,
        s[D].to.valueMax
      ], document.documentElement.addEventListener("pointermove", se), document.documentElement.addEventListener("pointerup", a), document.documentElement.addEventListener("pointercancel", a), !1;
  }, se = (g) => {
    Q = g.pageX - Y;
    const C = Q / B.width, [F, J] = W;
    let [X, ie] = V, Ae = ie - X;
    X += C, ie += C, ie > J && (ie = J, X = ie - Ae), X < F && (X = F, ie = X + Ae), k(D, [X, ie]);
  }, a = (g) => {
    document.documentElement.removeEventListener("pointermove", se), document.documentElement.removeEventListener("pointerup", a), document.documentElement.removeEventListener("pointercancel", a), D = void 0, n(11, b = "grab");
  };
  let b = "default";
  const z = () => {
    n(11, b = "default");
  }, R = (g) => {
    if (D >= 0)
      return;
    const C = Yn / B.width, F = g.offsetX / B.width, J = O(F, C);
    if (J && J.from.value === 0 && J.to.value === 1) {
      n(11, b = "default");
      return;
    }
    if (J) {
      n(11, b = "grab");
      return;
    }
    const X = 6 / B.width;
    if (L(F, X)) {
      n(11, b = "ew-resize");
      return;
    }
    n(11, b = "default");
  }, T = (g) => n(5, S = g), j = (g, C, F) => {
    if (k(g, [F, C.to.value]), _(F), F > C.from.valueMin || g - 1 < 0) {
      n(6, w = []), n(7, h = []);
      return;
    }
    n(7, h = [g * 2, 0]), n(6, w = [g - 1, g]);
  }, G = (g, C, F, J) => {
    if (F > g.to.valueAdjacent) {
      n(7, h = []), n(6, w = []);
      return;
    }
    const X = Math.abs(J - F), ie = Bt(X / 0.05, 0, 1);
    n(7, h = [C * 2, De(ie, 6)]);
  }, Z = (g, C, F) => {
    n(7, h = []), F === g.from.valueAdjacent ? C - 1 >= 0 && (n(6, w = []), P(C - 1, C)) : n(6, w = []), v();
  };
  function q(g, C) {
    je[g ? "unshift" : "push"](() => {
      U[C * 2] = g, n(10, U);
    });
  }
  const K = (g) => n(5, S = g), x = (g, C, F) => {
    if (k(g, [C.from.value, F]), _(F), F < C.to.valueMax || g + 1 >= u.length) {
      n(6, w = []), n(7, h = []);
      return;
    }
    n(7, h = [g * 2 + 1, 0]), n(6, w = [g, g + 1]);
  }, ge = (g, C, F, J) => {
    if (F < g.to.valueAdjacent) {
      n(7, h = []), n(6, w = []);
      return;
    }
    const X = Math.abs(J - F), ie = Bt(X / 0.05, 0, 1);
    n(7, h = [C * 2 + 1, De(ie, 6)]);
  }, fe = (g, C, F) => {
    n(7, h = []), F === g.to.valueAdjacent ? C + 1 < s.length && (n(6, w = []), P(C, C + 1)) : n(6, w = []), v();
  };
  function ae(g, C) {
    je[g ? "unshift" : "push"](() => {
      U[C * 2 + 1] = g, n(10, U);
    });
  }
  function Pe(g) {
    je[g ? "unshift" : "push"](() => {
      A = g, n(8, A);
    });
  }
  const re = (g) => n(9, B = g.detail);
  return e.$$set = (g) => {
    "ranges" in g && n(0, u = g.ranges), "minSize" in g && n(18, c = g.minSize), "maxSize" in g && n(19, f = g.maxSize), "precision" in g && n(20, d = g.precision), "onchange" in g && n(21, M = g.onchange), "ondragrange" in g && n(1, _ = g.ondragrange), "onrelease" in g && n(2, v = g.onrelease), "enablePointerdownListener" in g && n(3, m = g.enablePointerdownListener);
  }, e.$$.update = () => {
    e.$$.dirty[0] & /*ranges*/
    1 && n(24, o = u.reduce((g, [C, F]) => g + (F - C), 0)), e.$$.dirty[0] & /*maxSize, rangeSpaceTotal*/
    17301504 && n(22, r = f - o), e.$$.dirty[0] & /*rangeSpaceTotal, minSize*/
    17039360 && n(23, i = o - c), e.$$.dirty[0] & /*ranges, precision, rangeSpaceAvailableMax, rangeSpaceAvailableMin*/
    13631489 && n(12, s = u.map(([g, C], F) => {
      const [, J] = u[F - 1] || [, 0], [X] = u[F + 1] || [1];
      return {
        defaults: {
          min: 0,
          max: 1,
          step: d,
          enableSpinButtons: !1,
          enableForceUseKnob: !0,
          enableStopPropagation: !1,
          enablePointerdownListener: !1,
          maxInteractionDistance: Yn
        },
        indicator: {
          left: g,
          width: C - g,
          visible: !(De(g, 6) === 0 && De(C, 6) === 1)
        },
        from: {
          value: g,
          valueMin: Math.max(J, g - r),
          valueMax: Math.min(C, i + g),
          // end,
          valueAdjacent: J
        },
        to: {
          value: C,
          valueMin: Math.max(g, C - i),
          valueMax: Math.min(X, C + r),
          valueAdjacent: X
        }
      };
    }));
  }, [
    u,
    _,
    v,
    m,
    te,
    S,
    w,
    h,
    A,
    B,
    U,
    b,
    s,
    l,
    k,
    P,
    z,
    R,
    c,
    f,
    d,
    M,
    r,
    i,
    o,
    T,
    j,
    G,
    Z,
    q,
    K,
    x,
    ge,
    fe,
    ae,
    Pe,
    re
  ];
}
class Zi extends Ce {
  constructor(t) {
    super(), Te(
      this,
      t,
      Gi,
      Xi,
      ke,
      {
        ranges: 0,
        minSize: 18,
        maxSize: 19,
        precision: 20,
        onchange: 21,
        ondragrange: 1,
        onrelease: 2,
        enablePointerdownListener: 3,
        handlePointerDown: 4
      },
      null,
      [-1, -1]
    );
  }
  get handlePointerDown() {
    return this.$$.ctx[4];
  }
}
const Yi = (e, t = 0, n, o = 0) => {
  const r = [], i = [];
  let s = [], l = [], u = o;
  const c = () => {
    l.forEach((d) => d()), l = [];
  }, f = () => {
    c();
    for (let d = 0; d < u; d++) {
      r[d] = e(t, n);
      const M = r[d].subscribe((_) => {
        i[d] = _, s.forEach((v) => v(i));
      });
      l.push(M);
    }
  };
  return f(), {
    subscribe: (d) => (s.push(d), d(i), () => s = s.filter((M) => M === d)),
    unsubscribe: () => {
      c();
    },
    set: (d, M, _) => {
      r[d].set(M, _);
    },
    set length(d) {
      u = d, f();
    },
    reset: () => {
      for (let d = 0; d < u; d++)
        r[d].set(t, { hard: !0 });
    }
  };
}, Jn = (e, t) => {
  e.fastSeek && e.fastSeek(t), e.currentTime = t;
};
function Qn(e, t, n) {
  const o = e.slice();
  return o[31] = t[n], o[33] = n, o;
}
function xn(e) {
  let t, n;
  return {
    c() {
      t = ee("div"), y(t, "class", "PinturaMediaMask"), y(t, "style", n = `left: ${/*range*/
      e[31][0] * 100}%;width:${/*range*/
      (e[31][1] - /*range*/
      e[31][0]) * 100}%`), y(t, "data-index", `mask_${/*index*/
      e[33]}`);
    },
    m(o, r) {
      oe(o, t, r);
    },
    p(o, r) {
      r[0] & /*maskRanges*/
      4 && n !== (n = `left: ${/*range*/
      o[31][0] * 100}%;width:${/*range*/
      (o[31][1] - /*range*/
      o[31][0]) * 100}%`) && y(t, "style", n);
    },
    d(o) {
      o && ne(t);
    }
  };
}
function Ji(e) {
  let t, n, o, r = (
    /*maskRanges*/
    e[2]
  ), i = [];
  for (let s = 0; s < r.length; s += 1)
    i[s] = xn(Qn(e, r, s));
  return {
    c() {
      t = ee("div");
      for (let s = 0; s < i.length; s += 1)
        i[s].c();
      y(t, "class", "PinturaVideoFrames");
    },
    m(s, l) {
      oe(s, t, l);
      for (let u = 0; u < i.length; u += 1)
        i[u] && i[u].m(t, null);
      n || (o = [
        me(
          t,
          "measure",
          /*measure_handler*/
          e[23]
        ),
        Ke(
          /*measurable*/
          e[3].call(null, t, { observePosition: !0 })
        )
      ], n = !0);
    },
    p(s, l) {
      if (l[0] & /*maskRanges*/
      4) {
        r = /*maskRanges*/
        s[2];
        let u;
        for (u = 0; u < r.length; u += 1) {
          const c = Qn(s, r, u);
          i[u] ? i[u].p(c, l) : (i[u] = xn(c), i[u].c(), i[u].m(t, null));
        }
        for (; u < i.length; u += 1)
          i[u].d(1);
        i.length = r.length;
      }
    },
    i: ue,
    o: ue,
    d(s) {
      s && ne(t), vo(i, s), n = !1, Me(o);
    }
  };
}
function Qi(e, t, n) {
  let o, r, i, s, l, u, c, f, d, M, _, v, { video: m } = t, { videoSize: S } = t, { cropAspectRatio: w } = t, { pixelRatio: h } = t, { onchange: k = Se } = t, { frameBackgroundColor: P } = t, { frameScaleFactor: A = 2 } = t, { frameLimit: B = 16 } = t, { ranges: L } = t;
  const O = xe("measurable"), H = st() && document.createElement("canvas");
  let V = [], W;
  const D = Yi(Ro, 0, {
    stiffness: 0.2,
    damping: 0.7,
    precision: 0.01
  });
  le(e, D, (a) => n(22, v = a));
  const Y = () => {
    V.forEach((x) => ao(x)), V = [], D.reset();
    const a = m.cloneNode();
    let b = !1, z = !1, R = 0, T = 1, j = 0;
    const G = () => {
      if (b || !z)
        return;
      const x = document.createElement("canvas");
      if (x.width = M, x.height = _, x.getContext("2d").drawImage(a, 0, 0, M, _), x.dataset.retain = !0, V = [...V, x], D.set(j, 1), j++, R += T, R + T * 0.5 >= m.duration) {
        setTimeout(
          () => {
            a.removeEventListener("seeked", q), a.removeEventListener("error", K), a.src = "", a.remove();
          },
          0
        );
        return;
      }
      Jn(a, R + T * 0.5);
    }, Z = () => {
      a.removeEventListener("loadeddata", Z), !b && (T = m.duration / c, R = 0, z = !0, Jn(a, T * 0.5));
    }, q = () => {
      G();
    }, K = () => {
      console.error(a.error);
    };
    return a.addEventListener("seeked", q), a.addEventListener("error", K), Jt() && a.load(), a.readyState > 0 ? Z() : a.addEventListener("loadeddata", Z), () => {
      a.removeEventListener("seeked", q), a.removeEventListener("error", K), a.removeEventListener("loadeddata", Z), b = !0;
    };
  }, Q = () => {
    V = [], D.reset();
    let a = m.duration / c, b = !1;
    const z = (R, T) => {
      const j = m.cloneNode(), G = () => {
        b || (j.currentTime = T, V[R] = j, D.set(R, 1));
      };
      j.readyState > 0 ? G() : j.onloadeddata = G, Jt() && j.load();
    };
    for (let R = 0; R < c; R++) {
      const T = R * a + a * 0.5;
      z(R, T);
    }
    return () => {
      b = !0;
    };
  };
  let U;
  const te = (a, b, z) => {
    const R = [];
    for (let T = 0; T < a; T++) {
      const j = T, G = Math.min(1, b[j]), Z = z.width / a, q = z.height, K = Z * j;
      R.push({
        itemRect: { x: K, y: 0, width: Z, height: q },
        // fade in
        opacity: Math.min(G, 1),
        // placeholder background color while we wait for image to load
        backgroundColor: [...P],
        // frame data
        data: V[j] || H,
        // data size
        dataSize: { width: f, height: d }
      });
    }
    k(R);
  }, se = (a) => n(0, W = a.detail);
  return e.$$set = (a) => {
    "video" in a && n(4, m = a.video), "videoSize" in a && n(5, S = a.videoSize), "cropAspectRatio" in a && n(6, w = a.cropAspectRatio), "pixelRatio" in a && n(7, h = a.pixelRatio), "onchange" in a && n(8, k = a.onchange), "frameBackgroundColor" in a && n(9, P = a.frameBackgroundColor), "frameScaleFactor" in a && n(10, A = a.frameScaleFactor), "frameLimit" in a && n(11, B = a.frameLimit), "ranges" in a && n(12, L = a.ranges);
  }, e.$$.update = () => {
    e.$$.dirty[0] & /*ranges*/
    4096 && n(2, o = L.map(([a, b], z) => {
      const [, R] = L[z - 1] || [, 0];
      return [R, a];
    }).concat([[L[L.length - 1][1], 1]])), e.$$.dirty[0] & /*videoFrameContainerRect*/
    1 && n(15, r = W && W.width && W.height), e.$$.dirty[0] & /*video, videoSize*/
    48 && n(18, i = m && S.width / S.height), e.$$.dirty[0] & /*cropAspectRatio, videoAspectRatio*/
    262208 && n(20, s = w || i), e.$$.dirty[0] & /*videoFrameContainerRectHasSize, videoFrameContainerRect*/
    32769 && n(21, l = r && W.width / W.height), e.$$.dirty[0] & /*videoFramesContainerAspectRatio, frameAspectRatio*/
    3145728 && n(19, u = l && s && Math.ceil(l / s)), e.$$.dirty[0] & /*videoFramesMax, frameLimit*/
    526336 && n(14, c = u && Math.min(B, u)), e.$$.dirty[0] & /*videoFrameContainerRect, videoFramesTotal*/
    16385 && n(17, f = W && c && W.width / c), e.$$.dirty[0] & /*frameWidth, videoAspectRatio*/
    393216 && n(16, d = f && f / i), e.$$.dirty[0] & /*videoFramesTotal*/
    16384 && n(1, D.length = c, D), e.$$.dirty[0] & /*frameWidth, pixelRatio, frameScaleFactor*/
    132224 && (M = f && Math.floor(f * h * A)), e.$$.dirty[0] & /*frameHeight, pixelRatio, frameScaleFactor*/
    66688 && (_ = d && Math.floor(d * h * A)), e.$$.dirty[0] & /*video, videoFramesTotal, cancelFrameRedrawAction*/
    24592 && m && c && (U && U(), n(13, U = Dt() && $o() ? Q() : Y())), e.$$.dirty[0] & /*videoFrameContainerRectHasSize, videoFramesTotal, $videoFrameOpacitySprings, videoFrameContainerRect*/
    4243457 && r && te(c, v, W);
  }, [
    W,
    D,
    o,
    O,
    m,
    S,
    w,
    h,
    k,
    P,
    A,
    B,
    L,
    U,
    c,
    r,
    d,
    f,
    i,
    u,
    s,
    l,
    v,
    se
  ];
}
class xi extends Ce {
  constructor(t) {
    super(), Te(
      this,
      t,
      Qi,
      Ji,
      ke,
      {
        video: 4,
        videoSize: 5,
        cropAspectRatio: 6,
        pixelRatio: 7,
        onchange: 8,
        frameBackgroundColor: 9,
        frameScaleFactor: 10,
        frameLimit: 11,
        ranges: 12
      },
      null,
      [-1, -1]
    );
  }
}
function $n(e) {
  let t, n;
  return {
    c() {
      t = new Br(!1), n = So(), t.a = n;
    },
    m(o, r) {
      t.m(
        /*svg*/
        e[1],
        o,
        r
      ), oe(o, n, r);
    },
    p(o, r) {
      r & /*svg*/
      2 && t.p(
        /*svg*/
        o[1]
      );
    },
    d(o) {
      o && ne(n), o && t.d();
    }
  };
}
function $i(e) {
  let t, n, o, r = (
    /*svg*/
    e[1] && $n(e)
  );
  return {
    c() {
      t = ee("div"), r && r.c(), y(t, "class", "PinturaMeter");
    },
    m(i, s) {
      oe(i, t, s), r && r.m(t, null), n || (o = [
        me(
          t,
          "measure",
          /*measure_handler*/
          e[6]
        ),
        Ke(
          /*measurable*/
          e[2].call(null, t)
        )
      ], n = !0);
    },
    p(i, [s]) {
      /*svg*/
      i[1] ? r ? r.p(i, s) : (r = $n(i), r.c(), r.m(t, null)) : r && (r.d(1), r = null);
    },
    i: ue,
    o: ue,
    d(i) {
      i && ne(t), r && r.d(), n = !1, Me(o);
    }
  };
}
function es(e, t, n) {
  let { steps: o = 10 } = t, { skipFirst: r = !1 } = t, { skipLast: i = !1 } = t, { size: s = void 0 } = t;
  const l = xe("measurable");
  let u;
  const c = { x: 2, y: 0 }, f = (M, _, v) => `M ${M} ${_} L${M} ${_ + v}`, d = (M) => n(0, s = ur(M.detail));
  return e.$$set = (M) => {
    "steps" in M && n(3, o = M.steps), "skipFirst" in M && n(4, r = M.skipFirst), "skipLast" in M && n(5, i = M.skipLast), "size" in M && n(0, s = M.size);
  }, e.$$.update = () => {
    if (e.$$.dirty & /*size, steps, skipFirst, skipLast*/
    57 && s) {
      let M, _, v = "", m, S = s.y;
      const w = s.x / o;
      for (let h = 0; h <= o; h++)
        if (M = c.x + h * w, _ = 0, !(h === 0 && r)) {
          if (h === o && i)
            break;
          v += f(M, _, 4) + " ";
        }
      m = M + c.x, n(1, u = `<svg width="${m}" height="${S}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${m} ${S}" aria-hidden="true" focusable="false"><path fill-rule="evenodd" d="${v.trim()}"/></svg>`);
    }
  }, [s, u, l, o, r, i, d];
}
class ts extends Ce {
  constructor(t) {
    super(), Te(this, t, es, $i, ke, {
      steps: 3,
      skipFirst: 4,
      skipLast: 5,
      size: 0
    });
  }
}
const ns = (e) => {
  const t = Math.round(e), n = Math.floor(t / 3600), o = Math.floor((t - n * 3600) / 60), r = Math.round(t - n * 3600 - o * 60);
  return [n, o, r];
}, rn = (e) => ns(e).filter((t, n) => n === 0 ? t > 0 : !0).map((t, n) => n > 0 ? `${t}`.padStart(2, "0") : t).join(":");
function os(e) {
  let t, n, o, r, i, s, l, u, c, f, d, M, _;
  function v(S) {
    e[6](S);
  }
  let m = {
    steps: 16,
    skipFirst: !0,
    skipLast: !0
  };
  return (
    /*meterSize*/
    e[0] !== void 0 && (m.size = /*meterSize*/
    e[0]), n = new ts({ props: m }), je.push(() => Nr(n, "size", v)), {
      c() {
        t = ee("div"), be(n.$$.fragment), r = ce(), i = ee("div"), s = ee("time"), l = pt(
          /*start*/
          e[1]
        ), u = ce(), c = ee("time"), f = pt(
          /*end*/
          e[2]
        ), y(i, "class", "timeLabels"), y(t, "class", "PinturaTimeline");
      },
      m(S, w) {
        oe(S, t, w), _e(n, t, null), $(t, r), $(t, i), $(i, s), $(s, l), $(i, u), $(i, c), $(c, f), d = !0, M || (_ = [
          me(
            t,
            "measure",
            /*measure_handler*/
            e[5]
          ),
          Ke(
            /*measurable*/
            e[3].call(null, t)
          )
        ], M = !0);
      },
      p(S, [w]) {
        const h = {};
        !o && w & /*meterSize*/
        1 && (o = !0, h.size = /*meterSize*/
        S[0], Ur(() => o = !1)), n.$set(h), (!d || w & /*start*/
        2) && Qt(
          l,
          /*start*/
          S[1]
        ), (!d || w & /*end*/
        4) && Qt(
          f,
          /*end*/
          S[2]
        );
      },
      i(S) {
        d || (I(n.$$.fragment, S), d = !0);
      },
      o(S) {
        N(n.$$.fragment, S), d = !1;
      },
      d(S) {
        S && ne(t), pe(n), M = !1, Me(_);
      }
    }
  );
}
function rs(e, t, n) {
  let o, r, { duration: i } = t;
  const s = xe("measurable");
  let l;
  function u(f) {
    ln.call(this, e, f);
  }
  function c(f) {
    l = f, n(0, l);
  }
  return e.$$set = (f) => {
    "duration" in f && n(4, i = f.duration);
  }, e.$$.update = () => {
    e.$$.dirty & /*duration*/
    16 && n(2, o = rn(i));
  }, n(1, r = rn(0)), [
    l,
    r,
    o,
    s,
    i,
    u,
    c
  ];
}
class is extends Ce {
  constructor(t) {
    super(), Te(this, t, rs, os, ke, { duration: 4 });
  }
}
function ss(e) {
  let t, n;
  return {
    c() {
      t = ee("span"), n = pt(
        /*videoTime*/
        e[5]
      ), y(t, "slot", "knob");
    },
    m(o, r) {
      oe(o, t, r), $(t, n);
    },
    p(o, r) {
      r & /*videoTime*/
      32 && Qt(
        n,
        /*videoTime*/
        o[5]
      );
    },
    d(o) {
      o && ne(t);
    }
  };
}
function as(e) {
  let t, n;
  return t = new on({
    props: {
      class: "PinturaActiveFrame",
      enableSpinButtons: !1,
      enableStopPropagation: !1,
      knobClass: [
        "PinturaActiveFrameKnob",
        /*enableDetailIndicator*/
        e[4] && "PinturaActiveFrameDetailedKnob"
      ].filter(Boolean).join(" "),
      value: (
        /*offset*/
        e[0]
      ),
      onchange: (
        /*onchange*/
        e[2]
      ),
      onrelease: (
        /*onrelease*/
        e[3]
      ),
      min: 0,
      max: 1,
      step: (
        /*precision*/
        e[1]
      ),
      $$slots: { knob: [ss] },
      $$scope: { ctx: e }
    }
  }), {
    c() {
      be(t.$$.fragment);
    },
    m(o, r) {
      _e(t, o, r), n = !0;
    },
    p(o, [r]) {
      const i = {};
      r & /*enableDetailIndicator*/
      16 && (i.knobClass = [
        "PinturaActiveFrameKnob",
        /*enableDetailIndicator*/
        o[4] && "PinturaActiveFrameDetailedKnob"
      ].filter(Boolean).join(" ")), r & /*offset*/
      1 && (i.value = /*offset*/
      o[0]), r & /*onchange*/
      4 && (i.onchange = /*onchange*/
      o[2]), r & /*onrelease*/
      8 && (i.onrelease = /*onrelease*/
      o[3]), r & /*precision*/
      2 && (i.step = /*precision*/
      o[1]), r & /*$$scope, videoTime*/
      160 && (i.$$scope = { dirty: r, ctx: o }), t.$set(i);
    },
    i(o) {
      n || (I(t.$$.fragment, o), n = !0);
    },
    o(o) {
      N(t.$$.fragment, o), n = !1;
    },
    d(o) {
      pe(t, o);
    }
  };
}
function ls(e, t, n) {
  let o, { offset: r } = t, { duration: i } = t, { precision: s } = t, { onchange: l } = t, { onrelease: u } = t, { enableDetailIndicator: c = !0 } = t;
  return e.$$set = (f) => {
    "offset" in f && n(0, r = f.offset), "duration" in f && n(6, i = f.duration), "precision" in f && n(1, s = f.precision), "onchange" in f && n(2, l = f.onchange), "onrelease" in f && n(3, u = f.onrelease), "enableDetailIndicator" in f && n(4, c = f.enableDetailIndicator);
  }, e.$$.update = () => {
    e.$$.dirty & /*offset, duration*/
    65 && n(5, o = rn(r * i));
  }, [
    r,
    s,
    l,
    u,
    c,
    o,
    i
  ];
}
class cs extends Ce {
  constructor(t) {
    super(), Te(this, t, ls, as, ke, {
      offset: 0,
      duration: 6,
      precision: 1,
      onchange: 2,
      onrelease: 3,
      enableDetailIndicator: 4
    });
  }
}
function eo(e) {
  let t, n;
  return t = new fn({
    props: {
      onclick: (
        /*togglePlayPause*/
        e[30]
      ),
      label: (
        /*$isPlaying*/
        e[25] ? (
          /*locale*/
          e[7].trimLabelPause
        ) : (
          /*locale*/
          e[7].trimLabelPlay
        )
      ),
      icon: (
        /*$isPlaying*/
        e[25] ? (
          /*locale*/
          e[7].trimIconButtonPause
        ) : (
          /*locale*/
          e[7].trimIconButtonPlay
        )
      ),
      class: "PinturaButtonPlay",
      hideLabel: !0
    }
  }), {
    c() {
      be(t.$$.fragment);
    },
    m(o, r) {
      _e(t, o, r), n = !0;
    },
    p(o, r) {
      const i = {};
      r[0] & /*$isPlaying, locale*/
      33554560 && (i.label = /*$isPlaying*/
      o[25] ? (
        /*locale*/
        o[7].trimLabelPause
      ) : (
        /*locale*/
        o[7].trimLabelPlay
      )), r[0] & /*$isPlaying, locale*/
      33554560 && (i.icon = /*$isPlaying*/
      o[25] ? (
        /*locale*/
        o[7].trimIconButtonPause
      ) : (
        /*locale*/
        o[7].trimIconButtonPlay
      )), t.$set(i);
    },
    i(o) {
      n || (I(t.$$.fragment, o), n = !0);
    },
    o(o) {
      N(t.$$.fragment, o), n = !1;
    },
    d(o) {
      pe(t, o);
    }
  };
}
function to(e) {
  let t, n;
  return t = new fn({
    props: {
      onclick: (
        /*toggleMute*/
        e[31]
      ),
      label: (
        /*$isMuted*/
        e[26] ? (
          /*locale*/
          e[7].trimLabelUnmute
        ) : (
          /*locale*/
          e[7].trimLabelMute
        )
      ),
      icon: (
        /*$isMuted*/
        e[26] ? (
          /*locale*/
          e[7].trimIconButtonUnmute
        ) : (
          /*locale*/
          e[7].trimIconButtonMute
        )
      ),
      class: "PinturaButtonMute",
      hideLabel: !0
    }
  }), {
    c() {
      be(t.$$.fragment);
    },
    m(o, r) {
      _e(t, o, r), n = !0;
    },
    p(o, r) {
      const i = {};
      r[0] & /*$isMuted, locale*/
      67108992 && (i.label = /*$isMuted*/
      o[26] ? (
        /*locale*/
        o[7].trimLabelUnmute
      ) : (
        /*locale*/
        o[7].trimLabelMute
      )), r[0] & /*$isMuted, locale*/
      67108992 && (i.icon = /*$isMuted*/
      o[26] ? (
        /*locale*/
        o[7].trimIconButtonUnmute
      ) : (
        /*locale*/
        o[7].trimIconButtonMute
      )), t.$set(i);
    },
    i(o) {
      n || (I(t.$$.fragment, o), n = !0);
    },
    o(o) {
      N(t.$$.fragment, o), n = !1;
    },
    d(o) {
      pe(t, o);
    }
  };
}
function no(e) {
  let t, n;
  return t = new fn({
    props: {
      disabled: !/*canSplitVideo*/
      (e[12] ? !/*shouldDisableSplitVideo*/
      e[21] : (
        /*canMergeVideo*/
        e[22] && !/*shouldDisableMergeVideo*/
        e[20]
      )),
      onclick: (
        /*func*/
        e[81]
      ),
      label: (
        /*canSplitVideo*/
        e[12] ? (
          /*locale*/
          e[7].trimLabelSplit
        ) : (
          /*locale*/
          e[7].trimLabelMerge
        )
      ),
      icon: (
        /*canSplitVideo*/
        e[12] ? (
          /*locale*/
          e[7].trimIconButtonSplit
        ) : (
          /*locale*/
          e[7].trimIconButtonMerge
        )
      ),
      class: "PinturaButtonSplit"
    }
  }), {
    c() {
      be(t.$$.fragment);
    },
    m(o, r) {
      _e(t, o, r), n = !0;
    },
    p(o, r) {
      const i = {};
      r[0] & /*canSplitVideo, shouldDisableSplitVideo, canMergeVideo, shouldDisableMergeVideo*/
      7344128 && (i.disabled = !/*canSplitVideo*/
      (o[12] ? !/*shouldDisableSplitVideo*/
      o[21] : (
        /*canMergeVideo*/
        o[22] && !/*shouldDisableMergeVideo*/
        o[20]
      ))), r[0] & /*canSplitVideo, $frameOffset*/
      135168 && (i.onclick = /*func*/
      o[81]), r[0] & /*canSplitVideo, locale*/
      4224 && (i.label = /*canSplitVideo*/
      o[12] ? (
        /*locale*/
        o[7].trimLabelSplit
      ) : (
        /*locale*/
        o[7].trimLabelMerge
      )), r[0] & /*canSplitVideo, locale*/
      4224 && (i.icon = /*canSplitVideo*/
      o[12] ? (
        /*locale*/
        o[7].trimIconButtonSplit
      ) : (
        /*locale*/
        o[7].trimIconButtonMerge
      )), t.$set(i);
    },
    i(o) {
      n || (I(t.$$.fragment, o), n = !0);
    },
    o(o) {
      N(t.$$.fragment, o), n = !1;
    },
    d(o) {
      pe(t, o);
    }
  };
}
function oo(e) {
  let t, n;
  return t = new xi({
    props: {
      video: (
        /*$imagePreview*/
        e[24]
      ),
      videoSize: (
        /*$imageSize*/
        e[23]
      ),
      cropAspectRatio: (
        /*$imageCropRectAspectRatio*/
        e[16]
      ),
      ranges: (
        /*clipRanges*/
        e[13]
      ),
      pixelRatio: (
        /*$pixelRatio*/
        e[27]
      ),
      frameBackgroundColor: (
        /*$rootForegroundColor*/
        e[28]
      ),
      onchange: (
        /*func_1*/
        e[83]
      )
    }
  }), {
    c() {
      be(t.$$.fragment);
    },
    m(o, r) {
      _e(t, o, r), n = !0;
    },
    p(o, r) {
      const i = {};
      r[0] & /*$imagePreview*/
      16777216 && (i.video = /*$imagePreview*/
      o[24]), r[0] & /*$imageSize*/
      8388608 && (i.videoSize = /*$imageSize*/
      o[23]), r[0] & /*$imageCropRectAspectRatio*/
      65536 && (i.cropAspectRatio = /*$imageCropRectAspectRatio*/
      o[16]), r[0] & /*clipRanges*/
      8192 && (i.ranges = /*clipRanges*/
      o[13]), r[0] & /*$pixelRatio*/
      134217728 && (i.pixelRatio = /*$pixelRatio*/
      o[27]), r[0] & /*$rootForegroundColor*/
      268435456 && (i.frameBackgroundColor = /*$rootForegroundColor*/
      o[28]), r[0] & /*framePreviews*/
      2048 && (i.onchange = /*func_1*/
      o[83]), t.$set(i);
    },
    i(o) {
      n || (I(t.$$.fragment, o), n = !0);
    },
    o(o) {
      N(t.$$.fragment, o), n = !1;
    },
    d(o) {
      pe(t, o);
    }
  };
}
function ro(e) {
  let t, n;
  return t = new Zi({
    props: {
      ranges: (
        /*clipRanges*/
        e[13]
      ),
      minSize: (
        /*clipMinSize*/
        e[14]
      ),
      maxSize: (
        /*clipMaxSize*/
        e[15]
      ),
      precision: To,
      onchange: (
        /*func_2*/
        e[84]
      ),
      ondragrange: (
        /*func_3*/
        e[85]
      ),
      onrelease: (
        /*func_4*/
        e[86]
      )
    }
  }), {
    c() {
      be(t.$$.fragment);
    },
    m(o, r) {
      _e(t, o, r), n = !0;
    },
    p(o, r) {
      const i = {};
      r[0] & /*clipRanges*/
      8192 && (i.ranges = /*clipRanges*/
      o[13]), r[0] & /*clipMinSize*/
      16384 && (i.minSize = /*clipMinSize*/
      o[14]), r[0] & /*clipMaxSize*/
      32768 && (i.maxSize = /*clipMaxSize*/
      o[15]), t.$set(i);
    },
    i(o) {
      n || (I(t.$$.fragment, o), n = !0);
    },
    o(o) {
      N(t.$$.fragment, o), n = !1;
    },
    d(o) {
      pe(t, o);
    }
  };
}
function us(e) {
  let t, n, o, r, i, s, l, u, c, f, d, M, _, v, m = (
    /*trimEnablePlayPauseButton*/
    e[5] && eo(e)
  ), S = (
    /*trimEnableMuteButton*/
    e[6] && to(e)
  ), w = (
    /*trimEnableSplit*/
    e[2] && no(e)
  );
  l = new is({
    props: { duration: (
      /*$mediaDuration*/
      e[18]
    ) }
  }), l.$on(
    "measure",
    /*measure_handler_1*/
    e[82]
  );
  let h = (
    /*trimEnableFramePreview*/
    e[4] && oo(e)
  ), k = (
    /*trimEnableFramePreview*/
    e[4] && /*trimEnableMediaClipper*/
    e[3] && ro(e)
  );
  return d = new cs({
    props: {
      offset: (
        /*$frameOffset*/
        e[17]
      ),
      duration: (
        /*$mediaDuration*/
        e[18]
      ),
      precision: To,
      onchange: (
        /*didDragActiveFrame*/
        e[52]
      ),
      onrelease: (
        /*didReleaseActiveFrame*/
        e[53]
      ),
      enableDetailIndicator: (
        /*trimEnableFramePreview*/
        e[4]
      )
    }
  }), {
    c() {
      t = ee("div"), n = ee("div"), o = ee("div"), m && m.c(), r = ce(), S && S.c(), i = ce(), w && w.c(), s = ce(), be(l.$$.fragment), u = ce(), h && h.c(), c = ce(), k && k.c(), f = ce(), be(d.$$.fragment), y(o, "class", "PinturaTrimControls"), y(n, "class", "PinturaVideoTrimmer"), y(t, "slot", "footer"), y(
        t,
        "style",
        /*footerStyle*/
        e[19]
      );
    },
    m(P, A) {
      oe(P, t, A), $(t, n), $(n, o), m && m.m(o, null), $(o, r), S && S.m(o, null), $(o, i), w && w.m(o, null), $(n, s), _e(l, n, null), $(n, u), h && h.m(n, null), $(n, c), k && k.m(n, null), $(n, f), _e(d, n, null), M = !0, _ || (v = [
        me(
          n,
          "measure",
          /*measure_handler_2*/
          e[87]
        ),
        Ke(
          /*measurable*/
          e[29].call(null, n, { observePosition: !0 })
        ),
        me(
          t,
          "measure",
          /*measure_handler_3*/
          e[88]
        ),
        Ke(
          /*measurable*/
          e[29].call(null, t, { observePosition: !0 })
        )
      ], _ = !0);
    },
    p(P, A) {
      /*trimEnablePlayPauseButton*/
      P[5] ? m ? (m.p(P, A), A[0] & /*trimEnablePlayPauseButton*/
      32 && I(m, 1)) : (m = eo(P), m.c(), I(m, 1), m.m(o, r)) : m && (Be(), N(m, 1, 1, () => {
        m = null;
      }), Ie()), /*trimEnableMuteButton*/
      P[6] ? S ? (S.p(P, A), A[0] & /*trimEnableMuteButton*/
      64 && I(S, 1)) : (S = to(P), S.c(), I(S, 1), S.m(o, i)) : S && (Be(), N(S, 1, 1, () => {
        S = null;
      }), Ie()), /*trimEnableSplit*/
      P[2] ? w ? (w.p(P, A), A[0] & /*trimEnableSplit*/
      4 && I(w, 1)) : (w = no(P), w.c(), I(w, 1), w.m(o, null)) : w && (Be(), N(w, 1, 1, () => {
        w = null;
      }), Ie());
      const B = {};
      A[0] & /*$mediaDuration*/
      262144 && (B.duration = /*$mediaDuration*/
      P[18]), l.$set(B), /*trimEnableFramePreview*/
      P[4] ? h ? (h.p(P, A), A[0] & /*trimEnableFramePreview*/
      16 && I(h, 1)) : (h = oo(P), h.c(), I(h, 1), h.m(n, c)) : h && (Be(), N(h, 1, 1, () => {
        h = null;
      }), Ie()), /*trimEnableFramePreview*/
      P[4] && /*trimEnableMediaClipper*/
      P[3] ? k ? (k.p(P, A), A[0] & /*trimEnableFramePreview, trimEnableMediaClipper*/
      24 && I(k, 1)) : (k = ro(P), k.c(), I(k, 1), k.m(n, f)) : k && (Be(), N(k, 1, 1, () => {
        k = null;
      }), Ie());
      const L = {};
      A[0] & /*$frameOffset*/
      131072 && (L.offset = /*$frameOffset*/
      P[17]), A[0] & /*$mediaDuration*/
      262144 && (L.duration = /*$mediaDuration*/
      P[18]), A[0] & /*trimEnableFramePreview*/
      16 && (L.enableDetailIndicator = /*trimEnableFramePreview*/
      P[4]), d.$set(L), (!M || A[0] & /*footerStyle*/
      524288) && y(
        t,
        "style",
        /*footerStyle*/
        P[19]
      );
    },
    i(P) {
      M || (I(m), I(S), I(w), I(l.$$.fragment, P), I(h), I(k), I(d.$$.fragment, P), M = !0);
    },
    o(P) {
      N(m), N(S), N(w), N(l.$$.fragment, P), N(h), N(k), N(d.$$.fragment, P), M = !1;
    },
    d(P) {
      P && ne(t), m && m.d(), S && S.d(), w && w.d(), pe(l), h && h.d(), k && k.d(), pe(d), _ = !1, Me(v);
    }
  };
}
function fs(e) {
  let t, n;
  return t = new Oi({
    props: {
      $$slots: { footer: [us] },
      $$scope: { ctx: e }
    }
  }), t.$on(
    "measure",
    /*measure_handler*/
    e[89]
  ), {
    c() {
      be(t.$$.fragment);
    },
    m(o, r) {
      _e(t, o, r), n = !0;
    },
    p(o, r) {
      const i = {};
      r[0] & /*footerStyle, footerRect, videoTrimmerContainerRect, $frameOffset, $mediaDuration, trimEnableFramePreview, clipRanges, clipMinSize, clipMaxSize, trimEnableMediaClipper, $imagePreview, $imageSize, $imageCropRectAspectRatio, $pixelRatio, $rootForegroundColor, framePreviews, timelineSize, canSplitVideo, shouldDisableSplitVideo, canMergeVideo, shouldDisableMergeVideo, locale, trimEnableSplit, $isMuted, trimEnableMuteButton, $isPlaying, trimEnablePlayPauseButton*/
      536870908 | r[3] & /*$$scope*/
      2048 && (i.$$scope = { dirty: r, ctx: o }), t.$set(i);
    },
    i(o) {
      n || (I(t.$$.fragment, o), n = !0);
    },
    o(o) {
      N(t.$$.fragment, o), n = !1;
    },
    d(o) {
      pe(t, o);
    }
  };
}
const To = 1e-5, Yt = 5e-3;
function ds(e, t, n) {
  let o, r, i, s, l, u, c, f, d, M, _, v, m, S, w, h, k, P, A = ue, B = () => (A(), A = Lt(ae, (p) => n(70, P = p)), ae), L, O, H, V = ue, W = () => (V(), V = Lt(Pe, (p) => n(73, H = p)), Pe), D, Y, Q, U, te, se, a, b, z, R, T, j, G, Z, q, K, x;
  e.$$.on_destroy.push(() => A()), e.$$.on_destroy.push(() => V());
  const ge = !0, fe = "trim";
  let { isActive: ae } = t;
  B();
  let { isActiveFraction: Pe } = t;
  W();
  let { stores: re } = t, { trimEnableSplit: g = !0 } = t, { trimEnableMediaClipper: C = !0 } = t, { trimEnableFramePreview: F = !0 } = t, { trimEnablePlayPauseButton: J = !0 } = t, { trimEnableMuteButton: X = !0 } = t, { locale: ie = {} } = t;
  const Ae = xe("measurable"), { play: at, pause: ze, togglePlayPause: Ye, toggleMute: E, isPlaying: de, isMuted: Ee, frameOffset: ve, framePeekOffset: Fe } = xe("previewcontrols");
  le(e, de, (p) => n(25, Z = p)), le(e, Ee, (p) => n(26, q = p)), le(e, ve, (p) => n(17, b = p));
  const { imagePreview: he, imageEffects: Je, imageCropRectAspectRatio: lt, imageSize: Ot, animation: dn, pixelRatio: mn, stageRectBase: gn, interfaceImages: wt, mediaDuration: hn, mediaTrim: ct, rootForegroundColor: _n, utilRect: pn, history: Mt, imageCropRect: zt, imageRotation: Co, imageFlipX: Ao, imageFlipY: Fo, allowPan: Lo, allowZoom: Bo, allowZoomControls: Io, mediaMinDuration: yn, mediaMaxDuration: bn, allowPlayPause: Do } = re;
  le(e, he, (p) => n(24, G = p)), le(e, Je, (p) => n(72, O = p)), le(e, lt, (p) => n(16, k = p)), le(e, Ot, (p) => n(23, Y = p)), le(e, dn, (p) => n(77, a = p)), le(e, mn, (p) => n(27, K = p)), le(e, gn, (p) => n(76, se = p)), le(e, wt, (p) => n(92, Q = p)), le(e, hn, (p) => n(18, R = p)), le(e, ct, (p) => n(78, z = p)), le(e, _n, (p) => n(28, x = p)), le(e, pn, (p) => n(75, te = p)), le(e, zt, (p) => n(91, D = p)), le(e, yn, (p) => n(80, j = p)), le(e, bn, (p) => n(79, T = p));
  let vt, St, Pt, Et;
  const Oo = (p) => {
    Fe.set(void 0), Et === void 0 && (Et = !G.paused), ve.set(p), ze();
  }, zo = () => {
    Et && at(), Et = void 0;
  }, wn = (p) => {
    const we = i.findIndex(([$e, He]) => p > $e && p < He), [ye, Ne] = i[we], Le = [...i], ft = [ye, p - Yt], dt = [p + Yt, Ne];
    Le.splice(we, 1, ft, dt), ct.set(Le), Mt.write();
  }, Mn = (p) => {
    const we = [...i], ye = i.findIndex(([Le]) => p < Le) - 1, Ne = i.length - [...i].reverse().findIndex(([, Le]) => p > Le);
    we.splice(ye, 2, [i[ye][0], i[Ne][1]]), ct.set(we), Mt.write();
  }, Ut = Ro(a ? 20 : 0);
  le(e, Ut, (p) => n(74, U = p));
  let ut;
  const vn = () => Q.filter((p) => p.id !== "trim"), Uo = (p, we, { transforms: ye, effects: Ne }) => {
    const Le = ut.x, ft = ut.y;
    return p.map((dt, $e) => {
      const { itemRect: He, opacity: Vt, backgroundColor: jt, data: Rt, dataSize: Tt } = dt, mt = {
        x: Le + He.x,
        y: ft + He.y,
        width: He.width,
        height: He.height
      };
      let Kt = [0, 0, 0, 0];
      $e === 0 ? Kt = [6, 0, 6, 0] : $e === p.length - 1 && (Kt = [0, 6, 0, 6]);
      const Zo = {
        x: mt.x + mt.width * 0.5,
        y: Math.floor(mt.y + mt.height * 0.5)
      }, Yo = {
        width: Y.width,
        height: Y.height
      }, Jo = {
        x: ye.origin.x,
        y: ye.origin.y
      }, Qo = {
        x: ye.translation.x,
        y: ye.translation.y
      }, xo = Math.max(Tt.width / D.width, Tt.height / D.height);
      return {
        // apply current image effects
        ...Ne,
        // no blurring / sharpening as is too gpu intensive
        convolutionMatrix: void 0,
        // frame
        id: "trim",
        backgroundColor: jt,
        data: Rt,
        offset: Zo,
        size: Yo,
        mask: mt,
        maskCornerRadius: Kt,
        opacity: Math.min(Vt, we),
        scale: xo,
        origin: Jo,
        translation: Qo,
        rotation: ye.rotation
      };
    });
  }, Sn = qr([ae, zt, Ot, Co, Ao, Fo], ([
    p,
    we,
    ye,
    Ne,
    Le,
    ft
  ], dt) => {
    if (!p || !ye)
      return L;
    const $e = mo(ye), He = ot($e), Vt = Xr(ye, we, Ne), jt = ot(Vt), Rt = fo(kn(He), jt), Tt = fr(kn(Rt));
    dt({
      origin: Tt,
      translation: Rt,
      rotation: {
        x: ft ? Math.PI : 0,
        y: Le ? Math.PI : 0,
        z: Ne
      }
    });
  });
  le(e, Sn, (p) => n(71, L = p));
  let kt;
  const Vo = () => _ ? wn(b) : Mn(b), jo = (p) => n(10, Pt = p.detail), Ko = (p) => n(11, kt = p), No = (p) => ct.set(p), Ho = (p) => Fe.set(p), Wo = () => Mt.write(), qo = (p) => n(9, St = p.detail), Xo = (p) => n(8, vt = p.detail);
  function Go(p) {
    ln.call(this, e, p);
  }
  return e.$$set = (p) => {
    "isActive" in p && B(n(0, ae = p.isActive)), "isActiveFraction" in p && W(n(1, Pe = p.isActiveFraction)), "stores" in p && n(60, re = p.stores), "trimEnableSplit" in p && n(2, g = p.trimEnableSplit), "trimEnableMediaClipper" in p && n(3, C = p.trimEnableMediaClipper), "trimEnableFramePreview" in p && n(4, F = p.trimEnableFramePreview), "trimEnablePlayPauseButton" in p && n(5, J = p.trimEnablePlayPauseButton), "trimEnableMuteButton" in p && n(6, X = p.trimEnableMuteButton), "locale" in p && n(7, ie = p.locale);
  }, e.$$.update = () => {
    if (e.$$.dirty[2] & /*$isActive*/
    256 && Lo.set(P), e.$$.dirty[2] & /*$isActive*/
    256 && Bo.set(P), e.$$.dirty[2] & /*$isActive*/
    256 && Io.set(P), e.$$.dirty[2] & /*$isActive*/
    256 && P && Do.set(!0), e.$$.dirty[0] & /*$mediaDuration*/
    262144 | e.$$.dirty[2] & /*$mediaMinDuration*/
    262144 && n(14, o = j / R), e.$$.dirty[0] & /*$mediaDuration*/
    262144 | e.$$.dirty[2] & /*$mediaMaxDuration*/
    131072 && n(15, r = Math.min(R, T) / R), e.$$.dirty[0] & /*clipMaxSize*/
    32768 | e.$$.dirty[2] & /*$mediaTrim*/
    65536 && n(13, i = z || [[0, r]]), e.$$.dirty[0] & /*clipRanges*/
    8192 && n(69, s = i.reduce((p, [we, ye]) => p + (ye - we), 0)), e.$$.dirty[0] & /*clipMinSize*/
    16384 | e.$$.dirty[2] & /*clipRangeSpaceTotal*/
    128 && n(65, l = s - o), e.$$.dirty[0] & /*clipRanges*/
    8192 && n(64, u = i[0][0]), e.$$.dirty[0] & /*clipRanges*/
    8192 && n(63, c = i[i.length - 1][1]), e.$$.dirty[0] & /*$frameOffset*/
    131072 && n(67, f = De(b, 6)), e.$$.dirty[0] & /*clipRanges*/
    8192 && n(68, d = i.map(([p, we]) => [De(p, 6), De(we, 6)])), e.$$.dirty[2] & /*clipRangesFixed, frameOffsetFixed*/
    96 && n(66, M = d.findIndex(([p, we]) => f >= p && f <= we)), e.$$.dirty[2] & /*currentClipIndex*/
    16 && n(12, _ = M > -1), e.$$.dirty[0] & /*canSplitVideo*/
    4096 && n(22, v = !_), e.$$.dirty[2] & /*clipRangeSpaceAvailableMin*/
    8 && n(21, m = l < Yt * 2), e.$$.dirty[0] & /*$frameOffset*/
    131072 | e.$$.dirty[2] & /*trimFirst, trimLast*/
    6 && n(20, S = b < u || b > c), e.$$.dirty[2] & /*$animation, $isActive*/
    33024 && a && Ut.set(P ? 0 : 20), e.$$.dirty[2] & /*$footerOffset*/
    4096 && n(19, w = U ? `transform: translateY(${U}px)` : void 0), e.$$.dirty[0] & /*footerRect, videoTrimmerContainerRect, timelineSize*/
    1792 | e.$$.dirty[2] & /*$utilRect, $stageRectBase, $footerOffset*/
    28672 && te && se && vt && St && Pt && n(61, ut = {
      x: se.x - te.x + St.x,
      y: se.y - te.y + vt.y + U + Pt.height
    }), e.$$.dirty[0] & /*framePreviews*/
    2048 | e.$$.dirty[1] & /*frameContainerOffset*/
    1073741824 | e.$$.dirty[2] & /*$isActive, $isActiveFraction, $imageEffects, $imageTransforms*/
    3840)
      if (kt && ut && P) {
        const p = [
          ...vn(),
          ...Uo(kt, H, {
            effects: O,
            transforms: L
          })
        ];
        wt.set(p);
      } else {
        const p = vn();
        wt.set(p);
      }
    e.$$.dirty[0] & /*$imageCropRectAspectRatio*/
    65536 | e.$$.dirty[2] & /*$isActive, frameCropAspectRatio*/
    257 && n(62, h = P ? k : h || void 0);
  }, [
    ae,
    Pe,
    g,
    C,
    F,
    J,
    X,
    ie,
    vt,
    St,
    Pt,
    kt,
    _,
    i,
    o,
    r,
    k,
    b,
    R,
    w,
    S,
    m,
    v,
    Y,
    G,
    Z,
    q,
    K,
    x,
    Ae,
    Ye,
    E,
    de,
    Ee,
    ve,
    Fe,
    he,
    Je,
    lt,
    Ot,
    dn,
    mn,
    gn,
    wt,
    hn,
    ct,
    _n,
    pn,
    Mt,
    zt,
    yn,
    bn,
    Oo,
    zo,
    wn,
    Mn,
    Ut,
    Sn,
    ge,
    fe,
    re,
    ut,
    h,
    c,
    u,
    l,
    M,
    f,
    d,
    s,
    P,
    L,
    O,
    H,
    U,
    te,
    se,
    a,
    z,
    T,
    j,
    Vo,
    jo,
    Ko,
    No,
    Ho,
    Wo,
    qo,
    Xo,
    Go
  ];
}
class ms extends Ce {
  constructor(t) {
    super(), Te(
      this,
      t,
      ds,
      fs,
      ke,
      {
        external: 58,
        name: 59,
        isActive: 0,
        isActiveFraction: 1,
        stores: 60,
        trimEnableSplit: 2,
        trimEnableMediaClipper: 3,
        trimEnableFramePreview: 4,
        trimEnablePlayPauseButton: 5,
        trimEnableMuteButton: 6,
        locale: 7
      },
      null,
      [-1, -1, -1, -1]
    );
  }
  get external() {
    return this.$$.ctx[58];
  }
  get name() {
    return this.$$.ctx[59];
  }
  get isActive() {
    return this.$$.ctx[0];
  }
  set isActive(t) {
    this.$$set({ isActive: t }), Re();
  }
  get isActiveFraction() {
    return this.$$.ctx[1];
  }
  set isActiveFraction(t) {
    this.$$set({ isActiveFraction: t }), Re();
  }
  get stores() {
    return this.$$.ctx[60];
  }
  set stores(t) {
    this.$$set({ stores: t }), Re();
  }
  get trimEnableSplit() {
    return this.$$.ctx[2];
  }
  set trimEnableSplit(t) {
    this.$$set({ trimEnableSplit: t }), Re();
  }
  get trimEnableMediaClipper() {
    return this.$$.ctx[3];
  }
  set trimEnableMediaClipper(t) {
    this.$$set({ trimEnableMediaClipper: t }), Re();
  }
  get trimEnableFramePreview() {
    return this.$$.ctx[4];
  }
  set trimEnableFramePreview(t) {
    this.$$set({ trimEnableFramePreview: t }), Re();
  }
  get trimEnablePlayPauseButton() {
    return this.$$.ctx[5];
  }
  set trimEnablePlayPauseButton(t) {
    this.$$set({ trimEnablePlayPauseButton: t }), Re();
  }
  get trimEnableMuteButton() {
    return this.$$.ctx[6];
  }
  set trimEnableMuteButton(t) {
    this.$$set({ trimEnableMuteButton: t }), Re();
  }
  get locale() {
    return this.$$.ctx[7];
  }
  set locale(t) {
    this.$$set({ locale: t }), Re();
  }
}
const gs = (e) => /video/.test(e.type), bs = {
  util: ["trim", ms, ({ src: e }) => e && gs(e)]
}, ws = {
  trimLabel: "Trim",
  trimIcon: '<g stroke-width=".125em" stroke="currentColor" fill="none"><path d=" M1 3 v18 M5 3 v2 M9 3 v2 M5 18 v2 M9 18 v2 M1 3 h12 M1 6 h10 M1 18 h9 M1 21 h8 M14 0 l-4 24 M18 3 h5 M17.5 6 h6 M15.5 18 h7 M15 21 h8 M19 3 v2 M15 18 v2 M19 18 v2 M23 3 v18"/></g>',
  trimLabelPlay: "Play",
  trimLabelPause: "Pause",
  trimLabelMute: "Mute",
  trimLabelUnmute: "Unmute",
  trimLabelSplit: "Split",
  trimLabelMerge: "Merge",
  trimIconButtonMute: '<g stroke-width=".125em" stroke="currentColor"><polygon fill="currentColor" points="2 16 2 8 8 8 15 1 15 23 8 16"/><path d="M19.3781212,15.2166107 C20.3621122,14.4879168 21,13.3184517 21,12 C21,10.6815483 20.3621122,9.51208318 19.3781212,8.78338927"/></g>',
  trimIconButtonUnmute: '<g stroke-width=".125em" stroke="currentColor"><polygon fill="currentColor" points="2 16 2 8 3 8 15 20 15 23 8 16"/><polygon fill="currentColor" points="8 8 15 1 15 15"/><line x1="1" y1="1" x2="23" y2="23"/></g>',
  trimIconButtonPlay: '<polygon fill="currentColor" points="7 3, 21 12, 7 21"/>',
  trimIconButtonPause: '<g fill="currentColor"><rect x="6" y="4" width="4" height="16"/><rect x="14" y="4" width="4" height="16"/></g>',
  trimIconButtonSplit: `<g stroke="currentColor" stroke-width=".125em">
    <path d="M12 4 V20"/>
    <path fill="currentColor" d="M6 8 L6 16 L2 12 Z M18 8 L22 12 L18 16 Z"/>
    </g>`,
  trimIconButtonMerge: `<g stroke="currentColor" stroke-width=".125em">
    <path d="M1 4 V20 M23 4 V20"/>
    <path fill="currentColor" d="M6 8 L10 12 L6 16 Z M18 8 L14 12 L18 16 Z"/>
    </g>`,
  // overrides, replace image with media
  statusLabelLoadImage: (e) => !e || !e.task ? "Waiting for media" : e.error ? e.error.code === "IMAGE_TOO_SMALL" ? "Minimum media size is {minWidth} &times; {minHeight}" : e.error.code === "VIDEO_TOO_SHORT" ? `Minimum video duration is {minDuration} ${e.error.metadata.minDuration === 1 ? "second" : "seconds"}` : "Error loading media" : e.task === "blob-to-bitmap" ? "Preparing media&hellip;" : "Loading media&hellip;",
  statusLabelProcessImage: (e) => {
    if (!(!e || !e.task))
      return e.task === "store" ? e.error ? "Error uploading media" : "Uploading media&hellip;" : e.error ? "Error processing media" : "Processing media&hellip;";
  },
  cropLabelCropBoundaryEdge: "Edge of media"
};
export {
  hs as createDefaultVideoWriter,
  ps as createFFmpegEncoder,
  _s as createMediaStreamEncoder,
  ys as createMuxerEncoder,
  bs as plugin_trim,
  ws as plugin_trim_locale_en_gb
};
