<template>
  <div class="error-container">
    <h1 class="error-heading">Error</h1>
    <p class="error-message">{{ errorMessage }}</p>
    <a class="error-link" @click="toggleStackTrace">Show Stack Trace</a>
    <div v-if="showStackTrace" class="stack-trace">
      <pre>{{ errorStack }}</pre>
    </div>
  </div>
</template>

<style>
.error-container {
  text-align: center;
  margin: 20px auto 60px; /* Adjust the top margin for top alignment */
  padding: 60px; /* Padding remains 60px as before */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  max-width: 1200px;
}

.error-heading {
  font-size: 24px;
  font-weight: bold;
  color: #ff0000; /* Change the color as desired */
  margin-bottom: 10px;
}

.error-message {
  font-size: 16px;
  color: #333; /* Change the color as desired */
  margin-bottom: 20px;
}

.error-link {
  display: inline-block;
  margin-top: 10px;
  color: #007bff; /* Link color */
  text-decoration: underline; /* Underline the link */
  cursor: pointer;
}

.error-link:hover {
  color: #0056b3; /* Change the color on hover as desired */
  text-decoration: underline;
}

.stack-trace {
  margin-top: 10px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: left;
  max-height: 200px;
  overflow-y: scroll;
}
</style>









<script>
export default {
  props: {
    error: String, // The error message
    stackTrace: String, // The stack trace
  },
  data() {
    return {
      showStackTrace: false,
    };
  },
  mounted() {
    console.log('Component mounted');
    // Add more logs as needed
  },
  computed: {
    errorMessage() {
      // Parse the error message for user-friendly display
      return this.error; // You may want to format it as needed
    },
    errorStack() {
      // Format the stack trace for display
      return this.stackTrace;
    },
  },
  methods: {
    toggleStackTrace() {
      // Toggle the display of the stack trace
      this.showStackTrace = !this.showStackTrace;
    },
  },
};
</script>
  