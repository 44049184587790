<template>
  <v-dialog v-model="captionDialog" max-width="800px" scrollable persistent>
    <template v-slot:activator="{ on }">
      <v-list-item
        v-on="on"
        class="w-[293px] h-12 bg-slate-100 rounded-3xl mt-2"
      >
        <img
          src="@/assets/img/postidea.svg"
          :width="isSmallAndUp ? '12px' : '10px'"
          :height="isSmallAndUp ? '12px' : '10px'"
          max-height="50%"
          class="mr-2"
        />
        Generate post ideas using AI
      </v-list-item>
    </template>
    <form-wrapper ref="form" @submit="submit()">
      <v-card class="pa-2" v-if="currentUser.fly_ai_text_credits > 0">
        <v-card-title class="justify-between pt-3 pb-3 primary--text d-flex">
          <div
            class="items-center gap-2 d-flex"
            :class="!isSmallAndUp ? 'w-[77%]' : 'w-[80%]'"
          >
            <v-btn icon depressed v-if="steps == '2'" @click="steps = 1"
              ><v-icon>mdi-keyboard-backspace</v-icon></v-btn
            >
            <img
              src="../../assets/img/postidea.svg"
              :width="isSmallAndUp ? '30px' : '25px'"
              :height="isSmallAndUp ? '30px' : '25px'"
              max-height="100%"
              class="w-[22px] h-[18.06px] relative"
            />
            <span
              :class="!isSmallAndUp ? 'text-base' : ''"
              class="black--text"
              >{{ title }}</span
            >
          </div>
          <!-- <v-spacer /> -->
          <v-btn
            icon
            color="black"
            @click="handleDialogClose()"
            :disabled="loading"
            class="w-[15%]"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-stepper v-model="steps" flat>
            <v-stepper-items>
              <v-stepper-content class="pt-2" step="1">
                <v-row>
                  <v-col cols="12">
                    <ai-textarea
                      label="Enter prompt to generate post. Ex: Create a post celebrating Christmas Day"
                      rules="maxcharspostideainput"
                      v-model="captionForm.text"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <v-select
                      label="Select Tone"
                      :items="tones"
                      class="mt-3 w-[293px] h-12 bg-slate-100 rounded-3xl px-3 pt-3"
                      :rules="rules"
                      color="#8056DE"
                      dense
                      item-text="name"
                      v-model="captionForm.tone"
                      item-value="name"
                    >
                      <template v-slot:item="{ item, on }">
                        <v-list-item v-on="on">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.description
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <v-select
                      label="Select Language (Optional)"
                      :items="languages"
                      class="mt-3 w-[293px] h-12 bg-slate-100 rounded-3xl px-3 pt-3"
                      color="#8056DE"
                      dense
                      item-text="englishName"
                      v-model="captionForm.language"
                      item-value="englishName"
                    >
                      <template v-slot:item="{ item, on }">
                        <v-list-item v-on="on">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.englishName
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.localName
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6" class="pb-0">
                    <ai-input
                      label="Max Chars"
                      v-model="captionForm.maxChars"
                      type="number"
                      rules="maxcharspostideaoutput"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <ai-input
                      label="Max Suggestions"
                      v-model="captionForm.maxSuggestions"
                      type="number"
                      rules="maxsuggestions"
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="">
                    <v-checkbox
                      v-model="captionForm.withEmoji"
                      class="mt-6 w-[170px] h-12 bg-slate-100 rounded-3xl px-2 pt-3"
                      label="With Hashtags"
                    />
                  </v-col>
                  <!-- <v-col cols="6" md="4" class="pb-0">
                    <v-checkbox
                      :disabled="true"
                      v-model="captionForm.withImage"
                      class="mt-1"
                      label="With Image"
                    />
                  </v-col> -->
                  <v-col cols="12" md="4" class="">
                    <v-checkbox
                      v-model="captionForm.withHashtags"
                      class="mt-6 w-[170px] h-12 bg-slate-100 rounded-3xl px-2 pt-3"
                      label="With Emoji"
                    />
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content class="pt-2" step="2">
                <v-row class="justify-center mx-auto d-flex">
                  <template v-for="(item, i) in openaiPostContent">
                    <v-col cols="12" :key="i">
                      <div
                        class="flex-col w-full gap-4 p-4 mb-2 border-2 rounded-lg d-flex"
                      >
                        <span class="black--text">
                          {{ item }}
                        </span>
                        <div
                          class="flex-col justify-between w-full gap-2 mt-1 d-flex sm:flex-row"
                        >
                          <v-btn
                            @click="copyLink(item)"
                            height="30px"
                            outlined
                            color="primary"
                          >
                            Copy
                          </v-btn>
                          <v-btn
                            @click="selectPostContent(item)"
                            outlined
                            color="primary"
                            height="30px"
                            class="w-full max-w-full sm:w-fit"
                          >
                            Add to editor
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </template>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions class="pt-4 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            text
            v-show="steps == '1'"
            class="w-[139.30px] mt-3 py-5 h-12 bg-violet-500 rounded-xl"
            color="white"
            type="submit"
            >Generate</v-btn
          >
          <v-btn text color="primary" v-show="steps == '2'" @click="steps = 1"
            >Back</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="pa-2" v-else>
        <v-card-title>
          You have run out of Fly AI Writer credits. Please renew or upgrade
          to<br />
          proceed.
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="upgradeAndCloseDialog()"
            >Upgrade</v-btn
          >
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </form-wrapper>
  </v-dialog>
</template>

<script>
import rulesConstants from "@/assets/constants/rules.constants";
import DialogCard from "../ui/DialogCard.vue";
import InputField from "../ui/form/InputField.vue";
import openaiMixin from "@/mixins/openai.mixin";
import FormWrapper from "../ui/form/FormWrapper.vue";
import Textarea from "../ui/form/Textarea.vue";
import AiTextarea from "../ui/form/AiTextarea.vue";
import AiInput from "../ui/form/AiInput.vue";
export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { Textarea, InputField, FormWrapper, AiTextarea, AiInput },
  data() {
    return {
      openaiCaptions: [],
      openaiPostContent: [],
      steps: 1,
      loading: false,
      isCaptionLoading: false,
      captionDialog: false,
      rules: [rulesConstants.required],
      captionForm: {
        tone: "",
        text: "",
        maxChars: 140,
        maxSuggestions: 2,
        withHashtags: false,
        withImage: false,
        withEmoji: false,
        language: "",
      },
    };
  },
  mixins: [openaiMixin],
  computed: {
    title() {
      let title = "";
      if (this.steps == 1) {
        title = "Generate post ideas using AI";
      } else {
        title = "AI generated post ideas";
      }
      return title;
    },
  },
  methods: {
    upgradeAndCloseDialog() {
      this.dialog = false; // Close the current dialog
      // Navigate to the subscription page
      this.$router.push("/subscription");
    },
    closeDialog() {
      this.captionDialog = false;
    },
    copyLink(item) {
      navigator.clipboard.writeText(item);
      this.$snackbar.show("Caption Copied.");
    },
    generateCap() {
      this.steps = 2;
    },
    selectPostContent(item) {
      this.captionForm = {
        tone: "",
        text: "",
        maxChars: 140,
        maxSuggestions: 2,
        withHashtags: false,
        withImage: false,
        withEmoji: false,

        language: "",
      };
      this.steps = 1;
      this.openaiPostContent = [];
      this.captionDialog = false;
      this.$emit("insertPostIdea", item);
    },
    handleDialogClose() {
      this.captionDialog = false;
      this.steps = 1;
      this.captionForm = {
        tone: "",
        text: "",
        maxChars: 140,
        maxSuggestions: 2,
        withHashtags: false,
        withImage: false,
        withEmoji: false,
        language: "",
      };
      this.openaiPostContent = [];
    },
    submit() {
      this.$mixpanel.track("Shorthand3 Done")
      this.generatePostContent();
    },
    trackMixpanel() {
      this.$mixpanel.track("Shorthand3 Initiated");
    }
  },
  mounted() {
    // Call the trackMixpanel method when the component is mounted
    this.trackMixpanel();
  }
};
</script>

<style></style>
