<template>
  <div class="b" style="font-family: roboto">
    <nav
      class="fixed top-0 z-[99] text-blue-500 text-color text-color w-full shadow-md bg-white"
    >
      <div class="items-center justify-between hidden mx-auto lg:w-3/4 lg:flex">
        <div class="">
          <router-link to="/">
            <img
              src="../../../assets/img/fly-new-logo_transparent.png"
              width="100"
              height="50"
              style="object-fit: contain"
            />
          </router-link>
        </div>
        <div>
          <a
            href="#approved-platforms"
            @click="openRoute('/')"
            class="text-lg cursor-pointer"
            >Platforms</a
          >
        </div>
        <div>
          <a href="#fly-ai" class="text-lg cursor-pointer">Fly AI</a>
        </div>

        <div>
          <router-link to="/subscription" class="text-lg cursor-pointer"
            >Pricing</router-link
          >
        </div>
        <div>
          <router-link
            to="/dashboard"
            class="text-lg cursor-pointer"
            v-if="isLoggedIn"
            >Dashboard</router-link
          >
          <router-link
            to="/login"
            class="text-lg cursor-pointer"
            v-if="!isLoggedIn"
            >Login</router-link
          >
        </div>
      </div>
      <div class="flex items-center justify-between w-full lg:hidden">
        <router-link to="/">
          <img
            src="../../../assets/img/fly-new-logo_transparent.png"
            width="100"
            height="50"
            style="object-fit: contain"
          />
        </router-link>
        <div class="pr-4 md:pr-6">
          <img
            src="../../../assets/img/menu.svg"
            @click="showHeader()"
            class="w-10 p-2 bg-white rounded cursor-pointer"
          />
        </div>
      </div>
      <div
        v-if="showMobileView"
        class="flex flex-col items-center mx-auto lg:hidden"
      >
        <div class="mb-2">
          <a
            href="#approved-platforms"
            @click="openRoute('/')"
            class="text-lg cursor-pointer"
            >Platforms</a
          >
        </div>
        <div class="mb-2">
          <a href="#fly-ai" class="text-lg cursor-pointer">Fly AI</a>
        </div>

        <div class="mb-2">
          <router-link to="/subscription" class="text-lg cursor-pointer"
            >Pricing</router-link
          >
        </div>
        <div class="pb-2">
          <router-link
            to="/dashboard"
            class="text-lg cursor-pointer"
            v-if="isLoggedIn"
            >Dashboard</router-link
          >
          <router-link
            to="/login"
            class="text-lg cursor-pointer"
            v-if="!isLoggedIn"
            >Login</router-link
          >
        </div>
      </div>
    </nav>

    <section class="pt-[72px] bg-[#EFF6FF]">
      <div class="flex flex-col items-center justify-center">
        <h2
          class="pb-2 mb-4 text-[44px] w-full mt-4 font-bold text-transparent text-center bg-clip-text bg-gradient-to-r leading-[57.20px] from-blue-800 to-pink-500"
        >
          Getting Started with Fly
        </h2>
        <div
          class="grid grid-cols-1 gap-4 px-4 lg:gap-10 md:px-0 md:grid-cols-2"
        >
          <div v-for="(intro, index) in introVideo" :key="index" class="">
            <div
              class="md:mt-[10px] lg:w-[400px] bg-white xl:w-[590px] w-full rounded-[8px] border lg:rounded-[20px] overflow-hidden"
            >
              <div
                class="flex shadow-xl plyr__video-embed"
                :ref="`player1-${index}`"
              >
                <iframe
                  class=""
                  :src="intro.youtube_link"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="p-4">
                <h3 class="text-xl font-bold">{{ intro.title }}</h3>
                <p class="mt-2 text-base">
                  {{ intro.description }}
                </p>
              </div>
            </div>
            <!-- <div
            class="md:mt-[10px] bg-white lg:w-[400px] xl:w-[590px] w-full rounded-[8px] border lg:rounded-[20px] overflow-hidden"
          >
            <div class="flex shadow-xl plyr__video-embed" ref="player2">
              <iframe
                class=""
                src="https://www.youtube.com/embed/35jWWEwHQKI?si=bUUwBAbOnc_K5irE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div class="p-4">
              <h3 class="text-xl font-bold">
                Official Intro Video - Fly Social
              </h3>
              <p class="mt-2 text-base">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore
                temporibus nemo accusantium eum. Quis adipisci quisquam
                molestiae nisi pariatur? Expedita dolorum nihil mollitia dicta,
                illum dignissimos a? Reprehenderit, cum nulla!
              </p>
            </div>
          </div> -->
          </div>
        </div>
      </div>
    </section>

    <section class="pt-[72px] bg-[#EFF6FF]">
      <div class="flex flex-col items-center justify-center">
        <h2
          class="pb-2 mb-4 text-[44px] w-full mt-4 font-bold text-transparent text-center bg-clip-text bg-gradient-to-r leading-[57.20px] from-blue-800 to-pink-500"
        >
          How To Connect Your Social Media Accounts
        </h2>

        <div
          class="grid px-4 md:px-0 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 mx-auto mt-5 md:mt-[72px] xl:w-[1100px] md:w-full"
        >
          <div
            v-for="(help, index) in helpVideos"
            :key="index"
            class="bg-white rounded-[8px] border lg:rounded-[20px] shadow overflow-hidden"
          >
            <div :ref="`player-${index}`">
              <iframe
                :src="help.youtube_link"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                class="w-full rounded-md"
              ></iframe>
            </div>
            <div class="p-4">
              <h3 class="text-xl font-bold">{{ help.title }}</h3>
              <p class="mt-2 text-base">{{ help.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="fly-ai" class="md:pt-20 bg-[#EFF6FF]">
      <div class="container md:mx-auto">
        <div
          class="text-3xl font-bold capitalize text-start md:text-center md:pt-0 md:pb-12 md:text-5xl"
        >
          <h1 class="flex flex-col items-center justify-center">
            <span
              class="text-transparent text-center bg-clip-text bg-gradient-to-r leading-[57.20px] from-blue-800 to-pink-500"
            >
              How to use Fly AI feature
            </span>
          </h1>
        </div>
        <div
          class="grid px-4 md:px-0 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 mx-auto mt-5 md:mt-[72px] xl:w-[1100px] md:w-full"
        >
          <div
            v-for="(feature, index) in aiFeatures"
            :key="index"
            class="bg-white rounded-[8px] border lg:rounded-[20px] shadow overflow-hidden"
          >
            <div :ref="`player2-${index}`">
              <iframe
                :src="feature.youtube_link"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                class="w-full rounded-md"
              ></iframe>
            </div>
            <div class="p-4">
              <h3 class="text-xl font-bold">{{ feature.title }}</h3>
              <p class="mt-2 text-base">{{ feature.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="fly-ai" class="md:pt-20 bg-[#EFF6FF]">
      <div class="container md:mx-auto">
        <div
          class="text-3xl font-bold capitalize text-start md:text-center md:pt-0 md:pb-12 md:text-5xl"
        >
          <h1 class="flex flex-col items-center justify-center">
            <span
              class="text-transparent text-center bg-clip-text bg-gradient-to-r leading-[57.20px] from-blue-800 to-pink-500"
            >
              FlyAI Writer
            </span>
          </h1>
        </div>
        <div
          class="grid px-4 md:px-0 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 mx-auto mt-5 md:mt-[72px] xl:w-[1100px] md:w-full"
        >
          <div
            v-for="(feature, index) in aiWriter"
            :key="index"
            class="bg-white rounded-[8px] border lg:rounded-[20px] shadow overflow-hidden"
          >
            <div :ref="`player2-${index}`">
              <iframe
                :src="feature.youtube_link"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                class="w-full rounded-md"
              ></iframe>
            </div>
            <div class="p-4">
              <h3 class="text-xl font-bold">{{ feature.title }}</h3>
              <p class="mt-2 text-base">{{ feature.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="bg-[#080415]">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="px-4 pt-16 pb-[70px] xl:pb-8 mx-auto max-w-7xl lg:px-8">
        <div class="flex flex-wrap gap-8 xl:gap-[91px]">
          <div class="">
            <div class="flex flex-col items-start gap-">
              <router-link to="/">
                <img
                  src="../../../assets/img/logo-transparent.svg"
                  width="40"
                  height="50"
                />
              </router-link>
              <p class="text-white text-sm md:text-[22px] mt-2 mb-2">
                Your Social, Simplified!
              </p>
            </div>
            <div class="text-sm leading-6 text-gray-300">
              ZoomLocal Tech Private Limited <br />
              Pune, India
            </div>
            <div class="flex items-center gap-3 py-2 w-[130px] 2xl:w-[160px]">
              <a
                target="_blank"
                href=" https://www.facebook.com/flysocial.in/"
                class="p-[1px] border border-white rounded-full"
              >
                <img src="../../../assets/img/home-page/facebook.svg" />
              </a>
              <a
                target="_blank"
                href=" https://twitter.com/TheFlySocial"
                class="p-[1px] border border-white rounded-full"
              >
                <img src="../../../assets/img/home-page/twitter.svg" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/theflysocial/"
                class="p-[1px] border border-white rounded-full"
              >
                <img src="../../../assets/img/home-page/insta.svg" />
              </a>

              <a
                target="_blank"
                href="https://www.linkedin.com/company/zoom-local-pvt"
                class="p-[1px] border border-white rounded-full"
              >
                <img src="../../../assets/img/home-page/linkedin.svg" />
              </a>

              <a
                target="_blank"
                href="https://www.youtube.com/@TheFlySocial"
                class="p-[1px] border border-white rounded-full"
              >
                <img src="../../../assets/img/home-page/you-tube.svg" />
              </a>
            </div>
          </div>
          <div class="">
            <div
              class="text-base font-black leading-7 text-white md:text-lg 2xl:text-xl"
            >
              Company
            </div>
            <div
              class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
            >
              <router-link to="/about-us">About</router-link>
            </div>
            <div
              class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
            >
              <router-link to="/terms-of-use"> Terms & Conditions</router-link>
            </div>
            <div
              class="text-sm font-normal sm:w-[150px] leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
            >
              <router-link to="/privacy-policy">Privacy Policy</router-link>
            </div>
            <div
              class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
            >
              <router-link to="/cookie-policy">Cookie policy</router-link>
            </div>
          </div>

          <div class="">
            <div
              class="text-base font-black leading-7 text-white footer-text md:text-lg 2xl:text-xl:"
            >
              Contact
            </div>
            <div
              class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
            >
              <router-link to="/resources">Help</router-link>
            </div>

            <div
              class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
            >
              <span>
                <a href="mailto:fly@zoomlocal.in">fly@zoomlocal.in</a></span
              >
            </div>
            <div
              class="text-sm font-normal leading-tight text-white footer-text 2xl:mt-[24px] md:mt-[16px] mt-[12px]"
            >
              <span> <a href="tel:+919881474184">+919881474184</a></span>
            </div>
          </div>
        </div>
        <div
          class="text-white pt-[6px] text-center mt-4 md:mt-[120px] text-opacity-60 text-[10px] md:text-xs xl:text-sm font-normal leading-tight"
        >
          © Copyright 2024 ZoomLocal Tech Private Limited. All rights reserved.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "@/utils/firebase.utils";
import {
  collection,
  doc,
  docs,
  getDoc,
  getDocs,
  getDocsFromCache,
  orderBy,
  query,
} from "firebase/firestore";
import { functions } from "@/utils/firebase.utils";

import "plyr/dist/plyr.css"; // Import the Plyr CSS
import Plyr from "plyr"; // Import the Plyr JS
export default {
  data() {
    return {
      showMobileView: false,
      showList: false,
      items: [
        { name: "Facebook", href: "/help/facebook" },
        { name: "Instagram", href: "/help/instagram" },
        { name: "Twitter", href: "/help/twitter" },
        { name: "Youtube", href: "/help/youtube" },
      ],

      aiFeatures: [],
      helpVideos: [],
      introVideo: [],
      aiWriter: [],
      isLoggedIn: false,
      plyrInstances: [],
    };
  },
  watch: {
    aiFeatures() {
      this.initializePlyrInstances(this.aiFeatures, "player2");
    },

    helpVideos() {
      this.initializePlyrInstances(this.helpVideos, "player");
    },

    introVideo() {
      this.initializePlyrInstances(this.introVideo, "player1");
    },
    aiWriter() {
      this.initializePlyrInstances(this.aiWriter, "player3");
    },
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });

    const player2 = new Plyr(this.$refs.player2, {
      controls: [
        "play",
        "play-large",
        "progress",
        "fullscreen",
        "mute",
        "volume",
      ],
      // autoplay: true,
    });

    this.getHelpVideos();
  },

  methods: {
    async fetchCollectionData(collectionRef) {
      try {
        const querySnapshot = await getDocs(collectionRef);
        return querySnapshot.docs.map((doc) => doc.data());
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    },
    async getHelpVideos() {
      const videoCollection = collection(
        db,
        "fly_resources",
        "resources",
        "fly_aI_feature"
      );
      const videoCollection2 = collection(
        db,
        "fly_resources",
        "resources",
        "how_to_connect_your_social_media"
      );
      const videoCollection3 = collection(
        db,
        "fly_resources",
        "resources",
        "started_with_fly"
      );
      const videoCollection4 = collection(
        db,
        "fly_resources",
        "resources",
        "fly_ai_writer"
      );

      const [aiFeatures, helpVideos, introVideo, aiWriter] = await Promise.all([
        this.fetchCollectionData(videoCollection),
        this.fetchCollectionData(videoCollection2),
        this.fetchCollectionData(videoCollection3),
        this.fetchCollectionData(videoCollection4),
      ]);

      this.aiFeatures = aiFeatures;
      this.helpVideos = helpVideos;
      this.introVideo = introVideo;
      this.aiWriter = aiWriter;
    },
    initializePlyrInstances(videos, refPrefix) {
      this.$nextTick(() => {
        videos.forEach((video, index) => {
          const element = this.$refs[`${refPrefix}-${index}`];
          if (element) {
            this.plyrInstances.push(
              new Plyr(element, {
                controls: [
                  "play",
                  "play-large",
                  "progress",
                  "fullscreen",
                  "mute",
                  "volume",
                ],
              })
            );
          }
        });
      });
    },
    showHeader() {
      this.showMobileView = !this.showMobileView;
    },
    openInstructions() {
      this.showList = !this.showList;
    },
    openRoute(route) {
      this.$router.push(route);
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap");
.footer-text a {
  text-decoration: none;
  color: white !important;
}
</style>
