<template>
    <div class="thanks-container">
      <h1 class=" font-bold text-5xl text-[#8050DE]">Thank you for signing up.</h1>
      <h1 class=" font-bold text-5xl text-[#8050DE] mt-8 lg:mt-4">We are ready to Fly!  <img
        loading="lazy"
        src="@/assets/img/paperplane1.gif"
        alt="Thank you animation"
        class="w-52 ml-24 mt-2  "
      /></h1>

      <img
        loading="lazy"
        src="@/assets/img/thanks.vue.gif"
        alt="Thank you animation"
        class="overlay-image"
      />
      <img
        loading="lazy"
        src="@/assets/img/thanks.vue.gif"
        alt="Thank you animation"
        class="overlay-image"
      />
      <img
        loading="lazy"
        src="@/assets/img/thanks.vue.gif"
        alt="Thank you animation"
        class="overlay-image"
      />
      <button @click="goToDashboard" class="go-dashboard -ml-4 ">Go to Dashboard</button>

    </div>
  </template>

  
<script>

export default {
    name: 'Thanks-you',
    methods: {
    goToDashboard() {
      this.$router.push('/dashboard');
    }
  }
}
  </script>
  
  <style scoped>
  html, body {
    margin: 0;
    padding: 0;
    height: 100vh; /* Ensure the HTML and body take up the full viewport height */
    overflow: hidden; /* Prevent scrolling */
  }
  
  .thanks-container {
    position: relative; /* Needed for absolute positioning of the image */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    text-align: center;
    background-color: white;
  }
  
  h1 {
    
    color: #333;
    z-index: 1; /* Ensure text is above the image */
  }
  
  .overlay-image {
    position: absolute;
    max-width: 100%; /* Limit image size */
    height: auto;
    opacity: 0.8; /* Optional: make the image slightly transparent to see the text underneath */
    z-index: 0; /* Image goes behind the text */
  }
  .go-dashboard {
  position: absolute;
  bottom: 50px; /* Distance from the bottom */
  padding: 10px 20px;
  font-size: 20px;
  background-color: #8050DE;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
  </style>
  