<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px" scrollable>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :icon="btnText ? false : true"
          :outlined="btnText ? true : false"
          color="primary"
          class="target-element-5"
        >
          <img
            src="@/assets/img/translate.svg"
            alt="translate"
            class="w-10 h-6"
          />
          <span v-if="btnText">{{ btnText }}</span>
        </v-btn>
      </template>
      <form-wrapper
        ref="form"
        @submit="submit()"
        v-if="currentUser.fly_ai_text_credits > 0"
      >
        <v-card class="pa-2" v-if="currentUser.fly_ai_text_credits > 0">
          <v-card-title class="primary--text pb-3 pt-3 d-flex justify-between">
            <div
              class="d-flex gap-2 items-center"
              :class="!isSmallAndUp ? 'w-[77%]' : 'w-[80%]'"
            >
              <v-btn icon depressed v-if="steps == '2'" @click="steps = 1"
                ><v-icon>mdi-keyboard-backspace</v-icon></v-btn
              >
              <img
                src="../../assets/img/Ai .svg"
                :width="isSmallAndUp ? '30px' : '25px'"
                :height="isSmallAndUp ? '30px' : '25px'"
                max-height="100%"
              />
              <span :class="!isSmallAndUp ? 'text-base' : ''" class="black--text">{{
                title
              }}</span>
            </div>
            <!-- <v-spacer /> -->
            <v-btn
              icon
              color="black"
              @click="handleDialogClose()"
              :disabled="loading"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-stepper v-model="steps" flat>
              <v-stepper-items>
                <v-stepper-content class="pt-2" step="1">
                  <v-select
                    label="Select Language"
                    :items="languages"
                    class="mt-3 w-[293px] h-12 bg-slate-100 rounded-3xl px-3 pt-3"
                    :rules="rules"
                    color="#8056DE"
                    dense
                    item-text="englishName"
                    v-model="language"
                    item-value="englishName"
                  >
                    <template v-slot:item="{ item, on }">
                      <v-list-item v-on="on">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.englishName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.localName
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-stepper-content>
                <v-stepper-content class="pt-2" step="2">
                  <template v-if="translatedText">
                    <v-row class="mx-auto d-flex justify-center">
                      <v-col cols="12">
                        <div
                          class="d-flex flex-col mb-2 rounded-md w-full border p-4 gap-4"
                        >
                          <span class="black--text">
                            {{ translatedText }}
                          </span>
                          <div
                            class="d-flex flex-row justify-between w-full flex-wrap gap-2"
                          >
                            <v-btn
                              @click="copyLink(translatedText)"
                              height="30px"
                              outlined
                              color="primary"
                            >
                              Copy
                            </v-btn>
                            <v-btn
                              @click="selectText(translatedText)"
                              outlined
                              color="primary"
                              height="30px"
                            >
                              Add to editor
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>

          <v-card-actions class="mr-2 pt-4">
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              text
              v-if="steps == '1'"
              class="w-[139.30px] mt-3 py-5 h-12 bg-violet-500 rounded-xl"
            color="white"
              type="submit"
              >Generate</v-btn
            >
            <v-btn text color="primary" v-show="steps == '2'" @click="steps = 1"
              >Back</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card class="pa-2" v-else>
          <v-card-title>
            You have run out of Fly AI Writer credits. Please renew or upgrade
            to<br />proceed.
          </v-card-title>
          <v-card-actions>
            <v-btn color="primary" @click="upgradeAndCloseDialog()"
              >Upgrade</v-btn
            >
            <v-btn text @click="closeDialog">Cancel</v-btn>

          </v-card-actions>
        </v-card>
      </form-wrapper>

      <v-card class="pa-2" v-else>
        <v-card-title>
          You have run out of Fly AI Writer credits. Please <br />
          renew or upgrade to proceed.
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="upgradeAndCloseDialog()"
            >Upgrade</v-btn
          >
          <v-btn text @click="closeDialog">Cancel</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import rulesConstants from "@/assets/constants/rules.constants";
import openaiMixin from "@/mixins/openai.mixin";
import FormWrapper from "../ui/form/FormWrapper.vue";

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    btnText: {
      type: String,
      default: "",
    },
    platformEditors: Object,
    activeItem: String,
  },
  components: { FormWrapper },
  data() {
    return {
      dialog: false,
      loading: false,
      rules: [rulesConstants.required],
      language: "",
      translatedText: "",
      showOverlay: false,
      steps: 1,
    };
  },
  mixins: [openaiMixin],
  computed: {
    title() {
      let title = "";
      if (this.steps == 1) {
        title = "Translate your post";
      } else {
        title = "AI generated translated post";
      }
      return title;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false; 
    },
    copyLink(item) {
      navigator.clipboard.writeText(item);
      this.$snackbar.show("Content Copied.");
    },
    selectText() {
      this.language = "";
      this.dialog = false;
      this.steps = 1;
      this.$emit("updateText", this.translatedText);
      this.translatedText = "";
    },
    handleDialogClose() {
      this.dialog = false;
      this.steps = 1;
      this.language = "";
      this.translatedText = "";
    },
    submit() {
      this.translate();
    },
    handleOverlayClick() {
      this.showOverlay = false;
    },
    upgradeAndCloseDialog() {
      this.$router.push("/subscription");
    },
  },
};
</script>

<style></style>
