<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot> </slot>
      </span>
    </template>
    <span>{{ message }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    message: {},
  },
};
</script>

<style></style>
