<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable persistent>
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on" class="w-[293px] h-12 bg-slate-100 rounded-3xl mt-2" > <img
              src="@/assets/img/postart.svg"
              :width="isSmallAndUp ? '12px' : '10px'"
              :height="isSmallAndUp ? '12px' : '10px'"
              max-height="50%"
              class="mr-2"
            />Generate post for an article / text</v-list-item>
    </template>
    <form-wrapper ref="form" @submit="submit()">
      <v-card class="pa-2"       v-if="currentUser.fly_ai_text_credits > 0">
        <v-card-title class="primary--text pb-3 pt-3 d-flex justify-between">
          <div class="d-flex gap-2 items-center" :class="!isSmallAndUp ? 'w-[77%]' : 'w-[80%]'">
            <v-btn icon depressed v-if="steps == '2'" @click="steps = 1"
              ><v-icon>mdi-keyboard-backspace</v-icon></v-btn
            >
            <img
              src="../../assets/img/postart.svg"
              :width="isSmallAndUp ? '30px' : '25px'"
              :height="isSmallAndUp ? '30px' : '25px'"
              max-height="100%"
              class="w-4 h-[21.33px] relative"
            />
            <span class="black--text " :class="!isSmallAndUp ? 'text-base' : ''" >{{
              title
            }}</span>
          </div>
          <!-- <v-spacer /> -->
          <v-btn
            icon
            color="black"
            @click="handleDialogClose()"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-stepper v-model="steps" flat>
            <v-stepper-items>
              <v-stepper-content class="pt-2" step="1">
                <ai-textarea
                  label="Text"
                  rules="maxcharsposttext"
                  v-model="form.textContent"
                />
                <v-row>
                  <v-col cols="12" md="6" class="pb-0">
                    <v-select
                      label="Select Tone"
                      :items="tones"
                      class="mt-3 w-[293px] h-12 bg-slate-100 rounded-3xl px-3 pt-3"
                      :rules="rules"
                      color="#8056DE"
                      dense
                      item-text="name"
                      v-model="form.tone"
                      item-value="name"
                    >
                      <template v-slot:item="{ item, on }">
                        <v-list-item v-on="on">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.description
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <v-select
                      label="Select Language (Optional)"
                      :items="languages"
                      class="mt-3 w-[293px] h-12 bg-slate-100 rounded-3xl px-3 pt-3"
                      dense
                      item-text="englishName"
                      v-model="form.language"
                      item-value="englishName"
                      color="#8056DE"
                    >
                      <template v-slot:item="{ item, on }">
                        <v-list-item v-on="on">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.englishName
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.localName
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="">
                    <ai-input
                      label="Max Chars"
                      v-model="form.maxChars"
                      type="number"
                      rules="maxcharspost"
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="">
                    <ai-input
                      label="Max Suggestions"
                      v-model="form.maxSuggestions"
                      type="number"
                      rules="maxsuggestions"
                    />
                  </v-col>
                  <v-col cols="12"  class="" >
                    <v-checkbox
                      v-model="form.withEmoji"
                      class="mt-6 w-[170px] h-12 bg-slate-100 rounded-3xl px-2 pt-3 custom-checkbox"  
                      label="With Emoji"
                    />
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content class="pt-2" step="2">
                <template v-if="openaiContents.length">
                  <v-row class="mx-auto d-flex justify-center">
                    <template v-for="(item, i) in openaiContents">
                      <v-col cols="12" :key="i">
                        <div
                          class="d-flex flex-col mb-2 rounded-md w-full border p-4 gap-4"
                        >
                          <span class="black--text">
                            {{ item }}
                          </span>
                          <div
                            class="d-flex flex-row justify-between w-full flex-wrap gap-2"
                          >
                            <v-btn
                              @click="copyLink(item)"
                              height="30px"
                              outlined
                              color="primary"
                            >
                              Copy
                            </v-btn>
                            <v-btn
                              @click="selectCaption(item)"
                              outlined
                              color="primary"
                              height="30px"
                            >
                              Add to editor
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </template>
                  </v-row>
                </template>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>

        <v-card-actions class="mr-2 pt-4">
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            text
            v-if="steps == '1'"
            class="w-[139.30px] mt-3 py-5 h-12 bg-violet-500 rounded-xl"
            color="white"
            type="submit"
            >Generate</v-btn
          >
          <v-btn text color="primary" v-show="steps == '2'" @click="steps = 1"
            >Back</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="pa-2" v-else>
    <v-card-title>
      You have run out of Fly AI Writer credits. Please renew or upgrade to<br/>proceed.
    </v-card-title>
    <v-card-actions>
      <v-btn color="primary" @click="upgradeAndCloseDialog()">Upgrade</v-btn>
      <v-btn text @click="closeDialog">Cancel</v-btn>

    </v-card-actions>
  </v-card>
    </form-wrapper>
  </v-dialog>
</template>

<script>
import rulesConstants from "@/assets/constants/rules.constants";
import InputField from "../ui/form/InputField.vue";
import openaiMixin from "@/mixins/openai.mixin";
import Textarea from "../ui/form/Textarea.vue";
import FormWrapper from "../ui/form/FormWrapper.vue";
import AiTextarea from "../ui/form/AiTextarea.vue";
import AiInput from "../ui/form/AiInput.vue";
export default {
  components: { InputField, Textarea, FormWrapper,AiTextarea,AiInput },
  data() {
    return {
      openaiContents: [],
      steps: 1,
      dialog: false,
      loading: false,
      rules: [rulesConstants.required],
      form: {
        tone: "",
        textContent: "",
        maxSuggestions: 2,
        maxChars: 256,
        withEmoji: false,
        language: "",
      },
    };
  },
  mixins: [openaiMixin],
  computed: {
    title() {
      let title = "";
      if (this.steps == 1) {
        title = "Generate post for an article / text";
      } else {
        title = "AI generated post";
      }
      return title;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false; 
    },
    upgradeAndCloseDialog() {
    this.dialog = false; // Close the current dialog
    // Navigate to the subscription page
    this.$router.push('/subscription');
  },
    copyLink(item) {
      navigator.clipboard.writeText(item);
      this.$snackbar.show("Content Copied.");
    },
    selectCaption(item) {
      this.dialog = false;
      this.steps = 1;
      this.form = {
        tone: "",
        textContent: "",
        maxSuggestions: 2,
        withEmoji: false,
        maxChars: 256,
        language: "",
      };
      this.openaiContents = [];
      this.$emit("onSummarisePost", item);
    },
    handleDialogClose() {
      this.dialog = false;
      this.steps = 1;
      this.form = {
        tone: "",
        textContent: "",
        maxSuggestions: 2,
        withEmoji: false,
        maxChars: 256,
        language: "",
      };
      this.openaiContents = [];
    },
    submit() {
      this.$mixpanel.track("Shorthand2 Done");
      this.summarisePost();
    },
    trackMixpanel() {
      this.$mixpanel.track("Shorthand2 Initiated");
    }
  },
  mounted() {
    this.trackMixpanel();
  }
};
</script>

<style></style>
