<template>
  <v-menu offset-y v-model="menu"  rounded="3xl">
    <template v-slot:activator="{ on }">
      <v-btn depressed outlined color="#8056DE" class="mb-3" v-on="on">

        Shorthands
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list class="py-4 flex flex-col p-1 md:p-4" width="330px" >
      <div class="text-neutral-900 text-base font-medium font-['Poppins']">Shorthands</div>
      <generate-post-from-url
        @updatePostContent="
          $emit('updatePostContent', $event);
          menu = false;
        "
      />
      <openai-summarise
        @onSummarisePost="
          $emit('onSummarisePost', $event);
          menu = false;
        "
      />

      <openai-post-ideas @insertPostIdea=" $emit('insertPostIdea', $event);"/>
      <!-- <openai-image @addGeneratedImage="$emit('addGeneratedImage', $event);" @uploader="$emit('uploader', $event);"/> -->
    </v-list>
  </v-menu>
</template>

<script>
import GeneratePostFromUrl from "./GeneratePostFromUrl.vue";
import OpenaiSummarise from "./OpenaiSummarise.vue";
import OpenaiPostIdeas from "./OpenaiPostIdeas.vue";
import OpenaiImage from "./OpenaiImage.vue";
export default {
  components: { GeneratePostFromUrl, OpenaiSummarise, OpenaiPostIdeas, OpenaiImage },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style></style>
