<template>
  <v-card outlined class="mb-3">
    <v-card-text>
      <div
        class="subtitle-1 black--text font-weight-medium d-flex align-center"
      >
        <v-img :src="platform.logo_url" max-width="25px" class="mr-2"></v-img>
        TikTok Settings
      </div>
      <div class="py-3 px-7">
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <select-field
              label="User"
              @input="$emit('input', form)"
              v-model="form.tt_users"
              :items="users"
            ></select-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              label="Caption"
              outlined
              @input="$emit('input', form)"
              v-model="post.name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <select-field
              label="Privacy"
              @input="$emit('input', form)"
              v-model="form.tiktok_privacy"
              :items="privacy"
            ></select-field>
          </v-col>
        </v-row>
        <div class="flex items-center w-[450px]">
          <div class="flex items-center gap-1">
            <input
              v-model="form.tiktok_comment"
              type="checkbox"
              class="cursor-pointer"
            />
            <p class="font-medium">Comment</p>
          </div>
          <div class="flex items-center gap-1">
            <input
              v-model="form.tiktok_duet"
              type="checkbox"
              class="cursor-pointer"
            />
            <p class="font-medium">Duet</p>
          </div>
          <div class="flex items-center gap-1">
            <input
              v-model="form.tiktok_stitch"
              type="checkbox"
              class="cursor-pointer"
            />
            <p class="font-medium">Stitch</p>
          </div>
        </div>
        <div class="flex items-center justify-between mt-6 w-[450px] pr-14">
          <h1 class="font-bold">Disclose Video Content</h1>

          <label class="inline-flex items-center cursor-pointer">
            <input
              @click="discloseContent"
              type="checkbox"
              value=""
              class="sr-only peer"
            />
            <div
              class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#8056DE]"
            ></div>
          </label>
        </div>
        <p class="w-[370px] font-medium mt-6">
          Turn on to disclose that this video promotes goods or services in
          exchange for something of value.
        </p>

        <div v-if="showDiscloseContent">
          <div>
            <div class="flex items-center justify-between mt-6 w-[450px] pr-14">
              <h1 class="font-bold">Your brand</h1>

              <input
                v-model="form.tiktok_your_brand"
                type="checkbox"
                class="cursor-pointer"
              />
            </div>
            <p class="w-[370px] font-medium mt-2">
              You are promoting yourself or your own business. This video will
              be classified as a Brand Organic.
            </p>
          </div>
          <div>
            <div class="flex items-center justify-between mt-6 w-[450px] pr-14">
              <h1 class="font-bold">Brand content</h1>

              <input
                v-model="form.tiktok_brand_content"
                type="checkbox"
                class="cursor-pointer"
              />
            </div>
            <p class="w-[370px] font-medium mt-2">
              You are promoting another brand or a third-party. This video will
              be classified as a Branded Content.
            </p>
          </div>
          <p class="mt-2">
            By posting, you agree to our
            <a
              href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en#:~:text=You%20confirm%20that%20either%20(a,post%20on%20the%20TikTok%20platform."
              target="_blank"
              class="text-[] font-normal"
              style="color: #8056de !important"
              >Music Usage Confirmation</a
            >
          </p>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SelectField from "@/components/ui/form/SelectField.vue";
import Autocomplete from "@/components/ui/form/Autocomplete.vue";

export default {
  components: { SelectField, Autocomplete },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    users: Array,
    privacy: Array,
    configuration: {
      type: Object,
    },
    post: Object,
    isEditing: Boolean,
    isRepost: Boolean,
    isDraftPost: Boolean,
  },
  data() {
    return {
      showDiscloseContent: false,
      tiktok_privacy: [],

      form: {
        tt_users: [],
        tiktok_privacy: [],
        tiktok_comment: false,
        name: "",
        tiktok_duet: false,
        tiktok_stitch: false,
        tiktok_your_brand: false,
        tiktok_brand_content: false,
      },
    };
  },
  computed: {
    platform() {
      let platforms = this.$store.getters.flyPlatforms;
      let platform = platforms.find((i) => i.alias == "tiktok");
      return platform || {};
    },
  },
  methods: {
    initForm() {
      Object.keys(this.form).forEach((key) => {
        if (this.value[key] !== undefined) {
          this.form[key] = this.value[key];
        }
      });
      if (this.users && this.users.length === 1) {
        console.log(this.tt_userss);
        this.form.tt_users = this.users[0];
        this.form.tiktok_privacy = this.privacy[this.privacy?.length - 1];
        this.$emit("input", this.form);
      }
    },
    updateConfiguration() {
      this.form.tt_users = this.configuration.tt_users;
      this.form.tiktok_privacy = this.configuration.tiktok_privacy;
      this.form.tiktok_comment = this.configuration.tiktok_comment;
      this.form.tiktok_duet = this.configuration.tiktok_duet;
      this.form.tiktok_stitch = this.configuration.tiktok_stitch;
      this.form.tiktok_your_brand = this.configuration.tiktok_your_brand;
      this.form.tiktok_brand_content = this.configuration.tiktok_brand_content;
      this.form.name = this.configuration.name;
    },
    discloseContent() {
      this.showDiscloseContent = !this.showDiscloseContent;
    },
  },
  mounted() {},
  beforeMount() {
    this.initForm();
    if (this.isEditing || this.isRepost || this.isDraftPost) {
      this.updateConfiguration();

      if (
        this.configuration.tiktok_your_brand ||
        this.configuration.tiktok_brand_content
      ) {
        this.showDiscloseContent = true;
      }
    }
  },
};
</script>

<style>
/* Add any specific styling for your TikTok configuration component here */
</style>
