<template>
  <div v-if="isActiveDomain && item.alias !== 'around'">
    <v-list-item>
      <v-list-item-avatar tile>
        <img width="48" height="48" :src="item.logo_url" :alt="item.name" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <template v-if="!isHelpCMS">
          <v-list-item-subtitle v-if="item.connection?.connection_status == 'in progress'">
            Connecting...
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="isManualPosting">
            Manual posting | <br class="d-sm-none" />Automation coming soon
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="connectionName" class="d-flex align-center">
  <img width="24" height="24" src="https://img.icons8.com/material-rounded/24/92d500/checkmark--v1.png" alt="checkmark--v1"/>
  Connected As: <br class="d-sm-none" />
  <span class="ml-1 connection-name">{{ connectionName }}</span>
</v-list-item-subtitle>

          <v-list-item-subtitle v-else>{{ platform }}</v-list-item-subtitle>
          <v-list-item-subtitle>
            <span v-if="expiryDate">
              Connection Expires on <br class="d-sm-none" /> {{ expiryDate }}
            </span>
          </v-list-item-subtitle>
        </template>
      </v-list-item-content>
      <v-list-item-action class="d-flex flex-row align-center">
        <v-btn
          icon
          v-if="isHelpCMS"
          @click="$router.push(`/update-help-cms/${item.id}`)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <template v-else>
          <div class="grow" v-if="platform == 'Connection required'">
            <v-btn
              depressed
              outlined
              color="#8050DE"
              height="30px"
              class="py-[-1px] px-2"
              @click="openConnectionDialog"
            >
              <span class="text-xs">Connect Now</span>
            </v-btn>
          </div>

          <tool-tip message="Disconnect">
            <v-btn
              icon
              v-if="item.connection"
              :loading="isDisconnectLoading"
              @onDisconnect="$emit('onDisconnect')"
              @click="disconnectConnection(item.alias, profile)"
              class="mr-3"
              color="red"
            >
              <v-icon>mdi-connection</v-icon>
            </v-btn>
          </tool-tip>

          <!-- <tool-tip v-if="checkPlatformAvailability(item.alias)" message="Automate on Social Broadcast">
            <v-btn
              icon
              v-if="item.connection"
              @click="showSocialBroadcast = true;"
              color="blue"
              class="mr-3"
            >
              <v-icon>mdi-cast</v-icon>
            </v-btn>
          </tool-tip> -->
          <connection-info-dialog ref="connectionInfoDialog" :item="item" :profile="profile" />
        </template>
      </v-list-item-action>
    </v-list-item>
    <v-divider v-if="!isLast"></v-divider>
      <v-dialog v-model="showSocialBroadcast" persistent max-width="500" >
        <v-card v-if="!proceedWithBroadcast">
          <!-- Close Icon -->
          <v-card-title class="d-flex justify-between align-center">
            <span class="headline">Automate Your Posts</span>
            <v-icon @click="showSocialBroadcast = false" class="cursor-pointer -mt-5">mdi-close</v-icon>
          </v-card-title>

          <!-- Dialog Message -->
          <v-card-text>
            <p>
              This feature enables you to automatically broadcast your post from current platform to across all connected platforms, ensuring your content reaches more audiences with minimal effort.
            </p>
          </v-card-text>

          <!-- Actions -->
          <v-card-actions class="justify-end">
            <v-btn text color="grey" @click="showSocialBroadcast = false">Cancel</v-btn>
            <v-btn color="primary" @click="proceedWithBroadcast = true">Proceed</v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="proceedWithBroadcast" max-height="80%">
          <!-- Close Icon and Title -->
          <v-card-title class="d-flex justify-between align-center">
            <span v-if="checkIfEnabled" class="headline">Disable Social Broadcasting</span>
            <span v-else class="headline">Automate Your Posts</span>
            <v-icon @click="closeBroadcastDialog" class="cursor-pointer">mdi-close</v-icon>
          </v-card-title>

          <!-- Dialog Message -->
          <v-card-text>
            <p v-if="checkIfEnabled">
              You will be unsubscribed from the social broadcasting feature, no longer automated updation will happen
            </p>
            <p v-else>
              Select the platforms where you'd like to automate your post broadcasts. Once enabled, your content will be published across the chosen platforms simultaneously.
            </p>

            <!-- Multi-select for Platforms -->
            <div class="m-auto font-semibold" v-for="(profile, index) in filteredPlatformOptions" :key="index">
              <div class="flex justify-between items-center max-h-20">
                <!-- Parent Autocomplete for profiles with children -->
                <v-combobox v-if="profile.children?.length" v-model="profile.selectedProfiles" :items="profile.children" :label="profile.name" item-text="name" class="mt-4 " :persistent-placeholder="false"
                  item-value="value" return-object dense :multiple="!checkMultiPlatform(profile.name)" :disabled="checkIfEnabled" chips deletable-chips >
                  <template v-if="!checkMultiPlatform(profile.name)" v-slot:selection="{ item, index }">
                    <span v-if="index < maxDisplay">{{ item.name }},</span>
                    <span  v-if="index==maxDisplay" class="text-blue-600" @click="maxDisplay=profile.selectedProfiles?.length">(+{{ profile.selectedProfiles?.length - maxDisplay }} more)</span>
                  </template>
                </v-combobox>
                <!-- Simple checkbox for profiles without children -->
                <v-checkbox
                  v-else
                  v-model="profile.checked"
                  :label="profile.name"
                  :disabled="checkIfEnabled"
                />

                <!-- 'Select All' or 'Deselect All' for sub-options -->
                <p v-if="profile.children && profile.children.length && !checkIfEnabled &&!checkMultiPlatform(profile.name) " 
                  class="cursor-pointer text-blue-600" 
                  @click="toggleAllChildren(profile)" >
                  {{ profile.children?.length===profile.selectedProfiles?.length ? 'Deselect All' : 'Select All' }}
                </p>
              </div>
            </div>

          </v-card-text>
          <!-- Actions -->
          <v-card-actions class="justify-end">
            <v-btn text color="grey" @click="closeBroadcastDialog">Cancel</v-btn>
            <v-btn :color="checkIfEnabled?`error`:`primary`" @click="enableBroadcast" :disabled="!checkSelected">{{checkIfEnabled?"Disable":"Enable"}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="messageDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline">{{ messageTitle }}</v-card-title>
        <v-card-text><strong>{{ messageContent }}</strong></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="messageDialog= false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>



<script>
import connectedAppsMixins from "@/mixins/connected.apps.mixins";
import { auth } from "@/utils/firebase.utils";
import ConnectionInfoDialog from "./ConnectionInfoDialog.vue";
import ToolTip from "../ui/ToolTip.vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import "hopscotch/dist/css/hopscotch.min.css"; // Import Hopscotch CSS
import hopscotch from "hopscotch";
export default {
  components: { ConnectionInfoDialog, ToolTip },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: String,
      default: "personal",
    },
    allConnectedProfiles:{
      type:Array,
      default:[],
    },
  },
  data() {
    return {
      showDisconnectModal: false,
      isLoading: false,
      messageDialog:false,
      messageTitle:"",
      messageContent:"",
      showSocialBroadcast:false,
      proceedWithBroadcast:false,
      availablePlatforms: ['youtube'],  
      isDisconnectLoading: false,
      expiryDate: "",
      maxDisplay:2,
      show: false,
      singlePagePlatforms : ['tumblr','reddit','pinterest'],
      filteredPlatformOptions:[],
    };
  },
  async created() {
    let uid,wid;
    wid = this.WID
    if(this.$route.name=='store-invitation-accepted'){
      uid = this.$route.query.uid
      wid = this.$route.query.wid
    }
    const expiryDate = await this.connectedHandle(this.item.alias, wid,uid);
    this.expiryDate = expiryDate;
    this.filteredPlatformOptions = this.allConnectedProfiles
      .filter((data) => data.name.toLocaleLowerCase() !== this.item.alias)
      .map((data) => ({
        ...data,
        checked: false,  // Add a default checked field for each platform
        selectedProfiles:this.checkMultiPlatform(data.name)?null:[]
      }));
  },
  computed: {
    checkSelected(){
      let value = false;
      this.filteredPlatformOptions.map((item)=>{
        if((Array.isArray(item.selectedProfiles) && item.selectedProfiles.length)||item.checked||(!Array.isArray(item.selectedProfiles) && item.selectedProfiles)){
          value = true;
        }

      })
      return value;
    },
    checkIfEnabled(){
      if(this.item?.connection?.socialBroadcast){
        this.filteredPlatformOptions = [];
        this.item?.connection?.socialBroadcast.map((data) => {
          const obj={
            name:data.name,
            children:data.pages,
            selectedProfiles:this.checkMultiPlatform(data.name)?data.pages[0]:data.pages,
            checked:true,
          }
          this.filteredPlatformOptions.push(obj);
        });
        return true;
      }
      return false;
    },
    isHelpCMS() {
      return this.$route.name == "help-cms";
    },
    platform() {
      // if (this.item.alias == "around") return "Connected";
      if (this.item.connection) return "Reconnect";
      if (this.isActiveDomain) return "Connection required";
      return "Coming Soon";
    },
    isActiveDomain() {
      const currentHostname = window.location.hostname;
      return this.item.active_domains?.some(
        (domain) => new URL(domain).hostname === currentHostname
      );
    },
    igUsers() {
      if (this.item.connection.id == "instagram") {
        if (
          this.item.connection.users &&
          (this.item.connection.users.length == 0 ||
          !this.item.connection.users[0])
        ) {
          // console.log('this.item.connect.name:', this.item.connection)
          return false;
        }
        return true;
      }
      return true;
    },
    connectionName() {
      if (this.item.type === "marketplace") {
        if (this.item.connection && this.item.connection.phone_number) {
          return this.item?.connection?.phone_number || '';
        }
      }

      //if (this.item.alias == "around") return name;
      if (
        this.item.connection &&
        this.item.connection.name &&
        this.item.connection.name.length > 0
      )
      return this.item.connection.name;
      if (
        this.item.connection &&
        this.item.connection.user_name &&
        this.item.connection.user_name.length > 0
      )
      return this.item.connection.user_name;
      return null;
    },
    isManualPosting() {
      let whitelist = ["Whatsapp"];
      return whitelist.indexOf(this.item.name) != -1;
    },
  },
  mixins: [connectedAppsMixins],
  methods: {
    checkMultiPlatform(name){
      if(this.singlePagePlatforms.includes(name)) return true;
      return false;
    },
    formatSelectedPlat(){
      const platforms = [];
      this.filteredPlatformOptions.map((item)=>{
        if(item.checked||(Array.isArray(item.selectedProfiles)&&item.selectedProfiles.length)||(!Array.isArray(item.selectedProfiles)&&item.selectedProfiles)){
          let selectedP;
          if(Array.isArray(item.selectedProfiles)){
            selectedP = item.selectedProfiles
          }
          else{
            selectedP=[];
            selectedP.push(item.selectedProfiles);
          }
          const obj ={
            name:item.name,
            pages:selectedP
          }
          platforms.push(obj);
        }
        item.selectedProfiles=this.checkMultiPlatform(item.name)?null:[];
        item.checked=false;
      })
      return platforms;
    },
    trimtext(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    toggleAllChildren(profile) {
      if(profile.selectedProfiles.length===profile.children.length){
        profile.selectedProfiles = [];
      }
      else{
        profile.selectedProfiles = profile.children;
      }
      return ;
    },

    checkPlatformAvailability(item){
      return this.availablePlatforms.includes(item);
    },
    showMessageDialog(title, message) {
      this.messageTitle = title;
      this.messageContent = message;
      this.messageDialog = true;
    },
    async enableBroadcast(){
      return ;
    },
    closeBroadcastDialog(){
      this.proceedWithBroadcast = false;
      this.showSocialBroadcast = false;  
      this.maxDisplay = 2;
    },
  openConnectionDialog() {
    this.$refs.connectionInfoDialog.dialog = true;
  },
  handleButtonClick(item, profile) {
    if (item.type === "marketplace") {
      if (item.name === "Olx" || item.name === "Quickr") {
        this.showTermsDialog = true; // This will show the terms dialog before proceeding
      }
    } else {
      if(this.$route?.name == 'accept-invite') {
          profile = 'location';
      }
      this.initConnection(item.alias, profile); // Proceed with the normal flow for other types
    }
  },
    shouldShowItem(item) {
      // List of restricted aliases
      const restrictedAliases = ["olx", "quickr"];
      // Check if the item's alias is restricted and if the phone number starts with "+91"
      if (restrictedAliases.includes(item.alias)) {
        return this.authUser.country_code === "IN";
      }
      // If the item's alias is not restricted, always show it
      return true;
    },
    showDisconnectConfirmation() {
      this.$mixpanel.track("Disconnect App Initiated");
      this.showDisconnectModal = true;
    },

    // Method to hide the disconnect modal
    hideDisconnectModal() {
      this.showDisconnectModal = false;
    },

    // Method to handle disconnection confirmed
    disconnectConnectionConfirmed() {
      const disconnection = true;
      if(this.checkPlatformAvailability(item.alias)){
        this.enableBroadcast();
      }
      this.$mixpanel.track("Disconnected App Successfully");
      this.disconnectConnection(this.item.alias, this.profile);
      this.hideDisconnectModal();
    },
    openVideo({ intro_video }) {
      window.open(intro_video, "_blank");
    },
  },
};
</script>

<style></style>

<style scoped>
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.connection-name {
  white-space: normal;
  word-wrap: break-word;
}

</style>
