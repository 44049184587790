<template>
  <v-dialog v-model="toneDialog" max-width="800px" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <!-- <tool-tip message="Add Video"> -->
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
        :icon="btnText ? false : true"
        :outlined="btnText ? true : false"
      >
        <img src="@/assets/img/chtone.svg" alt="translate" width="25px" />
        <span v-if="btnText">{{ btnText }}</span>

        <!-- <span class="d-none d-sm-flex"> Change Tone </span> -->
      </v-btn>
      <!-- </tool-tip> -->
    </template>
    <form-wrapper
      ref="form"
      @submit="submit()"
      v-if="currentUser.fly_ai_text_credits > 0"
    >
      <v-card class="pa-2" v-if="currentUser.fly_ai_text_credits > 0">
        <v-card-title class="justify-between pt-3 pb-3 primary--text d-flex">
          <div
            class="items-center gap-2 d-flex"
            :class="!isSmallAndUp ? 'w-[77%]' : 'w-[80%]'"
          >
            <v-btn icon depressed v-if="steps == '2'" @click="steps = 1"
              ><v-icon>mdi-keyboard-backspace</v-icon></v-btn
            >
            <img
              src="../../assets/img/Ai .svg"
              :width="isSmallAndUp ? '30px' : '25px'"
              :height="isSmallAndUp ? '30px' : '25px'"
              max-height="100%"
            />
            <span
              :class="!isSmallAndUp ? 'text-base' : ''"
              class="black--text"
              >{{ title }}</span
            >
          </div>
          <!-- <v-spacer /> -->
          <v-btn
            icon
            color="black"
            @click="handleDialogClose()"
            :disabled="isToneLoading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-stepper v-model="steps" flat>
            <v-stepper-items>
              <v-stepper-content class="pt-2" step="1">
                <v-row>
                  <v-col cols="12" md="6" class="pb-0">
                    <v-select
                      label="Select Tone"
                      :items="tones"
                      class="mt-3 w-[293px] h-12 bg-slate-100 rounded-3xl px-3 pt-3"
                      :rules="rules"
                      color="#8056DE"
                      dense
                      item-text="name"
                      v-model="toneForm.tone"
                      item-value="name"
                    >
                      <template v-slot:item="{ item, on }">
                        <v-list-item v-on="on">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.description
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <v-select
                      label="Select Language (Optional)"
                      :items="languages"
                      class="mt-3 w-[293px] h-12 bg-slate-100 rounded-3xl px-3 pt-3"
                      color="#8056DE"
                      dense
                      item-text="englishName"
                      v-model="toneForm.language"
                      item-value="englishName"
                    >
                      <template v-slot:item="{ item, on }">
                        <v-list-item v-on="on">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.englishName
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.localName
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="">
                    <v-checkbox
                      v-model="toneForm.withEmoji"
                      class="mt-1 w-[170px] h-12 bg-slate-100 rounded-3xl px-2 pt-3"
                      label="With Emoji"
                    />
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content class="pt-2" step="2">
                <template v-if="openaiContents.length">
                  <v-row class="justify-center mx-auto d-flex">
                    <template v-for="(item, i) in openaiContents">
                      <v-col cols="12" :key="i">
                        <div
                          class="flex-col w-full gap-4 p-4 mb-2 border rounded-md d-flex"
                        >
                          <span class="black--text">
                            {{ item }}
                          </span>
                          <div
                            class="flex-row flex-wrap justify-between w-full gap-2 d-flex"
                          >
                            <v-btn
                              @click="copyLink(item)"
                              height="30px"
                              outlined
                              color="primary"
                            >
                              Copy
                            </v-btn>
                            <v-btn
                              @click="selectCaption(item)"
                              outlined
                              color="primary"
                              height="30px"
                            >
                              Add to editor
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </template>
                  </v-row>
                </template>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>

        <v-card-actions class="pt-4 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            :loading="isToneLoading"
            text
            v-if="steps == '1'"
            class="w-[139.30px] mt-3 py-5 h-12 bg-violet-500 rounded-xl"
            color="white"
            type="submit"
            >Generate</v-btn
          >
          <v-btn text color="primary" v-show="steps == '2'" @click="steps = 1"
            >Back</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="pa-2" v-else>
        <v-card-title>
          You have run out of Fly AI Writer credits. Please renew or upgrade
          to<br />proceed.
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="upgradeAndCloseDialog()"
            >Upgrade</v-btn
          >
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </form-wrapper>
    <v-card class="pa-2" v-else>
      <v-card-title>
        You have run out of Fly AI Writer credits. Please renew or upgrade to
        <br />proceed.
      </v-card-title>
      <v-card-actions>
        <v-btn color="primary" @click="upgradeAndCloseDialog()">Upgrade</v-btn>
        <v-btn text @click="closeDialog">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rulesConstants from "@/assets/constants/rules.constants";
import DialogCard from "../ui/DialogCard.vue";
import openaiMixin from "@/mixins/openai.mixin";
import FormWrapper from "../ui/form/FormWrapper.vue";

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    btnText: {
      type: String,
      default: "",
    },
    platformEditors: Object,
    activeItem: String,
  },
  components: { DialogCard, FormWrapper },
  data() {
    return {
      openaiContents: [],
      steps: 1,
      toneDialog: false,
      isToneLoading: false,
      rules: [rulesConstants.required],
      toneForm: {
        tone: "",
        language: "",
        withEmoji: false,
      },
    };
  },
  mixins: [openaiMixin],
  computed: {
    title() {
      let title = "";
      if (this.steps == 1) {
        title = "Change Tone";
      } else {
        title = "AI generated changed tone";
      }
      return title;
    },
  },
  methods: {
    closeDialog() {
      this.toneDialog = false;
    },
    copyLink(item) {
      navigator.clipboard.writeText(item);
      this.$snackbar.show("Content Copied.");
    },
    selectCaption(item) {
      this.toneForm = {
        tone: "",
        language: "",
        withEmoji: false,
      };
      this.steps = 1;
      this.openaiContents = [];
      this.toneDialog = false;
      this.$emit("updatePost", item);
    },
    handleDialogClose() {
      this.toneDialog = false;
      this.steps = 1;
      this.toneForm = {
        tone: "",
        language: "",
        withEmoji: false,
      };
      this.openaiContents = [];
    },
    submit() {
      this.changePostTone();
    },
    upgradeAndCloseDialog() {
      this.$router.push("/subscription");
    },
  },
};
</script>

<style>
.v-messages__message {
  color: rgb(225, 16, 16) !important;
  font-size: 12px !important;
  display: inline;
}
</style>
