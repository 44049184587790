<template>
  <div>
    <!-- <div class="flex items-start justify-center"> -->
      <!-- <div class="w-full h-[280px] bg-white rounded-3xl outline p-4  max-h-[550px]"> -->
        <div class="w-full h-[280px] bg-white rounded-3xl outline p-4  max-h-[550px] flex flex-col">
          <div v-if="carousels.length" class="w-full flex flex-col justify-between gap-1">
            <v-carousel hide-delimiters v-model="carousel"
              class="mt-1 w-full rounded-lg carousel-desktop-width items-center justify-center mx-auto"
              :show-arrows="carousels.length > 1" :height="isMobile ? '120px' : '80px'">
              <v-carousel-item v-for="(item, index) in carousels" :key="index">
                <div class="h-full d-flex align-center items-center">
                  <v-img v-if="item.type == 'image'" :src="item.src" style="object-fit: cover"
                    :height="isMobile ? '120px' : '90px'" />
                  <video-auto-play v-if="item.type == 'video'" :autoplay="false" :videoSrc="item.src"
                    style="height: 120px; object-fit: cover; width: 100%" />
                </div>
              </v-carousel-item>
            </v-carousel>
            <div
              class="md w-full text-xs font-normal text-justify text-slate-500 mt-2 md:mt-0 md:ml-4 mx-auto px-2 mb-2">
              <span class="max-w-[95%]">
                {{ showFullText ? post.text : shortText }}
              </span>
              <button v-if="post.text.length > 20" @click="showFullText = !showFullText" class="text-xs text-blue-600">
                {{ showFullText ? "Show Less" : "Show More" }}
              </button>
              <div class="text-slate-500 mt-2">
                {{ formatHashtags(post.hashtags) }}
              </div>
              <div>
                <a :href="post.post_url" target="_blank" class="text-blue-500 break-all">{{ post.post_url }}</a>
              </div>
              <div class="flex flex-wrap items-center justify-between w-full mt-2">
                {{ formatDateDDMMYYYY(post.created_at) }}
              </div>
            </div>
            <div class="my-2">
              <v-btn depressed outlined @click="publishCrossPost(post)">Cross Post</v-btn>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import {
  auth,
  colUsers,
  colScheduledPostsDelete,
  colSocialPostsDelete,
} from "@/utils/firebase.utils";
import VideoAutoPlay from "@/components/public/VideoAutoPlay.vue";
import PostOutput from "@/components/post/PostOutput.vue";
import { deleteDoc, doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import { formatDateDDMMYYYY } from "@/utils/common.utils";
export default {
  components: { VideoAutoPlay, PostOutput },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    openOutput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFullText: false,
      carousel: 0,
      loading: false,
      postId: this.$route.params.id,
    };
  },

  computed: {
    checkUID() {
      if (
        auth.currentUser.uid == this.currentUID ||
        (this.post.post_status == "draft" &&
          this.post.postBy == auth.currentUser.uid)
      )
        return true;
      else return false;
    },
    shortText() {
      const maxLength = 100; // Max length of visible text when not expanded
      if (this.post.text && this.post.text.length > maxLength) {
        return this.post.text.substring(0, maxLength) + "...";
      }
      return this.post.text;
    },
    carousels() {
      let list = [];
      if (this.post.videos) {
        this.post.videos.forEach((item) => {
          list.push({
            src: item.url || item,
            type: "video",
          });
        });
      }
      if (this.post.images) {
        this.post.images.forEach((item) => {
          list.push({
            src: item.thumbnail || item.url || item,
            type: "image",
          });
        });
      }
      return list;
    },
    hasIg() {
      return (
        this.post.social_media?.includes("instagram") ||
        this.post.social_media?.some((social) => social.name === "instagram")
      );
    },
    hasTh() {
      return (
        this.post.social_media?.includes("threads") ||
        this.post.social_media?.some((social) => social.name === "threads")
      );
    },
  },
  methods: {
    async publishCrossPost(post) {
      this.$emit('closeDialog')
      this.$snackbar.show("Your post has been submitted for cross posting. We'll notify you once published.")
      let addSocialPost = httpsCallable(functions, "instagramCrossPostsPublish");
      let res = (await addSocialPost(post)).data;
    },
    formatHashtags(tags) {
      if (tags) {
        return tags
          .map((i) => {
            if (i.toString().startsWith("#")) return i;
            return `#${i}`;
          })
          .map((i) => i.split(" ").join(""))
          .toString()
          .split(",")
          .join(" ");
      }
      return "";
    },
  },
};
</script>

<style scoped>
.status-published {
  color: #8056de;
}

.status-deleted {
  color: red;
}

.status-scheduled {
  color: green;
}

.status-draft {
  color: orange;
}

.status-container {
  display: flex;
  justify-content: flex-end;
  /* Aligns children (status spans) to the right */
  width: 100%;
  /* Ensures the div takes full width */
}

@media (min-width: 1024px) {
  .carousel-desktop-width {
    width: 90%;
  }

  .images {
    height: 90%;
  }
}

@media (min-width: 768px) {
  .md\:w-1\/6 {
    width: 9.666667%;
  }
}

@media (min-width: 768px) {

  .v-carousel .v-window__prev,
  .v-carousel .v-window__next {
    width: 24px;
    /* Adjust width as needed */
    height: 24px;
    /* Adjust height as needed */
    margin: 0 1px;
    /* Add 1px horizontal margin */
  }

  .v-carousel .v-window__prev .v-icon,
  .v-carousel .v-window__next .v-icon {
    font-size: 18px !important;
    margin-bottom: 10px !important;
    margin-right: 9px !important;
  }
}

.eye-icon {
  font-size: initial;
}
</style>